import jpgIcon from "../assets/img/brand/jpg_icon.png";
import docIcon from "../assets/img/brand/doc_icon.png";
import pngIcon from "../assets/img/brand/png_icon.png";
import pdfIcon from "../assets/img/brand/pdf_icon.png";
import Government from "../assets/img/brand/government.png";
import Firm from "../assets/img/brand/firm.png";

const userRoles = {
  ADMIN: 1,
  GOVT: 2,
  GOVT_FIRMS: 3,
  FIRMS: 4
};

export default userRoles;

export const getUserRoleName = (role) => {
  switch (role) {
    case userRoles.ADMIN:
      return "Admin";
    case userRoles.GOVT:
      return "Government";
    case userRoles.GOVT_FIRMS:
      return "Government Firm";
    case userRoles.FIRMS:
      return "Firm";
    default:
      return "Unknown";
  }
};

export const assessmentSectionTitles = {
  1: "Financials",
  2: "Business Development",
  3: "Operations",
  4: "Insurance",
  5: "Credit",
  6: "Bonding"
};

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const getFileType = (file) => {
  switch (file) {
    case "jpg":
      return jpgIcon;
    case "png":
      return pngIcon;
    case "doc":
      return docIcon;
    case "pdf":
      return pdfIcon;
    default:
      return docIcon;
  }
};

export const getFile = (file) => {
  switch (file) {
    case 3:
      return Government;
    case 4:
      return Firm;
    default:
      return docIcon;
  }
};

export const industriesData = [
  "Agriculture, Forestry, Fishing and Hunting",
  "Mining, Quarrying, and Oil and Gas Extraction",
  "Utilities",
  "Construction",
  "Manufacturing",
  "Wholesale Trade",
  "Retail Trade",
  "Transportation and Warehousing",
  "Information",
  "Finance and Insurance",
  "Real Estate and Rental and Leasing",
  "Professional, Scientific, and Technical Services",
  "Management of Companies and Enterprises",
  "Administrative and Support and Waste Management and Remediation Services",
  "Educational Services",
  "Health Care and Social Assistance",
  "Arts, Entertainment, and Recreation",
  "Accommodation and Food Services",
  "Other Services (except Public Administration)",
  "Public Administration (not covered in economic census)"
];

export function getFormattedContact(phoneNumberString) {
  var cleaned = ("" + phoneNumberString)?.replace(/\D/g, "");
  var match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export function getCompanyRiskBadgeClass(riskLevel) {
  switch (riskLevel) {
    case "High":
      return "company_high_risk_badge";
    case "Medium":
      return "company_medium_risk_badge";
    case "Low":
      return "company_low_risk_badge";
    default:
      return "";
  }
}

export const capitalize = (s) => {
  return s[0].toUpperCase() + s.slice(1);
};
