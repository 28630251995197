import axiosInstance from "./axiosInstance";

export const getGovtListApi = () => {
  return axiosInstance.get("/api/v1/admin/govts");
};

export const postDataMigrationApi = (data) => {
  return axiosInstance.post("/api/v1/admin/seed/firms", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*"
    }
  });
};
