import toast from "react-hot-toast";
import { deleteFileManagerApi } from "services/fileManagerService";
import { getFileManagerListingApi } from "services/fileManagerService";
import { postFileManagerApi } from "services/fileManagerService";

import {
  FETCH_FILES_DATA_REQUEST,
  FETCH_FILES_DATA_SUCCESS,
  FETCH_FILES_DATA_FAILURE
} from "./types";

export const fetchFilesRequest = () => {
  return {
    type: FETCH_FILES_DATA_REQUEST
  };
};

export const fetchFilesSuccess = (data) => {
  return {
    type: FETCH_FILES_DATA_SUCCESS,
    payload: { data }
  };
};

export const fetchFilesFailure = (error) => {
  return {
    type: FETCH_FILES_DATA_FAILURE,
    payload: error
  };
};

export const fetchFileListingThunkAction = (reqParams, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchFilesRequest());
      const response = await getFileManagerListingApi(reqParams);

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      dispatch(fetchFilesSuccess(response.data.data.userFiles));
      onSuccess();
    } catch (error) {
      dispatch(fetchFilesFailure(error?.response?.data?.message || error?.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const postFileManagerThunkAction = (postData, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      const promise = postFileManagerApi(postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "File uploaded successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const response = await promise;

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      onSuccess();
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
};

export const deleteFileManagerThunkAction = (postData, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      const promise = deleteFileManagerApi(postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Files deleted successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const response = await promise;

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      onSuccess();
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
};
