import {
  FETCH_SUBSCRIPTION_PLAN_FAILURE,
  FETCH_SUBSCRIPTION_PLAN_REQUEST,
  FETCH_SUBSCRIPTION_PLAN_SUCCESS,
  FETCH_CURRENT_PLAN_FAILURE,
  FETCH_CURRENT_PLAN_SUCCESS,
  FETCH_CURRENT_PLAN_REQUEST,
  FETCH_BILLING_HISTORY_FAILURE,
  FETCH_BILLING_HISTORY_SUCCESS,
  FETCH_BILLING_HISTORY_REQUEST,
  FETCH_USER_PLAN_LIST_FAILURE,
  FETCH_USER_PLAN_LIST_SUCCESS,
  FETCH_USER_PLAN_LIST_REQUEST
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  Planslist: [],
  BillingHistoryList: [],
  UserPlanLists: [],
  PlanDetail: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Planslist: action.payload.Planslist,
        error: ""
      };
    case FETCH_SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        Planslist: [],
        error: action.payload
      };
    case FETCH_CURRENT_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_CURRENT_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PlanDetail: action.payload.Plandetail,
        error: ""
      };
    case FETCH_CURRENT_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        PlanDetail: {},
        error: action.payload
      };
    case FETCH_BILLING_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_BILLING_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        BillingHistoryList: action.payload.billingHistory,
        error: ""
      };
    case FETCH_BILLING_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        BillingHistoryList: [],
        error: action.payload
      };
    case FETCH_USER_PLAN_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_USER_PLAN_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        UserPlanLists: action.payload.UserPlanList,
        error: ""
      };
    case FETCH_USER_PLAN_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        UserPlanLists: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
