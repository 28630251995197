import { useState, useEffect, Fragment, useMemo } from "react";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { useTable, useSortBy } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillingHistoryThunkAction } from "../../redux/subscriptionPlan/actions";
import { selectSubscriptionPlan } from "../../redux/subscriptionPlan/selectors";
import "./GovernmentBillingPlans.css";
import Loader from "components/Loader/Loader";
import { getColumns } from "./BiilingHistortColumn";
import { selectUserRole } from "redux/user/selectors";
import userRoles from "utils/roles";

const BillingHistoryTable = () => {
  const [error, setError] = useState(null);
  const [billingHistory, setBillingHistory] = useState([]);
  const [sort, setSort] = useState(null);

  const dispatch = useDispatch();
  const { BillingHistoryList, isLoading } = useSelector(selectSubscriptionPlan);
  const role = useSelector(selectUserRole) || localStorage.getItem("role");
  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  useEffect(() => {
    setBillingHistory(BillingHistoryList);
  }, [BillingHistoryList]);

  useEffect(() => {
    dispatch(
      fetchBillingHistoryThunkAction(
        {
          column: sort?.sortBy,
          sort: sort?.sortDirection
        },
        onSuccess,
        onError
      )
    );
  }, [sort]);

  const columns = useMemo(() => getColumns(), []);
  const data = useMemo(() => billingHistory, [billingHistory]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns:
          Number(role) === userRoles.GOVT_FIRMS || Number(role) === userRoles.FIRMS
            ? ["plan_expiry_date"]
            : []
      }
      // manualSortBy: true,
      // disableMultiSort: false
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, setSortBy } =
    tableInstance;

  return (
    <Col sm={12} className={`content active-content`}>
      <Card
        className="card-stats mb-xl-0"
        style={{
          borderRadius: "0.5rem"
        }}
      >
        <CardHeader>
          <Row className="justify-content-start">
            <div className="ml-3">Billing History</div>
          </Row>
        </CardHeader>
        {isLoading ? (
          <Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {error ? (
              <Fragment>
                <div className="post_error_loading">{error}</div>
              </Fragment>
            ) : (
              <Fragment>
                <CardBody>
                  <div>
                    <Table
                      {...getTableProps()}
                      bordered
                      className="billing_history_table_container"
                      responsive
                    >
                      <thead className="billing_history_table_header">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                            {headerGroup.headers.map((column) => (
                              <th
                                key={column.id}
                                // sorting from server side.
                                // onClick={() => {
                                //   if (!column.disableSortBy) {
                                //     const desc =
                                //       column.isSortedDesc === true
                                //         ? undefined
                                //         : column.isSortedDesc === false
                                //         ? true
                                //         : false;
                                //     setSort({
                                //       sortBy: column.id,
                                //       sortDirection: desc ? "desc" : "asc"
                                //     });
                                //     setSortBy([{ id: column.id, desc }]);
                                //   }
                                // }}
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                              >
                                {column.render("Header")}
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fas fa-arrow-down ml-2"></i>
                                    ) : (
                                      <i className="fas fa-arrow-up ml-2"></i>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()} className="jira_issue_table_body">
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} key={row.id}>
                              {row.cells.map((cell) => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Fragment>
            )}
          </Fragment>
        )}
      </Card>
    </Col>
  );
};

export default BillingHistoryTable;
