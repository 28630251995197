export const FETCH_SUBSCRIPTION_PLAN_REQUEST = "FETCH_SUBSCRIPTION_PLAN_REQUEST";
export const FETCH_SUBSCRIPTION_PLAN_SUCCESS = "FETCH_SUBSCRIPTION_PLAN_SUCCESS";
export const FETCH_SUBSCRIPTION_PLAN_FAILURE = "FETCH_SUBSCRIPTION_PLAN_FAILURE";

export const FETCH_CURRENT_PLAN_REQUEST = "FETCH_CURRENT_PLAN_REQUEST";
export const FETCH_CURRENT_PLAN_SUCCESS = "FETCH_CURRENT_PLAN_SUCCESS";
export const FETCH_CURRENT_PLAN_FAILURE = "FETCH_CURRENT_PLAN_FAILURE";

export const FETCH_BILLING_HISTORY_REQUEST = "FETCH_BILLING_HISTORY_REQUEST";
export const FETCH_BILLING_HISTORY_SUCCESS = "FETCH_BILLING_HISTORY_SUCCESS";
export const FETCH_BILLING_HISTORY_FAILURE = "FETCH_BILLING_HISTORY_FAILURE";

export const FETCH_USER_PLAN_LIST_REQUEST = "FETCH_USER_PLAN_LIST_REQUEST";
export const FETCH_USER_PLAN_LIST_SUCCESS = "FETCH_USER_PLAN_LIST_SUCCESS";
export const FETCH_USER_PLAN_LIST_FAILURE = "FETCH_USER_PLAN_LIST_FAILURE";
