import AdminNavbar from "components/Navbar/Navbar";
import RequireAuth from "components/RequireAuth";
import Sidebar from "components/Sidebar/Sidebar";
import adminSidebarRoutes from "data/sidebar";
import Dashboard from "pages/Dashboard/Dashboard";
import Directfirms from "pages/DirectFirms/Directfirms";
import Reports from "pages/Reports/Reports";
import PricingPlans from "pages/PricingPlans/PricingPlans";
import AddFirm from "pages/AddFirm/AddFirm";
import Profile from "pages/Profile/Profile";

import React from "react";
import { Route, Routes } from "react-router-dom";
import userRoles from "utils/roles";
import AllAssessment from "pages/AllAssessment/AllAssessment";
import FileManager from "pages/FileManager/FileManager";
import NotFound from "pages/NotFound/NotFound";
import { toggleContext } from "App";
import AdminListofGovernment from "pages/AdminListofGovernment/AdminListofGovernment";
import AssessmentAnalysis from "pages/AssessmentAnalysis/AssessmentAnalysis";
import AdminFileManager from "pages/FileManager/AdminFileManager";
import FirmsFileManager from "pages/FileManager/FirmsFileManager";
import AdminDataMigrationUploadCsv from "pages/AdminDataMigration/AdminDataMigrationUploadCsv";
import JobMatchmaking from "pages/JobMatchmaking/JobMatchmaking";
import BidsOversight from "pages/BidsOversight/BidsOversight";
import OpportunityDetails from "pages/OpportunityDetails/OpportunityDetails";
import GovernmentStartSurvey from "pages/GovernmentAddSurvey/GovernmentStartSurvey";
import GovernmentAddSurvey from "pages/GovernmentAddSurvey/GovernmentAddSurvey";

function AdminLayout() {
  const [isopen, setisOpen] = React.useState(true);
  return (
    <>
      <toggleContext.Provider
        value={{
          isopen,
          setisOpen
        }}
      >
        <Sidebar routes={adminSidebarRoutes} />
        <div className={isopen ? "sidecontainer" : "sidecontainerclose"}>
          <AdminNavbar />
          <div className="p-2">
            <Routes>
              <Route element={<RequireAuth allowedRoles={[userRoles.ADMIN]} />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="bids-oversight" element={<BidsOversight />} />
                <Route path="job-matching/:id" element={<OpportunityDetails />} />
                <Route path="job-matching" element={<JobMatchmaking />} />
                <Route path="report" element={<Reports />} />
                <Route path="plans" element={<PricingPlans />} />
                <Route path="add/firm" element={<AddFirm />} />
                <Route path="add/Assessment" element={<GovernmentAddSurvey />} />
                <Route path="start/Assessment" element={<GovernmentStartSurvey />} />
                <Route path="directfirms/list" element={<Directfirms />} />
                <Route path="/listofgovernment" element={<AdminListofGovernment />} />
                <Route path="allassessment" element={<AllAssessment />} />
                <Route path="assessment/analysis/:id" element={<AssessmentAnalysis />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/file-manager" element={<FileManager />} />
                <Route path="/firms-files" element={<AdminFileManager />} />
                <Route path="/firms-files/:id" element={<FirmsFileManager />} />
                <Route path="data-migration" element={<AdminDataMigrationUploadCsv />} />
                <Route
                  path="/unauthorized"
                  element={
                    <NotFound
                      statusCode="403"
                      message="Sorry, you have no privilage to view this page."
                    />
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </div>
        </div>
      </toggleContext.Provider>
    </>
  );
}

export default AdminLayout;
