import { Divider } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";
import "./Dashboard.css";
import DropDown from "components/DropDown/DropDown";
import ApexBarChart from "components/Charts/BarChart";
import ReversedBarChart from "components/Charts/ReversedBarChart";
import PieChart from "components/Charts/PieChart";
import LineChart from "components/Charts/LineChart";
import RegisterfirmsList from "./RegisterfirmsList";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboardData } from "redux/dashboard/selectors";
import { fetchAdminTotalFirmsThunkAction } from "redux/dashboard/actions";
import { fetchAssessmentScoreSubcategoryThunkAction } from "redux/dashboard/actions";
import { fetchRevenueThunkAction } from "redux/dashboard/actions";
import moment from "moment";
import Loader from "components/Loader/Loader";
import { assessmentSectionTitles } from "utils/roles";
import { fetchDistributionIndustryThunkAction } from "redux/dashboard/actions";
import { fetchTotalChangesThunkAction } from "redux/dashboard/actions";
import { fetchMonthlyRegFirmsThunkAction } from "redux/dashboard/actions";
import { selectUserRole } from "redux/user/selectors";
import toast from "react-hot-toast";
import { getCurrentPlanApi } from "services/dashboardServices";
import { useNavigate } from "react-router-dom";
import userRoles from "utils/roles";
import { selectUser } from "redux/user/selectors";
import DashboardDropdown from "components/DashboardDropdown/DashboardDropdown";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ViewReport = () => {
    {
      Number(role) === 1 ? navigate("/admin/report") : null;
    }
  };

  const [error, setError] = useState(null);
  const [globalDate, setGlobalDate] = useState({
    end_date: moment.utc(moment().endOf("day")).format(),
    start_date: moment.utc(moment().subtract(1, "month").startOf("day").format()).format()
  });
  const [distributionIndustryDate, setDistributionIndustryDate] = useState({
    end_date: null,
    start_date: null
  });
  const [subcategoryDate, setSubcategoryDate] = useState({
    end_date: null,
    start_date: null
  });

  const [reversedBarChartData, setReversedBarChartData] = useState([]);
  const [reversedBarChartTitles, setReversedBarChartTitles] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [barChartTitles, setBarChartTitles] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartTitles, setLineChartTitles] = useState([]);
  const [PieChartGovData, setPieChartGovData] = useState([]);
  const [PieChartGovTitles, setPieChartGovTitles] = useState([]);
  const [PieChartFirmData, setPieChartFirmData] = useState([]);
  const [PieChartFirmTitles, setPieChartFirmTitles] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);

  const [globalDropdownValue, setGlobalDropdownValue] = useState("Last Month");
  const [distributionIndustryDropdownValue, setDistributionIndustryDropdownValue] = useState("");
  const [subcategoryDropdownValue, setSubcategoryDropdownValue] = useState("");

  const onSuccess = () => {};

  const onError = (error) => {
    setError(error);
  };

  const {
    isLoadingTotalFirms,
    isLoadingDistributionIndustry,
    isLoadingSubcategory,
    isLoadingTotalChanges,
    isLoadingRevenue,
    isLoadingMonthlyRegFirms,

    totalFirmsData,
    distributionIndustryData,
    assessmentScoreSubcategoryData,
    totalChangesData,
    revenueData,
    monthlyRegFirmsData
  } = useSelector(selectDashboardData);

  const role = useSelector(selectUserRole) || localStorage.getItem("role");
  const { loggedInUser } = useSelector(selectUser);

  useEffect(() => {
    setDistributionIndustryDropdownValue(globalDropdownValue);
    setSubcategoryDropdownValue(globalDropdownValue);
    dispatch(fetchAdminTotalFirmsThunkAction(globalDate, onSuccess, onError));
    dispatch(fetchAssessmentScoreSubcategoryThunkAction(globalDate, onSuccess, onError));

    dispatch(fetchDistributionIndustryThunkAction(globalDate, onSuccess, onError));
    dispatch(fetchTotalChangesThunkAction(globalDate, onSuccess, onError));
    {
      Number(role) === userRoles.ADMIN
        ? (dispatch(fetchMonthlyRegFirmsThunkAction(globalDate, onSuccess, onError)),
          dispatch(fetchRevenueThunkAction(globalDate, onSuccess, onError)))
        : null;
    }
  }, [globalDate]);

  const handleValue = (start_date, end_date, type) => {
    switch (type) {
      case "global":
        setGlobalDate({
          end_date: end_date,
          start_date: start_date
        });
        break;
      case "distributed":
        setDistributionIndustryDate({
          end_date: end_date,
          start_date: start_date
        });
        break;

      case "subcategory":
        setSubcategoryDate({
          end_date: end_date,
          start_date: start_date
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (subcategoryDate?.start_date !== null) {
      dispatch(fetchAssessmentScoreSubcategoryThunkAction(subcategoryDate, onSuccess, onError));
    }
  }, [subcategoryDate]);

  useEffect(() => {
    if (distributionIndustryDate?.start_date !== null) {
      dispatch(fetchDistributionIndustryThunkAction(distributionIndustryDate, onSuccess, onError));
    }
  }, [distributionIndustryDate]);

  useEffect(() => {
    setReversedBarChartData(
      assessmentScoreSubcategoryData?.survey_score_chart_data?.map((item) =>
        Number(item?.value?.toFixed(0))
      )
    );
    setReversedBarChartTitles(
      assessmentScoreSubcategoryData?.survey_score_chart_data?.map(
        (item) => assessmentSectionTitles[`${item?.id}`]
      )
    );
  }, [assessmentScoreSubcategoryData]);

  useEffect(() => {
    setBarChartData(distributionIndustryData?.map((item) => Number(item?.avg_score).toFixed(0)));
    setBarChartTitles(distributionIndustryData?.map((item) => item?.industries));
  }, [distributionIndustryData]);

  useEffect(() => {
    setPieChartFirmData(Object.values(revenueData?.firms || [])?.map((item) => Number(item)));
    setPieChartFirmTitles(Object.keys(revenueData?.firms || []));
    setPieChartGovData(Object.values(revenueData?.govt || [])?.map((item) => Number(item)));
    setPieChartGovTitles(Object.keys(revenueData?.govt || []));
  }, [revenueData]);

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const { data } = await getCurrentPlanApi();
        setCurrentPlan(data?.data?.plan_name);
        if (data.success !== true) {
          throw new Error(data?.message);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error?.message);
      }
    };

    if (Number(role) !== 1) {
      fetchCurrentPlan();
    }
  }, [role]);

  useEffect(() => {
    setLineChartData(
      monthlyRegFirmsData?.chart_data?.map((item) => ({
        firm: item?.firm,
        govtFirm: item?.govt_firm
      }))
    );
    setLineChartTitles(monthlyRegFirmsData?.chart_data?.map((item) => item?.monthName));
  }, [monthlyRegFirmsData]);

  const showNumberSign = (item) => {
    switch (true) {
      case item > 0:
        return "+";
      case item < 0:
        return "-";
      case item === 0:
        return "";
      default:
        return "";
    }
  };

  return (
    <div className="px-3">
      <div className="d-flex justify-content-between my-2 text-capitalize">
        <h4>Hi {loggedInUser?.username}!</h4>
        {/* <DropDown
          handleValue={(start_date, end_date) => handleValue(start_date, end_date, "global")}
        /> */}
        <DashboardDropdown
          handleValue={(start_date, end_date) => handleValue(start_date, end_date, "global")}
          setSelectedValue={setGlobalDropdownValue}
          selectedValue={globalDropdownValue}
        />
      </div>

      {isLoadingTotalFirms || isLoadingTotalChanges ? (
        <Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {isLoadingTotalFirms ? (
            <Fragment>
              <div className="post_error_loading">
                <Loader />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {Number(role) === 1 ? (
                <Row>
                  <Col lg="6" xl="4">
                    <Card className="card-stats mb-4 py-2 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h6"
                              className="text-capitalize mb-0 total_count_data_text"
                            >
                              Total Clients
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalFirmsData?.total_firm}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4">
                    <Card
                      className="card-stats mb-4 py-2 mb-xl-0 "
                      onClick={() => {
                        navigate("/admin/directfirms/list");
                      }}
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h6"
                              className="text-capitalize mb-0 total_count_data_text"
                            >
                              Firms
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalFirmsData?.total_user}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4">
                    <Card
                      className="card-stats mb-4 py-2 mb-xl-0"
                      onClick={() => {
                        navigate("/admin/listofgovernment");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h6"
                              className="text-capitalize mb-0 total_count_data_text"
                            >
                              Goverment Agencies
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalFirmsData?.total_govt}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg="6" xl="3">
                    <Card
                      className="card-stats mb-4 py-2 mb-xl-0"
                      style={{ backgroundColor: "#E7F1FD", cursor: "pointer" }}
                      onClick={() => {
                        navigate("/gov/plans");
                      }}
                    >
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h6" className="text-capitalize text-muted mb-0">
                              Current Active Plan
                            </CardTitle>
                            <span
                              className="h2 font-weight-bold mb-0"
                              style={{ color: " #1273EB" }}
                            >
                              {currentPlan}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card
                      className="card-stats mb-4 py-2 mb-xl-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/gov/directfirms/list")}
                    >
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h6" className="text-capitalize text-muted mb-0">
                              Active Firms
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalFirmsData?.total_govt_firms}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card
                      className="card-stats mb-4 py-2 mb-xl-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/gov/allassessment")}
                    >
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h6" className="text-capitalize text-muted mb-0">
                              Assessments in Progress
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalFirmsData?.completed_survey}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 py-2 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h6" className="text-capitalize text-muted mb-0">
                              Average Score
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalFirmsData?.avg_survey_score}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Fragment>
          )}

          <Row className="mt-4">
            <Col lg="12">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{
                  borderRadius: "0.5rem",
                  height: "610px"
                }}
              >
                <CardHeader
                  style={{
                    borderRadius: "0.5rem",
                    paddingLeft: "0"
                  }}
                >
                  <Row className="justify-content-between align-items-center mx-1">
                    <Col xs={8}>
                      {Number(role) === 1 ? (
                        <h6 className="mb-0">Distribution of Industries</h6>
                      ) : (
                        <h6 className="mb-0">Firms by Industry</h6>
                      )}
                    </Col>
                    <Col xs={3} className="d-flex justify-content-end">
                      {/* <DropDown
                        handleValue={(start_date, end_date) =>
                          handleValue(start_date, end_date, "distributed")
                        }
                      /> */}
                      <DashboardDropdown
                        handleValue={(start_date, end_date) =>
                          handleValue(start_date, end_date, "distributed")
                        }
                        setSelectedValue={setDistributionIndustryDropdownValue}
                        selectedValue={distributionIndustryDropdownValue}
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {isLoadingDistributionIndustry ? (
                    <Fragment>
                      <div className="post_error_loading">
                        <Loader />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {barChartData?.length ? (
                        <ApexBarChart data={barChartData} categories={barChartTitles} />
                      ) : (
                        <div className="d-flex justify-content-center align-items-center h-100">
                          No data found
                        </div>
                      )}
                    </Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="9">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{
                  borderRadius: "0.5rem",
                  height: "610px"
                }}
              >
                <CardHeader
                  style={{
                    height: "auto",
                    borderRadius: "0.5rem",
                    paddingLeft: "0"
                  }}
                >
                  <Row className="justify-content-between align-items-center mx-1 text-capitalize">
                    <Col xs={8}>
                      {Number(role) === 1 ? (
                        <h6 className="mb-0">Assessment average score of subcategory</h6>
                      ) : (
                        <h6 className="mb-0">Average Score per Category</h6>
                      )}
                    </Col>
                    <Col xs={3} className="d-flex justify-content-end">
                      {/* <DropDown
                        handleValue={(start_date, end_date) =>
                          handleValue(start_date, end_date, "subcategory")
                        }
                      /> */}
                      <DashboardDropdown
                        handleValue={(start_date, end_date) =>
                          handleValue(start_date, end_date, "subcategory")
                        }
                        setSelectedValue={setSubcategoryDropdownValue}
                        selectedValue={subcategoryDropdownValue}
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody
                  style={{
                    height: "auto",
                    padding: "5px 0"
                  }}
                >
                  {isLoadingSubcategory ? (
                    <Fragment>
                      <div className="post_error_loading">
                        <Loader />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {reversedBarChartData?.length ? (
                        <ReversedBarChart
                          data={reversedBarChartData}
                          categories={reversedBarChartTitles}
                        />
                      ) : (
                        <div className="d-flex justify-content-center align-items-center h-100">
                          No data found
                        </div>
                      )}
                    </Fragment>
                  )}
                </CardBody>
                {Number(role) === 1 ? (
                  <CardFooter
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "0.5rem"
                    }}
                  >
                    <Row>
                      <div sm="6" className="d-flex justify-content-between px-3 flex-grow-1">
                        <div className="card_footer_text">
                          Total Assessment
                          <br />
                          Completed
                        </div>
                        <div>
                          <h4 className="mt-2" style={{ color: "#1273EB" }}>
                            {assessmentScoreSubcategoryData?.total_survey_completed}
                          </h4>
                        </div>
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div sm="6" className="d-flex justify-content-between px-3 flex-grow-1">
                        <div className="card_footer_text mt-1">Score</div>
                        <div>
                          <h4 className="mt-2" style={{ color: "#1273EB" }}>
                            {assessmentScoreSubcategoryData?.avg_survey_score}
                          </h4>
                        </div>
                      </div>
                    </Row>
                  </CardFooter>
                ) : null}
              </Card>
            </Col>
            <Col lg="3">
              <Col>
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{
                    borderRadius: "0.5rem"
                  }}
                >
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6} className="total_change_data_text mb-0 text-capitalize">
                        Total change in employment
                      </Col>
                      <Col xs={6} className="d-flex justify-content-center">
                        <h4 className="change_score">
                          {showNumberSign(totalChangesData?.employee_diff)}{" "}
                          {new Intl.NumberFormat("en-US").format(
                            Math.abs(Number(totalChangesData?.employee_diff))
                          )}
                        </h4>
                        {showNumberSign(totalChangesData?.employee_diff) === "+" && (
                          <i className="fa fa-arrow-up ml-1 my-1" />
                        )}
                        {showNumberSign(totalChangesData?.employee_diff) === "-" && (
                          <i className="fas fa-arrow-down ml-1 my-1" />
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter
                    className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#E7F1FD",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                      height: "36px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      ViewReport();
                    }}
                  >
                    <span className="text-nowrap employment_view_full_report">
                      View Full Report
                    </span>
                  </CardFooter>
                </Card>
              </Col>
              <Col className="mt-3">
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{
                    borderRadius: "0.5rem"
                  }}
                >
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={5} className="total_change_data_text mb-0 text-capitalize">
                        Total change in revenue
                      </Col>
                      <Col xs={7} className="d-flex justify-content-center">
                        <h4 className="change_score">
                          {showNumberSign(totalChangesData?.revenue_diff)}{" "}
                          {Math.abs(Number(totalChangesData?.revenue_diff).toFixed(2))}%
                        </h4>
                        {showNumberSign(totalChangesData?.revenue_diff) === "+" && (
                          <i className="fa fa-arrow-up ml-1 my-1" />
                        )}
                        {showNumberSign(totalChangesData?.revenue_diff) === "-" && (
                          <i className="fas fa-arrow-down ml-1 my-1" />
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter
                    className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#E7F1FD",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                      height: "36px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      ViewReport();
                    }}
                  >
                    <span className="text-nowrap employment_view_full_report">
                      View Full Report
                    </span>
                  </CardFooter>
                </Card>
              </Col>

              <Col className="mt-3">
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{
                    borderRadius: "0.5rem"
                  }}
                >
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={5} className="total_change_data_text mb-0 text-capitalize">
                        Total change in sales
                      </Col>
                      <Col xs={7} className="d-flex justify-content-center">
                        <h4 className="change_score">
                          {showNumberSign(totalChangesData?.sales_diff)}{" "}
                          {Math.abs(Number(totalChangesData?.sales_diff).toFixed(2))}%
                        </h4>
                        {showNumberSign(totalChangesData?.sales_diff) === "+" && (
                          <i className="fa fa-arrow-up ml-1 my-1" />
                        )}
                        {showNumberSign(totalChangesData?.sales_diff) === "-" && (
                          <i className="fas fa-arrow-down ml-1 my-1" />
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter
                    className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#E7F1FD",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                      height: "36px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      ViewReport();
                    }}
                  >
                    <span className="text-nowrap employment_view_full_report">
                      View Full Report
                    </span>
                  </CardFooter>
                </Card>
              </Col>
              <Col className="mt-3">
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{
                    borderRadius: "0.5rem"
                  }}
                >
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6} className="total_change_data_text mb-0 text-capitalize">
                        Average change in credit score
                      </Col>
                      <Col xs={6} className="d-flex justify-content-center">
                        <h4 className="change_score">
                          {showNumberSign(totalChangesData?.avg_score)}{" "}
                          {Number(
                            new Intl.NumberFormat("en-US").format(
                              Math.abs(totalChangesData?.avg_score)
                            )
                          ).toFixed(2)}
                        </h4>
                        {showNumberSign(totalChangesData?.avg_score) === "+" && (
                          <i className="fa fa-arrow-up ml-1 my-1" />
                        )}
                        {showNumberSign(totalChangesData?.avg_score) === "-" && (
                          <i className="fas fa-arrow-down ml-1 my-1" />
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter
                    className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#E7F1FD",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                      height: "36px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      ViewReport();
                    }}
                  >
                    <span className="text-nowrap employment_view_full_report">
                      View Full Report
                    </span>
                  </CardFooter>
                </Card>
              </Col>
            </Col>
          </Row>

          {Number(role) === 1 ? (
            <Row className="mt-4">
              <Col lg="4">
                <Card
                  className=""
                  style={{
                    height: "550px",
                    border: "none"
                  }}
                >
                  <CardBody
                    style={{
                      padding: "0 1.25rem"
                    }}
                  >
                    <Row
                      className="border"
                      style={{
                        height: "48%",
                        borderRadius: "0.5rem",
                        marginBottom: "4%"
                      }}
                    >
                      {isLoadingRevenue ? (
                        <Fragment>
                          <div className="post_error_loading">
                            <Loader />
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {PieChartGovData.length && (
                            <PieChart
                              text="Revenue from Government"
                              data={PieChartGovData}
                              labels={PieChartGovTitles}
                              colors={["#0D52A7", "#1273EB", "#60A1F2"]}
                            />
                          )}
                        </Fragment>
                      )}
                    </Row>

                    <Row
                      className="border"
                      style={{
                        height: "48%",
                        borderRadius: "0.5rem"
                      }}
                    >
                      {isLoadingRevenue ? (
                        <Fragment>
                          <div className="post_error_loading">
                            <Loader />
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {PieChartFirmData.length && (
                            <PieChart
                              text="Revenue from Firms"
                              data={PieChartFirmData}
                              labels={PieChartFirmTitles}
                              colors={["#0D52A7", "#E8913D"]}
                            />
                          )}
                        </Fragment>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="8">
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{
                    borderRadius: "0.5rem",
                    height: "550px"
                  }}
                >
                  <CardBody
                    style={{
                      height: "auto"
                    }}
                  >
                    {isLoadingMonthlyRegFirms ? (
                      <Fragment>
                        <div className="post_error_loading">
                          <Loader />
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {lineChartData?.length ? (
                          <LineChart data={lineChartData} categories={lineChartTitles} />
                        ) : (
                          <div className="d-flex justify-content-center align-items-center h-100">
                            No data found
                          </div>
                        )}
                      </Fragment>
                    )}
                  </CardBody>
                  <CardFooter
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "0.5rem"
                    }}
                  >
                    <Row>
                      <div
                        sm="12"
                        md="4"
                        className="d-flex justify-content-between px-3 flex-grow-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/admin/listofgovernment");
                        }}
                      >
                        <div className="card_footer_text mt-2">Total Government Agencies</div>
                        <div>
                          <h4 className="mt-2" style={{ color: "#1273EB" }}>
                            {monthlyRegFirmsData?.total_govt}
                          </h4>
                        </div>
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div
                        sm="12"
                        md="4"
                        className="d-flex justify-content-between px-3 flex-grow-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/admin/directfirms/list");
                        }}
                      >
                        <div className="card_footer_text mt-2">Last Month Register Firms </div>
                        <div>
                          <h4 className="mt-2" style={{ color: "#1273EB" }}>
                            {monthlyRegFirmsData?.last_month_firms}
                          </h4>
                        </div>
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div
                        sm="12"
                        md="4"
                        className="d-flex justify-content-between px-3 flex-grow-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/admin/listofgovernment");
                        }}
                      >
                        <div className="card_footer_text">
                          Last Month Register
                          <br />
                          Government Agencies
                        </div>
                        <div>
                          <h4 className="mt-2" style={{ color: "#1273EB" }}>
                            {monthlyRegFirmsData?.last_month_govt}
                          </h4>
                        </div>
                      </div>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          ) : null}

          <RegisterfirmsList globalDate={globalDate} globalDropdownValue={globalDropdownValue} />
        </Fragment>
      )}
    </div>
  );
}

export default Dashboard;
