import AdminNavbar from "components/Navbar/Navbar";
import RequireAuth from "components/RequireAuth";
import Sidebar from "components/Sidebar/Sidebar";
import { governmentSidebarRoutes } from "data/sidebar";
import Dashboard from "pages/Dashboard/Dashboard";
import Directfirms from "pages/DirectFirms/Directfirms";
import Reports from "pages/Reports/Reports";
import AddFirm from "pages/AddFirm/AddFirm";
import Profile from "pages/Profile/Profile";

import React from "react";
import { Route, Routes } from "react-router-dom";
import userRoles from "utils/roles";
import AllAssessment from "pages/AllAssessment/AllAssessment";
import FileManager from "pages/FileManager/FileManager";
import NotFound from "pages/NotFound/NotFound";
import { toggleContext } from "App";
import GovernmentPrincingplans from "pages/GovernmentPrincingplans/GovernmentPrincingplans";
import GovernmentBillingPlans from "pages/GovernmentBillingPlans/GovernmentBillingPlans";
import AssessmentAnalysis from "pages/AssessmentAnalysis/AssessmentAnalysis";

const GovAgencyLayout = () => {
  const [isopen, setisOpen] = React.useState(true);
  return (
    <>
      <toggleContext.Provider
        value={{
          isopen,
          setisOpen
        }}
      >
        <Sidebar routes={governmentSidebarRoutes} />
        <div className={isopen ? "sidecontainer" : "sidecontainerclose"}>
          <AdminNavbar />
          <div className="p-2">
            <Routes>
              <Route element={<RequireAuth allowedRoles={[userRoles.GOVT]} />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="report" element={<Reports />} />
                <Route path="plans" element={<GovernmentPrincingplans />} />
                <Route path="billing/plans" element={<GovernmentBillingPlans />} />
                <Route path="add/firm" element={<AddFirm />} />
                <Route path="directfirms/list" element={<Directfirms />} />
                <Route path="allassessment" element={<AllAssessment />} />
                <Route path="assessment/analysis/:id" element={<AssessmentAnalysis />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/file-manager" element={<FileManager />} />
                <Route
                  path="/unauthorized"
                  element={
                    <NotFound
                      statusCode="403"
                      message="Sorry, you have no privilage to view this page."
                    />
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </div>
        </div>
      </toggleContext.Provider>
    </>
  );
};

export default GovAgencyLayout;
