import {
  FETCH_FIRMS_REPORT_LIST_REQUEST,
  FETCH_FIRMS_REPORT_LIST_SUCCESS,
  FETCH_FIRMS_REPORT_LIST_FAILURE,
  FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_REQUEST,
  FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_SUCCESS,
  FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_FAILURE,
  RESET_FIRMS_REPORT_LIST_DATA
} from "./types";

import {
  getFirmsReportListApi,
  getAdminCompareSurveyAnalysisApi
} from "../../services/firmsReportServices";

export const fetchFirmsReportListRequest = () => {
  return {
    type: FETCH_FIRMS_REPORT_LIST_REQUEST
  };
};

export const fetchFirmsReportListSuccess = (firmsReportList, totalPage) => {
  return {
    type: FETCH_FIRMS_REPORT_LIST_SUCCESS,
    payload: { firmsReportList, totalPage }
  };
};

export const fetchFirmsReportListFailure = (error) => {
  return {
    type: FETCH_FIRMS_REPORT_LIST_FAILURE,
    payload: error
  };
};

export const resetFirmsReportList = () => {
  return {
    type: RESET_FIRMS_REPORT_LIST_DATA
  };
};

export const fetchFirmsReportListThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchFirmsReportListRequest());
      const { data } = await getFirmsReportListApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(
        fetchFirmsReportListSuccess(
          data?.data?.data,
          data?.data?.meta?.total_records / params?.limit
        )
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchFirmsReportListFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchCompareSurveyAnalysisRequest = () => {
  return {
    type: FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_REQUEST
  };
};

export const fetchCompareSurveyAnalysisSuccess = (data) => {
  return {
    type: FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_SUCCESS,
    payload: data
  };
};

export const fetchCompareSurveyAnalysisFailure = (error) => {
  return {
    type: FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_FAILURE,
    payload: error
  };
};

export const fetchCompareSurveyAnalysisThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCompareSurveyAnalysisRequest());
      const { data } = await getAdminCompareSurveyAnalysisApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data?.message);
      }

      dispatch(fetchCompareSurveyAnalysisSuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchCompareSurveyAnalysisFailure(error?.response?.data?.message || error?.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};
