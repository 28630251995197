import React from "react";
import ReactApexChart from "react-apexcharts";

class GovernmentDashboardChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "First Assessment",
          data: this.props.data_1
        },
        {
          name: "Second Assessment",
          data: this.props.data_2
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 410,
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          curve: "smooth"
        },
        colors: ["#007bff", "#083063"],
        // plotOptions: {
        //   bar: {
        //     borderRadius: 3,
        //     horizontal: true,
        //     dataLabels: {
        //       position: "top" // top, center, bottom
        //     }
        //   }
        // },
        // legend: {
        //   markers: {
        //     radius: 50
        //   }
        // },
        // dataLabels: {
        //   enabled: true,
        //   style: {
        //     colors: ["#333"]
        //   },
        //   offsetX: 30
        // },
        grid: {
          strokeDashArray: 7
        },
        markers: {
          size: 5
        },
        yaxis: {
          title: {
            text: "Sum of Score"
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          title: {
            text: "Categories"
          },
          categories: this.props.categories
        }
      }
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={510}
        />
      </div>
    );
  }
}

export default GovernmentDashboardChart;
