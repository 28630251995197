const adminSidebarRoutes = [
  {
    title: "Dashboard",
    icon: "clarity:dashboard-line",
    path: "/admin/dashboard"
  },
  {
    title: "Bids Oversight",
    icon: "carbon:report-data",
    path: "/admin/bids-oversight"
  },
  {
    title: "Job Matchmaking",
    icon: "ic:baseline-add-chart",
    path: "/admin/job-matching"
  },
  {
    title: "Firms",
    icon: "fluent:document-bullet-list-20-regular",
    path: "/admin/directfirms/list"
  },
  {
    title: "Government ",
    icon: "fluent:building-government-20-regular",
    path: "/admin/listofgovernment"
  },
  {
    title: "Business Assessment",
    icon: "ic:baseline-add-chart",
    path: "/admin/start/Assessment"
  },
  {
    title: "All Assessments",
    icon: "ic:outline-assessment",
    path: "/admin/allassessment"
  },
  {
    title: "Plans",
    icon: "ri:price-tag-2-line",
    path: "/admin/plans"
  },
  {
    title: "Business Assessment Report",
    icon: "carbon:report-data",
    path: "/admin/report"
  },
  {
    title: "File Manager",
    icon: "bi:folder",
    childrens: [
      {
        title: "My Files",
        icon: "radix-icons:dot",
        path: "/admin/file-manager"
      },
      {
        title: "Firm Files",
        icon: "radix-icons:dot",
        path: "/admin/firms-files"
      }
    ]
  },
  {
    title: "Firm Migration",
    icon: "carbon:ibm-data-replication",
    path: "/admin/data-migration"
  }
];

export const firmsSidebarRoutes = [
  {
    title: "Dashboard",
    icon: "clarity:dashboard-line",
    path: "/firms/dashboard"
  },
  {
    title: "Start Assessment",
    icon: "ic:baseline-add-chart",
    path: "/firms/start/Assessment"
  },
  {
    title: "My Assessments",
    icon: "fluent:task-list-square-ltr-24-regular",
    path: "/firms/Assessment/list"
  },
  // {
  //   title: "Plans",
  //   icon: "ri:price-tag-2-line",
  //   path: "/firms/plans"
  // },
  // {
  //   title: "Billing & Plans",
  //   icon: "la:file-invoice-dollar",
  //   path: "/firms/billing/plans"
  // },
  {
    title: "File Manager",
    icon: "bi:folder",
    path: "/firms/file-manager"
  },
  {
    title: "Bid Opportunity List",
    icon: "mdi:briefcase-search-outline",
    path: "/firms/bid/opportunity"
  }
];

export const governmentSidebarRoutes = [
  {
    title: "Dashboard",
    icon: "clarity:dashboard-line",
    path: "/gov/dashboard"
  },
  {
    title: "My Firms",
    icon: "fluent:document-bullet-list-20-regular",
    path: "/gov/directfirms/list"
  },
  {
    title: "Invite Firms",
    icon: "mdi:file-document-plus-outline",
    path: "/gov/add/firm"
  },
  {
    title: "All Assessments",
    icon: "ic:outline-assessment",
    path: "/gov/allassessment"
  }
  // {
  //   title: "Plans",
  //   icon: "ri:price-tag-2-line",
  //   path: "/gov/plans"
  // },
  // {
  //   title: "Billing and Plan",
  //   icon: "la:file-invoice-dollar",
  //   path: "/gov/billing/plans"
  // },
  // {
  //   title: "Business Asse. Report",
  //   icon: "carbon:report-data",
  //   path: "/gov/report"
  // },
  // {
  //   title: "File Manager",
  //   icon: "bi:folder",
  //   path: "/gov/file-manager"
  // }
];

export const governmentFirmsSidebarRoutes = [
  {
    title: "Dashboard",
    icon: "clarity:dashboard-line",
    path: "/govt-firms/dashboard"
  },
  {
    title: "Start Assessment",
    icon: "ic:baseline-add-chart",
    path: "/govt-firms/start/Assessment"
  },
  {
    title: "My Assessments",
    icon: "fluent:task-list-square-ltr-24-regular",
    path: "/govt-firms/Assessment/list"
  },
  {
    title: "File Manager",
    icon: "bi:folder",
    path: "/govt-firms/file-manager"
  },
  {
    title: "Bid Opportunity List",
    icon: "mdi:briefcase-search-outline",
    path: "/govt-firms/bid/opportunity"
  }
];

export default adminSidebarRoutes;
