import React from "react";
import { Row } from "reactstrap";
import "./GovernmentListofSurvey.css";
import CompleteAssessmentTable from "./CompleteAssessmentTable";
import OngoingAssessmentTable from "./OngoingAssessmentTable";

const GovernmentListofSurvey = () => {
  return (
    <div className="px-3">
      <Row className="align-items-center m-3">
        <span className="Headers">
          <h4>My Assessments</h4>
        </span>
      </Row>
      <CompleteAssessmentTable />
      <OngoingAssessmentTable />
    </div>
  );
};

export default GovernmentListofSurvey;
