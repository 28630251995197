import moment from "moment";

export const getColumns = () => {
  return [
    {
      Header: "id",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        return `${row.index + 1}`;
      }
    },
    {
      Header: "transaction ID",
      accessor: "payment_intent_id"
    },
    {
      Header: "transaction Date",
      accessor: "created_at",
      Cell: ({ row }) => {
        return <span>{moment(`${row.original.created_at}`).format("MM-DD-YYYY")}</span>;
      }
    },
    {
      Header: "Plan Type",
      accessor: "name"
    },
    {
      Header: "Amount",
      accessor: "price"
    },
    {
      Header: "Plan Start Date",
      accessor: "purchase_date",
      Cell: ({ row }) => {
        return <span>{moment(`${row.original.purchase_date}`).format("MM-DD-YYYY")}</span>;
      }
    },
    {
      Header: "Plan End Date",
      accessor: "plan_expiry_date",
      Cell: ({ row }) => {
        if (row.original.plan_expiry_date !== null) {
          return <span>{moment(`${row.original.plan_expiry_date}`).format("MM-DD-YYYY")}</span>;
        } else {
          return <>-----</>;
        }
      }
    }
  ];
};
