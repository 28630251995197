export const FETCH_FIRMS_REPORT_LIST_REQUEST = "FETCH_FIRMS_REPORT_LIST_REQUEST";
export const FETCH_FIRMS_REPORT_LIST_SUCCESS = "FETCH_FIRMS_REPORT_LIST_SUCCESS";
export const FETCH_FIRMS_REPORT_LIST_FAILURE = "FETCH_FIRMS_REPORT_LIST_FAILURE";

export const FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_REQUEST =
  "FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_REQUEST";
export const FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_SUCCESS =
  "FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_SUCCESS";
export const FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_FAILURE =
  "FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_FAILURE";

export const RESET_FIRMS_REPORT_LIST_DATA = "RESET_FIRMS_REPORT_LIST_DATA";
