import React, { useEffect, useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import userRoles from "utils/roles";
import { useSelector } from "react-redux";
import { selectUserRole } from "redux/user/selectors";
import toast from "react-hot-toast";

const Payment = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMessage(null);
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Please provide valid card information to proceed payment.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const NavigateTo = (id) => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return `/admin/profile`;
      case userRoles.GOVT:
        return `/gov/profile`;
      case userRoles.FIRMS:
        return `/firms/profile`;
      case userRoles.GOVT_FIRMS:
        return `/govt-firms/profile`;
      default:
        return `/dashboard/${id}`;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_FRONTEND_URL}${NavigateTo(role)}`
      }
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" className="payment_form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        className="payment_submit_button"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isLoading ? <div className="payment_spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default Payment;
