import React from "react";
import ReactApexChart from "react-apexcharts";

class BidAmountDistributionChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Bid Amount",
          data: [800, 10000, 15000, 12000, 17000, 14000]
        }
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            }
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#EA5455", "#1273EB", "#00CFE8", "#85C808", "#FF9F43", "#28C76F"],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            borderRadius: 10,
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        grid: {
          strokeDashArray: 7
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            if (val > 1000) {
              return val / 1000 + "K";
            }
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#083063"]
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: ["<$1M", "$1M-$5M", "$5M-$10M", "$10M-$50M", "$50M-$100M", "$100M"],
          labels: {
            style: {
              fontSize: "12px"
            }
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              if (val > 1000) {
                return val / 1000 + "K";
              }
              return val;
            }
          }
        }
      }
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default BidAmountDistributionChart;
