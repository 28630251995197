import {
  FETCH_GOVERNMENT_LIST_REQUEST,
  FETCH_GOVERNMENT_LIST_SUCCESS,
  FETCH_GOVERNMENT_LIST_FAILURE,
  RESET_GOVERNMENT_LIST_DATA
} from "./types";

import { getGovernmentListApi } from "../../services/adminListofGovernmentServices";

export const fetchGovernmentListRequest = () => {
  return {
    type: FETCH_GOVERNMENT_LIST_REQUEST
  };
};

export const fetchGovernmentListSuccess = (governmentlist, totalPage) => {
  return {
    type: FETCH_GOVERNMENT_LIST_SUCCESS,
    payload: { governmentlist, totalPage }
  };
};

export const fetchGovernmentListFailure = (error) => {
  return {
    type: FETCH_GOVERNMENT_LIST_FAILURE,
    payload: error
  };
};

export const resetGovernmentList = () => {
  return {
    type: RESET_GOVERNMENT_LIST_DATA
  };
};

export const fetchGovernmentListThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchGovernmentListRequest());
      const { data } = await getGovernmentListApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(
        fetchGovernmentListSuccess(
          data?.data?.data,
          data?.data?.meta?.total_records / params?.limit
        )
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchGovernmentListFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};
