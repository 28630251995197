import moment from "moment";
import { Button, Badge } from "reactstrap";
import converter from "number-to-words";

export const getColumns = (index, onClickViewHandler) => {
  return [
    {
      Header: "id",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        return `${index + row.index + 1}`;
      }
    },
    {
      Header: "Date",
      accessor: "created_at",
      Cell: ({ row }) => {
        return <span>{moment(`${row.original.created_at}`).format("MM-DD-YYYY")}</span>;
      }
    },
    {
      Header: "Number",
      accessor: "attempt_number",
      Cell: ({ row }) => {
        return (
          <Badge
            color="primary"
            style={{
              color: `${row.original?.attempt_number % 2 === 0 ? "#FF9F43" : "#1273EB"}`,
              backgroundColor: `${row.original?.attempt_number % 2 === 0 ? "#FFF5EC" : "#E7F1FD"}`
            }}
            className="text-capitalize"
            pill
          >
            {converter.toWordsOrdinal(row.original?.attempt_number)}
          </Badge>
        );
      }
    },
    {
      Header: "Score",
      accessor: "total_score"
    },
    {
      Header: "version",
      accessor: "version",
      disableSortBy: true
    },
    {
      Header: "View analysis",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span>
            <Button
              color="primary"
              size="sm"
              className="px-2"
              outline
              onClick={() => onClickViewHandler(row?.original?.id)}
            >
              <i className="far fa-file-alt mr-2" />
              view
            </Button>
          </span>
        );
      }
    }
  ];
};
