import { useState, useEffect, Fragment } from "react";
import { Card, CardBody, CardFooter, Col, Row, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersPlanListThunkAction } from "../../redux/subscriptionPlan/actions";
import { selectSubscriptionPlan } from "../../redux/subscriptionPlan/selectors";
import { Tooltip } from "reactstrap";
import "./GovernmentPrincingplans.css";
import Loader from "components/Loader/Loader";
import toast from "react-hot-toast";
import PaymentModal from "components/Modals/PaymentModal";

const GovernmentPrincingplans = () => {
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const dispatch = useDispatch();
  const { UserPlanLists, isLoading } = useSelector(selectSubscriptionPlan);

  const [plans, setPlans] = useState([]);
  const [planId, setPlanId] = useState(null);
  useEffect(() => {
    setPlans(UserPlanLists);
  }, [UserPlanLists]);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };
  const toggleModal = () => {
    if (open) {
      setPlanId(null);
    }
    setOpen((open) => !open);
  };
  const handleOpenModal = (planId) => {
    setPlanId(planId);
    setOpen(true);
  };

  useEffect(() => {
    dispatch(fetchUsersPlanListThunkAction(onSuccess, onError));
  }, []);

  const onUpdateSuccess = () => {
    dispatch(fetchUsersPlanListThunkAction(onSuccess, onError));
  };
  const onUpdateError = (error) => {
    toast.error(error);
  };

  return (
    <>
      <div className="px-3">
        <Row className="text-center my-5">
          <Col xs={12} className="pricing_plan_page_title">
            <h4>Pricing Plans</h4>
          </Col>
          <Col className="pricing_plan_page_description" xs={12}>
            <p>
              Alacriti Analytics is a seed state diversity procurement platform with 70 + projects
              with a total value of <br /> $664 million dollars in contract opportunities.
            </p>{" "}
          </Col>
        </Row>
        {isLoading ? (
          <Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {error ? (
              <Fragment>
                <div className="post_error_loading">{error}</div>
              </Fragment>
            ) : (
              <Fragment>
                <Row className="mt-4 justify-content-center">
                  {plans?.length > 0 &&
                    plans.map((plan) => {
                      const tooltipId = `TooltipExample${plan?.id}`;
                      return (
                        <Col lg="6" xl="4" key={plan?.id}>
                          <Card
                            className="card-stats mb-4 mb-xl-0"
                            style={{
                              height: "450px",
                              border: "none",
                              boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
                              backgroundColor: "#F2F2F2"
                            }}
                          >
                            {plan?.current_plan ? (
                              <div className="ribbon ribbon-top-left">
                                <span>Current Plan</span>
                              </div>
                            ) : null}
                            <CardHeader style={{ backgroundColor: "#283046" }}>
                              <Row className="text-light text-center mx-2">
                                <h5 className="mb-0 col">{plan.name}</h5>
                              </Row>
                            </CardHeader>
                            <CardBody className="pt-0">
                              <Row
                                style={{
                                  backgroundColor: "#161D31",
                                  marginLeft: "-20px",
                                  marginRight: "-20px"
                                }}
                                className="text-light text-center rounded-bottom"
                              >
                                {plan?.price > 0 ? (
                                  <Col className="my-5">
                                    <h1 className="d-inline-block position-relative text-warning">
                                      ${plan.price}
                                    </h1>{" "}
                                    / {plan?.bill_type}
                                  </Col>
                                ) : (
                                  <Col className="my-5">
                                    <h1 className="d-inline-block position-relative text-warning">
                                      Call for Pricing
                                    </h1>{" "}
                                  </Col>
                                )}
                              </Row>
                              <Row className="text-center mt-5">
                                <Col>
                                  {plan.description.length > 150 ? (
                                    <>
                                      <p id={tooltipId}>{plan.description.substring(0, 150)}...</p>
                                      <Tooltip
                                        isOpen={tooltipOpen}
                                        placement="top"
                                        target={tooltipId}
                                        toggle={() => {
                                          setTooltipOpen(!tooltipOpen);
                                        }}
                                      >
                                        {plan.description}
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <p>{plan.description}</p>
                                  )}
                                </Col>
                              </Row>
                            </CardBody>
                            <CardFooter
                              style={{
                                backgroundColor: "#F2F2F2",
                                borderTop: "0px",
                                borderBottomLeftRadius: "0.5rem",
                                borderBottomRightRadius: "0.5rem"
                              }}
                            >
                              <Row className="mx-4 bg-light">
                                {plan?.current_plan || plan?.price < 0 ? null : (
                                  <>
                                    <div
                                      sm="6"
                                      className="d-flex align-items-center justify-content-center flex-grow-1 border py-2 rounded border-primary"
                                      style={{
                                        cursor: "pointer"
                                      }}
                                      onClick={() => handleOpenModal(plan?.id)}
                                    >
                                      <span className="FiEdit"></span>
                                      <div className="text-primary">Purchase Plan</div>
                                    </div>
                                  </>
                                )}
                              </Row>
                            </CardFooter>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
      {planId && <PaymentModal toggleModal={toggleModal} isOpen={open} planId={planId} />}
    </>
  );
};

export default GovernmentPrincingplans;
