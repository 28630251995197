import {
  FETCH_FIRMS_REPORT_LIST_REQUEST,
  FETCH_FIRMS_REPORT_LIST_SUCCESS,
  FETCH_FIRMS_REPORT_LIST_FAILURE,
  FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_REQUEST,
  FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_SUCCESS,
  FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_FAILURE,
  RESET_FIRMS_REPORT_LIST_DATA
} from "./types";

const initialState = {
  isLoading: false,
  surveyLoading: false,
  error: "",
  firmsReportList: [],
  compareSurveyAnalysisData: null,
  totalPage: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIRMS_REPORT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_FIRMS_REPORT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        firmsReportList: action.payload.firmsReportList,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_FIRMS_REPORT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        firmsReportList: [],
        error: action.payload
      };
    case RESET_FIRMS_REPORT_LIST_DATA:
      return {
        ...state,
        firmsReportList: [],
        totalPage: 0
      };
    case FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_REQUEST:
      return {
        ...state,
        surveyLoading: true,
        compareSurveyAnalysisData: null
      };
    case FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_SUCCESS:
      return {
        ...state,
        surveyLoading: false,
        compareSurveyAnalysisData: action.payload,
        error: ""
      };
    case FETCH_VIEW_COMPARE_SURVEY_ANALYSIS_FAILURE:
      return {
        ...state,
        surveyLoading: false,
        compareSurveyAnalysisData: null,
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
