import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { selectUserRole } from "redux/user/selectors";
import { selectUserToken } from "redux/user/selectors";
import userRoles from "utils/roles";

const RestrictedAuth = ({ restricted = false }) => {
  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const defaultHomePage = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/dashboard";
      case userRoles.GOVT:
        return "/gov/dashboard";
      case userRoles.FIRMS:
        return "/firms/start/Assessment";
      case userRoles.GOVT_FIRMS:
        return "/govt-firms/start/Assessment";
      default:
        return "/dashboard";
    }
  };

  const location = useLocation();

  return token && restricted ? (
    <Navigate to={`${defaultHomePage()}`} replace state={{ from: location }} />
  ) : (
    <Outlet />
  );
};

export default RestrictedAuth;
