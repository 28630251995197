import React from "react";
import "./AuthLogo.css";
import alacritiLogo from "../../assets/img/brand/alcriti_logo.png";
import alacritiLogoBlue from "../../assets/img/theme/alcriti_logo.png";

import { useLocation } from "react-router-dom";

function AuthLogo() {
  const { pathname } = useLocation();
  const isFirmLoginPage = pathname.includes("firm/login");
  const isGovernmentLoginPage = pathname.includes("government/login");

  return (
    <div className="authlogo_container">
      <img
        src={isFirmLoginPage || isGovernmentLoginPage ? alacritiLogoBlue : alacritiLogo}
        alt="logo"
        className="authlogo_image"
      />
      <div className="authlogo_text">Capacity Analytics</div>
    </div>
  );
}

export default AuthLogo;
