import axiosInstance from "./axiosInstance";

export const getFileListingApi = (prms) =>
  axiosInstance.get(
    "/api/v1/admin/files/list",

    {
      params: {
        limit: 12,
        offset: prms?.offset || 0,
        search: prms?.search || "",
        filter: prms?.filter || "all"
      }
      // cancelToken: new CancelToken((c) => {
      //   // An executor function receives a cancel function as a parameter
      //   requests["getFileManagerListingApi"] = c;
      // }),
    }
  );

export const getFirmsFilesListingApi = (prms, firmId) =>
  axiosInstance.get(
    `/api/v1/admin/files/${firmId}`,

    {
      params: {
        start_date: prms?.start_date,
        end_date: prms?.end_date
      }
    }
  );

export const postFirmsFilesApi = (postData, firmId) =>
  axiosInstance.post(`/api/v1/admin/files/${firmId}`, postData);

export const postDownloadFirmsFilesApi = (postData) =>
  axiosInstance.post(`/api/v1/admin/files/list`, postData, {
    responseType: "arraybuffer"
  });
