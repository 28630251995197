import { useState, useEffect, Fragment } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import UncontrolledDropDown from "components/UncontrolledDropDown/UncontrolledDropDown";
import "./FileManager.css";
import AddImage from "components/Modals/AddImage";
import { postFileManagerThunkAction } from "redux/fileManager/actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchFileListingThunkAction } from "redux/fileManager/actions";
import { selectFileManager } from "redux/fileManager/selectors";
import { selectUserToken } from "redux/user/selectors";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { getFileType } from "utils/roles";
import toast from "react-hot-toast";
import { deleteFileManagerThunkAction } from "redux/fileManager/actions";
import { Icon } from "@iconify/react";
import { Tooltip } from "reactstrap";
// import { cancelRequest } from "services/fileManagerService";

function FileManager() {
  const dispatch = useDispatch();
  const { fileManagerData, isLoading, totalPage } = useSelector(selectFileManager);
  const token = useSelector(selectUserToken) || localStorage.getItem("token");

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [date, setDate] = useState({
    end_date: "",
    start_date: ""
  });
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  useEffect(() => {
    if (token) {
      dispatch(
        fetchFileListingThunkAction(
          {
            start_date: date?.start_date,
            end_date: date?.end_date
          },
          onSuccess,
          onError
        )
      );
    }

    // return () => {
    //   cancelRequest("getFileManagerListingApi");
    // }
  }, [token, date]);

  useEffect(() => {
    setFileData(fileManagerData);
  }, [fileManagerData]);

  const addNewImageHandler = (files = []) => {
    const requestBodyFormData = new FormData();

    files?.map((item) => {
      requestBodyFormData.append("files", item);
    });

    dispatch(
      postFileManagerThunkAction(requestBodyFormData, () => {
        dispatch(
          fetchFileListingThunkAction(
            {
              start_date: date?.start_date,
              end_date: date?.end_date
            },
            onSuccess,
            onError
          )
        );
      })
    );
  };

  const downloadFileHandle = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  const handleValue = (start_date, end_date) => {
    setDate({
      end_date: end_date,
      start_date: start_date
    });
  };

  const deleteFilesHandler = () => {
    if (!fileIds?.length) {
      toast.error("please select files.");
      return;
    }

    const reqBody = {
      file_ids: fileIds
    };

    dispatch(
      deleteFileManagerThunkAction(reqBody, () => {
        dispatch(
          fetchFileListingThunkAction(
            {
              start_date: date?.start_date,
              end_date: date?.end_date
            },
            () => {
              onSuccess();
              setFileIds([]);
            },
            onError
          )
        );
      })
    );
  };

  return (
    <div className="px-3">
      <div className="d-flex justify-content-between my-2">
        <h4>My Files</h4>
        <div>
          <Button color="primary" className="px-3 py-2" onClick={handleOpen}>
            + Upload File
          </Button>
          <AddImage
            addNewImageHandler={addNewImageHandler}
            handleClose={handleClose}
            modalOpen={modalOpen}
          />
        </div>
      </div>

      <Row className="mt-4">
        <Col>
          {isLoading ? (
            <Fragment>
              <div className="post_error_loading">
                <Loader />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {error ? (
                <Fragment>
                  <div className="post_error_loading">{error}</div>
                </Fragment>
              ) : (
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{
                    borderRadius: "0.5rem"
                  }}
                >
                  <CardHeader
                    style={{
                      borderRadius: "0.5rem"
                    }}
                  >
                    <Row className="justify-content-between">
                      <h5 className="mt-2 ml-2">Files</h5>{" "}
                      <div>
                        <Icon
                          icon="ci:trash-empty"
                          color="red"
                          width="20"
                          height="20"
                          className="mr-2"
                          style={{
                            cursor: "pointer"
                          }}
                          onClick={deleteFilesHandler}
                        />
                        <span id="TooltipExample">
                          <Icon
                            icon="ant-design:info-circle-outlined"
                            color="blue"
                            width="20"
                            height="20"
                          />
                        </span>

                        <Tooltip
                          isOpen={tooltipOpen}
                          placement="bottom"
                          target="TooltipExample"
                          toggle={() => {
                            setTooltipOpen(!tooltipOpen);
                          }}
                        >
                          Number of files - {fileData?.length}
                        </Tooltip>
                        <UncontrolledDropDown
                          dateFilter={date}
                          setDateFilter={setDate}
                          handleValue={handleValue}
                        />
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="row gallery">
                      {fileData?.map((file) => {
                        return (
                          <div className="col-lg-3 col-md-4 col-xs-6 thumb" key={file?.id}>
                            <div
                              key={file?.id}
                              className="card file-manager-item file"
                              onClick={() => downloadFileHandle(file?.url)}
                            >
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input largerCheckbox"
                                  id="customCheck7"
                                  onChange={() => {}}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (fileIds?.includes(file?.id)) {
                                      setFileIds((preData) =>
                                        preData?.filter((item) => item !== file?.id)
                                      );
                                    } else {
                                      setFileIds((preData) => [...preData, file?.id]);
                                    }
                                  }}
                                  checked={fileIds?.includes(file?.id)}
                                />
                                <label className="form-check-label" htmlFor="customCheck7"></label>
                              </div>
                              <div
                                className="card-img-top file-logo-wrapper"
                                style={{ backgroundColor: "#F2F2F2" }}
                              >
                                <div className="dropdown float-end">
                                  <i
                                    data-feather="more-vertical"
                                    className="toggle-dropdown mt-n25"
                                  ></i>
                                </div>
                                <div className="d-flex align-items-center justify-content-center w-100">
                                  <img
                                    src={getFileType(file?.type)}
                                    alt="file-icon"
                                    className="img-fluid img-thumbnail"
                                  />
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="content-wrapper">
                                  <p className="card-text file-name mb-0">{file?.filename}</p>
                                </div>
                                <small className="file-accessed text-muted">
                                  created {moment(file?.created_at).fromNow()}
                                </small>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {!error && fileData?.length === 0 && (
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <i data-feather="alert-circle" className="me-50"></i>
                          No Results
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Fragment>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default FileManager;
