import axiosInstance from "./axiosInstance";

export const getGovernmentListApi = (prms) =>
  axiosInstance.get("/api/v1/admin/govt-list", {
    params: {
      limit: prms.limit || 5,
      offset: prms?.page || 0,
      search: prms?.search || "",
      sort: prms?.sort || "",
      column: prms?.column || ""
    }
  });
