import { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import usePasswordShow from "hooks/usePasswordShow";
import { ResetPasswordUserApiThunkAction } from "redux/user/actions";
import { selectUser } from "redux/user/selectors";

import showPassword from "../../assets/img/theme/show-password-icon.png";
import hidePassword from "../../assets/img/theme/hide-password-icon.png";

import { Col, FormGroup, Row } from "reactstrap";
import AuthLogo from "components/AuthLogo/AuthLogo";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isVerify } = useSelector(selectUser);

  const { token } = useParams();

  const {
    passwordShown: passwordShown1,
    togglePasswordVisiblity: togglePasswordVisiblity1,
    type: type1
  } = usePasswordShow();
  const {
    passwordShown: passwordShown2,
    togglePasswordVisiblity: togglePasswordVisiblity2,
    type: type2
  } = usePasswordShow();

  const navigateToHome = () => {
    navigate("/auth/admin/login");
  };

  const submitHadler = async (e) => {
    e.preventDefault();

    if (password === "" || confirmPassword === "") {
      toast.error("Password and confirmPassword cannot be empty.");
      return;
    }

    if (password.trim() === "" || confirmPassword.trim() === "") {
      toast.error("White spaces are not allowed.");
      return;
    }

    if (password.includes(" ") || confirmPassword.includes(" ")) {
      toast.error("White spaces are not allowed.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Password and confirmPassword doesn't match.");
      return;
    }

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password)) {
      toast.error(
        "Password must contain 8 Characters that includes upper case,lower case,special character and digits"
      );
      return;
    }

    await dispatch(
      await ResetPasswordUserApiThunkAction(token, { password: password }, navigateToHome)
    );
  };

  return (
    <Fragment>
      <div className="login_container">
        <AuthLogo />
        <div className="login_form mx-auto">
          <Row className="d-flex justify-content-between px-5 pt-4">
            <div>
              <div className="login_welcome_text">Welcome to Alacriti</div>
              <h2 className="login_form_title">Reset Password</h2>
            </div>
          </Row>
          <form className="sign_up_form" onSubmit={submitHadler}>
            <Row className="px-lg-5 px-md-4 px-3 py-3">
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="password">
                    Password*
                  </label>
                  <div className="position-relative">
                    <input
                      type={type1}
                      className="form-control"
                      placeholder="Password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordShown1 ? (
                      <img
                        src={hidePassword}
                        alt="hide-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity1}
                      />
                    ) : (
                      <img
                        src={showPassword}
                        alt="show-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity1}
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="password">
                    Confirm Password*
                  </label>
                  <div className="position-relative">
                    <input
                      type={type2}
                      className="form-control"
                      placeholder="confirmPassword"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {passwordShown2 ? (
                      <img
                        src={hidePassword}
                        alt="hide-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity2}
                      />
                    ) : (
                      <img
                        src={showPassword}
                        alt="show-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity2}
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col sm={12}>
                <button className="btn login_btn mx-auto mb-4 mt-2" type="submit">
                  {isVerify ? "Submiting in..." : "Submit"}
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
