import Loader from "components/Loader/Loader";
import React from "react";
import "./BackdropLoader.css";

function BackdropLoader() {
  return (
    <div className="backdrop-loader">
      <div className="backdrop-text-center loading">
        <div className="backdrop-spinner-border" role="status">
          <Loader />
        </div>
      </div>
    </div>
  );
}

export default BackdropLoader;
