import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";

import { alpha, Avatar, Badge, Button, Menu, MenuItem, styled } from "@mui/material";
import "./Navbar.css";
import useViewport from "hooks/useViewport";
import { useDispatch, useSelector } from "react-redux";
import { selectUserRole } from "redux/user/selectors";
import userRoles from "utils/roles";
import { selectUser } from "redux/user/selectors";
import { getUserRoleName } from "utils/roles";
import { loggingOutUserThunkAction } from "redux/user/actions";
import { Progress } from "reactstrap";
import { fetchUsersProfileDetailThunkAction } from "redux/user/actions";
import { profilePercentageThunkAction } from "redux/user/actions";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""'
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0
    }
  }
}));

const AdminNavbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const role = useSelector(selectUserRole) || localStorage.getItem("role");
  const { loggedInUser, profilePercentage, userprofileDetail } = useSelector(selectUser);
  const [error, setError] = useState(null);
  const [usersProfileData, setUsersProfileData] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { width } = useViewport();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setUsersProfileData(userprofileDetail);
  }, [userprofileDetail, userprofileDetail?.length]);

  const completedProfilePercentage = () => {
    let percentage = 0;
    if (usersProfileData) {
      if (usersProfileData?.first_name !== "" && usersProfileData?.first_name !== null) {
        percentage += 1;
      }
      if (usersProfileData?.last_name !== "" && usersProfileData?.last_name !== null) {
        percentage += 1;
      }
      if (usersProfileData?.description !== "" && usersProfileData?.description !== null) {
        percentage += 1;
      }
      if (usersProfileData?.job_title !== "" && usersProfileData?.job_title !== null) {
        percentage += 1;
      }
      if (usersProfileData?.phone) {
        percentage += 1;
      }
      if (usersProfileData?.email) {
        percentage += 1;
      }
      if (usersProfileData?.union_member !== "" || usersProfileData?.union_member === null) {
        percentage += 1;
      }
      if (usersProfileData?.industries) {
        percentage += 1;
      }
      if (userprofileDetail?.gender) {
        percentage += 1;
      }
      if (usersProfileData?.ethnicity) {
        percentage += 1;
      }
      if (usersProfileData?.company !== "" && usersProfileData?.company !== null) {
        percentage += 1;
      }
      if (usersProfileData?.fein !== "" && usersProfileData?.fein !== null) {
        percentage += 1;
      }
      if (usersProfileData?.year_founded) {
        percentage += 1;
      }
      if (usersProfileData?.certification_type) {
        percentage += 1;
      }
      if (usersProfileData?.naics_code !== "" && usersProfileData?.naics_code !== null) {
        percentage += 1;
      }
      if (usersProfileData?.franchise !== "" || usersProfileData?.franchise === null) {
        percentage += 1;
      }
      if (usersProfileData?.division) {
        percentage += 1;
      }
      if (usersProfileData?.zip_code) {
        percentage += 1;
      }
      if (usersProfileData?.city) {
        percentage += 1;
      }
    }
    return percentage;
  };

  useEffect(() => {
    dispatch(profilePercentageThunkAction(completedProfilePercentage()));
  }, [completedProfilePercentage()]);
  const profilePageRoute = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/profile";
      case userRoles.GOVT:
        return "/gov/profile";
      case userRoles.FIRMS:
        return "/firms/profile";
      case userRoles.GOVT_FIRMS:
        return "/govt-firms/profile";
      default:
        return "/nofound";
    }
  };

  const loginPageRoute = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/auth/admin/login";
      case userRoles.GOVT:
        return "/auth/government/login";
      case userRoles.FIRMS:
        return "/auth/firm/login";
      case userRoles.GOVT_FIRMS:
        return "/auth/gov/firm/login";
      default:
        return "/auth/admin/login";
    }
  };

  const navigateToLoginPage = (userRole) => {
    navigate(`${loginPageRoute(userRole)}`, { replace: true });
  };

  const signOut = () => {
    dispatch(loggingOutUserThunkAction(navigateToLoginPage));
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  // const profilePercentage = sessionStorage.getItem("profilePercentage");

  useEffect(() => {
    dispatch(fetchUsersProfileDetailThunkAction(onSuccess, onError));
  }, []);

  const [profilePercentageValue, setProfilePercentageValue] = React.useState(profilePercentage);

  React.useEffect(() => {
    setProfilePercentageValue(profilePercentage);
  }, [profilePercentage]);

  return (
    <>
      <nav className="navbar navbar-light justify-content-between border-bottom">
        <a className="navbar-brand" href={`${process.env.REACT_APP_GO_TO_WEBSITE}`}>
          <Icon
            icon="pepicons:internet"
            width="24"
            heigth="24"
            className={width > 768 ? "mr-3 mb-1" : "ml-5 mb-1"}
          />
          {width > 768 ? <span style={{ fontWeight: "500" }}>Go to Website</span> : null}
        </a>

        <div className="d-flex align-items-center">
          {Number(role) === userRoles.FIRMS || Number(role) === userRoles.GOVT_FIRMS ? (
            <div
              style={{
                width: "200px",
                cursor: "pointer",
                borderRight: "1px solid #000000",
                marginRight: "1rem"
              }}
              onClick={() => navigate("profile")}
            >
              <span
                style={{
                  fontSize: "0.75rem",
                  fontWeight: "500"
                }}
              >
                Profile Completed
              </span>
              <Progress
                color="progressbar"
                style={{
                  height: "1.2rem",
                  borderRadius: "10px",
                  width: "90%",
                  marginRight: "-10rem"
                }}
                value={profilePercentageValue}
                max={19}
              >
                {((profilePercentageValue * 100) / 19)?.toFixed(2)}%
              </Progress>
            </div>
          ) : null}

          <div>
            <div className="navbar_username">{loggedInUser?.username}</div>
            <div className="navbar_user_role">{getUserRoleName(Number(role))}</div>
          </div>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            className="navbar-dropdown-btn"
            style={{
              backgroundColor: "#FFFFFF",
              color: "black"
            }}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </StyledBadge>
          </Button>
        </div>

        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <Link
            to={profilePageRoute()}
            style={{
              textDecoration: "none",
              color: "black"
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
          </Link>
          <MenuItem
            onClick={() => {
              signOut();
              handleClose();
            }}
            disableRipple
          >
            Sign out
          </MenuItem>
        </StyledMenu>
      </nav>
    </>
  );
};

export default AdminNavbar;
