import { useState, useEffect, Fragment } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Progress,
  Row
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentPlanThunkAction } from "../../redux/subscriptionPlan/actions";
import { selectSubscriptionPlan } from "../../redux/subscriptionPlan/selectors";

import "./GovernmentBillingPlans.css";
import BillingHistoryTable from "./BillingHistoryTable";
import Loader from "components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { selectUserRole } from "redux/user/selectors";
import toast from "react-hot-toast";
import moment from "moment";
import { CancelSubscriptionApi } from "services/plansServices";
import Swal from "sweetalert2";

function GovernmentBillingPlans({ freePlan }) {
  const [error, setError] = useState(null);
  const [currentPlan, setCurrentPlan] = useState({ current_plan: {} });

  const dispatch = useDispatch();
  const { PlanDetail, isLoading } = useSelector(selectSubscriptionPlan);
  const role = useSelector(selectUserRole) || localStorage.getItem("role");
  const navigate = useNavigate();

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  useEffect(() => {
    setCurrentPlan(PlanDetail);
  }, [PlanDetail]);

  useEffect(() => {
    dispatch(fetchCurrentPlanThunkAction(onSuccess, onError));
  }, []);

  const onUpdateSuccess = () => {
    dispatch(fetchCurrentPlanThunkAction(onSuccess, onError));
  };
  const onUpdateError = (error) => {
    toast.error(error);
  };

  const handleNavigation = () => {
    if (Number(role) === 4) {
      navigate("/firms/plans");
    } else {
      navigate("/gov/plans");
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const popupResponse = await Swal.fire({
        background: "#283046",
        color: "#fff",
        html: `<h5>Are you sure you want to cancel current subscription plan?</h5>`,
        showCancelButton: true,
        cancelButtonColor: "red",
        confirmButtonColor: "#005DD6",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      });

      if (popupResponse.isConfirmed) {
        const { data } = await CancelSubscriptionApi();

        if (data.success !== true) {
          throw new Error(data?.message);
        }
        toast.success("Cancel Subscription successfully");
        onUpdateSuccess();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error?.message);
      onUpdateError();
    }
  };

  return (
    <div>
      <Row className="mt-4">
        <Col sm={12} className={`content active-content`}>
          <Card
            className="card-stats mb-xl-0"
            style={{
              borderRadius: "0.5rem"
            }}
          >
            <CardHeader
              style={{
                borderTopLeftRadius: "0.5rem",
                borderTopRightRadius: "0.5rem"
              }}
            >
              <Row className="justify-content-between">
                <h6 className="ml-3">Current plan</h6>
                {Number(role) === 4 ? (
                  <h6
                    className="mr-3"
                    style={{
                      color: "#22c55e"
                    }}
                  >
                    Free Assessment : {freePlan}
                  </h6>
                ) : null}
              </Row>
            </CardHeader>
            {isLoading ? (
              <Fragment>
                <div className="post_error_loading">
                  <Loader />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {error ? (
                  <Fragment>
                    <div className="post_error_loading">{error}</div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <CardBody>
                      {Object?.keys(currentPlan?.current_plan || {}).length > 0 ? (
                        <Row>
                          <Col lg={6} md={12} className="mt-2">
                            <div className="mb-4">
                              <div className="Current_plan_header">
                                Your Current Plan is <b>{currentPlan?.current_plan?.plan_name}</b>
                              </div>
                              <div className="mt-1">{currentPlan?.current_plan?.description}</div>
                            </div>

                            <div className="mb-4">
                              {" "}
                              <div className="mt-2 Current_plan_header">
                                Your plan has been Active since{" "}
                                {moment(currentPlan?.current_plan?.plan_created_at)
                                  .add(1, "month")
                                  .format("MM-DD-YYYY")}
                              </div>
                            </div>
                            <div className="mt-3 text-capitalize Current_plan_header">
                              ${currentPlan?.popular_plan?.plan_price}{" "}
                              {currentPlan?.popular_plan?.plan_bill_type}{" "}
                              <Badge style={{ color: "#28C76F", backgroundColor: "#EAF9F1" }}>
                                popular
                              </Badge>
                            </div>
                            <div className="mt-1">
                              {currentPlan?.popular_plan?.plan_description}
                            </div>
                          </Col>
                          {Number(role) === 4 ? (
                            <Col lg={6} md={12} className="mt-2">
                              {currentPlan?.attention?.survey_limit / 2 <=
                              currentPlan?.attention?.count_of_survey ? (
                                <div className="profile_current_plan_alert">
                                  &nbsp; We need your attention!
                                  <div className="profile_current_plan_alert_child">
                                    &nbsp; your plan requires update because{" "}
                                    {currentPlan?.attention?.survey_limit -
                                      currentPlan?.attention?.count_of_survey}{" "}
                                    Assessment is over
                                  </div>
                                </div>
                              ) : null}

                              <>
                                <div className="d-flex justify-content-between mt-3">
                                  <span>
                                    <b>{Number(role) === 4 ? "Assessment" : "Firms"}</b>
                                  </span>
                                  <span>
                                    <b>
                                      {currentPlan?.attention?.count_of_survey} of{" "}
                                      {currentPlan?.attention?.survey_limit}
                                    </b>
                                  </span>
                                </div>
                                <Progress
                                  value={currentPlan?.attention?.count_of_survey}
                                  max={currentPlan?.attention?.survey_limit}
                                />
                                <div>
                                  {currentPlan?.attention?.survey_limit -
                                    currentPlan?.attention?.count_of_survey}{" "}
                                  {Number(role) === 4 ? "Assessment" : "firms"} remaining until your
                                  plan requires update
                                </div>
                              </>
                            </Col>
                          ) : (
                            <Col lg={6} md={12} className="mt-2">
                              {currentPlan?.attention?.contractor_limit / 2 <=
                              currentPlan?.attention?.count_of_contractors ? (
                                <div className="profile_current_plan_alert">
                                  &nbsp; We need your attention!
                                  <div className="profile_current_plan_alert_child">
                                    {Number(role) === 4 ? (
                                      <>
                                        &nbsp; your plan requires update because{" "}
                                        {currentPlan?.attention?.contractor_limit -
                                          currentPlan?.attention?.count_of_contractors}{" "}
                                        Assessment is over
                                      </>
                                    ) : (
                                      <>
                                        &nbsp; You are nearing your plan&lsquo;s limit. If you would
                                        like to upgrade your plan, please use the upgrade button or
                                        contact your account representative.
                                      </>
                                    )}
                                  </div>
                                </div>
                              ) : null}

                              <>
                                <div className="d-flex justify-content-between mt-3">
                                  <span>
                                    <b>{Number(role) === 4 ? "Assessment" : "Firms"}</b>
                                  </span>
                                  <span>
                                    <b>
                                      {currentPlan?.attention?.count_of_contractors} of{" "}
                                      {currentPlan?.attention?.contractor_limit}
                                    </b>
                                  </span>
                                </div>
                                <Progress
                                  value={currentPlan?.attention?.count_of_contractors}
                                  max={currentPlan?.attention?.contractor_limit}
                                />
                                <div>
                                  {currentPlan?.attention?.contractor_limit -
                                    currentPlan?.attention?.count_of_contractors}{" "}
                                  {Number(role) === 4 ? "Assessment" : "firms"} remaining until your
                                  plan requires update
                                </div>
                              </>
                            </Col>
                          )}
                        </Row>
                      ) : null}

                      {!error && Object?.keys(currentPlan?.current_plan || {}).length === 0 && (
                        <div className="text-center">
                          <div className="mt-3 text-capitalize">
                            ${currentPlan?.popular_plan?.plan_price}{" "}
                            {currentPlan?.popular_plan?.plan_bill_type}{" "}
                            <span>
                              <Badge style={{ color: "#28C76F", backgroundColor: "#EAF9F1" }}>
                                popular
                              </Badge>
                            </span>
                          </div>
                          <div className="mt-1">{currentPlan?.popular_plan?.plan_description}</div>
                        </div>
                      )}
                    </CardBody>
                  </Fragment>
                )}
              </Fragment>
            )}

            <CardFooter className="text-left">
              <Button color="primary" className="px-3 py-2" onClick={handleNavigation}>
                {Object?.keys(currentPlan?.current_plan || {}).length !== 0
                  ? "Upgrade Plan"
                  : "Add plan"}
              </Button>
              {/* {Object?.keys(currentPlan?.current_plan || {}).length !== 0 ? (
                <Button
                  outline
                  color="danger"
                  className=" px-3 py-2 ml-2"
                  onClick={handleCancelSubscription}
                >
                  Cancel Subscription
                </Button>
              ) : null} */}
            </CardFooter>
          </Card>
        </Col>

        <BillingHistoryTable />
      </Row>
    </div>
  );
}

export default GovernmentBillingPlans;
