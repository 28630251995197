import React from "react";
import ReactApexChart from "react-apexcharts";

class ApexBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Inflation",
          data: this.props.data
        }
      ],
      options: {
        fill: {
          colors: ["#083063"]
        },
        chart: {
          height: 480,
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: "45%",
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        grid: {
          strokeDashArray: 7
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#083063"]
          }
        },

        xaxis: {
          labels: {
            rotate: 0,
            trim: true,
            hideOverlappingLabels: false
          },
          categories: this.props.categories,
          position: "bottom",
          axisBorder: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          max: Math.max(...this.props.data) + 16,
          floating: false,
          decimalsInFloat: 0,
          min: 0,
          labels: {
            show: true,
            formatter: function (val) {
              return val.toFixed(0);
            }
          }
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => ""
            }
          },
          marker: {
            show: false
          }
        },
        title: {}
      }
    };
  }
  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={480}
        />
      </div>
    );
  }
}

export default ApexBarChart;
