import React, { Fragment, useState } from "react";
import { Alert, Button, Card, CardBody, Table, Tooltip } from "reactstrap";
import { Icon } from "@iconify/react";
import TooltipItem from "components/TooltipItem/TooltipItem";

function Documents({ docData }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className="p-4">
      <Card className="border-0">
        <CardBody>
          <Alert color="warning" className="mb-0">
            <div className="d-flex align-items-center">
              <span className="mr-3">
                <Icon icon="octicon:alert-16" width="28" height="28" />
              </span>
              <span className="">
                Downloading or printing a bid solicitation does not constitute registration to bid
                with the soliciting agency for this procurement. Failure to register, if required,
                releases the soliciting agency from any and all responsibility for advising the
                Registered User of any changes to the bid solicitation, or to the existence of
                supplemental information which may become available.&quot;
              </span>
            </div>
          </Alert>
        </CardBody>
      </Card>
      <h6 className="mt-4">Supporting document(s) shown below:</h6>
      <span>Click on the document title or the view icon to open/save</span>
      <Table className="mt-4 rounded" responsive bordered>
        <thead
          className="rounded"
          style={{
            backgroundColor: "#D8D8D8",
            borderRadius: "0.375rem 0.375rem 0rem 0rem"
          }}
        >
          <tr>
            <th>#</th>
            <th>Document Title</th>
            <th>Description</th>
            <th>Download/view</th>
          </tr>
        </thead>
        <tbody>
          {docData?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item?.title}</td>
              <td>{item?.description}</td>
              <td>
                {item?.file_name?.includes("http") ? (
                  <a href={item?.file_name} target="_blank" rel="noreferrer">
                    <Button color="primary" outline className="px-3 py-2">
                      <Icon icon="material-symbols:download" width="24" height="24" />
                      <span className="ml-2">View</span>
                    </Button>
                  </a>
                ) : (
                  <TooltipItem item={item} index={index} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Documents;
