import React from "react";
import ReactApexChart from "react-apexcharts";

class ReversedBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: this.props.data
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: "auto",
          toolbar: {
            show: false
          }
        },
        fill: {
          colors: ["#083063"]
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            barHeight: "45%",
            horizontal: true,
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#333"]
          },
          offsetX: 35
        },
        grid: {
          strokeDashArray: 7
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 500
            }
          },
          max: Math.max(...this.props.data) + 15,
          categories: this.props.categories
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 500
            }
          }
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => ""
            }
          },
          marker: {
            show: false
          }
        }
      }
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={localStorage.getItem("role") === "1" ? 430 : 460}
        />
      </div>
    );
  }
}

export default ReversedBarChart;
