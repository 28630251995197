import {
  FETCH_ALL_ASSESSMENT_LIST_REQUEST,
  FETCH_ALL_ASSESSMENT_LIST_SUCCESS,
  FETCH_ALL_ASSESSMENT_LIST_FAILURE,
  RESET_ALL_ASSESSMENT_LIST_DATA
} from "./types";

import { getAllAssessmentListApi } from "../../services/adminAllAssessmentServices";

export const fetchAllAssessmentListRequest = () => {
  return {
    type: FETCH_ALL_ASSESSMENT_LIST_REQUEST
  };
};

export const fetchAllAssessmentListSuccess = (allassessmentlist, totalPage) => {
  return {
    type: FETCH_ALL_ASSESSMENT_LIST_SUCCESS,
    payload: { allassessmentlist, totalPage }
  };
};

export const fetchAllAssessmentListFailure = (error) => {
  return {
    type: FETCH_ALL_ASSESSMENT_LIST_FAILURE,
    payload: error
  };
};

export const resetAllAssessmentList = () => {
  return {
    type: RESET_ALL_ASSESSMENT_LIST_DATA
  };
};

export const fetchAllAssessmentListThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAllAssessmentListRequest());
      const { data } = await getAllAssessmentListApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(
        fetchAllAssessmentListSuccess(data.data.data, data.data.meta.total_records / params.limit)
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchAllAssessmentListFailure(error));
      onError(error.response?.data?.message || error.message);
    }
  };
};
