import moment from "moment";

export const getColumns = (handleOpenModal, index) => {
  function convertToInternationalCurrencySystem(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(0) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + "K"
      : Math.abs(Number(labelValue));
  }
  return [
    {
      Header: " ",
      disableSortBy: true,
      columns: [
        {
          Header: "No.",
          accessor: "user.user_id",
          disableFilters: true,
          disableSortBy: true,
          disableGroupBy: false,
          Cell: ({ row }) => {
            return `${index + row.index + 1}`;
          }
        }
      ]
    },
    {
      Header: " ",
      disableSortBy: true,
      columns: [
        {
          Header: "Firm Name",
          accessor: "company",
          disableGroupBy: true,
          Cell: ({ row }) => {
            return <span>{row?.original?.user?.company}</span>;
          }
        }
      ]
    },

    {
      Header: "First Survey",
      disableSortBy: true,
      columns: [
        {
          Header: "Date",
          disableSortBy: true,
          accessor: "survey[0].created_at",
          Cell: ({ row }) => {
            return (
              <span>{moment(`${row.original.survey[0].created_at}`).format("MM-DD-YYYY")}</span>
            );
          }
        },
        {
          Header: "Score",
          disableSortBy: true,
          accessor: "survey[0].total_score"
        },
        {
          Header: "Employ.",
          disableSortBy: true,
          accessor: "survey[0].answers[2].answer_value",
          Cell: ({ row }) => {
            return (
              <span>
                {new Intl.NumberFormat("en-US").format(
                  row?.original?.survey[0].answers[2].answer_value
                )}
              </span>
            );
          }
        },
        {
          Header: "Revenue",
          disableSortBy: true,
          accessor: "survey[0].answers[1].answer_value",
          Cell: ({ row }) => {
            return (
              <span>
                {row?.original?.survey[0].answers[1].answer_value >= 0 ? "" : "-"}$
                {convertToInternationalCurrencySystem(
                  row?.original?.survey[0].answers[1].answer_value
                )}
              </span>
            );
          }
        },
        {
          Header: "Sales",
          disableSortBy: true,
          accessor: "survey[0].answers[0].answer_value",
          Cell: ({ row }) => {
            return (
              <span>
                {row?.original?.survey[0].answers[0].answer_value >= 0 ? "" : "-"}$
                {convertToInternationalCurrencySystem(
                  row?.original?.survey[0].answers[0].answer_value
                )}
              </span>
            );
          }
        }
      ]
    },
    {
      Header: "Second Survey",
      disableSortBy: true,
      columns: [
        {
          Header: "date",
          disableSortBy: true,
          accessor: "survey[1].created_at",
          Cell: ({ row }) => {
            return (
              <span>{moment(`${row.original.survey[1].created_at}`).format("MM-DD-YYYY")}</span>
            );
          }
        },
        {
          Header: "score",
          disableSortBy: true,
          accessor: "survey[1].total_score"
        },
        {
          Header: "employ.",
          disableSortBy: true,
          accessor: "survey[1].answers[2].answer_value",
          Cell: ({ row }) => {
            return (
              <span>
                {new Intl.NumberFormat("en-US").format(
                  row?.original?.survey[1].answers[2].answer_value
                )}
              </span>
            );
          }
        },
        {
          Header: "revenue",
          disableSortBy: true,
          accessor: "survey[1].answers[1].answer_value",
          Cell: ({ row }) => {
            return (
              <span>
                {row?.original?.survey[1].answers[1].answer_value >= 0 ? "" : "-"}$
                {convertToInternationalCurrencySystem(
                  row?.original?.survey[1].answers[1].answer_value
                )}
              </span>
            );
          }
        },
        {
          Header: "sales",
          disableSortBy: true,
          accessor: "survey[1].answers[0].answer_value",
          Cell: ({ row }) => {
            return (
              <span>
                {row?.original?.survey[1].answers[0].answer_value >= 0 ? "" : "-"}$
                {convertToInternationalCurrencySystem(
                  row?.original?.survey[1].answers[0].answer_value
                )}
              </span>
            );
          }
        }
      ]
    },
    {
      Header: " ",
      disableSortBy: true,
      columns: [
        {
          Header: "Average Credit Score",
          disableSortBy: true,
          accessor: "diff.avg_score"
        }
      ]
    },
    {
      Header: " ",
      disableSortBy: true,
      columns: [
        {
          Header: "New Employees",
          disableSortBy: true,
          accessor: "diff.employee_diff",
          Cell: ({ row }) => {
            return (
              <span>
                {new Intl.NumberFormat("en-US").format(row?.original?.diff.employee_diff)}
              </span>
            );
          }
        }
      ]
    },
    {
      Header: " ",
      disableSortBy: true,
      columns: [
        {
          Header: "Change Revenue",
          disableSortBy: true,
          accessor: "diff.revenue_diff",
          Cell: ({ row }) => {
            return (
              <span>${convertToInternationalCurrencySystem(row?.original?.diff.revenue_diff)}</span>
            );
          }
        }
      ]
    },
    {
      Header: " ",
      disableSortBy: true,
      columns: [
        {
          Header: "Change Sales",
          disableSortBy: true,
          accessor: "diff.sales_diff",
          Cell: ({ row }) => {
            return (
              <span>${convertToInternationalCurrencySystem(row?.original?.diff.sales_diff)}</span>
            );
          }
        }
      ]
    },
    {
      Header: " ",
      disableSortBy: true,
      columns: [
        {
          Header: "Sub.Category Score ",
          disableSortBy: true,
          Cell: ({ row }) => {
            return (
              <div
                className="mx-auto"
                style={{ color: "#1273EB", cursor: "pointer", textAlign: "center" }}
                onClick={() => handleOpenModal(row?.original)}
              >
                View
              </div>
            );
          }
        }
      ]
    }
  ];
};
