import React from "react";
import ReactApexChart from "react-apexcharts";

class BidAmountChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterBy: this.props.filterBy,
      series: [
        {
          name: "Amount",
          data: this.props.ChartData
        }
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            }
          },
          toolbar: {
            show: false
          }
        },
        fill: {
          colors: ["#FF9F43"]
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            borderRadius: 10
          }
        },
        grid: {
          strokeDashArray: 7
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: this.props.ChartLabels,
          labels: {
            style: {
              fontSize: "12px"
            }
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return "$" + val / 1000000 + "M";
            }
          },
          min: 0,
          max: 150000000,
          tickAmount: 6
        }
      }
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.ChartData !== prevState.ChartData) {
      return {
        filterBy: nextProps.filterBy,
        series: [
          {
            name: "Amount",
            data: nextProps.ChartData
          }
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            events: {
              click: function (chart, w, e) {
                // console.log(chart, w, e)
              }
            },
            toolbar: {
              show: false
            }
          },
          fill: {
            colors: ["#FF9F43"]
          },
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
              borderRadius: 10
            }
          },
          grid: {
            strokeDashArray: 7
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: nextProps.ChartLabels,
            labels: {
              style: {
                fontSize: "12px"
              }
            },
            axisBorder: {
              show: false
            }
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return "$" + val / 1000000 + "M";
              }
            },
            min: 0,
            max: 150000000,
            tickAmount: 6
          }
        }
      };
    }
    return null;
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default BidAmountChart;
