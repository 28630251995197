import React from "react";
import Sidebaritems from "./Sidebaritems";
import logo from "../../assets/img/theme/alcriti_logo.png";
import "./Sidebar.css";
import useViewport from "hooks/useViewport";
import { Button } from "reactstrap";
import { toggleContext } from "App";
import { Icon } from "@iconify/react";

const Sidebar = (props) => {
  const { routes } = props;
  const { width } = useViewport();
  const { isopen, setisOpen } = React.useContext(toggleContext);
  return (
    <React.Fragment>
      {width > 785 && (
        <div className={isopen ? "sidebar" : "sidebarclose"}>
          <div>
            <span className="d-flex justify-content-between align-items-center">
              <span className={isopen ? "mt-4 ml-2" : "d-none"}>
                <img alt=".." src={logo} width="160" />
                <p
                  className="text-light"
                  style={{
                    letterSpacing: "1px"
                  }}
                >
                  Capacity Analytics
                </p>
              </span>
              {isopen ? (
                <Icon
                  icon="heroicons-solid:chevron-double-left"
                  color="#b4b7bd"
                  width="32"
                  height="32"
                  alt="open icon"
                  className="m-3 px-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => setisOpen(!isopen)}
                />
              ) : (
                <Icon
                  icon="heroicons-solid:chevron-double-right"
                  color="#b4b7bd"
                  width="32"
                  height="32"
                  alt="open icon"
                  className="m-3 px-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => setisOpen(!isopen)}
                />
              )}
            </span>
          </div>

          {routes?.map((item, index) => (
            <Sidebaritems key={index} item={item} isopen={isopen} />
          ))}
        </div>
      )}
      {width < 785 && (
        <div className={isopen ? "sidebar" : "sidebarclose-active"}>
          <div>
            <span className="d-flex justify-content-between align-items-center">
              <span className={isopen ? "mt-4 ml-2" : "d-none"}>
                <img alt=".." src={logo} width="160" />
                <p
                  className="text-light"
                  style={{
                    letterSpacing: "1px"
                  }}
                >
                  Capacity Analytics
                </p>
              </span>
              <div className="toggle_button" onClick={() => setisOpen(!isopen)}>
                {isopen ? (
                  <Icon
                    icon="line-md:chevron-small-double-left"
                    color="#b4b7bd"
                    width="32"
                    height="32"
                    className="m-3 px-1"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Button className="m-1 px-1">
                    <i className="fas fa-align-left mx-2" />
                  </Button>
                )}
              </div>
            </span>
          </div>

          {routes?.map((item, index) => (
            <Sidebaritems key={index} item={item} isopen={isopen} />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
