import axiosInstance from "./axiosInstance";

export const getFirmsReportListApi = (prms) => {
  let path = "/api/v1/govt/firm-survey";

  if (localStorage.getItem("role") === "1") {
    path = "/api/v1/admin/firm-survey";
  }
  return axiosInstance.get(path, {
    params: {
      limit: prms.limit || 5,
      offset: prms?.page || 0,
      search: prms?.search || "",
      sort: prms?.sort || "",
      column: prms?.column || "",
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });
};

export const getAdminCompareSurveyAnalysisApi = (prms) => {
  let path = "/api/v1/govt/compare-survey-section";

  if (localStorage.getItem("role") === "1") {
    path = "/api/v1/admin/compare-survey-section";
  }
  return axiosInstance.get(path, {
    params: {
      survey_id1: prms?.survey_id1,
      survey_id2: prms?.survey_id2
    }
  });
};
