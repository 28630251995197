import React from "react";
import Uppy from "@uppy/core";

import { DashboardModal, useUppy } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Swal from "sweetalert2";

const AddImage = (props) => {
  const { handleClose, modalOpen, addNewImageHandler, ImageHandler } = props;

  const uppy = useUppy(() => {
    const upy = new Uppy({
      autoProceed: false,
      debug: false,
      restrictions: {
        minNumberOfFiles: 1,
        maxNumberOfFiles: 15,
        maxFileSize: 10485760,
        allowedFileTypes: [".jpg", ".jpeg", ".png", ".pdf", ".doc", ".docx"]
      },
      browserBackButtonClose: true
    }).on("complete", (response) => {
      const files = response.successful.map((file) => file.data);
      addNewImageHandler(files);
      handleClose();
      upy.cancelAll();
    });

    return upy;
  });

  React.useEffect(() => {
    return () => {
      uppy.close();
    };
  }, []);

  return (
    <>
      <DashboardModal
        showSelectedFiles
        uppy={uppy}
        closeModalOnClickOutside={false}
        closeAfterFinish
        open={modalOpen}
        onRequestClose={handleClose}
        plugins={["Webcam"]}
        proudlyDisplayPoweredByUppy={false}
      />
    </>
  );
};

export default AddImage;
