import { combineReducers } from "redux";
import userReducer from "./user/reducer";
import PlanslistReducer from "./subscriptionPlan/reducer";
import AssessmentListReducer from "./firmsListofAssessment/reducer";
import FirmsListreducer from "./adminListofFirms/reducer";
import GovernmentListreducer from "./adminListofGovernment/reducer";
import AllAssessmentListReducer from "./adminAssessmentList/reducer";
import DashboardReducer from "./dashboard/reducer";
import FirmsReportReducer from "./firmsReport/reducer";
import FileManagerReducer from "./fileManager/reducer";
import FirmsDashboardReducer from "./firmsDashboard/reducer";
import AdminFileManagerReducer from "./adminfileManager/reducer";
import bidReducer from "./biding/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  Planslist: PlanslistReducer,
  assessmentlists: AssessmentListReducer,
  firmslists: FirmsListreducer,
  governmentlists: GovernmentListreducer,
  allassessmentlists: AllAssessmentListReducer,
  dashboard: DashboardReducer,
  firmsReportLists: FirmsReportReducer,
  files: FileManagerReducer,
  adminfiles: AdminFileManagerReducer,
  firmsDashboard: FirmsDashboardReducer,
  bid: bidReducer
});

export default rootReducer;
