import { useState, useEffect, Fragment } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Progress,
  Row,
  Table,
  Tooltip
} from "reactstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import userRoles from "utils/roles";
import { selectUserToken } from "redux/user/selectors";
import Select from "react-select";
import {
  fetchUsersProfileDetailThunkAction,
  updateUserProfileThunkAction
} from "redux/user/actions";
import { selectUser } from "redux/user/selectors";
import { selectUserRole } from "redux/user/selectors";
import "./Profile.css";
import { Phone } from "@mui/icons-material";
import Loader from "components/Loader/Loader";
import GovernmentBillingPlans from "pages/GovernmentBillingPlans/GovernmentBillingPlans";
import MapModal from "components/Modals/MapModal";
import { Icon } from "@iconify/react";
import NumberFormat from "react-number-format";
import { industriesData } from "utils/roles";
import {
  GetCountries,
  GetState,
  GetCity //async functions
} from "react-country-state-city";
import { profilePercentageThunkAction } from "redux/user/actions";

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" }
];

function Profie() {
  const [usersProfileData, setUsersProfileData] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [year, setYear] = useState("");
  const [certification, setCertification] = useState("");
  // const [demographics, setDemographics] = useState("");
  const [naics_code, setNaics_code] = useState("");
  const [description, setDescription] = useState("");
  const [franchise, setFranchise] = useState("");
  const [franchisevalue, setFranchiseValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [regionData, setRegionData] = useState(null);
  const [oldData, setOldData] = useState(null);
  const [cityValue, setCityValue] = useState("");
  // const [categoryValue, setCategoryValue] = useState("");
  // const [subCategoryValue, setSubCategoryValue] = useState("");
  const [divisionValue, setDivisionValue] = useState("");
  const [zipCodeValue, setZipCodeValue] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [industryValue, setIndustryValue] = useState("");
  const [unionMember, setUnionMember] = useState("");
  const [unionMemberValue, setUnionMemberValue] = useState("");
  const [gender, setGender] = useState("");
  const [ethnicityValue, setEthnicityValue] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [FEIN, setFEIN] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [regionName, setRegionName] = useState("");
  const [countryId, setCountryId] = useState(0);
  const dispatch = useDispatch();
  const { userprofileDetail, isLoading } = useSelector(selectUser);
  const token = useSelector(selectUserToken);

  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const onUpdateSuccess = () => {
    dispatch(fetchUsersProfileDetailThunkAction(onSuccess, onError));
  };

  const onUpdateError = (error) => {
    toast.error(error);
  };

  const handleEdit = async () => {
    if (firstName?.trim() === "" || lastName?.trim() === "") {
      toast.error("First Name and Last Name is required.");
      return;
    }
    if (Number(role) === userRoles.FIRMS || Number(role) === userRoles.GOVT_FIRMS) {
      if (certification.trim() === "") {
        toast.error("Certification Type is required.");
        return;
      }
      if (description.trim() === "") {
        toast.error("Description is required.");
        return;
      }
      if (description.trim().length < 150) {
        toast.error("Description must be at least 150 characters.");
        return;
      }
      if (cityValue === "") {
        toast.error("City is required.");
        return;
      }
      if (zipCodeValue?.trim() === "") {
        toast.error("Zip Code is required.");
        return;
      }
      if (divisionValue?.trim() === "") {
        toast.error("Division is required.");
        return;
      }
      if (year?.trim() === "") {
        toast.error("Year is required.");
        return;
      }
      if (franchise === "true" && franchisevalue?.trim() === "") {
        toast.error("Franchise is required.");
        return;
      }
      if (unionMember === "true" && unionMemberValue?.trim() === "") {
        toast.error("Union Member is required.");
        return;
      }
      if (ethnicityValue?.trim() === "") {
        toast.error("Ethnicity is required.");
        return;
      }
      if (phoneNumber?.trim() === "") {
        toast.error("Phone Number is required.");
        return;
      }
      if (countryValue === "") {
        toast.error("Country is required.");
        return;
      }
      if (stateValue === "") {
        toast.error("State is required.");
        return;
      }
      // if (!regionData || regionData.length === 0) {
      //   toast.error("Please select at least one State, County and Region.");
      //   return;
      // }
    }
    setEdit((edit) => !edit);
    if (edit === false) {
      dispatch(
        await updateUserProfileThunkAction(
          {
            job_title: jobTitle?.trim() || "",
            year_founded: year || "",
            certification_type: certification || "",
            naics_code: naics_code?.trim() || "",
            description: description?.trim() || "",
            franchise: franchise === "true" ? franchisevalue : "No",
            union_member: unionMember === "true" ? unionMemberValue : "No",
            first_name: firstName || "",
            last_name: lastName || "",
            phone:
              phoneNumber.replace("-", "").replace("(", "").replace(")", "").replace(" ", "") || "",
            city: cityValue || "",
            industries: industryValue || "",
            division: divisionValue || "",
            zip_code: zipCodeValue || "",
            gender: gender,
            ethnicity: ethnicityValue || "",
            company: companyName?.trim() || "",
            fein: FEIN || "",

            location:
              cityValue !== "" && cityValue !== null
                ? [{ state: stateValue, county: countryValue, region: regionName }]
                : undefined
          },
          onUpdateSuccess,
          onUpdateError
        )
      );
    }
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  useEffect(() => {
    setUsersProfileData(userprofileDetail);
  }, [userprofileDetail, userprofileDetail?.length]);

  useEffect(() => {
    if (token) {
      dispatch(fetchUsersProfileDetailThunkAction(onSuccess, onError));
    }
  }, [token]);

  useEffect(() => {
    if (usersProfileData) {
      setJobTitle(usersProfileData?.job_title || "");
      setYear(usersProfileData?.year_founded || "");
      setCertification(usersProfileData?.certification_type || "");
      // setDemographics(usersProfileData?.owner_demographics);
      setNaics_code(usersProfileData?.naics_code || "");
      setDescription(usersProfileData?.description || "");
      setFranchiseValue(
        usersProfileData?.franchise === "No" ? "" : usersProfileData?.franchise || ""
      );
      setFranchise(usersProfileData?.franchise === "No" ? "false" : "true");
      setUnionMemberValue(
        usersProfileData?.union_member === "No" ? "" : usersProfileData?.union_member || ""
      );
      setUnionMember(
        usersProfileData?.union_member === "No" || usersProfileData?.union_member === null
          ? "false"
          : "true"
      );
      setFirstName(usersProfileData?.first_name || "");
      setLastName(usersProfileData?.last_name || "");
      setGender(userprofileDetail?.gender || "");
      setCityValue(usersProfileData?.city || "");
      setPhoneNumber(usersProfileData?.phone || "");
      // setCategoryValue(usersProfileData?.category);
      // setSubCategoryValue(usersProfileData?.sub_category);
      setIndustryValue(usersProfileData?.industries || "");
      setDivisionValue(usersProfileData?.division || "");
      setZipCodeValue(usersProfileData?.zip_code || "");
      setEthnicityValue(usersProfileData?.ethnicity || "");
      setCompanyName(usersProfileData?.company || "");
      setFEIN(usersProfileData?.fein || "");
      // setRegionData(
      //   userprofileDetail?.location?.map((data) => {
      //     return {
      //       stateName: data.state,
      //       countyName: data.county,
      //       regionName: data.region
      //     };
      //   })
      // );
      setCountryValue(userprofileDetail?.location[0]?.county);
      setStateValue(userprofileDetail?.location[0]?.state);
      setRegionName(userprofileDetail?.location[0]?.region);
    }
  }, [usersProfileData]);

  const mapModalHandler = (data) => {
    setRegionData(data);
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountryOptions(result.map((item) => ({ value: item.id, label: item.name, ...item })));
    });
  }, []);

  useEffect(() => {
    GetState(countryOptions.find((item) => item.name === countryValue)?.id).then((result) => {
      setStateOptions(result.map((item) => ({ value: item.id, label: item.name })));
    });
  }, [countryOptions, countryValue]);

  useEffect(() => {
    setTimeout(() => {
      GetCity(
        countryOptions.find((item) => item.name === countryValue)?.id,
        stateOptions.find((item) => item.label === stateValue)?.value
      ).then((result) => {
        setCityOptions(result.map((item) => ({ value: item.id, label: item.name })));
      });
    }, 1000);
  }, [stateOptions]);
  const completedProfilePercentage = () => {
    let percentage = 0;
    if (usersProfileData) {
      if (firstName.trim() !== "") {
        percentage += 1;
      }
      if (lastName.trim() !== "") {
        percentage += 1;
      }
      if (description.trim() !== "") {
        percentage += 1;
      }
      if (jobTitle.trim() !== "") {
        percentage += 1;
      }
      if (phoneNumber !== "") {
        percentage += 1;
      }
      if (usersProfileData?.email) {
        percentage += 1;
      }
      if (unionMember !== "") {
        percentage += 1;
      }
      if (industryValue !== "") {
        percentage += 1;
      }
      if (gender !== "") {
        percentage += 1;
      }
      if (ethnicityValue !== "") {
        percentage += 1;
      }
      if (companyName.trim() !== "") {
        percentage += 1;
      }
      if (FEIN.trim() !== "") {
        percentage += 1;
      }
      if (year !== "") {
        percentage += 1;
      }
      if (certification !== "") {
        percentage += 1;
      }
      if (naics_code.trim() !== "") {
        percentage += 1;
      }
      if (franchise !== "") {
        percentage += 1;
      }
      if (divisionValue !== "") {
        percentage += 1;
      }
      if (zipCodeValue !== "") {
        percentage += 1;
      }
      if (cityValue !== "") {
        percentage += 1;
      }
    }
    return percentage;
  };

  useEffect(() => {
    dispatch(profilePercentageThunkAction(completedProfilePercentage()));
  }, [completedProfilePercentage()]);

  const options = [
    { value: "SBE", label: "SBE" },
    { value: "MBE", label: "MBE" },
    { value: "LBE", label: "LBE" },
    { value: "WBE", label: "WBE" },
    { value: "DBE", label: "DBE" },
    { value: "SDVOB", label: "SDVOB" },
    { value: "LGBTQ", label: "LGBTQ" }
  ];

  const divisionOptions = [
    { value: "Construction", label: "Construction" },
    { value: "Goods", label: "Goods" },
    { value: "Services", label: "Services" }
  ];
  const ethnicityoptions = [
    { value: "Hispanic or Latino", label: "Hispanic or Latino" },
    { value: "Not Hispanic or Latino", label: "Not Hispanic or Latino" },
    { value: "White", label: "White" },
    { value: "Black or African American", label: "Black or African American" },
    { value: "American Indian or Alaska Native", label: "American Indian or Alaska Native" },
    { value: "Asian", label: "Asian" },
    {
      value: "Native Hawaiian or Other Pacific -Islander",
      label: "Native Hawaiian or Other Pacific -Islander"
    },
    { value: "Some Other Race", label: "Some Other Race" }
  ];

  // const cityOptions = [
  //   { value: "New York", label: "New York" },
  //   { value: "Los Angeles", label: "Los Angeles" },
  //   { value: "Chicago", label: "Chicago" },
  //   { value: "Houston", label: "Houston" },
  //   { value: "Phoenix", label: "Phoenix" },
  //   { value: "Philadelphia", label: "Philadelphia" },
  //   { value: "San Antonio", label: "San Antonio" },
  //   { value: "San Diego", label: "San Diego" },
  //   { value: "Dallas", label: "Dallas" },
  //   { value: "San Jose", label: "San Jose" },
  //   { value: "Austin", label: "Austin" },
  //   { value: "Jacksonville", label: "Jacksonville" },
  //   { value: "Fort Worth", label: "Fort Worth" },
  //   { value: "Columbus", label: "Columbus" },
  //   { value: "Charlotte", label: "Charlotte" },
  //   { value: "San Francisco", label: "San Francisco" },
  //   { value: "Indianapolis", label: "Indianapolis" },
  //   { value: "Seattle", label: "Seattle" },
  //   { value: "Denver", label: "Denver" },
  //   { value: "Washington", label: "Washington" },
  //   { value: "Boston", label: "Boston" },
  //   { value: "El Paso", label: "El Paso" },
  //   { value: "Nashville", label: "Nashville" },
  //   { value: "Detroit", label: "Detroit" },
  //   { value: "Oklahoma City", label: "Oklahoma City" },
  //   { value: "Portland", label: "Portland" },
  //   { value: "Las Vegas", label: "Las Vegas" },
  //   { value: "Memphis", label: "Memphis" },
  //   { value: "Louisville", label: "Louisville" },
  //   { value: "Baltimore", label: "Baltimore" },
  //   { value: "Milwaukee", label: "Milwaukee" },
  //   { value: "Albuquerque", label: "Albuquerque" },
  //   { value: "Tucson", label: "Tucson" },
  //   { value: "Fresno", label: "Fresno" },
  //   { value: "Mesa", label: "Mesa" },
  //   { value: "Sacramento", label: "Sacramento" },
  //   { value: "Atlanta", label: "Atlanta" }
  // ];

  const categoryOptions = [
    { value: "Construction", label: "Construction" },
    { value: "Goods", label: "Goods" },
    { value: "Services", label: "Services" }
  ];

  const subCategoryOptions = [
    { value: "Construction", label: "Construction" },
    { value: "Goods", label: "Goods" },
    { value: "Services", label: "Services" }
  ];

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "black"
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "black"
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 10000
    }),
    menuList: (base) => ({ ...base, maxHeight: "200px" })
  };

  return (
    <div className="px-3">
      <Row className="mt-4">
        <Col sm={12} className={`content active-content`}>
          <MapModal
            open={isOpen}
            oldData={oldData}
            closeModal={() => {
              setIsOpen(false);
            }}
            submit={mapModalHandler}
          />
          <Card
            className="card-stats mb-xl-0"
            style={{
              borderRadius: "0.5rem"
            }}
          >
            <CardHeader
              style={{
                borderTopLeftRadius: "0.5rem",
                borderTopRightRadius: "0.5rem"
              }}
            >
              <Row className="justify-content-between align-items-center mx-2">
                <h5>Profile Details</h5>
                {Number(role) === userRoles.FIRMS || Number(role) === userRoles.GOVT_FIRMS ? (
                  <Progress
                    color="progressbar"
                    style={{
                      height: "1.2rem",
                      borderRadius: "10px",
                      width: "50%",
                      marginRight: "-10rem"
                    }}
                    value={completedProfilePercentage()}
                    max={19}
                  >
                    {userprofileDetail && ((completedProfilePercentage() * 100) / 19)?.toFixed(2)}%
                  </Progress>
                ) : null}

                <Button
                  size="sm"
                  color="primary"
                  className="font-weight-bold text-sm px-3 py-2"
                  onClick={() => {
                    if (!edit) {
                      handleEdit();
                    } else {
                      setEdit(!edit);
                    }
                  }}
                >
                  Edit / Save
                </Button>
              </Row>
            </CardHeader>
            <CardBody
              style={{
                minHeight: "200px"
              }}
            >
              {isLoading ? (
                <Fragment>
                  <div className="post_error_loading">
                    <Loader />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {error ? (
                    <Fragment>
                      <div className="post_error_loading">{error}</div>
                    </Fragment>
                  ) : (
                    <Row>
                      {(() => {
                        switch (Number(role)) {
                          case userRoles.ADMIN:
                            return (
                              <Col lg={6} md={12}>
                                <Table className="table-borderless table_font" responsive>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Name{" "}
                                        <b
                                          style={{
                                            color: "red"
                                          }}
                                        >
                                          *
                                        </b>
                                      </td>
                                      <td>:</td>
                                      <td className="d-flex">
                                        {edit ? (
                                          <b>
                                            {usersProfileData?.first_name}{" "}
                                            {usersProfileData?.last_name}
                                          </b>
                                        ) : (
                                          <>
                                            <input
                                              value={firstName}
                                              type="text"
                                              className={edit ? "table_data" : "form-control"}
                                              id="firstName"
                                              name="firstName"
                                              onChange={(e) => setFirstName(e.target.value)}
                                            />{" "}
                                            <input
                                              value={lastName}
                                              type="text"
                                              className={edit ? "table_data" : "form-control"}
                                              id="lastName"
                                              name="lastName"
                                              onChange={(e) => setLastName(e.target.value)}
                                            />
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Username</td>
                                      <td>:</td>
                                      <td>
                                        <b>{usersProfileData?.username}</b>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Phone
                                        <b
                                          style={{
                                            color: "red"
                                          }}
                                        >
                                          *
                                        </b>
                                      </td>
                                      <td>:</td>

                                      {edit ? (
                                        <td>
                                          <b>
                                            {usersProfileData?.phone
                                              ? usersProfileData?.phone
                                              : "NA"}
                                          </b>
                                        </td>
                                      ) : (
                                        <td>
                                          <NumberFormat
                                            className="form-control"
                                            format="(###) ###-####"
                                            mask="_"
                                            placeholder="Phone"
                                            id="phone"
                                            name="phone"
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            value={phoneNumber}
                                          />
                                        </td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td>Email</td>
                                      <td>:</td>
                                      <td>
                                        <b>{usersProfileData?.email}</b>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            );
                          case userRoles.GOVT:
                            return (
                              <>
                                <Col lg={6} md={12}>
                                  <Table className="table-borderless table_font" responsive>
                                    <tbody>
                                      <tr>
                                        <td>
                                          Name{" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        <td className="d-flex">
                                          {edit ? (
                                            <b>
                                              {usersProfileData?.first_name}{" "}
                                              {usersProfileData?.last_name}
                                            </b>
                                          ) : (
                                            <>
                                              <input
                                                value={firstName}
                                                type="text"
                                                className={edit ? "table_data" : "form-control"}
                                                id="firstName"
                                                name="firstName"
                                                onChange={(e) => setFirstName(e.target.value)}
                                              />{" "}
                                              <input
                                                value={lastName}
                                                type="text"
                                                className={edit ? "table_data" : "form-control"}
                                                id="lastName"
                                                name="lastName"
                                                onChange={(e) => setLastName(e.target.value)}
                                              />
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Username</td>
                                        <td>:</td>
                                        <td>
                                          <b>{usersProfileData?.username}</b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Job Title</td>
                                        <td>:</td>
                                        <td>
                                          <input
                                            value={jobTitle}
                                            type="text"
                                            className={edit ? "table_data" : "form-control"}
                                            id="jobTitle"
                                            disabled={edit}
                                            name="jobTitle"
                                            onChange={(e) => setJobTitle(e.target.value)}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Phone
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>

                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.phone
                                                ? usersProfileData?.phone
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <NumberFormat
                                              className="form-control"
                                              format="(###) ###-####"
                                              mask="_"
                                              placeholder="Phone"
                                              id="phone"
                                              name="phone"
                                              onChange={(e) => setPhoneNumber(e.target.value)}
                                              value={phoneNumber}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>Email</td>
                                        <td>:</td>
                                        <td>
                                          <b>{usersProfileData?.email}</b>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                                <Col lg={6} md={12}>
                                  <Table className="table-borderless table_font" responsive>
                                    <tbody>
                                      <tr>
                                        <td>Agency Type</td>
                                        <td>:</td>
                                        <td>
                                          <b>
                                            {usersProfileData?.agency_type
                                              ? usersProfileData?.agency_type
                                              : "NA"}
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>FEIN</td>
                                        <td>:</td>
                                        <td>
                                          <b>
                                            {usersProfileData?.fein ? usersProfileData?.fein : "NA"}
                                          </b>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </>
                            );
                          default:
                            return (
                              <>
                                <Col lg={6} md={12}>
                                  <Table className="table-borderless table_font">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Name
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        <td className="d-flex">
                                          {edit ? (
                                            <b>
                                              {usersProfileData?.first_name}{" "}
                                              {usersProfileData?.last_name}
                                            </b>
                                          ) : (
                                            <>
                                              <input
                                                value={firstName}
                                                type="text"
                                                className={edit ? "table_data" : "form-control"}
                                                id="firstName"
                                                name="firstName"
                                                onChange={(e) => setFirstName(e.target.value)}
                                              />{" "}
                                              <input
                                                value={lastName}
                                                type="text"
                                                className={edit ? "table_data" : "form-control"}
                                                id="lastName"
                                                name="lastName"
                                                onChange={(e) => setLastName(e.target.value)}
                                              />
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Username</td>
                                        <td>:</td>
                                        <td>
                                          <b>{usersProfileData?.username}</b>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          Description{" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <td
                                            style={{
                                              wordBreak: "break-word"
                                            }}
                                          >
                                            <b>
                                              {usersProfileData?.description
                                                ? usersProfileData?.description
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <textarea
                                              value={description}
                                              className={edit ? "table_data" : "form-control"}
                                              id="description"
                                              disabled={edit}
                                              name="description"
                                              onChange={(e) => setDescription(e.target.value)}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>Job Title</td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.job_title
                                                ? usersProfileData?.job_title
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <input
                                              value={jobTitle}
                                              type="text"
                                              className={edit ? "table_data" : "form-control"}
                                              id="jobTitle"
                                              disabled={edit}
                                              name="jobTitle"
                                              onChange={(e) => setJobTitle(e.target.value)}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          Phone
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>

                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.phone
                                                ? usersProfileData?.phone
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <NumberFormat
                                              className="form-control"
                                              format="(###) ###-####"
                                              mask="_"
                                              placeholder="Phone"
                                              id="phone"
                                              name="phone"
                                              onChange={(e) => setPhoneNumber(e.target.value)}
                                              value={phoneNumber}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>Email</td>
                                        <td>:</td>
                                        <td>
                                          <b>{usersProfileData?.email}</b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Union member
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <>
                                            <td>
                                              <b>
                                                {usersProfileData?.union_member === null
                                                  ? "No"
                                                  : usersProfileData?.union_member}
                                              </b>
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td>
                                              <input
                                                value={"true"}
                                                id="yes"
                                                type="radio"
                                                className="mx-2"
                                                name="unionMember"
                                                defaultChecked={unionMember == "true"}
                                                onChange={(e) => setUnionMember(e.target.value)}
                                              />
                                              Yes
                                              <input
                                                value={"false"}
                                                id="no"
                                                type="radio"
                                                className="mx-2"
                                                name="unionMember"
                                                defaultChecked={unionMember === "false"}
                                                onChange={(e) => setUnionMember(e.target.value)}
                                              />
                                              No
                                              {unionMember === "true" && (
                                                <input
                                                  type="text"
                                                  value={unionMemberValue}
                                                  className="form-control"
                                                  placeholder="unionMember"
                                                  id="unionMember"
                                                  name="unionMemberValue"
                                                  onChange={(e) =>
                                                    setUnionMemberValue(e.target.value)
                                                  }
                                                />
                                              )}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>Industries</td>
                                        <td>:</td>

                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.industries
                                                ? usersProfileData?.industries
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Input
                                              type="select"
                                              className={edit ? "table_data" : "form-control"}
                                              placeholder="Industries"
                                              id="industries"
                                              name="industries"
                                              onChange={(e) => setIndustryValue(e.target.value)}
                                              value={industryValue || ""}
                                              defaultChecked={false}
                                            >
                                              <option disabled value="">
                                                Select Industries type
                                              </option>

                                              {industriesData.map((item) => {
                                                return <option key={item}>{item}</option>;
                                              })}
                                            </Input>
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>Gender</td>
                                        <td>:</td>

                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.gender
                                                ? usersProfileData?.gender
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Select
                                              options={genderOptions}
                                              onChange={(e) => {
                                                setGender(e.value);
                                              }}
                                              value={genderOptions.filter(
                                                (item) => item.value === gender
                                              )}
                                              styles={customStyles}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          Ethnicity
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>

                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.ethnicity
                                                ? usersProfileData?.ethnicity
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Select
                                              options={ethnicityoptions}
                                              isMulti
                                              onChange={(e) => {
                                                setEthnicityValue(
                                                  e.map((item) => item.value).toString()
                                                );
                                              }}
                                              value={
                                                ethnicityValue !== "" &&
                                                ethnicityValue?.split(",").map((data) => {
                                                  return { value: data, label: data };
                                                })
                                              }
                                              styles={customStyles}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>Generated Naics Codes</td>
                                        <td>:</td>
                                        <td>
                                          <b>
                                            {usersProfileData?.generated_naics_codes
                                              ? usersProfileData?.generated_naics_codes.substring(
                                                  1,
                                                  usersProfileData?.generated_naics_codes.length - 1
                                                )
                                              : "NA"}
                                          </b>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                                <Col lg={6} md={12}>
                                  <Table className="table-borderless table_font">
                                    <tbody>
                                      <tr>
                                        <td>Company Name</td>
                                        <td>:</td>

                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.company
                                                ? usersProfileData?.company
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <input
                                              value={companyName}
                                              type="text"
                                              className={edit ? "table_data" : "form-control"}
                                              id="companyName"
                                              disabled={edit}
                                              name="companyName"
                                              onChange={(e) => setCompanyName(e.target.value)}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>FEIN</td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.fein
                                                ? usersProfileData?.fein
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <input
                                              value={FEIN}
                                              type="text"
                                              className={edit ? "table_data" : "form-control"}
                                              id="FEIN"
                                              disabled={edit}
                                              name="FEIN"
                                              onChange={(e) => setFEIN(e.target.value)}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          Year founded{" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.year_founded
                                                ? usersProfileData?.year_founded
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <input
                                              value={year}
                                              type="number"
                                              min="0"
                                              className={edit ? "table_data" : "form-control"}
                                              id="year"
                                              disabled={edit}
                                              name="year"
                                              onChange={(e) => {
                                                if (Number(e.target.value) > -1) {
                                                  setYear(e.target.value);
                                                }
                                              }}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          Certification Type(s){" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.certification_type
                                                ? usersProfileData?.certification_type
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Select
                                              options={options}
                                              value={
                                                certification !== "" &&
                                                certification?.split(",").map((data) => {
                                                  return { value: data, label: data };
                                                })
                                              }
                                              maxMenuHeight={120}
                                              isMulti
                                              onChange={(e) => {
                                                setCertification(
                                                  e.map((item) => item.value).toString()
                                                );
                                              }}
                                              styles={customStyles}
                                              menuPortalTarget={document.body}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      {/* <tr>
                                        <td>Owner Demographics</td>
                                        <td>:</td>
                                        <td>
                                          <input
                                            value={demographics}
                                            type="text"
                                            className={edit ? "table_data" : "form-control"}
                                            id="demographics"
                                            disabled={edit}
                                            name="demographics"
                                            onChange={(e) => setDemographics(e.target.value)}
                                          />
                                        </td>
                                      </tr> */}

                                      <tr>
                                        <td>
                                          NAICS Code(s){" "}
                                          <a
                                            href="https://www.naics.com/search/"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <span id="TooltipExample">
                                              <Icon
                                                icon="ep:info-filled"
                                                color="black"
                                                width="15"
                                                height="15"
                                                className="mb-2"
                                              />
                                            </span>

                                            <Tooltip
                                              isOpen={tooltipOpen}
                                              placement="top"
                                              target="TooltipExample"
                                              toggle={() => {
                                                setTooltipOpen(!tooltipOpen);
                                              }}
                                            >
                                              <div className="text-center">
                                                <span className="m-1">
                                                  For more Information visit :{" "}
                                                  <b>www.naics.com/search</b>
                                                </span>
                                              </div>
                                            </Tooltip>
                                          </a>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>
                                              {usersProfileData?.naics_code
                                                ? usersProfileData?.naics_code
                                                : "NA"}
                                            </b>
                                          </td>
                                        ) : (
                                          <td>
                                            <input
                                              value={naics_code}
                                              type="text"
                                              className={edit ? "table_data" : "form-control"}
                                              id="naics_code"
                                              disabled={edit}
                                              name="naics_code"
                                              onChange={(e) => setNaics_code(e.target.value)}
                                            />
                                          </td>
                                        )}
                                      </tr>

                                      <tr>
                                        <td>
                                          Franchise{" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <>
                                            <td>
                                              <b>{usersProfileData?.franchise}</b>
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td>
                                              <input
                                                value={"true"}
                                                id="yes"
                                                type="radio"
                                                className="mx-2"
                                                name="franchise"
                                                defaultChecked={franchise == "true"}
                                                onChange={(e) => setFranchise(e.target.value)}
                                              />
                                              Yes
                                              <input
                                                value={"false"}
                                                id="no"
                                                type="radio"
                                                className="mx-2"
                                                name="franchise"
                                                defaultChecked={franchise === "false"}
                                                onChange={(e) => setFranchise(e.target.value)}
                                              />
                                              No
                                              {franchise === "true" && (
                                                <input
                                                  type="text"
                                                  value={franchisevalue}
                                                  className="form-control"
                                                  placeholder="franchise"
                                                  id="franchise"
                                                  name="franchiseValue"
                                                  onChange={(e) =>
                                                    setFranchiseValue(e.target.value)
                                                  }
                                                />
                                              )}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                      {/* <tr>
                                        <td>Choose State, County and Region via Map</td>
                                        <td>:</td>
                                        <td>
                                          <Button
                                            className="btn-block"
                                            type="button"
                                            size="lg"
                                            onClick={() => {
                                              setIsOpen(true);
                                              setOldData(regionData);
                                            }}
                                          >
                                            Click Here for Map
                                          </Button>
                                        </td>
                                      </tr> */}
                                      <tr>
                                        <td>
                                          Division{" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>{usersProfileData?.division}</b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Select
                                              options={divisionOptions}
                                              value={divisionValue?.split(",")?.map((data) => {
                                                return { value: data, label: data };
                                              })}
                                              maxMenuHeight={120}
                                              onChange={(e) => {
                                                setDivisionValue(e.value);
                                              }}
                                              styles={customStyles}
                                              menuPortalTarget={document.body}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          Country{" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>{countryValue}</b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Select
                                              options={countryOptions}
                                              placeholder=""
                                              isClearable={false}
                                              isSearchable={true}
                                              value={
                                                countryValue !== ""
                                                  ? countryOptions.find(
                                                      (option) => option.label == countryValue
                                                    )
                                                  : ""
                                              }
                                              onChange={(option) => {
                                                setStateValue("");
                                                setCityValue("");
                                                if (option) {
                                                  GetState(option.value).then((result) => {
                                                    setStateOptions(
                                                      result.map((item) => ({
                                                        value: item.id,
                                                        label: item.name
                                                      }))
                                                    );
                                                  });
                                                  setCountryValue(option.label);
                                                  setRegionName(option.region);
                                                  setCountryId(option.value);
                                                } else {
                                                  setStateOptions([]);
                                                  setCityOptions([]);
                                                  setCountryValue("");
                                                  setStateValue("");
                                                }
                                              }}
                                              styles={customStyles}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          State{" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>{stateValue}</b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Select
                                              options={stateOptions}
                                              isClearable={false}
                                              isSearchable={true}
                                              placeholder=""
                                              value={
                                                stateValue !== ""
                                                  ? stateOptions.find(
                                                      (option) => option.label === stateValue
                                                    )
                                                  : ""
                                              }
                                              onChange={(option) => {
                                                setCityValue("");
                                                if (option) {
                                                  GetCity(countryId, option.value).then(
                                                    (result) => {
                                                      setCityOptions(
                                                        result.map((item) => ({
                                                          value: item.id,
                                                          label: item.name
                                                        }))
                                                      );
                                                    }
                                                  );
                                                  setStateValue(option.label);
                                                } else {
                                                  setCityOptions([]);
                                                  setStateValue("");
                                                }
                                              }}
                                              styles={customStyles}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          City{" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>{cityValue}</b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Select
                                              options={cityOptions}
                                              placeholder=""
                                              isClearable={false}
                                              isSearchable={true}
                                              onChange={(option) => {
                                                setCityValue(option ? option.label : "");
                                              }}
                                              value={
                                                cityValue !== ""
                                                  ? cityOptions.find(
                                                      (option) => option.label === cityValue
                                                    )
                                                  : ""
                                              }
                                              styles={customStyles}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>
                                          ZipCode{" "}
                                          <b
                                            style={{
                                              color: "red"
                                            }}
                                          >
                                            *
                                          </b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                          {edit ? (
                                            <b>{usersProfileData?.zip_code}</b>
                                          ) : (
                                            <input
                                              value={zipCodeValue}
                                              type="text"
                                              className={edit ? "table_data" : "form-control"}
                                              id="zipCode"
                                              disabled={edit}
                                              name="zipCode"
                                              onChange={(e) => setZipCodeValue(e.target.value)}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <td>Category</td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>{usersProfileData?.category}</b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Select
                                              options={categoryOptions}
                                              value={categoryValue?.split(",").map((data) => {
                                                return { value: data, label: data };
                                              })}
                                              maxMenuHeight={120}
                                              onChange={(e) => {
                                                setCategoryValue(e.value);
                                              }}
                                              styles={customStyles}
                                              menuPortalTarget={document.body}
                                            />
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        <td>Sub Category</td>
                                        <td>:</td>
                                        {edit ? (
                                          <td>
                                            <b>{usersProfileData?.sub_category}</b>
                                          </td>
                                        ) : (
                                          <td>
                                            <Select
                                              options={subCategoryOptions}
                                              value={subCategoryValue?.split(",").map((data) => {
                                                return { value: data, label: data };
                                              })}
                                              maxMenuHeight={120}
                                              onChange={(e) => {
                                                setSubCategoryValue(e.value);
                                              }}
                                              styles={customStyles}
                                              menuPortalTarget={document.body}
                                            />
                                          </td>
                                        )}
                                      </tr> */}
                                      {Number(role) === 3 ? (
                                        <tr
                                          style={{
                                            color: "#22c55e"
                                          }}
                                        >
                                          <td>Free Assessment</td>
                                          <td>:</td>
                                          <td>
                                            <b>
                                              {usersProfileData?.free_survey_limit
                                                ? usersProfileData?.free_survey_limit
                                                : "0"}
                                            </b>
                                          </td>
                                        </tr>
                                      ) : null}
                                    </tbody>
                                  </Table>
                                </Col>
                              </>
                            );
                        }
                      })()}
                    </Row>
                  )}
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* {Number(role) === 3 || Number(role) === 4 ? (
        <Row className="mt-4">
          <Col sm={12} className={`content active-content`}>
            <Card
              className="card-stats mb-xl-0"
              style={{
                borderRadius: "0.5rem"
              }}
            >
              <CardHeader
                style={{
                  borderTopLeftRadius: "0.5rem",
                  borderTopRightRadius: "0.5rem"
                }}
              >
                <Row className="justify-content-between align-items-center mx-2">
                  <h5>Choose State, County and Region via Map</h5>
                  {edit ? null : (
                    <Button
                      size="sm"
                      color="primary"
                      className="font-weight-bold text-sm px-3 py-2"
                      onClick={() => {
                        setIsOpen(true);
                        setOldData(regionData);
                      }}
                    >
                      Click Here for Map
                    </Button>
                  )}
                </Row>
              </CardHeader>
              <CardBody
                style={{
                  minHeight: "200px"
                }}
              >
                {isLoading ? (
                  <Fragment>
                    <div className="post_error_loading">
                      <Loader />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    {error ? (
                      <Fragment>
                        <div className="post_error_loading">{error}</div>
                      </Fragment>
                    ) : (
                      <>
                        <Row>
                          <Col lg="4" className="">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="state">
                                State
                              </label>
                              <input
                                type="text"
                                disabled
                                className="form-control"
                                placeholder="State"
                                id="state"
                                name="state"
                                value={regionData?.length > 0 ? regionData[0]?.stateName : ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.state && formik.errors.state ? (
                                <div className="text-danger mt-2">{formik.errors.state}</div>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4" className="">
                            <label className="form-control-label" htmlFor="county">
                              County
                            </label>
                          </Col>
                          <Col lg="4" className="">
                            <label className="form-control-label" htmlFor="region">
                              Region
                            </label>
                          </Col>
                        </Row>

                        {regionData?.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <Row>
                                <Col lg="4" className="">
                                  <FormGroup>
                                    <input
                                      type="text"
                                      disabled
                                      className="form-control"
                                      placeholder="County"
                                      id="county"
                                      name="county"
                                      value={item.countyName}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.county && formik.errors.county ? (
                                      <div className="text-danger mt-2">{formik.errors.county}</div>
                                    ) : null}
                                  </FormGroup>
                                </Col>

                                <Col lg="4" className="">
                                  <FormGroup>
                                    <input
                                      type="text"
                                      disabled
                                      className="form-control"
                                      placeholder="Region"
                                      id="region"
                                      name="region"
                                      value={item.regionName}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.region && formik.errors.region ? (
                                      <div className="text-danger mt-2">{formik.errors.region}</div>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Fragment>
                          );
                        })}
                      </>
                    )}
                  </Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null} */}

      {Number(role) === 2 || Number(role) === 4 ? (
        <GovernmentBillingPlans freePlan={userprofileDetail?.free_survey_limit} />
      ) : null}
    </div>
  );
}

export default Profie;
