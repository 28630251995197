import axiosInstance from "./axiosInstance";

export const getAdminDashboardTotalFirmsApi = (prms) => {
  let path = "/api/v1/admin/dashboard/firms";

  if (localStorage.getItem("role") === "2") {
    path = "/api/v1/govt/dashboard/survey-data";
  }

  return axiosInstance.get(path, {
    params: {
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });
};

export const getAdminDashboardRevenueApi = (prms) =>
  axiosInstance.get(`/api/v1/admin/dashboard/revenue`, {
    params: {
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });

export const getAdminAssessmentScoreSubcategoryApi = (prms) => {
  let path = "/api/v1/admin/dashboard/avg-sub-category";

  if (localStorage.getItem("role") === "2") {
    path = "/api/v1/govt/dashboard/avg-sub-category";
  }

  return axiosInstance.get(path, {
    params: {
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });
};

export const getAdminDistributionIndustryApi = (prms) => {
  let path = "/api/v1/admin/dashboard/dist-of-industries";

  if (localStorage.getItem("role") === "2") {
    path = "/api/v1/govt/dashboard/dist-of-industries";
  }

  return axiosInstance.get(path, {
    params: {
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });
};

export const getAdminTotalChangesApi = (prms) => {
  let path = "/api/v1/admin/dashboard/analysis-data";

  if (localStorage.getItem("role") === "2") {
    path = "/api/v1/govt/dashboard/analysis-data";
  }
  return axiosInstance.get(path, {
    params: {
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });
};

export const getAdminMonthlyRegFirmApi = (prms) =>
  axiosInstance.get(`/api/v1/admin/dashboard/monthly-reg-firms`, {
    params: {
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });

export const getCurrentPlanApi = () => axiosInstance.get("/api/v1/govt/dashboard/active-plan");
