import {
  FETCH_COMPLETED_ASSESSMENT_LIST_REQUEST,
  FETCH_COMPLETED_ASSESSMENT_LIST_SUCCESS,
  FETCH_COMPLETED_ASSESSMENT_LIST_FAILURE,
  RESET_COMPLETED_ASSESSMENT_LIST_DATA,
  FETCH_ASSESSMENT_QUESTION_LIST_REQUEST,
  FETCH_ASSESSMENT_QUESTION_LIST_SUCCESS,
  FETCH_ASSESSMENT_QUESTION_LIST_FAILURE,
  FETCH_ONGOING_ASSESSMENT_LIST_REQUEST,
  FETCH_ONGOING_ASSESSMENT_LIST_SUCCESS,
  FETCH_ONGOING_ASSESSMENT_LIST_FAILURE,
  ASSESSMENT_STARTED,
  FETCH_VIEW_ASSESSMENT_ANALYSIS_REQUEST,
  FETCH_VIEW_ASSESSMENT_ANALYSIS_SUCCESS,
  FETCH_VIEW_ASSESSMENT_ANALYSIS_FAILURE
} from "./types";

const initialState = {
  isLoading: false,
  isOngoingLoading: false,
  error: "",
  assessmentlist: [],
  totalPage: 0,
  assessmentQuestionSectionsList: null,
  startSurveyData: null,
  ongoingAssessmentList: [],
  assessmentAnalysisData: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPLETED_ASSESSMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_COMPLETED_ASSESSMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assessmentlist: action.payload.assessmentlist,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_COMPLETED_ASSESSMENT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        assessmentlist: [],
        error: action.payload
      };
    case FETCH_ASSESSMENT_QUESTION_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ASSESSMENT_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assessmentQuestionSectionsList: action.payload,
        error: ""
      };
    case FETCH_ASSESSMENT_QUESTION_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        assessmentQuestionSectionsList: null,
        error: action.payload
      };
    case FETCH_ONGOING_ASSESSMENT_LIST_REQUEST:
      return {
        ...state,
        isOngoingLoading: true
      };
    case FETCH_ONGOING_ASSESSMENT_LIST_SUCCESS:
      return {
        ...state,
        isOngoingLoading: false,
        ongoingAssessmentList: action.payload.ongoingAssessmentList,
        error: ""
      };
    case FETCH_ONGOING_ASSESSMENT_LIST_FAILURE:
      return {
        ...state,
        isOngoingLoading: false,
        ongoingAssessmentList: [],
        error: action.payload
      };
    case RESET_COMPLETED_ASSESSMENT_LIST_DATA:
      return {
        ...state,
        assessmentlist: [],
        totalPage: 0
      };
    case ASSESSMENT_STARTED:
      return {
        ...state,
        startSurveyData: action.payload
      };

    case FETCH_VIEW_ASSESSMENT_ANALYSIS_REQUEST:
      return {
        ...state,
        isLoading: true,
        assessmentAnalysisData: null
      };
    case FETCH_VIEW_ASSESSMENT_ANALYSIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assessmentAnalysisData: action.payload,
        error: ""
      };
    case FETCH_VIEW_ASSESSMENT_ANALYSIS_FAILURE:
      return {
        ...state,
        isLoading: false,
        assessmentAnalysisData: null,
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
