import { useMemo, useState, useEffect, Fragment } from "react";
import { Col, Row, Table, Input } from "reactstrap";
import moment from "moment";
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import "./Directfirms.css";
import Paginate from "components/Pagination/Pagination";
import FirmsDetalModal from "components/Modals/FirmsDetailsModal";
import DropDown from "components/DropDown/DropDown";
import { selectFirmsList } from "redux/adminListofFirms/selectors";
import { selectUserToken } from "redux/user/selectors";
import { getColumns } from "./ListofFirmscolumn";
import { fetchFirmsListThunkAction } from "redux/adminListofFirms/actions";
import Search from "components/Search/Search";
import Loader from "components/Loader/Loader";
import { selectUserRole } from "redux/user/selectors";
import userRoles from "utils/roles";
import DropDownSelect from "components/DropDownselect/DropDownSelect";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

const Directfirms = () => {
  const [open, setOpen] = useState(false);
  const [firmsListData, setFirmsListData] = useState([]);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState(null);
  const [date, setDate] = useState({
    end_date: "",
    start_date: ""
  });
  const [firmId, setFirmId] = useState(null);
  const [selectFirms, setSelectFirms] = useState(null);
  const dispatch = useDispatch();
  const { firmslist, isLoading, totalPage } = useSelector(selectFirmsList);
  const token = useSelector(selectUserToken);
  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const toggleModal = () => {
    setOpen((open) => !open);
  };
  const handleOpenModal = (id) => {
    setOpen(true);
    setFirmId(id);
  };

  const handleValue = (start_date, end_date) => {
    setDate({
      end_date: end_date,
      start_date: start_date
    });
  };

  useEffect(() => {
    setFirmsListData(firmslist);
  }, [firmslist, firmslist?.length]);

  const columns = useMemo(() => getColumns(handleOpenModal), []);
  const data = useMemo(() => firmsListData, [firmsListData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: Number(role) === userRoles.ADMIN ? [] : ["role_id"]
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };
  const handleFirmValue = (id) => {
    setSelectFirms(id);
    gotoPage(0);
  };

  useEffect(() => {
    if (token) {
      dispatch(
        fetchFirmsListThunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex,
            column: sort?.sortBy,
            sort: sort?.sortDirection,
            start_date: date?.start_date,
            end_date: date?.end_date,
            list: selectFirms
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, sort, date, selectFirms]);

  return (
    <>
      <div className="px-3">
        <Row className="justify-content-between align-items-center mx-1">
          <span className="Headers">
            <h4>{Number(role) === userRoles.ADMIN ? "" : "My"} Firms</h4>
          </span>
          <div className="d-flex align-items-center">
            {Number(role) === userRoles.ADMIN ? (
              <>
                <span className="mr-3">Select List Type</span>
                <DropDownSelect handleFirmValue={handleFirmValue} />
              </>
            ) : (
              <DropDown handleValue={handleValue} />
            )}
          </div>
        </Row>
        <Row>
          <Col>
            <div className="py-2">
              <nav aria-label="..." className="post_footer">
                <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
                  <Col xs="12" md="10" className="d-flex align-items-center">
                    <span>Show</span>
                    <Input
                      type="select"
                      style={{
                        width: "8rem",
                        margin: "10px"
                      }}
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[5, 10, 20, 30, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col xs="12" md="2">
                    <Search onSubmitHandler={onSubmitHandler} />
                  </Col>
                </Row>
              </nav>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            {isLoading ? (
              <Fragment>
                <div className="post_error_loading">
                  <Loader />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {error ? (
                  <Fragment>
                    <div className="post_error_loading">{error}</div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Table
                      {...getTableProps()}
                      className="align-items-center table-flush Firms_table_container"
                      responsive
                      bordered
                    >
                      <thead className="thead-light Firms_table_header">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                            {headerGroup.headers.map((column) => (
                              <th
                                key={column.id}
                                onClick={() => {
                                  if (!column.disableSortBy) {
                                    const desc =
                                      column.isSortedDesc === true
                                        ? undefined
                                        : column.isSortedDesc === false
                                        ? true
                                        : false;
                                    setSort({
                                      sortBy: column.id,
                                      sortDirection: desc ? "desc" : "asc"
                                    });
                                    setSortBy([{ id: column.id, desc }]);
                                  }
                                }}
                                {...column.getHeaderProps()}
                              >
                                {column.render("Header")}
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fas fa-arrow-down ml-2"></i>
                                    ) : (
                                      <i className="fas fa-arrow-up ml-2"></i>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} key={row.id}>
                              {row.cells.map((cell) => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {!error && firmsListData?.length === 0 && (
                      <div className="post_error_loading">No firm data found.</div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
            {isLoading === false && error === null ? (
              <nav aria-label="..." className="post_footer">
                <Paginate
                  pageIndex={pageIndex}
                  pageCount={pageCount}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  gotoPage={gotoPage}
                />
              </nav>
            ) : null}
          </Col>
        </Row>
      </div>
      <FirmsDetalModal toggleModal={toggleModal} isOpen={open} id={firmId} />
    </>
  );
};

export default Directfirms;
