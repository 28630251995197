import {
  FETCH_ASSESSMENT_FIRMS_REQUEST,
  FETCH_ASSESSMENT_FIRMS_SUCCESS,
  FETCH_ASSESSMENT_FIRMS_FAILURE,
  FETCH_ASSESSMENT_SCORE_BY_CATEGORY_REQUEST,
  FETCH_ASSESSMENT_SCORE_BY_CATEGORY_SUCCESS,
  FETCH_ASSESSMENT_SCORE_BY_CATEGORY_FAILURE,
  FETCH_FIRMS_TOTAL_CHANGES_REQUEST,
  FETCH_FIRMS_TOTAL_CHANGES_SUCCESS,
  FETCH_FIRMS_TOTAL_CHANGES_FAILURE
} from "./types";

const initialState = {
  isLoadingAssessmentTotalFirms: false,
  isLoadingAssessmentScoreByCategory: false,
  isLoadingTotalChanges: false,

  totalAssessmentFirmsData: null,
  assessmentScoreByCategoryData: null,
  totalChangesData: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ASSESSMENT_FIRMS_REQUEST:
      return {
        ...state,
        isLoadingAssessmentTotalFirms: true,
        totalAssessmentFirmsData: null
      };
    case FETCH_ASSESSMENT_FIRMS_SUCCESS:
      return {
        ...state,
        totalAssessmentFirmsData: action.payload,
        isLoadingAssessmentTotalFirms: false,
        error: ""
      };
    case FETCH_ASSESSMENT_FIRMS_FAILURE:
      return {
        ...state,
        totalAssessmentFirmsData: null,
        isLoadingAssessmentTotalFirms: false,
        error: action.payload
      };
    case FETCH_ASSESSMENT_SCORE_BY_CATEGORY_REQUEST:
      return {
        ...state,
        isLoadingAssessmentScoreByCategory: true,
        assessmentScoreByCategoryData: null
      };
    case FETCH_ASSESSMENT_SCORE_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        assessmentScoreByCategoryData: action.payload,
        isLoadingAssessmentScoreByCategory: false,
        error: ""
      };
    case FETCH_ASSESSMENT_SCORE_BY_CATEGORY_FAILURE:
      return {
        ...state,
        assessmentScoreByCategoryData: null,
        isLoadingAssessmentScoreByCategory: false,
        error: action.payload
      };
    case FETCH_FIRMS_TOTAL_CHANGES_REQUEST:
      return {
        ...state,
        isLoadingTotalChanges: true,
        totalChangesData: null
      };
    case FETCH_FIRMS_TOTAL_CHANGES_SUCCESS:
      return {
        ...state,
        totalChangesData: action.payload,
        isLoadingTotalChanges: false,
        error: ""
      };
    case FETCH_FIRMS_TOTAL_CHANGES_FAILURE:
      return {
        ...state,
        totalChangesData: null,
        isLoadingTotalChanges: false,
        error: action.payload
      };
    default:
      return state;
  }
};
export default reducer;
