import { useState, useEffect, Fragment } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Table } from "reactstrap";
import DropDown from "components/DropDown/DropDown";
import GovernmentDashboardChart from "components/Charts/GovernmentDashboardChart";
import OngoingAssessmentTable from "pages/GovernmentListofSurvey/OngoingAssessmentTable";
import CompleteAssessmentTable from "pages/GovernmentListofSurvey/CompleteAssessmentTable";
import { assessmentSectionTitles } from "utils/roles";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectFirmsDashboardData } from "redux/firmsDashboard/selectors";
import { fetchAssessmentFirmsThunkAction } from "redux/firmsDashboard/actions";
import { fetchAssessmentScoreByCategoryThunkAction } from "redux/firmsDashboard/actions";
import { fetchTotalChangesThunkAction } from "redux/firmsDashboard/actions";
import moment from "moment";
import Loader from "components/Loader/Loader";
import "./GovernmentDashboard.css";
import { selectUserRole } from "redux/user/selectors";
import userRoles from "utils/roles";
import { getFirmsCurrentPlanApi } from "services/firmsDashboardServices";
import { selectUser } from "redux/user/selectors";
import BidCard from "components/Cards/BidCard";
import DashboardDropdown from "components/DashboardDropdown/DashboardDropdown";

const GovernmentDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [globalDate, setGlobalDate] = useState({
    end_date: "",
    start_date: ""
  });

  const [globalDropDownValue, setGlobalDropDownValue] = useState("");

  const [error, setError] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [assessmentScoreChartDataOne, setAssessmentScoreChartDataOne] = useState([]);
  const [assessmentScoreChartDataTwo, setAssessmentScoreChartDataTwo] = useState([]);
  const [assessmentScoreChartTitle, setAssessmentScoreChartTitle] = useState([]);

  const role = useSelector(selectUserRole) || localStorage.getItem("role");
  const { loggedInUser } = useSelector(selectUser);

  const onSuccess = () => {};

  const onError = (error) => {
    setError(error);
  };

  const {
    isLoadingAssessmentTotalFirms,
    isLoadingAssessmentScoreByCategory,
    isLoadingTotalChanges,

    totalAssessmentFirmsData,
    assessmentScoreByCategoryData,
    totalChangesData
  } = useSelector(selectFirmsDashboardData);

  useEffect(() => {
    dispatch(fetchAssessmentFirmsThunkAction(globalDate, onSuccess, onError));
    dispatch(fetchAssessmentScoreByCategoryThunkAction(globalDate, onSuccess, onError));
    dispatch(fetchTotalChangesThunkAction(globalDate, onSuccess, onError));
  }, [globalDate]);

  useEffect(() => {
    setAssessmentScoreChartDataOne(
      assessmentScoreByCategoryData?.map((item) => item?.first_survey?.toFixed(0))
    );
    setAssessmentScoreChartDataTwo(
      assessmentScoreByCategoryData?.map((item) => item?.second_survey?.toFixed(0))
    );
    setAssessmentScoreChartTitle(
      assessmentScoreByCategoryData?.map((item) =>
        assessmentSectionTitles[`${item?.section_id}`].split(" ")
      )
    );
  }, [assessmentScoreByCategoryData]);

  useEffect(async () => {
    try {
      const { data } = await getFirmsCurrentPlanApi();
      setCurrentPlan(data?.data?.plan_name);
      if (data.success !== true) {
        throw new Error(data?.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error?.message);
    }
  }, []);

  const showNumberSign = (item) => {
    switch (true) {
      case item > 0:
        return "+";
      case item < 0:
        return "-";
      case item === 0:
        return "";
      default:
        return "";
    }
  };
  const handleValue = (start_date, end_date) => {
    setGlobalDate({
      end_date: end_date,
      start_date: start_date
    });
  };

  const NavigateTo = (id) => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return `/admin/assessment/analysis/${id}`;
      case userRoles.GOVT:
        return `/gov/assessment/analysis/${id}`;
      case userRoles.FIRMS:
        return `/firms/assessment/analysis/${id}`;
      case userRoles.GOVT_FIRMS:
        return `/govt-firms/assessment/analysis/${id}`;
      default:
        return `/dashboard/${id}`;
    }
  };

  const onClickViewHandler = (id) => {
    if (id) {
      navigate(`${NavigateTo(id)}`);
    }
  };

  function convertToInternationalCurrencySystem(labelValue) {
    if (!isNaN(labelValue)) {
      // Nine Zeroes for Billions
      return Math.abs(Number(labelValue)) >= 1.0e9
        ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(0) + "B"
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + "K"
        : Math.abs(Number(labelValue));
    }
  }

  return (
    <div className="px-3">
      <div className="d-flex justify-content-between my-2">
        <h4>Hi {loggedInUser?.username}!</h4>
        <DashboardDropdown
          handleValue={(start_date, end_date) => handleValue(start_date, end_date)}
          selectedValue={globalDropDownValue}
          setSelectedValue={setGlobalDropDownValue}
        />
      </div>
      {isLoadingAssessmentTotalFirms &&
      isLoadingAssessmentScoreByCategory &&
      isLoadingTotalChanges ? (
        <Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {isLoadingAssessmentTotalFirms ? (
            <Fragment>
              <div className="post_error_loading">
                <Loader />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Row>
                <Col lg="6" xl="4">
                  <Card
                    className="card-stats mb-4 py-2 mb-xl-0"
                    style={{ backgroundColor: "#E7F1FD", minHeight: "115px" }}
                    onClick={() => (Number(role) === 3 ? null : navigate("/firms/plans"))}
                  >
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h6" className="text-capitalize text-muted mb-0">
                            Current Active Plan
                          </CardTitle>
                          <span
                            className="h2 font-weight-bold mb-0 text-capitalize"
                            style={{ color: "#1273EB" }}
                          >
                            {currentPlan}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card
                    className="card-stats mb-4 py-2 mb-xl-0"
                    style={{ minHeight: "115px", cursor: "pointer" }}
                    onClick={() =>
                      Number(role) === 3
                        ? navigate("/govt-firms/Assessment/list")
                        : navigate("/firms/Assessment/list")
                    }
                  >
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h6" className="text-capitalize text-muted mb-0">
                            Assessment completed
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalAssessmentFirmsData?.completed_survey}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card
                    className="card-stats mb-4 py-2 mb-xl-0"
                    style={{ minHeight: "115px", cursor: "pointer" }}
                    onClick={() =>
                      Number(role) === 3
                        ? navigate("/govt-firms/Assessment/list")
                        : navigate("/firms/Assessment/list")
                    }
                  >
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h6" className="text-capitalize text-muted mb-0">
                            Ongoing Assessment
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalAssessmentFirmsData?.on_going_survey}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* new section for bid opportunity */}

              <Row className="mt-4">
                <Col lg="6" xl="4">
                  <BidCard
                    cardColor={"#EAF9F1"}
                    borderColor={"#BCEED2"}
                    count={totalAssessmentFirmsData?.my_bid}
                    titleColor={"#28C76F"}
                    countColor={"#28C76F"}
                  >
                    Bid Opportunity Now
                  </BidCard>
                </Col>
                <Col lg="6" xl="4">
                  <BidCard
                    cardColor={"#FFF5EC"}
                    borderColor={"#FFE1C5"}
                    count={totalAssessmentFirmsData?.last_week_bids}
                    titleColor={"#B57130"}
                    countColor={"#FF9F43"}
                  >
                    Bid Opportunity in <br /> Last 7 days
                  </BidCard>
                </Col>
                <Col lg="6" xl="4">
                  <BidCard
                    cardColor={"#E6FAFD"}
                    borderColor={"#B0F0F8"}
                    count={totalAssessmentFirmsData?.total_bids}
                    titleColor={"#0093A5"}
                    countColor={"#00CFE8"}
                  >
                    Total available <br /> Bid Opportunity
                  </BidCard>
                </Col>
              </Row>
            </Fragment>
          )}
          <Row className="mt-4 government_change_in_assessment_row">
            <Col
              lg="6"
              style={{
                height: "inherit"
              }}
            >
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{
                  borderRadius: "0.5rem",
                  height: "100%",
                  minHeight: "500px"
                }}
              >
                <CardHeader
                  style={{
                    borderRadius: "0.5rem"
                  }}
                >
                  <Row className="justify-content-between align-items-center mx-1">
                    <h6 className="mb-0">Change in Assessment score By category</h6>
                  </Row>
                </CardHeader>
                <CardBody>
                  {isLoadingAssessmentScoreByCategory ? (
                    <Fragment>
                      <div className="post_error_loading">
                        <Loader />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {assessmentScoreChartDataOne?.length &&
                      assessmentScoreChartDataTwo?.length ? (
                        <GovernmentDashboardChart
                          data_1={assessmentScoreChartDataOne}
                          data_2={assessmentScoreChartDataTwo}
                          categories={assessmentScoreChartTitle}
                        />
                      ) : (
                        <div className="d-flex justify-content-center align-items-center h-100">
                          No data found
                        </div>
                      )}
                    </Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Row>
                <Col lg="6">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    style={{
                      borderRadius: "0.5rem",
                      height: "170px"
                    }}
                  >
                    <CardBody>
                      <Row className="align-items-center mb-3">
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-capitalize mb-0"
                            style={{ fontSize: "16px" }}
                          >
                            Change in Total Score
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="align-items-end">
                        <Col className="d-flex align-items-end">
                          <span className="mb-0 ChangeInScores">
                            {new Intl.NumberFormat("en-US", {
                              maximumSignificantDigits: 3
                            }).format(totalChangesData?.latest_score)}
                          </span>
                          {/* <span
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              lineHeight: "17px"
                            }}
                          >
                            Current Score
                          </span> */}
                        </Col>
                        <Col className="col-auto d-flex">
                          {showNumberSign(totalChangesData?.score_diff) === "+" && (
                            <>
                              <h4 className="mb-0 ScoreUp">+{totalChangesData?.score_diff}%</h4>
                              <i className="fas fa-arrow-circle-up ml-1 my-1 iconUp" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.score_diff) === "-" && (
                            <>
                              <h4 className="mb-0 ScoreDown">{totalChangesData?.score_diff}%</h4>
                              <i className="fas fa-arrow-circle-down ml-1 my-1 iconDown" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.score_diff) === "" && (
                            <h4 className="mb-0 ScoreDown">{totalChangesData?.score_diff}%</h4>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter
                      className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#E7F1FD",
                        borderBottomLeftRadius: "0.5rem",
                        borderBottomRightRadius: "0.5rem",
                        height: "36px",
                        cursor: "pointer"
                      }}
                      onClick={() => onClickViewHandler(totalChangesData?.latest_user_id)}
                    >
                      <span
                        className={
                          totalChangesData?.latest_user_id
                            ? "text-nowrap employment_view_full_report"
                            : "text-nowrap employment_view_full_report_disabled"
                        }
                      >
                        View Full Report
                      </span>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    style={{
                      borderRadius: "0.5rem",
                      height: "170px"
                    }}
                  >
                    <CardBody>
                      <Row className="align-items-center mb-3">
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-capitalize mb-0"
                            style={{ fontSize: "16px" }}
                          >
                            Change in number of jobs
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="align-items-end">
                        <Col className="d-flex align-items-end">
                          <span className="mb-0 ChangeInScores">
                            {new Intl.NumberFormat("en-US", {
                              maximumSignificantDigits: 3
                            }).format(totalChangesData?.latest_employee)}
                          </span>
                          {/* <span
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              lineHeight: "17px"
                            }}
                          >
                            Current employee
                          </span> */}
                        </Col>
                        <Col className="col-auto d-flex">
                          {showNumberSign(totalChangesData?.employee_diff) === "+" && (
                            <>
                              <h4 className="mb-0 ScoreUp">+{totalChangesData?.employee_diff}%</h4>
                              <i className="fas fa-arrow-circle-up ml-1 my-1 iconUp" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.employee_diff) === "-" && (
                            <>
                              <h4 className="mb-0 ScoreDown">{totalChangesData?.employee_diff}%</h4>
                              <i className="fas fa-arrow-circle-down ml-1 my-1 iconDown" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.employee_diff) === "" && (
                            <h4 className="mb-0 ScoreDown">{totalChangesData?.employee_diff}%</h4>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter
                      className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#E7F1FD",
                        borderBottomLeftRadius: "0.5rem",
                        borderBottomRightRadius: "0.5rem",
                        height: "36px",
                        cursor: "pointer"
                      }}
                      onClick={() => onClickViewHandler(totalChangesData?.latest_user_id)}
                    >
                      <span
                        className={
                          totalChangesData?.latest_user_id
                            ? "text-nowrap employment_view_full_report"
                            : "text-nowrap employment_view_full_report_disabled"
                        }
                      >
                        View Full Report
                      </span>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg="6">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    style={{
                      borderRadius: "0.5rem",
                      height: "170px"
                    }}
                  >
                    <CardBody>
                      <Row className="align-items-center mb-3">
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-capitalize mb-0"
                            style={{ fontSize: "16px" }}
                          >
                            Change in revenue
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="align-items-end">
                        <Col className="d-flex align-items-end">
                          <span className="mb-0 ChangeInScores">
                            $
                            {convertToInternationalCurrencySystem(totalChangesData?.latest_revenue)}
                          </span>
                          {/* <span
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              lineHeight: "17px"
                            }}
                          >
                            Current revenue
                          </span> */}
                        </Col>
                        <Col className="col-auto d-flex">
                          {showNumberSign(totalChangesData?.revenue_diff) === "+" && (
                            <>
                              <h4 className="mb-0 ScoreUp">+{totalChangesData?.revenue_diff}%</h4>
                              <i className="fas fa-arrow-circle-up ml-1 my-1 iconUp" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.revenue_diff) === "-" && (
                            <>
                              <h4 className="mb-0 ScoreDown">{totalChangesData?.revenue_diff}%</h4>
                              <i className="fas fa-arrow-circle-down ml-1 my-1 iconDown" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.revenue_diff) === "" && (
                            <h4 className="mb-0 ScoreDown">{totalChangesData?.revenue_diff}%</h4>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter
                      className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#E7F1FD",
                        borderBottomLeftRadius: "0.5rem",
                        borderBottomRightRadius: "0.5rem",
                        height: "36px",
                        cursor: "pointer"
                      }}
                      onClick={() => onClickViewHandler(totalChangesData?.latest_user_id)}
                    >
                      <span
                        className={
                          totalChangesData?.latest_user_id
                            ? "text-nowrap employment_view_full_report"
                            : "text-nowrap employment_view_full_report_disabled"
                        }
                      >
                        View Full Report
                      </span>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    style={{
                      borderRadius: "0.5rem",
                      height: "170px"
                    }}
                  >
                    <CardBody>
                      <Row className="align-items-center mb-3">
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-capitalize mb-0"
                            style={{ fontSize: "16px" }}
                          >
                            Change in sales
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="align-items-end">
                        <Col className="d-flex align-items-end">
                          <h4 className="mb-0 ChangeInScores">
                            ${convertToInternationalCurrencySystem(totalChangesData?.latest_sales)}
                          </h4>
                          {/* <span
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              lineHeight: "17px"
                            }}
                          >
                            Current sales
                          </span> */}
                        </Col>
                        <Col className="col-auto d-flex">
                          {showNumberSign(totalChangesData?.sales_diff) === "+" && (
                            <>
                              <h4 className="mb-0 ScoreUp">+{totalChangesData?.sales_diff}%</h4>
                              <i className="fas fa-arrow-circle-up ml-1 my-1 iconUp" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.sales_diff) === "-" && (
                            <>
                              <h4 className="mb-0 ScoreDown">{totalChangesData?.sales_diff}%</h4>
                              <i className="fas fa-arrow-circle-down ml-1 my-1 iconDown" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.sales_diff) === "" && (
                            <h4 className="mb-0 ScoreDown">{totalChangesData?.sales_diff}%</h4>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter
                      className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#E7F1FD",
                        borderBottomLeftRadius: "0.5rem",
                        borderBottomRightRadius: "0.5rem",
                        height: "36px",
                        cursor: "pointer"
                      }}
                      onClick={() => onClickViewHandler(totalChangesData?.latest_user_id)}
                    >
                      <span
                        className={
                          totalChangesData?.latest_user_id
                            ? "text-nowrap employment_view_full_report"
                            : "text-nowrap employment_view_full_report_disabled"
                        }
                      >
                        View Full Report
                      </span>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg="6">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    style={{
                      borderRadius: "0.5rem",
                      height: "170px"
                    }}
                  >
                    <CardBody>
                      <Row className="align-items-center mb-3">
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-capitalize mb-0"
                            style={{ fontSize: "16px" }}
                          >
                            Change in contract size
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="align-items-end">
                        <Col className="d-flex align-items-end">
                          <h4 className="mb-0 ChangeInScores">
                            {new Intl.NumberFormat("en-US", {
                              maximumSignificantDigits: 3
                            }).format(totalChangesData?.latest_bidding_activity)}
                          </h4>
                          {/* <span
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              lineHeight: "17px"
                            }}
                          >
                            Current Year
                          </span> */}
                        </Col>
                        <Col className="col-auto d-flex">
                          {showNumberSign(totalChangesData?.bidding_activity_diff) === "+" && (
                            <>
                              <h4 className="mb-0 ScoreUp">
                                +{totalChangesData?.bidding_activity_diff}%
                              </h4>
                              <i className="fas fa-arrow-circle-up ml-1 my-1 iconUp" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.bidding_activity_diff) === "-" && (
                            <>
                              <h4 className="mb-0 ScoreDown">
                                {totalChangesData?.bidding_activity_diff}%
                              </h4>
                              <i className="fas fa-arrow-circle-down ml-1 my-1 iconDown" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.bidding_activity_diff) === "" && (
                            <h4 className="mb-0 ScoreDown">
                              {totalChangesData?.bidding_activity_diff}%
                            </h4>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter
                      className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#E7F1FD",
                        borderBottomLeftRadius: "0.5rem",
                        borderBottomRightRadius: "0.5rem",
                        height: "36px",
                        cursor: "pointer"
                      }}
                      onClick={() => onClickViewHandler(totalChangesData?.latest_user_id)}
                    >
                      <span
                        className={
                          totalChangesData?.latest_user_id
                            ? "text-nowrap employment_view_full_report"
                            : "text-nowrap employment_view_full_report_disabled"
                        }
                      >
                        View Full Report
                      </span>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    style={{
                      borderRadius: "0.5rem",
                      height: "170px"
                    }}
                  >
                    <CardBody>
                      <Row className="align-items-center mb-3">
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-capitalize mb-0"
                            style={{ fontSize: "16px" }}
                          >
                            Change in awards
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="align-items-end">
                        <Col className="d-flex align-items-end">
                          <h4 className="mb-0 ChangeInScores">
                            {new Intl.NumberFormat("en-US", {
                              maximumSignificantDigits: 3
                            }).format(totalChangesData?.latest_awards)}
                          </h4>
                          {/* <span
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              lineHeight: "17px"
                            }}
                          >
                            Current awards
                          </span> */}
                        </Col>
                        <Col className="col-auto d-flex">
                          {showNumberSign(totalChangesData?.awards_diff) === "+" && (
                            <>
                              <h4 className="mb-0 ScoreUp">+{totalChangesData?.awards_diff}%</h4>
                              <i className="fas fa-arrow-circle-up ml-1 my-1 iconUp" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.awards_diff) === "-" && (
                            <>
                              <h4 className="mb-0 ScoreDown">{totalChangesData?.awards_diff}%</h4>
                              <i className="fas fa-arrow-circle-down ml-1 my-1 iconDown" />
                            </>
                          )}
                          {showNumberSign(totalChangesData?.awards_diff) === "" && (
                            <h4 className="mb-0 ScoreDown">{totalChangesData?.awards_diff}%</h4>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter
                      className="mt-3 mb-0 text-sm text-center d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#E7F1FD",
                        borderBottomLeftRadius: "0.5rem",
                        borderBottomRightRadius: "0.5rem",
                        height: "36px",
                        cursor: "pointer"
                      }}
                      onClick={() => onClickViewHandler(totalChangesData?.latest_user_id)}
                    >
                      <span
                        className={
                          totalChangesData?.latest_user_id
                            ? "text-nowrap employment_view_full_report"
                            : "text-nowrap employment_view_full_report_disabled"
                        }
                      >
                        View Full Report
                      </span>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <OngoingAssessmentTable />
          <CompleteAssessmentTable
            globalDate={globalDate}
            globalDropDownValue={globalDropDownValue}
          />
        </Fragment>
      )}
    </div>
  );
};

export default GovernmentDashboard;
