import axiosInstance from "./axiosInstance";

export const getAssessmentListApi = (prms) => {
  let path = "/api/v1/firms/completed-surveys";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/completed-surveys";
  }
  return axiosInstance.get(path, {
    params: {
      limit: prms.limit || 5,
      offset: prms?.page || 0,
      search: prms?.search || "",
      sort: prms?.sort || "",
      column: prms?.column || "",
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });
};

export const getAssessmentQuestionListApi = () =>
  axiosInstance.get("/api/v1/user/survey/questions");

export const postStartNewAssessmentApi = () => {
  let path = "/api/v1/user/survey/start";

  if (localStorage.getItem("role") === "1") {
    path = "/api/v1/admin/survey/start";
  }
  return axiosInstance.post(path);
};

export const postSubmitAssessmentApi = (reqBody) => {
  let path = "/api/v1/user/survey/submit";

  if (localStorage.getItem("role") === "1") {
    path = "/api/v1/admin/survey/submit";
  }
  return axiosInstance.post(path, reqBody);
};

export const getOngoingAssessmentListApi = () => {
  let path = "/api/v1/firms/ongoing-surveys";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/ongoing-surveys";
  }
  return axiosInstance.get(path);
};

export const getPendingAssessmentQuestionListApi = (id) =>
  axiosInstance.get(`/api/v1/user/survey/questions/${id}`);

export const getAdminViewAssessmentAnalysisApi = (id) => {
  let path = `/api/v1/admin/survey/${id}`;

  if (localStorage.getItem("role") === "4") {
    path = `/api/v1/firms/survey/${id}`;
  } else if (localStorage.getItem("role") === "2") {
    path = `/api/v1/govt/survey/${id}`;
  } else if (localStorage.getItem("role") === "3") {
    path = `/api/v1/govt-firms/survey/${id}`;
  }
  return axiosInstance.get(path);
};

export const postSendEmailAssessmentAnalysisApi = (reqBody) => {
  let path = "/api/v1/admin/survey/pdf";

  if (localStorage.getItem("role") === "4") {
    path = "/api/v1/firms/survey/pdf";
  } else if (localStorage.getItem("role") === "2") {
    path = `/api/v1/govt/survey/pdf`;
  } else if (localStorage.getItem("role") === "3") {
    path = `/api/v1/govt-firms/survey/pdf`;
  }
  return axiosInstance.post(path, reqBody);
};

export const getexportpdfData = (id) => {
  let path = `/api/v1/admin/survey/report/${id}`;

  if (localStorage.getItem("role") === "4") {
    path = `/api/v1/firms/survey/report/${id}`;
  } else if (localStorage.getItem("role") === "2") {
    path = `/api/v1/govt/survey/report/${id}`;
  } else if (localStorage.getItem("role") === "3") {
    path = `/api/v1/govt-firms/survey/report/${id}`;
  }
  return axiosInstance.get(path);
};
