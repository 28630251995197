import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";

function BidOversightDropDown({ direction = "down", handleValue, ...args }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className="mr-3">
      <Dropdown color="primary" isOpen={dropdownOpen} toggle={toggle} direction={direction}>
        <DropdownToggle caret style={{ backgroundColor: "#1273EB" }}>
          <Icon icon="akar-icons:calendar" className="mr-2 mb-1" />
          {selectedValue ? selectedValue : "Monthly"}
        </DropdownToggle>
        <DropdownMenu {...args} right>
          <DropdownItem
            onClick={() => {
              handleValue("monthly");
              setSelectedValue("Monthly");
            }}
          >
            Monthly
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              handleValue("quarterly");
              setSelectedValue("Quarterly");
            }}
          >
            Quarterly
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              handleValue("yearly");
              setSelectedValue("Yearly");
            }}
          >
            Yearly
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

BidOversightDropDown.propTypes = {
  direction: PropTypes.string
};

export default BidOversightDropDown;
