import AuthLogo from "components/AuthLogo/AuthLogo";
import toast from "react-hot-toast";
import { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, FormGroup } from "reactstrap";
import { selectUser } from "redux/user/selectors";
import { useNavigate } from "react-router-dom";
import { forgetPasswordEmailThunkAction } from "redux/user/actions";
import "./Forgotpassword.css";

const Forgotpassword = () => {
  const [username, setUsername] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isVerify } = useSelector(selectUser);

  const navigateToHome = () => {
    navigate(-1);
  };

  const submitHadler = (e) => {
    e.preventDefault();

    if (username === "") {
      toast.error("UserName is requried");
      return;
    }

    dispatch(forgetPasswordEmailThunkAction(username, navigateToHome));
  };

  return (
    <Fragment>
      <div className="login_container">
        <AuthLogo />
        <div className="login_form mx-auto">
          <Row className="d-flex justify-content-between align-items-center px-5 pt-4">
            <Col sm="8">
              <h6 className="login_form_title">Forgot Password</h6>
            </Col>
            <Col sm="4">
              <span
                style={{
                  textDecoration: "none",
                  color: "#1273EB",
                  fontWeight: "700",
                  fontSize: "16px",
                  cursor: "pointer"
                }}
                onClick={() => navigateToHome()}
              >
                Back to Sign in
              </span>
            </Col>
          </Row>
          <form className="sign_up_form" onSubmit={submitHadler}>
            <Row className="px-lg-5 px-md-4 px-3 py-3">
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="userName">
                    Username
                  </label>
                  <input
                    placeholder="Enter Username"
                    className="form-control"
                    type="text"
                    required
                    name="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <button className="btn login_btn mx-auto mb-4 mt-3" type="submit">
                  {isVerify ? "Submiting in..." : "Submit"}
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Forgotpassword;
