import DropDown from "components/DropDown/DropDown";
import { useMemo, useState, useEffect, Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Row, Table, CardFooter, Input } from "reactstrap";
import moment from "moment";
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { getColumns } from "./ReportColumns";
import Search from "components/Search/Search";
import Paginate from "components/Pagination/Pagination";
import "./Reports.css";
import ReportSubCategoryModal from "components/Modals/ReportSubCategoryModal";
import { selectFirmsReportList } from "redux/firmsReport/selectors";
import { selectUserToken } from "redux/user/selectors";
import { fetchFirmsReportListThunkAction } from "redux/firmsReport/actions";
import Loader from "components/Loader/Loader";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

const Reports = () => {
  const [open, setOpen] = useState(false);
  const [firmsReportListData, setFirmsReportListData] = useState([]);
  const [index, setIndex] = useState();
  const [date, setDate] = useState({
    end_date: "",
    start_date: ""
  });
  const [error, setError] = useState(null);
  const [compereSurveyData, setCompereSurveyData] = useState(null);
  const [sort, setSort] = useState(null);

  const dispatch = useDispatch();
  const { firmsReportList, isLoading, totalPage } = useSelector(selectFirmsReportList);
  const token = useSelector(selectUserToken);

  useEffect(() => {
    setFirmsReportListData(firmsReportList);
  }, [firmsReportList, firmsReportList?.length]);

  const handleOpenModal = (row) => {
    setOpen(true);
    setCompereSurveyData(row);
  };

  const columns = useMemo(() => getColumns(handleOpenModal, index), [index]);
  const data = useMemo(() => firmsReportListData, [firmsReportListData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: INITIAL_PAGE_INDEX, sortBy: [], pageSize: INITIAL_PAGE_SIZE },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  useEffect(() => {
    if (token) {
      dispatch(
        fetchFirmsReportListThunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex,
            column: sort?.sortBy,
            sort: sort?.sortDirection,
            start_date: date?.start_date,
            end_date: date?.end_date
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, sort, date]);

  useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);

  const handleValue = (start_date, end_date) => {
    setDate({
      end_date: end_date,
      start_date: start_date
    });
  };

  const toggleModal = () => {
    setOpen((open) => !open);
  };

  return (
    <>
      <div className="px-3">
        <div className="d-flex align-items-center">
          <span className="Headers">
            <h4>Business Assessment Report</h4>
          </span>
        </div>
        <Row className="mt-2">
          <Col>
            <Card>
              <CardHeader>
                <Row className="justify-content-between align-items-center mx-1">
                  <h6 className="mb-0">Firms Reports</h6>
                  <DropDown handleValue={handleValue} />
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div className="py-2">
                      <nav aria-label="..." className="post_footer">
                        <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
                          <Col xs="12" md="7" lg={10} className="d-flex align-items-center">
                            <span>Show</span>
                            <Input
                              type="select"
                              style={{
                                width: "65px",
                                margin: "10px"
                              }}
                              value={pageSize}
                              onChange={(e) => {
                                setPageSize(Number(e.target.value));
                              }}
                            >
                              {[5, 10, 20, 30, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              ))}
                            </Input>
                          </Col>
                          <Col xs="12" md="5" lg={2}>
                            <Search onSubmitHandler={onSubmitHandler} />
                          </Col>
                        </Row>
                      </nav>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    {isLoading ? (
                      <Fragment>
                        <div className="post_error_loading">
                          <Loader />
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {error ? (
                          <Fragment>
                            <div className="post_error_loading">{error}</div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Table
                              {...getTableProps()}
                              responsive
                              bordered
                              className="reports_table_container table-responsive"
                            >
                              <thead className="reports_table_header">
                                {headerGroups.map((headerGroup) => (
                                  <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    key={headerGroup.headers}
                                  >
                                    {headerGroup.headers.map((column) => (
                                      <th
                                        className="text-center border-none"
                                        key={column.id}
                                        onClick={() => {
                                          if (!column.disableSortBy) {
                                            const desc =
                                              column.isSortedDesc === true
                                                ? undefined
                                                : column.isSortedDesc === false
                                                ? true
                                                : false;
                                            setSort({
                                              sortBy: column.id,
                                              sortDirection: desc ? "desc" : "asc"
                                            });
                                            setSortBy([{ id: column.id, desc }]);
                                          }
                                        }}
                                        {...column.getHeaderProps()}
                                      >
                                        {column.render("Header")}
                                        <span>
                                          {column.isSorted ? (
                                            column.isSortedDesc ? (
                                              <i className="fas fa-arrow-down ml-2"></i>
                                            ) : (
                                              <i className="fas fa-arrow-up ml-2"></i>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </th>
                                    ))}
                                  </tr>
                                ))}
                              </thead>
                              <tbody {...getTableBodyProps()} className="reports_table_body">
                                {page.map((row) => {
                                  prepareRow(row);
                                  return (
                                    <tr {...row.getRowProps()} key={row.id}>
                                      {row.cells.map((cell) => {
                                        return (
                                          <td key={cell.id} {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            {!error && firmsReportListData?.length === 0 && (
                              <div className="post_error_loading">No reports found.</div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter
                style={{
                  backgroundColor: "#fff",
                  borderTopWidth: "0px",
                  borderRadius: "0.5rem"
                }}
              >
                <nav aria-label="..." className="post_footer">
                  <Paginate
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
      {open && (
        <ReportSubCategoryModal
          toggleModal={toggleModal}
          isOpen={open}
          SurveyData={compereSurveyData}
        />
      )}
    </>
  );
};

export default Reports;
