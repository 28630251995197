import axiosInstance from "./axiosInstance";

export const getSubscriptionPlanApi = (prms) =>
  axiosInstance.get("/api/v1/admin/plan", {
    params: {
      type: prms.type
    }
  });

export const getUserPlanListApi = () => {
  let path = "/api/v1/firms/plan/list";

  if (localStorage.getItem("role") === "2") {
    path = "/api/v1/govt/plan/list";
  }

  return axiosInstance.get(path);
};

export const updateSubscriptionPlanApi = (id, PlanData) =>
  axiosInstance.put(`/api/v1/admin/plan/${id}`, PlanData);

export const CurrentPlanApi = () => {
  let path = "/api/v1/firms/current-plan";
  if (localStorage.getItem("role") === "2") {
    path = "/api/v1/govt/current-plan";
  }

  return axiosInstance.get(path);
};

export const BillingHistoryApi = (prms) => {
  let path = "/api/v1/firms/billing";
  if (localStorage.getItem("role") === "2") {
    path = "/api/v1/govt/billing";
  }
  return axiosInstance.get(path, {
    params: {
      sort: prms?.sort || "",
      column: prms?.column || ""
    }
  });
};

export const CancelSubscriptionApi = () => {
  let path = "/api/v1/firms/cancel-plan";
  if (localStorage.getItem("role") === "2") {
    path = "/api/v1/govt/cancel-plan";
  }
  return axiosInstance.delete(path);
};
