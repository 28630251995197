import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AES, enc } from "crypto-js";

import { fetchLoginUserThunkAction } from "redux/user/actions";
import usePasswordShow from "hooks/usePasswordShow";

import showPassword from "../../assets/img/theme/show-password-icon.png";
import hidePassword from "../../assets/img/theme/hide-password-icon.png";

import "./Login.css";
import { Col, FormGroup, Row } from "reactstrap";
import AuthLogo from "components/AuthLogo/AuthLogo";
import { selectUser } from "redux/user/selectors";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAdminLoginPage = pathname.includes("admin");

  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();

  const token = localStorage.getItem("token");
  const remember = localStorage.getItem("isRemember");

  const [isRemember, setIsRemember] = useState(remember?.toLowerCase() === "true" || false);
  const { isSigning } = useSelector(selectUser);

  const navigateToHome = () => {
    localStorage.setItem("isRemember", isRemember);

    if (isRemember) {
      const envryptedEmail = AES.encrypt(
        formik.values.userName,
        process.env.REACT_APP_DEV_CRYPTO_HASH
      ).toString();
      const envryptedPassword = AES.encrypt(
        formik.values.password,
        process.env.REACT_APP_DEV_CRYPTO_HASH
      ).toString();
      localStorage.setItem("username", envryptedEmail);
      localStorage.setItem("password", envryptedPassword);
    }
    // navigate("/");
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required")
      // .min(6)
    }),
    onSubmit: (values) => {
      dispatch(
        fetchLoginUserThunkAction(
          { username: values.userName, password: values.password },
          navigateToHome
        )
      );
    }
  });

  useEffect(() => {
    async function getUser() {
      if (remember?.toLowerCase() === "true") {
        await formik.setFieldValue(
          "userName",
          AES.decrypt(
            localStorage.getItem("username")?.toString(),
            process.env.REACT_APP_DEV_CRYPTO_HASH
          )?.toString(enc.Utf8)
        );
        await formik.setFieldValue(
          "password",
          AES.decrypt(
            localStorage.getItem("password")?.toString(),
            process.env.REACT_APP_DEV_CRYPTO_HASH
          )?.toString(enc.Utf8)
        );
        // formik.submitForm();
      }
    }

    getUser();
  }, [remember]);

  return (
    <Fragment>
      <div className="login_container">
        <AuthLogo />
        <div className="login_form mx-auto">
          <Row className="d-flex justify-content-between px-5 pt-4">
            <div className="w-100">
              <h2 className="login_form_title">{isAdminLoginPage ? "Admin" : "Firm"}</h2>
              <div className="login_welcome_text">Welcome to Alacriti</div>
              <div className="w-100 d-flex justify-content-between ">
                <h2 className="login_form_title">Log in</h2>
              </div>
            </div>
          </Row>
          <form onSubmit={formik.handleSubmit} className="sign_up_form">
            <Row className="px-lg-5 px-md-4 px-3 py-3">
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="userName">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Username"
                    id="userName"
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                  />
                  {formik.touched.userName && formik.errors.userName ? (
                    <div className="text-danger mt-2">{formik.errors.userName}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="password">
                    Password
                  </label>
                  <div className="position-relative">
                    <input
                      type={type}
                      className="form-control"
                      placeholder="Enter Password"
                      id="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {passwordShown ? (
                      <img
                        src={hidePassword}
                        alt="hide-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity}
                      />
                    ) : (
                      <img
                        src={showPassword}
                        alt="show-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity}
                      />
                    )}
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger mt-2">{formik.errors.password}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <input
                    type="checkbox"
                    name="remember"
                    id="sendEmail"
                    className="mr-3"
                    checked={isRemember}
                    onChange={(e) => {
                      setIsRemember(e.target.checked);
                    }}
                  />{" "}
                  <label className="form-control-label" htmlFor="sendEmail">
                    Remember me
                  </label>
                </FormGroup>
              </Col>
              <Col lg="6 text-right">
                <NavLink
                  to="/auth/forgotpassword"
                  tag={Link}
                  className="nav-link-inner--text"
                  style={{ textAlign: "right", color: "black" }}
                >
                  Forgot Password?
                </NavLink>
              </Col>
              <Col sm={12}>
                <button
                  disabled={!formik.isValid || isSigning}
                  className="btn login_btn mx-auto mb-4 mt-2"
                  type="submit"
                >
                  {isSigning ? "Logging in..." : "Login"}
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
