import BidCard from "components/Cards/BidCard";
import BidAmountChart from "components/Charts/BidAmountChart";
import BidAmountDistributionChart from "components/Charts/BidAmountDistributionChart";
import ContractsAwardedPieChart from "components/Charts/ContractsAwardedPieChart";
import RadialBarChart from "components/Charts/RadialBarChart";
import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import "./BidsOversight.css";
import BidOversightDropDown from "components/BidOversightDropDown";

function BidsOversight() {
  const [filterBy, setFilterBy] = React.useState("monthly");
  const [chartData, setChartData] = React.useState([]);
  const [chartLabels, setChartLabels] = React.useState([]); // [

  const AverageBidAmountData = [
    {
      id: 1,
      category: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      values: [
        1500000, 7000000, 30000000, 52000000, 6000000, 10000000, 120000000, 20000000, 140000000,
        15000000, 4000000, 70000000
      ],
      valueBy: "monthly"
    },
    {
      id: 2,
      category: ["Q1", "Q2", "Q3", "Q4"],
      values: [100000000, 120000000, 130000000, 140000000],
      valueBy: "quarterly"
    },
    {
      id: 3,
      category: ["2018", "2019", "2020", "2021"],
      values: [100000000, 120000000, 130000000, 50000000],
      valueBy: "yearly"
    }
  ];
  const handleValue = (value) => {
    setFilterBy(value);
  };

  useEffect(() => {
    console.log("Value: ", filterBy);
    const BidAmountChartData = AverageBidAmountData.find((items) => items.valueBy === filterBy);
    setChartData(BidAmountChartData?.values);
    setChartLabels(BidAmountChartData?.category);
  }, [filterBy]);

  const tableData = [
    {
      id: 1,
      firm_name: "10 November LLC",
      contract_size: "$20,500",
      agency: "DASNY",
      scope_of_word: "Fabrication and Installation of Signage"
    },
    {
      id: 2,
      firm_name: "10 Steps Ahead Inc",
      contract_size: "$670k",
      agency: "NYS Agency",
      scope_of_word: "Apparel, Textile & Leather"
    },
    {
      id: 3,
      firm_name: "11:11 Transportation Corp",
      contract_size: "$450k",
      agency: "Public Benefit Corp.",
      scope_of_word: "Maintenance, Repair & Building "
    },
    {
      id: 4,
      firm_name: "135th Street Agency LLC",
      contract_size: "$440k",
      agency: "CUNY",
      scope_of_word: "Highways & Roadways"
    },
    {
      id: 5,
      firm_name: "147th St. Homes Inc.",
      contract_size: "$400k",
      agency: "NYS Office of General Service",
      scope_of_word: "Vehicles & Equipment"
    },
    {
      id: 6,
      firm_name: "149 Street Food Corp",
      contract_size: "$270k",
      agency: "DASNY",
      scope_of_word: "Fuels & Lubricants"
    },
    {
      id: 7,
      firm_name: "192 Branch Interior Services, Inc.",
      contract_size: "$260k",
      agency: "NYS Public Authority",
      scope_of_word: "Photography & Video"
    },
    {
      id: 8,
      firm_name: "1mpact First, LLC",
      contract_size: "$240k",
      agency: "SUNY",
      scope_of_word: "Medical & Laboratory Equipment"
    },
    {
      id: 9,
      firm_name: "1 Of A Kind Home Health Care LLC",
      contract_size: "$200k",
      agency: "DASNY",
      scope_of_word: "Gardening, Landscaping, Lawn Maintenance"
    },
    {
      id: 10,
      firm_name: "1Star-Networks LLC",
      contract_size: "$120k",
      agency: "NYS Agency",
      scope_of_word: "Safety & Security"
    }
  ];
  return (
    <div className="px-3">
      <div>
        <h4>Awarded Bids Oversight</h4>
      </div>
      <Row className="mt-3">
        <Col xl="5">
          <Card>
            <CardBody>
              <div>
                <h6>Contracts Awarded Through The Platform</h6>
              </div>
              <div className="mt-3 d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <span
                    style={{
                      fontSize: "64px",
                      fontWeight: "700",
                      color: "#1273EB",
                      marginBottom: "5px",
                      lineHeight: "64px"
                    }}
                  >
                    $53
                  </span>
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#1273EB",
                      fontWeight: "700",
                      lineHeight: "18px"
                    }}
                  >
                    Million
                  </span>
                  <span className="mt-4">68.2% more bid than last month.</span>
                </div>
                <div>
                  <RadialBarChart />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="7">
          <Card>
            <CardBody>
              <div>
                <h6>Contracts Awarded Through The Platform</h6>
              </div>
              <div className="mt-3 d-flex justify-content-between align-items-center">
                <div className="mt-3">
                  <ContractsAwardedPieChart />
                </div>
                <div className="mt-3">
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td className="d-flex align-items-center justify-content-center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              border: "2px solid #1273EB",
                              marginTop: "4px"
                            }}
                          ></div>
                        </td>
                        <td className="pl-2">
                          <span>MBE</span>
                          <span
                            className="ml-2"
                            style={{
                              fontSize: "14px"
                            }}
                          >{`(Minority Business Enterprise)`}</span>
                        </td>
                        <td
                          className="text-right"
                          style={{
                            color: "#1273EB"
                          }}
                        >
                          15%
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex align-items-center justify-content-center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              border: "2px solid #28C76F",
                              marginTop: "4px"
                            }}
                          ></div>
                        </td>
                        <td className="pl-2">
                          <span>WBE</span>
                          <span
                            className=" ml-2"
                            style={{
                              fontSize: "14px"
                            }}
                          >{`(Women Business Enterprise)`}</span>
                        </td>
                        <td
                          className="text-right"
                          style={{
                            color: "#28C76F"
                          }}
                        >
                          13%
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex align-items-center justify-content-center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              border: "2px solid #EA5455",
                              marginTop: "4px"
                            }}
                          ></div>
                        </td>
                        <td className="pl-2">
                          <span>M/WBE</span>
                          <span
                            className=" ml-2"
                            style={{
                              fontSize: "14px"
                            }}
                          >{`(Minority Women Business Enterprise)`}</span>
                        </td>
                        <td
                          className="text-right"
                          style={{
                            color: "#EA5455"
                          }}
                        >
                          15%
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex align-items-center justify-content-center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              border: "2px solid #FF9F43",
                              marginTop: "4px"
                            }}
                          ></div>
                        </td>
                        <td className="pl-2">
                          <span>SBE</span>
                          <span
                            className=" ml-2"
                            style={{
                              fontSize: "14px"
                            }}
                          >{`(Small Business Enterprise)`}</span>
                        </td>
                        <td
                          className="text-right"
                          style={{
                            color: "#FF9F43"
                          }}
                        >
                          19%
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex align-items-center justify-content-center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              border: "2px solid #00CFE8",
                              marginTop: "4px"
                            }}
                          ></div>
                        </td>
                        <td className="pl-2">
                          <span>DBE</span>
                          <span
                            className=" ml-2"
                            style={{
                              fontSize: "14px"
                            }}
                          >{`(Disadvantaged Business Enterprise)`}</span>
                        </td>
                        <td
                          className="text-right"
                          style={{
                            color: "#00CFE8"
                          }}
                        >
                          22%
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex align-items-center justify-content-center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              border: "2px solid #82868B",
                              marginTop: "4px"
                            }}
                          ></div>
                        </td>
                        <td className="pl-2">
                          <span>DVOB</span>
                          <span
                            className=" ml-2"
                            style={{
                              fontSize: "14px"
                            }}
                          >{`(Small Disadvantaged Veteran Owned Business)`}</span>
                        </td>
                        <td
                          className="text-right"
                          style={{
                            color: "#82868B"
                          }}
                        >
                          16%
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="6" xl="4">
          <BidCard
            cardColor={"#EAF9F1"}
            borderColor={"#BCEED2"}
            count={530}
            titleColor={"#28C76F"}
            countColor={"#28C76F"}
          >
            Number of Matching Bids
          </BidCard>
        </Col>
        <Col lg="6" xl="4">
          <BidCard
            cardColor={"#FDEEEE"}
            borderColor={"#FBDDDD"}
            count={420}
            titleColor={"#A63C3C"}
            countColor={"#EA5455"}
          >
            Number of awarded bids
          </BidCard>
        </Col>
        <Col lg="6" xl="4">
          <BidCard
            cardColor={"#FFF5EC"}
            borderColor={"#FFE1C5"}
            count={"$25k"}
            titleColor={"#B57130"}
            countColor={"#FF9F43"}
          >
            Average Bid Amount
          </BidCard>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="5">
          <Card>
            <CardHeader>
              <h6>Bid Amount Distribution</h6>
            </CardHeader>
            <CardBody>
              <BidAmountDistributionChart />
            </CardBody>
          </Card>
        </Col>
        <Col lg="7">
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h6>Average Bid Amount by Monthly</h6>
                <BidOversightDropDown handleValue={handleValue} />
              </div>
            </CardHeader>
            <CardBody>
              {chartData.length > 0 ? (
                <BidAmountChart
                  ChartData={chartData}
                  ChartLabels={chartLabels}
                  filterBy={filterBy}
                />
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="12">
          <Card>
            <CardHeader>
              <h6>Top 10 Contracts Awarded</h6>
            </CardHeader>
            <CardBody>
              <Table responsive bordered className="award_table_container">
                <thead className="award_table_header">
                  <tr>
                    <th>SR. No</th>
                    <th>Firm Name</th>
                    <th>Contract Size</th>
                    <th>Agency</th>
                    <th>Scope of Work</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data) => (
                    <tr key={data.id}>
                      <td>{data.id}</td>
                      <td>{data.firm_name}</td>
                      <td>{data.contract_size}</td>
                      <td>{data.agency}</td>
                      <td>{data.scope_of_word}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default BidsOversight;
