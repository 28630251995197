/* eslint-disable no-useless-escape */
import { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { useSelector } from "react-redux";
import { selectUser } from "redux/user/selectors";
import { postFirmInvitationApi } from "services/firmInvitationServices";

function AddFirm() {
  const navigate = useNavigate();
  const { loggedInUser } = useSelector(selectUser);

  const [invitationType, setInvitationType] = useState("email");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState(
    `Click here to accept and begin [gov org name]’s invitation to Alacriti Analytics' business assessment tool`
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(`
      <p>Welcome to Alacriti Analytics!  Our platform is designed to assist small and medium-sized businesses, prime contractors and government agencies in better understanding how they can improve their performance and participation in the public sector procurement ecosystem through data. </p>
<p>Unlike other solutions, Alacriti Analytics doesn’t just add businesses to a stale directory.  Alacriti Analytics' assessment tool assesses a firm’s readiness to do business with the government based on the six most common areas of business failure, and provides an easy to read, credit-report style analysis of the strengths and opportunities for each small business to improve, and aggregates reporting to inform government programs and policy.  [government agency name] has invited you, [firm name] to utilize, free of charge, the Alacriti Analytics assessment tool, and the customized action plan that comes with it.
 Click on the link below to get started.</p>

<p>Prior to beginning your assessment, we advise you to gather relevant documents that contain the following information.  You will need to refer to them during the assessment:</p>
<ul>
  <li>Annual sales information</li>
  <li>Annual revenue information </li>
  <li>Credit score</li>
  <li>Business line of credit information</li>
  <li>Liability insurance information</li>
  <li>Bonding limit, both single job, and aggregate </li>
</ul>`)
      )
    )
  );

  useEffect(() => {
    setDescription(
      `Click here to accept and begin ${loggedInUser?.company}’s invitation to Alacriti Analytics' business assessment tool`
    );
  }, [loggedInUser]);

  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();

      if (invitationType === "email") {
        if (email === "") {
          return toast.error("Please enter email address.");
        }
      }

      if (invitationType === "email") {
        const emails = email.split(",");
        if (!emails.every((item) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(item.trim()))) {
          return toast.error("Please enter valid email address.");
        }
      }

      if (invitationType === "sms") {
        if (phone === "") {
          return toast.error("Please enter phone number.");
        }
      }

      if (invitationType === "sms") {
        const phones = phone.split(",");
        if (!phones.every((item) => /^\d{10}$/.test(item.trim()))) {
          return toast.error("Please enter valid phone number.");
        }
      }

      const reqBody = {
        invite_type: invitationType,
        description:
          invitationType === "email"
            ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
            : description
      };

      if (invitationType === "email") {
        reqBody["email_ids"] = email.split(",").map((item) => item?.trim());
      } else {
        reqBody["sms_numbers"] = phone.split(",");
      }

      const promise = postFirmInvitationApi(reqBody);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Invitation sent successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      navigate("/gov/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="px-3">
      <Row className="m-3">
        <h4>Invite Firms</h4>
      </Row>

      <Row className="mt-4">
        <Col sm="10" className="mx-auto">
          <Card
            className="card-stats mb-4 mb-xl-0"
            style={{
              minHeight: "500px"
            }}
          >
            <CardHeader>
              <Row className="justify-content-center">
                <h3>Firm Invitation</h3>
              </Row>
            </CardHeader>
            <CardBody className="p-5">
              <form onSubmit={onSubmitHandler}>
                <Row>
                  <div className="mr-5">Select Your Invitation Type*</div>
                  <div>
                    <input
                      type="radio"
                      value="email"
                      id="email"
                      className="mr-2"
                      checked={invitationType === "email"}
                      onChange={(e) => setInvitationType(e.target.value)}
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="mx-5">or</div>
                  <div>
                    <input
                      type="radio"
                      value="sms"
                      id="phoneNumber"
                      className="mr-2"
                      checked={invitationType === "sms"}
                      onChange={(e) => setInvitationType(e.target.value)}
                    />
                    <label htmlFor="phoneNumber">Phone Number</label>
                  </div>
                </Row>
                <Row>
                  <Col lg="12" className="p-0 mt-3">
                    {invitationType === "email" ? (
                      <FormGroup>
                        <label className="form-control-label" htmlFor="email">
                          Email Id*
                          <span className="pl-2 company_name_label">
                            Add multiple Id&lsquo;s with (,)
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="RosalynJNugent@jourrapide.com"
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <label className="form-control-label" htmlFor="phone">
                          Phone Number*
                          <span className="pl-2 company_name_label">
                            Add multiple Id&lsquo;s with (,)
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="2305404044"
                          id="phone"
                          name="phone"
                          value={phone}
                          onChange={(e) => {
                            const re = /^[0-9,\b]+$/;
                            if (e.target.value === "" || re.test(e.target.value)) {
                              setPhone(e.target.value);
                            }
                          }}
                        />
                      </FormGroup>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" className="p-0 mt-3">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="description">
                        Description*
                      </label>

                      {invitationType === "email" ? (
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={setEditorState}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                        />
                      ) : (
                        <Input
                          type="textarea"
                          className="form-control"
                          id="description"
                          name="description"
                          rows="5"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#1273EB",
                      width: "15rem",
                      display: "block"
                    }}
                  >
                    Send Invitation
                  </Button>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AddFirm;
