import axios from "axios";
import axiosInstance from "./axiosInstance";

export const postFileManagerApi = (postData) => axiosInstance.post("/api/v1/user/files", postData);
// const requests = {};

// var CancelToken = axios.CancelToken;
export const getFileManagerListingApi = (prms) =>
  axiosInstance.get(
    "/api/v1/user/files",

    {
      params: {
        start_date: prms?.start_date,
        end_date: prms?.end_date
      }
      // cancelToken: new CancelToken((c) => {
      //   // An executor function receives a cancel function as a parameter
      //   requests["getFileManagerListingApi"] = c;
      // }),
    }
  );

export const deleteFileManagerApi = (postData) => {
  return axiosInstance.delete("/api/v1/user/file", {
    data: {
      file_ids: postData?.file_ids
    }
  });
};

// export const cancelRequest = (functionName) => {
//   const cancel = requests[functionName];
//   if(typeof cancel === 'function') {
//     cancel();
//   }
// };
