import { listOfNewYorkState } from "data/mapData";
import { washingtonRegions } from "data/mapData";
import { washingtonShortStates } from "data/mapData";
import { kansasShortStatesName } from "data/mapData";
import { kansasShortStates } from "data/mapData";
import { kansasRegions } from "data/mapData";
import { washingtonShortStatesName } from "data/mapData";
import { newYorkShortStates } from "data/mapData";
import { NewYorkRegions } from "data/mapData";
import { colors } from "data/mapData";

const { default: listOfStates } = require("data/mapData");
const { arrayOfColors } = require("data/mapData");

var colorIndex = 0;

function colorIndexCheck() {
  if (colorIndex >= arrayOfColors.length) {
    colorIndex = 0;
  }
}

var objectStates = (function (arrayOfStates) {
  var objectOfStates = {};
  for (var i = 0; i < arrayOfStates.length; i++) {
    var itemId = arrayOfStates[i].toUpperCase();
    colorIndexCheck();
    objectOfStates[itemId] = {
      "background-color": arrayOfColors[colorIndex++]
      // alpha: 0.3
    };
  }

  return objectOfStates;
})(Object.keys(listOfStates));

export var objectStateCountys = function (arrayOfStates, regions, shortStates) {
  var objectOfStates = {};
  for (var i = 0; i < arrayOfStates.length; i++) {
    var itemId = arrayOfStates[i];
    objectOfStates[itemId.toUpperCase()] = {
      "background-color":
        colors[
          Object.keys(regions).find((reg) =>
            regions[reg].find((name) => name === shortStates[itemId])
          )
        ] || "#80CBC4"
      // alpha: 0.3,
    };
  }

  return objectOfStates;
};

export function getStateDetails(id) {
  switch (id) {
    case "NY":
      return {
        regions: NewYorkRegions,
        shortStateId: listOfNewYorkState,
        shortStatesName: newYorkShortStates
      };
    case "WA":
      return {
        regions: washingtonRegions,
        shortStateId: washingtonShortStatesName,
        shortStatesName: washingtonShortStates
      };
    case "KS":
      return {
        regions: kansasRegions,
        shortStateId: kansasShortStatesName,
        shortStatesName: kansasShortStates
      };

    default:
      return {
        regions: {},
        shortStateId: [],
        shortStatesName: {}
      };
  }
}

export default objectStates;
