import React from "react";
import ReactApexChart from "react-apexcharts";

class RadialBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [25],
      options: {
        chart: {
          height: 310,
          type: "radialBar"
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%"
            }
          }
        },
        labels: ["last 3 Months"]
      }
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="radialBar"
            height={310}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default RadialBarChart;
