import {
  FETCH_ADMIN_FILES_DATA_REQUEST,
  FETCH_ADMIN_FILES_DATA_SUCCESS,
  FETCH_ADMIN_FILES_DATA_FAILURE,
  FETCH_FIRMS_FILES_LISTING_DATA_REQUEST,
  FETCH_FIRMS_FILES_LISTING_DATA_SUCCESS,
  FETCH_FIRMS_FILES_LISTING_DATA_FAILURE
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  AdminFileManagerData: [],
  PageCount: 1,
  totalRecords: 0,
  firmsFileManagerData: [],
  firmUserName: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_FILES_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ADMIN_FILES_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        AdminFileManagerData: action.payload.data,
        PageCount: action.payload.page_count,
        totalRecords: action.payload.totalRecords,
        error: ""
      };
    case FETCH_ADMIN_FILES_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        AdminFileManagerData: [],
        PageCount: 1,
        totalRecords: 0,
        error: action.payload
      };

    case FETCH_FIRMS_FILES_LISTING_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_FIRMS_FILES_LISTING_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        firmsFileManagerData: action.payload.data,
        firmUserName: action.payload.username,
        error: ""
      };
    case FETCH_FIRMS_FILES_LISTING_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        firmsFileManagerData: [],
        firmUserName: "",
        error: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
