import React from "react";
import { Col, Row } from "reactstrap";

function Contact({ contactData, AgencyName }) {
  return (
    <div className="p-4">
      {contactData?.map((item, index) => {
        if (
          item?.first_name === "" &&
          item?.last_name === "" &&
          item?.email === "" &&
          item?.phone === "" &&
          item?.address1 === "" &&
          item?.city === "" &&
          item?.state === "" &&
          item?.zip === "" &&
          item?.country === ""
        ) {
          return null;
        } else {
          return (
            <div key={index}>
              <h6 className="mt-4 text-capitalize">{item?.type} contact:</h6>
              <Row className="mt-3">
                <Col md="2">
                  <span>Agency Name:</span>
                </Col>
                <Col md="10">
                  <span>{AgencyName ? AgencyName : "---"}</span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="2">
                  <span>Contact Person:</span>
                </Col>
                <Col md="10">
                  <span>
                    {item?.first_name} {item?.last_name}
                  </span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="2">
                  <span>Location:</span>
                </Col>
                <Col md="10">
                  <span>{item?.address1}</span>
                  <br />
                  <span>
                    {item?.city}, {item?.state} {item?.zip}
                  </span>
                  <br />
                  <span>{item?.country} </span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="2">
                  <span>Phone Number:</span>
                </Col>
                <Col md="10">
                  <span>{item?.phone}</span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="2">
                  <span>Email:</span>
                </Col>
                <Col md="10">
                  <span>{item?.email}</span>
                </Col>
              </Row>
            </div>
          );
        }
      })}
    </div>
  );
}

export default Contact;
