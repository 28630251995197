import { useMemo, useState, useEffect, Fragment } from "react";
import { Col, Row, Table, Input, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Paginate from "components/Pagination/Pagination";
import Search from "components/Search/Search";

import { selectAllAssessmentList } from "redux/adminAssessmentList/selectors";
import { selectUserToken } from "redux/user/selectors";
import { fetchAllAssessmentListThunkAction } from "redux/adminAssessmentList/actions";
import { getColumns } from "./AllAssessmentcolumn";

import DropDown from "components/DropDown/DropDown";
import "./AllAssessment.css";
import Loader from "components/Loader/Loader";
import userRoles from "utils/roles";
import { useNavigate } from "react-router-dom";
import { selectUserRole } from "redux/user/selectors";
import DropDownSelect from "components/DropDownselect/DropDownSelect";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

const AllAssessment = () => {
  const [assessmentlistData, setAssessmentListData] = useState([]);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState(null);
  const [index, setIndex] = useState();
  const [date, setDate] = useState({
    end_date: "",
    start_date: ""
  });
  const [selectFirms, setSelectFirms] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector(selectUserRole) || localStorage.getItem("role");
  const { allassessmentlist, isLoading, totalPage } = useSelector(selectAllAssessmentList);
  const token = useSelector(selectUserToken);

  useEffect(() => {
    setAssessmentListData(allassessmentlist);
  }, [allassessmentlist, allassessmentlist?.length]);

  const NavigateTo = (id) => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return `/admin/assessment/analysis/${id}`;
      case userRoles.GOVT:
        return `/gov/assessment/analysis/${id}`;
      case userRoles.FIRMS:
        return `/firms/assessment/analysis/${id}`;
      case userRoles.GOVT_FIRMS:
        return `/govt-firms/assessment/analysis/${id}`;
      default:
        return `/dashboard/${id}`;
    }
  };

  const onClickViewHandler = (id) => {
    if (id) {
      navigate(`${NavigateTo(id)}`);
    }
  };

  const columns = useMemo(() => getColumns(index, onClickViewHandler), [index]);
  const data = useMemo(() => assessmentlistData, [assessmentlistData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: Number(role) === userRoles.ADMIN ? ["attempt_number"] : ["role_id"]
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  useEffect(() => {
    if (token) {
      dispatch(
        fetchAllAssessmentListThunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex,
            column: sort?.sortBy,
            sort: sort?.sortDirection,
            start_date: date?.start_date,
            end_date: date?.end_date,
            list: selectFirms
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, sort, date, selectFirms]);

  useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);

  const handleValue = (start_date, end_date) => {
    setDate({
      end_date: end_date,
      start_date: start_date
    });
  };

  const handleFirmValue = (id) => {
    setSelectFirms(id);
    gotoPage(0);
  };

  return (
    <div className="px-3">
      <div className="d-flex align-items-center m-2">
        <span className="Headers">
          <h4>All Assessments</h4>
        </span>
      </div>
      <Row className="mt-3">
        <Col>
          <Card>
            <CardHeader>
              <Row className="justify-content-between  align-items-center mx-2">
                {Number(role) === userRoles.ADMIN ? (
                  <>
                    <div className="mr-3 d-flex">
                      <p className="m-2">Select List Type</p>
                      <DropDownSelect handleFirmValue={handleFirmValue} />
                    </div>
                  </>
                ) : null}

                <DropDown handleValue={handleValue} />
              </Row>
            </CardHeader>
            <CardBody>
              <div>
                <Row>
                  <Col>
                    <div className="">
                      <nav aria-label="..." className="post_footer">
                        <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
                          <Col xs="12" md="10" className="d-flex align-items-center">
                            <span>Show</span>
                            <Input
                              type="select"
                              style={{
                                width: "7rem",
                                margin: "10px"
                              }}
                              value={pageSize}
                              onChange={(e) => {
                                setPageSize(Number(e.target.value));
                              }}
                            >
                              {[5, 10, 20, 30, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              ))}
                            </Input>
                          </Col>
                          <Col xs="12" md="2">
                            <Search onSubmitHandler={onSubmitHandler} />
                          </Col>
                        </Row>
                      </nav>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    {isLoading ? (
                      <Fragment>
                        <div className="post_error_loading">
                          <Loader />
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {error ? (
                          <Fragment>
                            <div className="post_error_loading">{error}</div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Table
                              {...getTableProps()}
                              className="align-items-center table-flush survey_table_container"
                              responsive
                              bordered
                            >
                              <thead className="thead-light survey_table_header">
                                {headerGroups.map((headerGroup) => (
                                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                                    {headerGroup.headers.map((column) => (
                                      <th
                                        key={column.id}
                                        onClick={() => {
                                          if (!column.disableSortBy) {
                                            const desc =
                                              column.isSortedDesc === true
                                                ? undefined
                                                : column.isSortedDesc === false
                                                ? true
                                                : false;
                                            setSort({
                                              sortBy: column.id,
                                              sortDirection: desc ? "desc" : "asc"
                                            });
                                            setSortBy([{ id: column.id, desc }]);
                                          }
                                        }}
                                        {...column.getHeaderProps()}
                                      >
                                        {column.render("Header")}
                                        <span>
                                          {column.isSorted ? (
                                            column.isSortedDesc ? (
                                              <i className="fas fa-arrow-down ml-2"></i>
                                            ) : (
                                              <i className="fas fa-arrow-up ml-2"></i>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </th>
                                    ))}
                                  </tr>
                                ))}
                              </thead>
                              <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                  prepareRow(row);
                                  return (
                                    <tr {...row.getRowProps()} key={row.id}>
                                      {row.cells.map((cell) => {
                                        return (
                                          <td key={cell.id} {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            {!error && assessmentlistData?.length === 0 && (
                              <div className="post_error_loading">No assessment data found.</div>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter
              style={{
                backgroundColor: "#fff",
                borderTopWidth: "0px",
                borderRadius: "0.5rem"
              }}
            >
              <nav aria-label="..." className="post_footer">
                <Paginate
                  pageIndex={pageIndex}
                  pageCount={pageCount}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  gotoPage={gotoPage}
                />
              </nav>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AllAssessment;
