import "zingchart/es6";
import ZingChart from "zingchart-react";
import { Component } from "react";
// EXPLICITLY IMPORT MODULE
import "zingchart/modules-es6/zingchart-maps.min.js";
import "zingchart/modules-es6/zingchart-maps-usa.min.js";

import listOfStates from "data/mapData";
import objectStates from "utils/map";
import { objectStateCountys } from "utils/map";
import { getStateDetails } from "utils/map";
import { colors } from "data/mapData";

export default class Zingchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        regions: {},
        shortStateId: [],
        shortStatesName: {},
        shapes: [
          {
            type: "zingchart.maps",
            options: {
              name: "usa",
              zooming: false,
              panning: false,
              scrolling: false,
              style: {
                controls: {
                  visible: false
                },
                fillType: "radial",
                cursor: "pointer",

                items: objectStates
              }
            }
          }
        ]
      },
      stateConfig: {
        shapes: [
          {
            type: "zingchart.maps",
            options: {
              name: "",

              zooming: false,
              panning: false,
              scrolling: false,

              style: {
                controls: {
                  visible: false
                },
                fillType: "radial",
                hoverState: {
                  alpha: 0.3,
                  backgroundColor: "white"
                },
                tooltip: {
                  alpha: 0.8,
                  backgroundColor: "white",
                  borderColor: "white",
                  borderRadius: 3,
                  fontColor: "black",
                  fontFamily: "Georgia",
                  fontSize: 12,
                  textAlpha: 1
                }
              }
            }
          }
        ]
      }
    };
    this.selectStateHandler = this.selectStateHandler.bind(this);
    this.selectRegionHandler = this.selectRegionHandler.bind(this);
    this.dynamicImportStateFile = this.dynamicImportStateFile.bind(this);
  }

  async dynamicImportStateFile(stateCode) {
    switch (stateCode) {
      case "NY":
        await import("zingchart/modules-es6/zingchart-maps-usa_ny.min.js");
        break;
      case "CA":
        await import("zingchart/modules-es6/zingchart-maps-usa_ca.min.js");
        break;
      case "WA":
        await import("zingchart/modules-es6/zingchart-maps-usa_wa.min.js");
        break;
      case "AZ":
        await import("zingchart/modules-es6/zingchart-maps-usa_az.min.js");
        break;
      case "TX":
        await import("zingchart/modules-es6/zingchart-maps-usa_tx.min.js");
        break;
      case "NM":
        await import("zingchart/modules-es6/zingchart-maps-usa_nm.min.js");
        break;
      case "PA":
        await import("zingchart/modules-es6/zingchart-maps-usa_pa.min.js");
        break;
      case "OR":
        await import("zingchart/modules-es6/zingchart-maps-usa_or.min.js");
        break;
      case "MT":
        await import("zingchart/modules-es6/zingchart-maps-usa_mt.min.js");
        break;
      case "ID":
        await import("zingchart/modules-es6/zingchart-maps-usa_id.min.js");
        break;
      case "WY":
        await import("zingchart/modules-es6/zingchart-maps-usa_wy.min.js");
        break;
      case "UT":
        await import("zingchart/modules-es6/zingchart-maps-usa_ut.min.js");
        break;
      case "NV":
        await import("zingchart/modules-es6/zingchart-maps-usa_nv.min.js");
        break;
      case "CO":
        await import("zingchart/modules-es6/zingchart-maps-usa_co.min.js");
        break;
      case "ND":
        await import("zingchart/modules-es6/zingchart-maps-usa_nd.min.js");
        break;
      case "SD":
        await import("zingchart/modules-es6/zingchart-maps-usa_sd.min.js");
        break;
      case "NE":
        await import("zingchart/modules-es6/zingchart-maps-usa_ne.min.js");
        break;
      case "KS":
        await import("zingchart/modules-es6/zingchart-maps-usa_ks.min.js");
        break;
      case "OK":
        await import("zingchart/modules-es6/zingchart-maps-usa_ok.min.js");
        break;
      case "MN":
        await import("zingchart/modules-es6/zingchart-maps-usa_mn.min.js");
        break;
      case "IA":
        await import("zingchart/modules-es6/zingchart-maps-usa_ia.min.js");
        break;
      case "MO":
        await import("zingchart/modules-es6/zingchart-maps-usa_mo.min.js");
        break;
      case "AR":
        await import("zingchart/modules-es6/zingchart-maps-usa_ar.min.js");
        break;
      case "LA":
        await import("zingchart/modules-es6/zingchart-maps-usa_la.min.js");
        break;
      case "WI":
        await import("zingchart/modules-es6/zingchart-maps-usa_wi.min.js");
        break;
      case "IL":
        await import("zingchart/modules-es6/zingchart-maps-usa_il.min.js");
        break;
      case "MI":
        await import("zingchart/modules-es6/zingchart-maps-usa_mi.min.js");
        break;
      case "IN":
        await import("zingchart/modules-es6/zingchart-maps-usa_in.min.js");
        break;
      case "KY":
        await import("zingchart/modules-es6/zingchart-maps-usa_ky.min.js");
        break;
      case "TN":
        await import("zingchart/modules-es6/zingchart-maps-usa_tn.min.js");
        break;
      case "MS":
        await import("zingchart/modules-es6/zingchart-maps-usa_ms.min.js");
        break;
      case "AL":
        await import("zingchart/modules-es6/zingchart-maps-usa_al.min.js");
        break;
      case "OH":
        await import("zingchart/modules-es6/zingchart-maps-usa_oh.min.js");
        break;
      case "WV":
        await import("zingchart/modules-es6/zingchart-maps-usa_wv.min.js");
        break;
      case "VA":
        await import("zingchart/modules-es6/zingchart-maps-usa_va.min.js");
        break;
      case "NC":
        await import("zingchart/modules-es6/zingchart-maps-usa_nc.min.js");
        break;
      case "SC":
        await import("zingchart/modules-es6/zingchart-maps-usa_sc.min.js");
        break;
      case "GA":
        await import("zingchart/modules-es6/zingchart-maps-usa_ga.min.js");
        break;
      case "FL":
        await import("zingchart/modules-es6/zingchart-maps-usa_fl.min.js");
        break;
      case "DE":
        await import("zingchart/modules-es6/zingchart-maps-usa_de.min.js");
        break;
      case "MD":
        await import("zingchart/modules-es6/zingchart-maps-usa_md.min.js");
        break;
      case "NJ":
        await import("zingchart/modules-es6/zingchart-maps-usa_nj.min.js");
        break;
      case "CT":
        await import("zingchart/modules-es6/zingchart-maps-usa_ct.min.js");
        break;
      case "RI":
        await import("zingchart/modules-es6/zingchart-maps-usa_ri.min.js");
        break;
      case "MA":
        await import("zingchart/modules-es6/zingchart-maps-usa_ma.min.js");
        break;
      case "NH":
        await import("zingchart/modules-es6/zingchart-maps-usa_nh.min.js");
        break;
      case "ME":
        await import("zingchart/modules-es6/zingchart-maps-usa_me.min.js");
        break;
      case "VT":
        await import("zingchart/modules-es6/zingchart-maps-usa_vt.min.js");
        break;
      case "HI":
        await import("zingchart/modules-es6/zingchart-maps-usa_hi.min.js");
        break;
      case "AK":
        await import("zingchart/modules-es6/zingchart-maps-usa_ak.min.js");
        break;
      default:
        "";
    }
  }

  isStateExist(newMapId) {
    let drillDownCountries = Object.keys(listOfStates).map(function (curVal) {
      return "usa_" + curVal;
    });
    for (let i = 0; i < drillDownCountries.length; i++) {
      if (newMapId === drillDownCountries[i]) return true;
    }

    return false;
  }

  async selectStateHandler(event) {
    if (event.shapeid === "back_button") {
      return;
    }
    let shapeId = event.shapeid;
    let newMapId = "usa_" + String(event.shapeid).toLowerCase();
    await this.dynamicImportStateFile(shapeId);

    this.props.setState(shapeId);

    let details = getStateDetails(shapeId);
    this.setState({
      regions: details.regions,
      shortStateId: details.shortStateId,
      shortStatesName: details.shortStatesName
    });

    if (this.isStateExist(newMapId)) {
      this.setState({
        stateConfig: {
          shapes: [
            {
              type: "zingchart.maps",
              options: {
                name: newMapId,

                zooming: false,
                panning: false,
                scrolling: false,

                style: {
                  controls: {
                    visible: false
                  },
                  fillType: "radial",
                  hoverState: {
                    alpha: 0.3,
                    backgroundColor: "white"
                  },
                  items: objectStateCountys(
                    details.shortStateId,
                    details.regions,
                    details.shortStatesName
                  ),
                  tooltip: {
                    alpha: 0.8,
                    backgroundColor: "white",
                    borderColor: "white",
                    borderRadius: 3,
                    fontColor: "black",
                    fontFamily: "Georgia",
                    fontSize: 12,
                    textAlpha: 1
                  }
                }
              }
            }
          ]
        }
      });
    }
  }

  async selectRegionHandler(event) {
    const countyName = this.state.shortStatesName[String(event.shapeid).toLowerCase()];
    const regionName = Object.keys(this.state.regions).find((reg) =>
      this.state.regions[reg].includes(countyName)
    );
    const stateName = listOfStates[String(this.props.state).toLowerCase()];

    this.props.setRegion({
      countyName,
      regionName,
      stateName
    });
  }

  render() {
    if (!this.props.state) {
      return <ZingChart data={this.state.config} shape_click={this.selectStateHandler} />;
    }
    return (
      <div className="d-flex">
        <ZingChart data={this.state.stateConfig} shape_click={this.selectRegionHandler} />
        <div
          style={{
            width: "20%"
          }}
        >
          <h4 className="mb-4">Region</h4>
          <div className="d-block mr-2">
            {Object.keys(this.state.regions || {}).map((item) => (
              <div
                key={item}
                className="d-flex"
                style={{
                  columnGap: "10px",
                  whiteSpace: "nowrap",
                  marginBottom: "0.8rem"
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                    backgroundColor: colors[item]
                  }}
                ></div>
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
