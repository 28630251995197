export const FETCH_COMPLETED_ASSESSMENT_LIST_REQUEST = "FETCH_COMPLETED_ASSESSMENT_LIST_REQUEST";
export const FETCH_COMPLETED_ASSESSMENT_LIST_SUCCESS = "FETCH_COMPLETED_ASSESSMENT_LIST_SUCCESS";
export const FETCH_COMPLETED_ASSESSMENT_LIST_FAILURE = "FETCH_COMPLETED_ASSESSMENT_LIST_FAILURE";

export const RESET_COMPLETED_ASSESSMENT_LIST_DATA = "RESET_COMPLETED_ASSESSMENT_LIST_DATA";

export const FETCH_ASSESSMENT_QUESTION_LIST_REQUEST = "FETCH_ASSESSMENT_QUESTION_LIST_REQUEST";
export const FETCH_ASSESSMENT_QUESTION_LIST_SUCCESS = "FETCH_ASSESSMENT_QUESTION_LIST_SUCCESS";
export const FETCH_ASSESSMENT_QUESTION_LIST_FAILURE = "FETCH_ASSESSMENT_QUESTION_LIST_FAILURE";

export const FETCH_ONGOING_ASSESSMENT_LIST_REQUEST = "FETCH_ONGOING_ASSESSMENT_LIST_REQUEST";
export const FETCH_ONGOING_ASSESSMENT_LIST_SUCCESS = "FETCH_ONGOING_ASSESSMENT_LIST_SUCCESS";
export const FETCH_ONGOING_ASSESSMENT_LIST_FAILURE = "FETCH_ONGOING_ASSESSMENT_LIST_FAILURE";

export const FETCH_VIEW_ASSESSMENT_ANALYSIS_REQUEST = "FETCH_VIEW_ASSESSMENT_ANALYSIS_REQUEST";
export const FETCH_VIEW_ASSESSMENT_ANALYSIS_SUCCESS = "FETCH_VIEW_ASSESSMENT_ANALYSIS_SUCCESS";
export const FETCH_VIEW_ASSESSMENT_ANALYSIS_FAILURE = "FETCH_VIEW_ASSESSMENT_ANALYSIS_FAILURE";

export const ASSESSMENT_STARTED = "ASSESSMENT_STARTED";
