import axiosInstance from "./axiosInstance";

export const getFirmBidOpportunityListingApi = (reqBody, currentPage) => {
  let path = "/api/v1/firms/bid-list";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/bid-list";
  }

  if (localStorage.getItem("role") === "1") {
    path = "/api/v1/admin/bid-list";
  }
  return axiosInstance.post(path, {
    agency_type: reqBody.agencyType.length > 0 ? reqBody.agencyType : undefined,
    job_type: reqBody.jobType.length > 0 ? reqBody.jobType : undefined,
    category_type: reqBody.category.length > 0 ? reqBody.category : undefined,
    location: reqBody.location.length > 0 ? reqBody.location : undefined,
    limit: reqBody.limit,
    offset: currentPage,
    start_date: reqBody.startDate ? reqBody.startDate : undefined,
    end_date: reqBody.endDate ? reqBody.endDate : undefined,
    search: reqBody.search ? reqBody.search : undefined,
    sort: reqBody.sortBy ? reqBody.sortBy : undefined,
    column: reqBody.columnName ? reqBody.columnName : undefined,
    my_bid: localStorage.getItem("role") === "1" ? undefined : reqBody.bidType
  });
};

export const getFirmBidOpportunityDetailsApi = (id) => {
  let path = `/api/v1/firms/bid/${id}`;

  if (localStorage.getItem("role") === "3") {
    path = `/api/v1/govt-firms/bid/${id}`;
  }
  if (localStorage.getItem("role") === "1") {
    path = `/api/v1/admin/bid/${id}`;
  }
  return axiosInstance.get(path);
};

export const getCategoryListApi = () => {
  let path = "/api/v1/firms/categories";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/categories";
  }
  return axiosInstance.get(path);
};

export const getAgencyTypeListApi = () => {
  let path = "/api/v1/firms/agency-type";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/agency-type";
  }
  return axiosInstance.get(path);
};

export const getJobTypeListApi = () => {
  let path = "/api/v1/firms/job-type";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/job-type";
  }
  return axiosInstance.get(path);
};

export const getLocationsListApi = () => {
  let path = "/api/v1/firms/locations";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/locations";
  }
  return axiosInstance.get(path);
};

export const GenerateNAICScode = (reqBody) => {
  return axiosInstance.post("/api/v1/admin/generate/naics-code", reqBody);
};
