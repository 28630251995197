import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import PropTypes from "prop-types";

function DropDownSelect({ direction = "down", handleFirmValue, ...args }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className="">
      <Dropdown color="primary" isOpen={dropdownOpen} toggle={toggle} direction={direction}>
        <DropdownToggle caret style={{ backgroundColor: "#1273EB" }}>
          {selectedValue ? selectedValue : "All Firms"}
        </DropdownToggle>
        <DropdownMenu {...args} right>
          <DropdownItem
            onClick={() => {
              handleFirmValue();
              setSelectedValue("All Firms");
            }}
          >
            All Firms
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              handleFirmValue(4);
              setSelectedValue("Self-Service");
            }}
          >
            Self-Service
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              handleFirmValue(3);
              setSelectedValue("Government");
            }}
          >
            Government
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

DropDownSelect.propTypes = {
  direction: PropTypes.string
};

export default DropDownSelect;
