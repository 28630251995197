import { useState, useEffect, Fragment } from "react";
import { Badge, Card, CardBody, CardHeader, Col, Row, Table, Button, Progress } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectAssessmentList } from "redux/firmsListofAssessment/selectors";
import { fetchOngoingAssessmentListThunkAction } from "redux/firmsListofAssessment/actions";
import { selectUserToken } from "redux/user/selectors";
import "./GovernmentListofSurvey.css";
import moment from "moment";
import { selectUserRole } from "redux/user/selectors";
import userRoles from "utils/roles";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader/Loader";

import converter from "number-to-words";

const OngoingAssessmentTable = () => {
  const [ongoingassessmentlistData, setOnGoingAssessmentListData] = useState([]);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ongoingAssessmentList, isOngoingLoading } = useSelector(selectAssessmentList);
  const token = useSelector(selectUserToken);

  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const NavigateTo = (id) => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return `/admin/pending/Assessment/${id}`;
      case userRoles.GOVT:
        return `/gov/pending/Assessment/${id}`;
      case userRoles.FIRMS:
        return `/firms/pending/Assessment/${id}`;
      case userRoles.GOVT_FIRMS:
        return `/govt-firms/pending/Assessment/${id}`;
      default:
        return `/dashboard/${id}`;
    }
  };

  useEffect(() => {
    setOnGoingAssessmentListData(ongoingAssessmentList);
  }, [ongoingAssessmentList, ongoingAssessmentList?.length]);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchOngoingAssessmentListThunkAction(onSuccess, onError));
    }
  }, [token]);

  const surveyPendingContinueHandler = (id) => {
    navigate(`${NavigateTo(id)}`);
  };

  return (
    <Row className="mt-4">
      <Col>
        <Card>
          <CardHeader>
            <Row className="justify-content-between align-items-center mx-1">
              <h6 className="mb-0">Ongoing Assessment</h6>
            </Row>
          </CardHeader>
          <CardBody>
            <Table responsive bordered className="governmentlistofsurvey_table_container">
              <thead className="governmentlistofsurvey_table_header">
                <tr>
                  <th>Id</th>
                  <th className="Survey_Date_table_column">Number</th>
                  <th className="Survey_number_table_column">date</th>
                  <th className="Survey_Progress_table_column">Progress</th>
                  <th className="Survey_Progress_table_column">Version</th>
                  <th className="Action_table_column">Actions</th>
                </tr>
              </thead>
              {isOngoingLoading ? (
                <Fragment>
                  <div className="post_loading">
                    <Loader />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {error ? (
                    <Fragment>
                      <div className="post_loading">{error}</div>
                    </Fragment>
                  ) : (
                    <tbody>
                      {ongoingassessmentlistData?.length > 0 &&
                        ongoingassessmentlistData?.map((AssessmentList, index) => {
                          return (
                            <tr key={AssessmentList?.id}>
                              <td>{index + 1}</td>
                              <td>
                                <Badge
                                  color="primary"
                                  style={{
                                    color: `${
                                      AssessmentList?.attempt_number % 2 === 0
                                        ? "#FF9F43"
                                        : "#1273EB"
                                    }`,
                                    backgroundColor: `${
                                      AssessmentList?.attempt_number % 2 === 0
                                        ? "#FFF5EC"
                                        : "#E7F1FD"
                                    }`
                                  }}
                                  className="text-capitalize"
                                  pill
                                >
                                  {converter.toWordsOrdinal(AssessmentList?.attempt_number)}
                                </Badge>
                              </td>
                              <td>
                                {moment(`${AssessmentList?.created_at}`).format("MM-DD-YYYY")}
                              </td>
                              <td className="position-relative">
                                <>
                                  <Progress
                                    value={AssessmentList?.completion_percent}
                                    style={{
                                      borderRadius: "10px",
                                      position: "absolute",
                                      top: "50%",
                                      width: "90%",
                                      transform: "translateY(-50%)"
                                    }}
                                  >
                                    {AssessmentList?.completion_percent}%
                                  </Progress>
                                </>
                              </td>
                              <td>{AssessmentList?.version}</td>
                              <td>
                                <Button
                                  color="continue"
                                  size="sm"
                                  className="mr-3"
                                  outline
                                  onClick={() => surveyPendingContinueHandler(AssessmentList?.id)}
                                >
                                  Continue
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </Fragment>
              )}
            </Table>
            {!error && ongoingassessmentlistData?.length === 0 && (
              <div className="post_error_loading pt-3">No assessments found.</div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default OngoingAssessmentTable;
