import { toggleContext } from "App";
import React from "react";
import { NavLink } from "react-router-dom";
import useViewport from "hooks/useViewport";
import { Icon } from "@iconify/react";

const Sidebaritems = ({ item }) => {
  const [open, setOpen] = React.useState(false);
  const { isopen, setisOpen } = React.useContext(toggleContext);
  const { width } = useViewport();

  if (item.childrens) {
    return (
      <div className={open ? "open" : "sidebar-item"}>
        <div className="sidebar-title" onClick={() => setOpen(!open)}>
          <span>
            {item.icon && <Icon icon={item.icon} className="mr-2 mb-1" />}
            <span className={isopen ? "" : "d-none"}>{item.title}</span>
          </span>
          {open ? (
            <Icon icon="material-symbols:keyboard-arrow-down" width="20" height="20" />
          ) : (
            <Icon icon="material-symbols:keyboard-arrow-right" width="20" height="20" />
          )}
        </div>
        <div className="sidebar-content">
          {item.childrens.map((item, index) => (
            <NavLink
              key={index}
              to={item.path || "/"}
              className={(navData) =>
                navData.isActive ? "sidebar-item plain childactive" : "sidebar-item plain"
              }
            >
              {item.icon && <Icon icon={item.icon} className="ml-2 mb-1" width="23" height="23" />}
              <span className={isopen ? "" : "d-none"}>{item.title}</span>
            </NavLink>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        to={item.path || "/"}
        className={(navData) =>
          navData.isActive ? "sidebar-item plain active align-items-center" : "sidebar-item plain"
        }
        onClick={() => {
          width < 785 ? setisOpen(false) : {};
        }}
      >
        {item.icon && <Icon icon={item.icon} className="mr-2 mb-1" />}
        <span className={isopen ? "" : "d-none"}>{item.title}</span>
      </NavLink>
    );
  }
};

export default Sidebaritems;
