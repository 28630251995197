import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AES, enc } from "crypto-js";

import "../Login/Login.css";
import { Col, FormGroup, Row } from "reactstrap";
import AuthLogo from "components/AuthLogo/AuthLogo";
import { selectUser } from "redux/user/selectors";
import { verifyEmailThunkAction } from "redux/user/actions";

function SendEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isGovernmentEmailPage = pathname.includes("government/verifyemail");
  const { isSigning } = useSelector(selectUser);

  const navigateToOTPPage = (email) => {
    localStorage.setItem("verifiedEmail", email);
    if (isGovernmentEmailPage) {
      navigate("/auth/government/verifyotp");
    } else {
      navigate("/auth/firm/verifyotp");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email is required.").email("Invalid email address.")
    }),
    onSubmit: (values) => {
      dispatch(verifyEmailThunkAction({ email: values.email }, navigateToOTPPage));
    }
  });

  return (
    <Fragment>
      <div className="login_container">
        <AuthLogo />
        <div className="login_form mx-auto">
          <Row className="d-flex justify-content-between px-5 pt-4">
            <div>
              <h2 className="verify_email_title">
                {" "}
                {isGovernmentEmailPage ? "Government Agency" : "Firm"}
              </h2>
              <h2 className="login_form_title">
                Verify Email{" "}
                {/* <span
                  className="register_page_title"
                  style={{
                    fontSize: "1.5rem"
                  }}
                >
                  {isGovernmentEmailPage ?  "(Government Agency)" : "(Firm)"}
                </span> */}
              </h2>
            </div>
          </Row>
          <form onSubmit={formik.handleSubmit} className="sign_up_form">
            <Row className="px-lg-5 px-md-4 px-3 py-1">
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="userName">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger mt-2">{formik.errors.email}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm={12}>
                <button
                  disabled={!formik.isValid || isSigning}
                  className="btn login_btn mx-auto mb-4 mt-2"
                  type="submit"
                >
                  {isSigning ? "Verifiying..." : "Submit"}
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default SendEmail;
