import React from "react";
import ReactApexChart from "react-apexcharts";

class PieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: this.props.data?.filter((item, index) => index < this.props.data?.length - 1),

      options: {
        title: {
          text: [
            `${this.props.text}`,
            `$${new Intl.NumberFormat("en-US").format(
              this.props.data[this.props.data?.length - 1]
            )}`
          ],
          align: "left",
          offsetX: 0,
          offsetY: 10,
          floating: false,
          style: {
            fontSize: "15px",
            fontWeight: "500",
            fontFamily: "Montserrat, sans-serif",
            lineHeight: "23px"
          }
        },
        chart: {
          type: "pie"
        },
        plotOptions: {
          pie: {
            offsetY: 0,
            offsetX: 0
          }
        },
        colors: this.props.colors,
        labels: this.props.labels?.filter((item, index) => index < this.props.labels?.length - 1),

        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          floating: false,
          fontFamily: "Montserrat , sans-serif",
          fontWeight: 500,
          verticalAlign: "left",
          lineHeight: "23px",
          offsetX: 0,
          offsetY: 0,
          itemMargin: {
            horizontal: 10,
            vertical: 0
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$" + new Intl.NumberFormat("en-US").format(val);
            }
          }
        },
        responsive: [
          {
            breakpoint: 780,
            options: {
              chart: {
                type: "pie",
                width: 340,
                height: 250,
                offsetY: 0,
                offsetX: 0
              },
              legend: {
                position: "bottom",
                floating: false,
                offsetX: -20,
                offsetY: 0,
                itemMargin: {
                  horizontal: 3,
                  vertical: 0
                }
              },
              plotOptions: {
                pie: {
                  offsetY: 0,
                  offsetX: 0
                }
              }
            }
          }
        ]
      }
    };
  }

  render() {
    return (
      <div id="chart" style={{ margin: "auto", textTransform: "capitalize" }}>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          width={350}
          height={280}
        />
      </div>
    );
  }
}

export default PieChart;
