import axiosInstance from "./axiosInstance";

export const getAllAssessmentListApi = (prms) => {
  let path = "/api/v1/govt/survey-list";

  if (localStorage.getItem("role") === "1") {
    path = "/api/v1/admin/survey-list";
  }
  return axiosInstance.get(path, {
    params: {
      limit: prms.limit || 5,
      offset: prms?.page || 0,
      search: prms?.search || "",
      sort: prms?.sort || "",
      column: prms?.column || "",
      start_date: prms?.start_date,
      end_date: prms?.end_date,
      list: prms?.list
    }
  });
};
