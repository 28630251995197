import { getFirmBidOpportunityListingApi } from "services/firmBidOpportunityService";
import {
  FETCH_BID_LIST_REQUEST,
  FETCH_BID_LIST_SUCCESS,
  FETCH_BID_LIST_FAILURE,
  FETCH_BID_DETAILS_REQUEST,
  FETCH_BID_DETAILS_SUCCESS,
  FETCH_BID_DETAILS_FAILURE
} from "./types";
import { getFirmBidOpportunityDetailsApi } from "services/firmBidOpportunityService";

export const fetchBidListingRequest = () => {
  return {
    type: FETCH_BID_LIST_REQUEST
  };
};

export const fetchBidListingSuccess = (listData, totalPage, naics_code) => {
  return {
    type: FETCH_BID_LIST_SUCCESS,
    payload: { listData, totalPage, naics_code }
  };
};

export const fetchBidListingFailure = (error) => {
  return {
    type: FETCH_BID_LIST_FAILURE,
    payload: error
  };
};

export const fetchBidDetailsRequest = () => {
  return {
    type: FETCH_BID_DETAILS_REQUEST
  };
};

export const fetchBidDetailsSuccess = (data) => {
  return {
    type: FETCH_BID_DETAILS_SUCCESS,
    payload: data
  };
};

export const fetchBidDetailsFailure = (error) => {
  return {
    type: FETCH_BID_DETAILS_FAILURE,
    payload: error
  };
};

export const fetchBidListingThunkAction = (params, currentPage, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchBidListingRequest());
      const { data } = await getFirmBidOpportunityListingApi(params, currentPage);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(
        fetchBidListingSuccess(
          data.data,
          data.data.paginatedList.meta.total_records / params.limit,
          data.data.naics_codes
        )
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchBidListingFailure(error));
      onError(error.response?.data?.message || error.message);
    }
  };
};

export const fetchBidDetailsThunkAction = (id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchBidDetailsRequest());
      const { data } = await getFirmBidOpportunityDetailsApi(id);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchBidDetailsSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchBidDetailsFailure(error));
      onError(error.response?.data?.message || error.message);
    }
  };
};
