import moment from "moment";
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import { Icon } from "@iconify/react";

function DashboardDropdown({
  direction = "down",
  handleValue,
  selectedValue,
  setSelectedValue,
  ...args
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  //const [selectedValue, setSelectedValue] = useState("");

  const [cutumDateRange, setCutumDateRange] = useState(null);

  let isSelected = false;

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="">
      <Dropdown
        color="primary"
        isOpen={dropdownOpen}
        toggle={() => {
          if (isSelected) {
            if (dropdownOpen === false) {
              setDropdownOpen(true);
            }
          } else {
            setDropdownOpen((preState) => !preState);
          }
        }}
        direction={direction}
      >
        <DropdownToggle caret style={{ backgroundColor: "#1273EB" }}>
          <Icon icon="akar-icons:calendar" className="mr-2 mb-1" />

          {selectedValue ? selectedValue : "Select"}
        </DropdownToggle>
        <DropdownMenu
          {...args}
          right
          positionFixed
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DropdownItem
            onClick={(e) => {
              isSelected = true;
              e.stopPropagation();
              handleValue(
                moment.utc(moment().startOf("day")).format(),
                moment.utc(moment().endOf("day")).format()
              );
              setSelectedValue("Today");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            Today
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              isSelected = true;
              handleValue(
                moment.utc(moment().subtract(1, "month").startOf("day").format()).format(),
                moment.utc(moment().endOf("day")).format()
              );
              setSelectedValue("Last Month");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            Last Month
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              isSelected = true;
              handleValue(
                moment.utc(moment().subtract(6, "month").startOf("day").format()).format(),
                moment.utc(moment().endOf("day")).format()
              );
              setSelectedValue("Last 6 Month");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            Last 6 Month
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              isSelected = true;
              handleValue(
                moment.utc(moment().subtract(1, "year").startOf("day").format()).format(),
                moment.utc(moment().endOf("day")).format()
              );
              setSelectedValue("Last Year");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            Last Year
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              isSelected = true;
              handleValue("", "");
              setSelectedValue("All");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            All
          </DropdownItem>

          <DropdownItem
            toggle={false}
            onClick={(e) => {
              isSelected = true;
              e.stopPropagation();
            }}
          >
            <Flatpickr
              required={true}
              value={cutumDateRange}
              options={{
                mode: "range",
                dateFormat: "m-d-Y",
                disableMobile: true,
                allowInput: true,
                position: "below right",

                onClose: (selectedDates) => {
                  if (selectedDates?.length === 2) {
                    setCutumDateRange(selectedDates);
                    setSelectedValue("Custom Date");
                    handleValue(
                      moment.utc(moment(selectedDates[0]).startOf("day").format()).format(),
                      moment.utc(moment(selectedDates[1]).endOf("day").format()).format()
                    );
                  }
                  setDropdownOpen(false);
                }
              }}
              onChange={(e) => {
                isSelected = true;
              }}
              className="flatpickr datetimepicker form-control"
              placeholder="MM-DD-YYYY"
            />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

DashboardDropdown.propTypes = {
  direction: PropTypes.string
};

export default DashboardDropdown;
