import { useMemo, useState, useEffect, Fragment } from "react";
import { Col, Row, Table, Input, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import moment from "moment";
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import "../DirectFirms/Directfirms.css";
import Paginate from "components/Pagination/Pagination";
import FirmsDetalModal from "components/Modals/FirmsDetailsModal";
import DropDown from "components/DropDown/DropDown";
import { selectFirmsList } from "redux/adminListofFirms/selectors";
import { selectUserToken } from "redux/user/selectors";
import { getColumns } from "../DirectFirms/ListofFirmscolumn";
import { fetchFirmsListThunkAction } from "redux/adminListofFirms/actions";
import Search from "components/Search/Search";
import Loader from "components/Loader/Loader";
import { selectUserRole } from "redux/user/selectors";
import userRoles from "utils/roles";
import DashboardDropdown from "components/DashboardDropdown/DashboardDropdown";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

const RegisterfirmsList = ({ globalDate, globalDropdownValue }) => {
  const [open, setOpen] = useState(false);
  const [firmId, setFirmId] = useState(null);
  const [firmslistData, setFirmsListData] = useState([]);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState(null);
  const [date, setDate] = useState({
    ...globalDate
  });
  const [onBoarderDropDownValue, setOnBoarderDropDownValue] = useState(globalDropdownValue);

  const dispatch = useDispatch();
  const { firmslist, isLoading, totalPage } = useSelector(selectFirmsList);
  const token = useSelector(selectUserToken);
  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const toggleModal = () => {
    setOpen((open) => !open);
  };
  const handleOpenModal = (id) => {
    setOpen(true);
    setFirmId(id);
  };

  useEffect(() => {
    setFirmsListData(firmslist);
  }, [firmslist, firmslist?.length]);

  const columns = useMemo(() => getColumns(handleOpenModal), []);
  const data = useMemo(() => firmslistData, [firmslistData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: Number(role) === userRoles.ADMIN ? [] : ["role_id"]
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  useEffect(() => {
    if (token) {
      dispatch(
        fetchFirmsListThunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex,
            column: sort?.sortBy,
            sort: sort?.sortDirection,
            start_date: date?.start_date,
            end_date: date?.end_date
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, sort, date]);

  const handleValue = (start_date, end_date) => {
    setDate({
      end_date: end_date,
      start_date: start_date
    });
  };

  return (
    <>
      <Row className="mt-4">
        <Col>
          <Card
            className="card-stats mb-4 mb-xl-0"
            style={{
              borderRadius: "0.5rem"
            }}
          >
            <CardHeader
              style={{
                borderRadius: "0.5rem"
              }}
            >
              <Row className="justify-content-between align-items-center mx-1">
                {localStorage.getItem("role") === "1" ? (
                  <h6 className="mb-0">Newly Onboarded </h6>
                ) : (
                  <h6 className="mb-0">Registered Firms </h6>
                )}

                {/* <DropDown handleValue={handleValue} /> */}
                <DashboardDropdown
                  handleValue={handleValue}
                  selectedValue={onBoarderDropDownValue}
                  setSelectedValue={setOnBoarderDropDownValue}
                />
              </Row>
            </CardHeader>
            <CardBody
              style={{
                height: "auto"
              }}
            >
              <Row>
                <Col>
                  <div className="">
                    <nav aria-label="..." className="post_footer">
                      <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
                        <Col xs="12" md="8" lg="9" className="d-flex align-items-center">
                          <span>Show</span>
                          <Input
                            type="select"
                            style={{
                              width: "65px",
                              margin: "10px"
                            }}
                            value={pageSize}
                            onChange={(e) => {
                              setPageSize(Number(e.target.value));
                            }}
                          >
                            {[5, 10, 20, 30, 50].map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                {pageSize}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col xs="12" md="4" lg="3">
                          <Search onSubmitHandler={onSubmitHandler} />
                        </Col>
                      </Row>
                    </nav>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  {isLoading ? (
                    <Fragment>
                      <div className="post_error_loading">
                        <Loader />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {error ? (
                        <Fragment>
                          <div className="post_error_loading">{error}</div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Table
                            {...getTableProps()}
                            className="align-items-center table-flush Firms_table_container"
                            responsive
                            bordered
                          >
                            <thead className="thead-light Firms_table_header">
                              {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                                  {headerGroup.headers.map((column) => (
                                    <th
                                      key={column.id}
                                      onClick={() => {
                                        if (!column.disableSortBy) {
                                          const desc =
                                            column.isSortedDesc === true
                                              ? undefined
                                              : column.isSortedDesc === false
                                              ? true
                                              : false;
                                          setSort({
                                            sortBy: column.id,
                                            sortDirection: desc ? "desc" : "asc"
                                          });
                                          setSortBy([{ id: column.id, desc }]);
                                        }
                                      }}
                                      {...column.getHeaderProps()}
                                    >
                                      {column.render("Header")}
                                      <span>
                                        {column.isSorted ? (
                                          column.isSortedDesc ? (
                                            <i className="fas fa-arrow-down ml-2"></i>
                                          ) : (
                                            <i className="fas fa-arrow-up ml-2"></i>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                              {page.map((row) => {
                                prepareRow(row);
                                return (
                                  <tr {...row.getRowProps()} key={row.id}>
                                    {row.cells.map((cell) => {
                                      return (
                                        <td key={cell.id} {...cell.getCellProps()}>
                                          {cell.render("Cell")}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                          {!error && firmslistData?.length === 0 && (
                            <div className="post_error_loading pt-3">
                              No registered firms found.
                            </div>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardFooter
              style={{
                backgroundColor: "#fff",
                borderTopWidth: "0px",
                borderRadius: "0.5rem"
              }}
            >
              <nav aria-label="..." className="post_footer">
                <Paginate
                  pageIndex={pageIndex}
                  pageCount={pageCount}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  gotoPage={gotoPage}
                />
              </nav>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <FirmsDetalModal toggleModal={toggleModal} isOpen={open} id={firmId} />
    </>
  );
};

export default RegisterfirmsList;
