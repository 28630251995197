const listOfStates = {
  // "dc": "Florida",
  al: "Alabama",
  ak: "Alaska",
  az: "Arizona",
  ar: "Arkansas",
  ca: "California",
  co: "Colorado",
  ct: "Connecticut",
  de: "Delaware",
  fl: "Florida",
  ga: "Georgia",
  hi: "Hawaii",
  id: "Idaho",
  il: "Illinois",
  in: "Indiana",
  ia: "Iowa",
  ks: "Kansas",
  ky: "Kentucky",
  la: "Louisiana",
  me: "Maine",
  md: "Maryland",
  ma: "Massachusetts",
  mi: "Michigan",
  mn: "Minnesota",
  ms: "Mississippi",
  mo: "Missouri",
  mt: "Montana",
  ne: "Nebraska",
  nv: "Nevada",
  nh: "New Hampshire",
  nj: "New Jersey",
  nm: "New Mexico",
  ny: "New York",
  nc: "North Carolina",
  nd: "North Dakota",
  oh: "Ohio",
  ok: "Oklahoma",
  or: "Oregon",
  pa: "Pennsylvania",
  ri: "Rhode Island",
  sc: "South Carolina",
  sd: "South Dakota",
  tn: "Tennessee",
  tx: "Texas",
  ut: "Utah",
  vt: "Vermont",
  va: "Virginia",
  wa: "Washington",
  wv: "West Virginia",
  wi: "Wisconsin",
  wy: "Wyoming"
};

export const arrayOfColors = [
  "#5FBA63",
  "#30B1C1",
  "#ED6593",
  "#CA5B5B",
  "#C0CF38",
  "#CFAE49",
  "#8461C5",
  "#DA704F",
  "#5BB4AB",
  "#5796C9"
];

export const listOfNewYorkState = [
  "cl",
  "fr",
  "sl",
  "je",
  "ha",
  "es",
  "wa",
  "ws",
  "he",
  "le",
  "os",
  "on",
  "fu",
  "sa",
  "re",
  "al",
  "sh",
  "og",
  "cn",
  "ma",
  "oo",
  "sc",
  "mo",
  "de",
  "gr",
  "co",
  "du",
  "ul",
  "sv",
  "or",
  "pu",
  "we",
  "ro",
  "ri",
  "ki",
  "br",
  "na",
  "sf",
  "ch",
  "ca",
  "ae",
  "st",
  "ti",
  "bo",
  "er",
  "ni",
  "ol",
  "mn",
  "ge",
  "wo",
  "li",
  "ot",
  "wy",
  "se",
  "su",
  "ce",
  "to",
  "cy",
  "ct",
  "ya",
  "qu",
  "ny"
];

export const NewYorkRegions = {
  "north country": [
    "franklin",
    "clinton",
    "essex",
    "hamilton",
    "lewis",
    "jefferson",
    "st. lawrence"
  ],
  "mohawk valley": ["oneida", "herkimer", "fulton", "montgomery", "schoharie", "otsego"],
  "capital regoin": [
    "warren",
    "washington",
    "saratoga",
    "greene",
    "columbia",
    "albany",
    "rensselaer",
    "schenectady"
  ],
  "mid-hudson": ["ulster", "dutchess", "putnam", "orange", "westchester", "sullivan", "rockland"],
  "southern tier": [
    "steuben",
    "chemung",
    "tioga",
    "broome",
    "delaware",
    "chenango",
    "tompkins",
    "schuyler"
  ],
  "western new york": ["niagara", "erie", "allegany", "cattaraugus", "chautauqua"],
  "fonger lakes": [
    "monroe",
    "orleans",
    "wayne",
    "ontario",
    "seneca",
    "yates",
    "livingston",
    "wyoming",
    "genesee"
  ],
  "central new york": ["oswego", "madison", "onondaga", "cayuga", "cortland"],
  "new york city": ["bronx", "queens", "brooklyn", "richmond", "new york", "kings"],
  "long island": ["nassau", "suffolk"]
};

export const newYorkShortStates = {
  cl: "clinton",
  fr: "franklin",
  sl: "st. lawrence",
  je: "jefferson",
  ha: "hamilton",
  es: "essex",
  wa: "warren",
  ws: "washington",
  he: "herkimer",
  le: "lewis",
  os: "oswego",
  on: "oneida",
  fu: "fulton",
  sa: "saratoga",
  re: "rensselaer",
  al: "albany",
  sh: "schoharie",
  og: "otsego",
  cn: "chenango",
  ma: "madison",
  oo: "onondaga",
  sc: "schenectady",
  mo: "montgomery",
  de: "delaware",
  gr: "greene",
  co: "columbia",
  du: "dutchess",
  ul: "ulster",
  sv: "sullivan",
  or: "orange",
  pu: "putnam",
  we: "westchester",
  ro: "rockland",
  ri: "richmond",
  ki: "kings",
  br: "bronx",
  na: "nassau",
  sf: "suffolk",
  ch: "chautauqua",
  ca: "cattaraugus",
  ae: "allegany",
  st: "steuben",
  ti: "tioga",
  bo: "broome",
  er: "erie",
  ni: "naigara",
  ol: "orleans",
  mn: "monroe",
  ge: "genesee",
  wo: "wyoming",
  li: "livingston",
  ot: "ontario",
  wy: "wayne",
  se: "seneca",
  su: "schuyler",
  ce: "chemung",
  to: "tompkins",
  cy: "cayuga",
  ct: "cortland",
  ya: "yates",
  qu: "queens",
  ny: "new york"
};

export const colors = {
  "north country": "#5FBA63",
  "mohawk valley": "#30B1C1",
  "capital regoin": "#ED6593",
  "mid-hudson": "#CA5B5B",
  "southern tier": "#C0CF38",
  "western new york": "#CFAE49",
  "fonger lakes": "#8461C5",
  "central new york": "#DA704F",
  "new york city": "#5BB4AB",
  "long island": "#5796C9",
  "northwest washington": "#5FBA63",
  "southwest washington": "#30B1C1",
  "northeast washington": "#ED6593",
  "southeast washington": "#CA5B5B",
  "northwest kansas": "#5FBA63",
  "northeast kansas": "#30B1C1",
  "central kansas": "#ED6593",
  "southwest kansas": "#CA5B5B",
  "southcentral kansas": "#C0CF38",
  "southeast kansas": "#CFAE49"
};

export default listOfStates;

export const washingtonShortStates = {
  // not duplicated keys
  ad: "adams",
  as: "asotin",
  cm: "clallam",
  je: "jefferson",
  gr: "grays harbor",
  pa: "pacific",
  wk: "wahkiakum",
  sj: "san juan",
  is: "island",
  kt: "kitsap",
  ma: "mason",
  th: "thurston",
  le: "lewis",
  co: "cowlitz",
  ca: "clark",
  wa: "whatcom",
  sa: "skagit",
  sn: "snohomish",
  kg: "king",
  pi: "pierce",
  sk: "skamania",
  ok: "okanogan",
  ch: "chelan",
  ki: "kittitas",
  ya: "yakima",
  kl: "klickitat",
  do: "douglas",
  ga: "grant",
  be: "benton",
  fe: "ferry",
  li: "lincoln",
  fr: "franklin",
  wl: "walla walla",
  st: "stevens",
  pe: "pend oreille",
  sp: "spokane",
  wh: "whitman",
  cl: "columbia",
  gf: "garfield"
};

export const washingtonRegions = {
  "northwest washington": ["whatcom", "skagit", "snohomish", "king", "pierce"],
  "southwest washington": [
    "thurston",
    "lewis",
    "cowlitz",
    "clark",
    "skamania",
    "pacific",
    "wahkiakum"
  ],
  "northeast washington": [
    "san juan",
    "island",
    "kitsap",
    "mason",
    "jefferson",
    "clallam",
    "grays harbor"
  ],
  "southeast washington": [
    "okanogan",
    "chelan",
    "kittitas",
    "yakima",
    "klickitat",
    "douglas",
    "grant",
    "benton",
    "ferry",
    "lincoln",
    "franklin",
    "walla walla",
    "stevens",
    "pend oreille",
    "spokane",
    "whitman",
    "columbia",
    "garfield",
    "adams",
    "asotin"
  ]
};

export const washingtonColors = {
  "northwest washington": "#5FBA63",
  "southwest washington": "#30B1C1",
  "northeast washington": "#ED6593",
  "southeast washington": "#CA5B5B"
};

export const washingtonShortStatesName = [
  "ad",
  "as",
  "cm",
  "je",
  "gr",
  "pa",
  "wk",
  "sj",
  "is",
  "kt",
  "ma",
  "th",
  "le",
  "co",
  "ca",
  "wa",
  "sa",
  "sn",
  "kg",
  "pi",
  "sk",
  "ok",
  "ch",
  "ki",
  "ya",
  "kl",
  "do",
  "ga",
  "be",
  "fe",
  "li",
  "fr",
  "wl",
  "st",
  "pe",
  "sp",
  "wh",
  "cl",
  "gf"
];

export const kansasShortStates = {
  cn: "cheyenne",
  sh: "sherman",
  wa: "wallace",
  gl: "greeley",
  hm: "hamilton",
  st: "stanton",
  mt: "mortan",
  ra: "rawlins",
  th: "thomas",
  lg: "logan",
  wh: "wichita",
  ke: "kearny",
  gt: "grant",
  sv: "stevens",
  dc: "decatur",
  sd: "sheridan",
  go: "gove",
  sc: "scott",
  le: "lane",
  fi: "finney",
  gy: "gray",
  hs: "haskell",
  sw: "seward",
  me: "meade",
  nt: "norton",
  gh: "graham",
  tr: "trego",
  ns: "ness",
  hg: "hodgeman",
  fo: "ford",
  ca: "clark",
  pl: "philips",
  ro: "rooks",
  el: "ellis",
  rh: "rush",
  pn: "pawnee",
  ed: "edwards",
  kw: "kiowa",
  cm: "comanche",
  sm: "smith",
  ob: "osborne",
  rs: "russell",
  bt: "barton",
  sf: "stafford",
  pr: "pratt",
  ba: "barber",
  jw: "jewell",
  mc: "mitchell",
  lc: "lincoln",
  ew: "ellsworth",
  rc: "rice",
  rn: "reno",
  km: "kingman",
  hp: "harper",
  rp: "republic",
  cd: "cloud",
  ot: "ottawa",
  sa: "saline",
  mp: "mcpherson",
  hv: "harvey",
  sg: "sedgwick",
  su: "sumner",
  ws: "washington",
  cy: "clay",
  dk: "dickinson",
  mn: "marion",
  bu: "butler",
  cl: "cowley",
  ms: "marshall",
  rl: "riley",
  pt: "pottawatomie",
  ge: "geary",
  wb: "wabaunsee",
  mr: "morris",
  ly: "lyon",
  cs: "chase",
  gw: "greenwood",
  ek: "elk",
  cq: "chautauqua",
  nm: "nemaha",
  ja: "jackson",
  sn: "shawnee",
  os: "osage",
  cf: "coffey",
  wo: "woodson",
  wl: "wilson",
  mg: "montgomery",
  br: "brown",
  dp: "doniphan",
  at: "atchison",
  jf: "jefferson",
  lv: "leavenworth",
  wy: "wyandotte",
  dg: "douglas",
  fr: "franklin",
  an: "anderson",
  al: "allen",
  no: "neosho",
  lb: "labette",
  jo: "johnson",
  mi: "miami",
  ln: "linn",
  bb: "bourbon",
  cr: "crawford",
  ck: "cherokee"
};

export const kansasRegions = {
  "northwest kansas": [
    "cheyenne",
    "sherman",
    "wallace",
    "greeley",
    "hamilton",
    "stanton",
    "mortan",
    "rawlins",
    "thomas",
    "logan",
    "wichita",
    "kearny",
    "grant",
    "stevens",
    "decatur",
    "sheridan",
    "gove",
    "scott",
    "lane",
    "finney",
    "gray",
    "haskell",
    "seward",
    "meade",
    "norton",
    "graham",
    "trego",
    "ness",
    "hodgeman",
    "ford",
    "clark",
    "philips",
    "rooks",
    "ellis",
    "rush",
    "pawnee",
    "edwards",
    "kiowa",
    "comanche",
    "smith",
    "osborne",
    "russell",
    "barton",
    "stafford",
    "pratt",
    "barber",
    "jewell",
    "mitchell",
    "lincoln",
    "ellsworth",
    "rice",
    "reno",
    "kingman",
    "harper",
    "republic",
    "cloud",
    "ottawa",
    "saline",
    "mcpherson",
    "harvey",
    "sedgwick",
    "sumner",
    "washington",
    "clay",
    "dickinson",
    "marion",
    "butler",
    "cowley",
    "marshall",
    "riley",
    "pottawatomie",
    "geary",
    "wabaunsee",
    "morris",
    "lyon",
    "chase",
    "greenwood",
    "elk",
    "chautauqua",
    "nemaha",
    "jackson",
    "shawnee",
    "osage",
    "coffey",
    "woodson",
    "wilson",
    "montgomery",
    "brown",
    "doniphan",
    "atchison",
    "jefferson",
    "leavenworth",
    "wyandotte",
    "douglas",
    "franklin"
  ],
  "northeast kansas": [
    "anderson",
    "allen",
    "neosho",
    "labette",
    "johnson",
    "miami",
    "linn",
    "bourbon",
    "crawford",
    "cherokee"
  ],
  "central kansas": [
    "clay",
    "dickinson",
    "marion",
    "butler",
    "cowley",
    "marshall",
    "riley",
    "pottawatomie",
    "geary",
    "wabaunsee",
    "morris",
    "lyon",
    "chase",
    "greenwood",
    "elk",
    "chautauqua",
    "nemaha",
    "jackson",
    "shawnee",
    "osage",
    "coffey",
    "woodson",
    "wilson",
    "montgomery",
    "brown",
    "doniphan",
    "atchison",
    "jefferson",
    "leavenworth",
    "wyandotte",
    "douglas",
    "franklin"
  ],
  "southwest kansas": [
    "cheyenne",
    "sherman",
    "wallace",
    "greeley",
    "hamilton",
    "stanton",
    "mortan",
    "rawlins",
    "thomas",
    "logan",
    "wichita",
    "kearny",
    "grant",
    "stevens",
    "decatur",
    "sheridan",
    "gove",
    "scott",
    "lane",
    "finney",
    "gray",
    "haskell",
    "seward",
    "meade",
    "norton",
    "graham",
    "trego",
    "ness",
    "hodgeman",
    "ford",
    "clark",
    "philips",
    "rooks",
    "ellis",
    "rush",
    "pawnee",
    "edwards",
    "kiowa",
    "comanche",
    "smith",
    "osborne",
    "russell",
    "barton",
    "stafford",
    "pratt",
    "barber"
  ],
  "southcentral kansas": [
    "harper",
    "republic",
    "cloud",
    "ottawa",
    "saline",
    "mcpherson",
    "harvey",
    "sedgwick",
    "sumner",
    "washington"
  ],
  "southeast kansas": [
    "anderson",
    "allen",
    "neosho",
    "labette",
    "johnson",
    "miami",
    "linn",
    "bourbon",
    "crawford",
    "cherokee"
  ]
};

export const kansasColors = {
  "northwest kansas": "#5FBA63",
  "northeast kansas": "#30B1C1",
  "central kansas": "#ED6593",
  "southwest kansas": "#CA5B5B",
  "southcentral kansas": "#C0CF38",
  "southeast kansas": "#CFAE49"
};

export const kansasShortStatesName = [
  "cn",
  "sh",
  "wa",
  "gl",
  "hm",
  "st",
  "mt",
  "ra",
  "th",
  "lg",
  "wh",
  "ke",
  "gt",
  "sv",
  "dc",
  "sd",
  "go",
  "sc",
  "le",
  "fi",
  "gy",
  "hs",
  "sw",
  "me",
  "nt",
  "gh",
  "tr",
  "ns",
  "hg",
  "fo",
  "ca",
  "pl",
  "ro",
  "el",
  "rh",
  "pn",
  "ed",
  "kw",
  "cm",
  "sm",
  "ob",
  "rs",
  "bt",
  "sf",
  "pr",
  "ba",
  "jw",
  "mc",
  "lc",
  "ew",
  "rc",
  "rn",
  "km",
  "hp",
  "rp",
  "cd",
  "ot",
  "sa",
  "mp",
  "hv",
  "sg",
  "su",
  "ws",
  "cy",
  "dk",
  "mn",
  "bu",
  "cl",
  "ms",
  "rl",
  "pt",
  "ge",
  "wb",
  "mr",
  "ly",
  "cs",
  "gw",
  "ek",
  "cq",
  "nm",
  "ja",
  "sn",
  "os",
  "cf",
  "wo",
  "wl",
  "mg",
  "br",
  "dp",
  "at",
  "jf",
  "lv",
  "wy",
  "dg",
  "fr",
  "an",
  "al",
  "no",
  "lb",
  "jo",
  "mi",
  "ln",
  "bb",
  "cr",
  "ck"
];
