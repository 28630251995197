import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { selectUserRole } from "redux/user/selectors";
import { selectUserToken } from "redux/user/selectors";

const RequireAuth = ({ allowedRoles = [] }) => {
  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const role = useSelector(selectUserRole) || localStorage.getItem("role");
  const location = useLocation();

  return token && role && allowedRoles?.includes(Number(role)) ? (
    <Outlet />
  ) : token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/auth/admin/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
