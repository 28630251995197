import UploadDataMigration from "components/Modals/UploadDataMigration";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import Select from "react-select";
import { getGovtListApi } from "services/adminDatamigrationService";
import { postDataMigrationApi } from "services/adminDatamigrationService";
import toast from "react-hot-toast";

function AdminDataMigrationUploadCsv() {
  const [migration, setMigration] = React.useState("firm");
  const [government, setGovernment] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const onSuccess = () => {
    setModalOpen(false);
    localStorage.setItem("government", "");
    setGovernment("");
    setMigration("firm");
  };

  const addNewImageHandler = async (files = []) => {
    const requestBodyFormData = new FormData();

    files?.map((item) => {
      requestBodyFormData.append("file", item);
    });

    // state not change for inner function so we use localstorage

    let governmentId = localStorage.getItem("government");

    {
      governmentId === "" ? null : requestBodyFormData.append("governmentId", governmentId);
    }

    try {
      const promise = postDataMigrationApi(requestBodyFormData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Firms data seed successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const response = await promise;

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      onSuccess();
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
      localStorage.setItem("government", "");
    }
  };

  useEffect(async () => {
    localStorage.setItem("government", "");
    try {
      const { data } = await getGovtListApi();
      const options = data.data.map((item) => {
        return {
          value: item.id,
          label: item.username
        };
      });
      setOptions(options);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="px-3 mt-3">
      {/* <h4>Data Migration</h4> */}
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center flex-wrap">
              <h5 className="card-title">Firm Migration</h5>
              <a href={`${process.env.REACT_APP_DEV_BASE_URL}/assets/SampleData.xlsx`}>
                <Button color="primary" className="px-3 py-2">
                  <i className="fa fa-download mx-2"></i>
                  Download sample file
                </Button>
              </a>
            </div>
            <div className="card-body">
              <div className="form-group">
                <input
                  type="radio"
                  name="migration"
                  id="firm"
                  value="firm"
                  className="mx-2"
                  checked={migration === "firm"}
                  onChange={(e) => {
                    setMigration(e.target.value);
                    localStorage.setItem("government", "");
                  }}
                />
                <label>Self Service Firm</label>
                <input
                  type="radio"
                  name="migration"
                  id="gov"
                  value="gov"
                  className="mx-2"
                  checked={migration === "gov"}
                  onChange={(e) => setMigration(e.target.value)}
                />
                <label>Government Firm</label>
              </div>
              {migration === "gov" ? (
                <div className="form-group">
                  <label>Select government</label>
                  <Select
                    options={options}
                    // isMulti
                    onChange={(e) => {
                      setGovernment(e.value.toString());
                      localStorage.setItem("government", e.value.toString());
                    }}
                    // styles={customStyles}
                  />
                </div>
              ) : null}

              <div className="form-group">
                <Button
                  color="primary"
                  className="px-3 py-2"
                  onClick={handleOpen}
                  disabled={migration === "gov" && government === ""}
                >
                  + Upload File
                </Button>
                <UploadDataMigration
                  addNewImageHandler={addNewImageHandler}
                  handleClose={handleClose}
                  modalOpen={modalOpen}
                />
              </div>
              {/* <div className="form-group">
                <button className="btn btn-primary">Upload</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDataMigrationUploadCsv;
