import {
  FETCH_ADMIN_TOTAL_FIRMS_REQUEST,
  FETCH_ADMIN_TOTAL_FIRMS_SUCCESS,
  FETCH_ADMIN_TOTAL_FIRMS_FAILURE,
  FETCH_DISTRIBUTION_INDUSTRY_REQUEST,
  FETCH_DISTRIBUTION_INDUSTRY_SUCCESS,
  FETCH_DISTRIBUTION_INDUSTRY_FAILURE,
  FETCH_ASSESSMENT_SCORE_SUBCATEGORY_REQUEST,
  FETCH_ASSESSMENT_SCORE_SUBCATEGORY_SUCCESS,
  FETCH_ASSESSMENT_SCORE_SUBCATEGORY_FAILURE,
  FETCH_ADMIN_TOTAL_CHANGES_REQUEST,
  FETCH_ADMIN_TOTAL_CHANGES_SUCCESS,
  FETCH_ADMIN_TOTAL_CHANGES_FAILURE,
  FETCH_ADMIN_REVENUE_REQUEST,
  FETCH_ADMIN_REVENUE_SUCCESS,
  FETCH_ADMIN_REVENUE_FAILURE,
  FETCH_MONTHLY_REG_FIRMS_REQUEST,
  FETCH_MONTHLY_REG_FIRMS_SUCCESS,
  FETCH_MONTHLY_REG_FIRMS_FAILURE
} from "./types";

const initialState = {
  isLoadingTotalFirms: false,
  isLoadingDistributionIndustry: false,
  isLoadingSubcategory: false,
  isLoadingTotalChanges: false,
  isLoadingRevenue: false,
  isLoadingMonthlyRegFirms: false,

  totalFirmsData: null,
  distributionIndustryData: null,
  assessmentScoreSubcategoryData: null,
  totalChangesData: null,
  revenueData: null,
  monthlyRegFirmsData: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_TOTAL_FIRMS_REQUEST:
      return {
        ...state,
        isLoadingTotalFirms: true,
        totalFirmsData: null
      };
    case FETCH_ADMIN_TOTAL_FIRMS_SUCCESS:
      return {
        ...state,
        totalFirmsData: action.payload,
        isLoadingTotalFirms: false,
        error: ""
      };
    case FETCH_ADMIN_TOTAL_FIRMS_FAILURE:
      return {
        ...state,
        totalFirmsData: null,
        isLoadingTotalFirms: false,
        error: action.payload
      };
    case FETCH_DISTRIBUTION_INDUSTRY_REQUEST:
      return {
        ...state,
        isLoadingDistributionIndustry: true,
        distributionIndustryData: null
      };
    case FETCH_DISTRIBUTION_INDUSTRY_SUCCESS:
      return {
        ...state,
        distributionIndustryData: action.payload,
        isLoadingDistributionIndustry: false,
        error: ""
      };
    case FETCH_DISTRIBUTION_INDUSTRY_FAILURE:
      return {
        ...state,
        distributionIndustryData: null,
        isLoadingDistributionIndustry: false,
        error: action.payload
      };

    case FETCH_ASSESSMENT_SCORE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        isLoadingSubcategory: true,
        assessmentScoreSubcategoryData: null
      };
    case FETCH_ASSESSMENT_SCORE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        assessmentScoreSubcategoryData: action.payload,
        isLoadingSubcategory: false,
        error: ""
      };
    case FETCH_ASSESSMENT_SCORE_SUBCATEGORY_FAILURE:
      return {
        ...state,
        assessmentScoreSubcategoryData: null,
        isLoadingSubcategory: false,
        error: action.payload
      };

    case FETCH_ADMIN_TOTAL_CHANGES_REQUEST:
      return {
        ...state,
        isLoadingTotalChanges: true,
        totalChangesData: null
      };
    case FETCH_ADMIN_TOTAL_CHANGES_SUCCESS:
      return {
        ...state,
        totalChangesData: action.payload,
        isLoadingTotalChanges: false,
        error: ""
      };
    case FETCH_ADMIN_TOTAL_CHANGES_FAILURE:
      return {
        ...state,
        totalChangesData: null,
        isLoadingTotalChanges: false,
        error: action.payload
      };

    case FETCH_ADMIN_REVENUE_REQUEST:
      return {
        ...state,
        isLoadingRevenue: true,
        revenueData: null
      };
    case FETCH_ADMIN_REVENUE_SUCCESS:
      return {
        ...state,
        revenueData: action.payload,
        isLoadingRevenue: false,
        error: ""
      };
    case FETCH_ADMIN_REVENUE_FAILURE:
      return {
        ...state,
        revenueData: null,
        isLoadingRevenue: false,
        error: action.payload
      };

    case FETCH_MONTHLY_REG_FIRMS_REQUEST:
      return {
        ...state,
        isLoadingMonthlyRegFirms: true,
        monthlyRegFirmsData: null
      };
    case FETCH_MONTHLY_REG_FIRMS_SUCCESS:
      return {
        ...state,
        monthlyRegFirmsData: action.payload,
        isLoadingMonthlyRegFirms: false,
        error: ""
      };
    case FETCH_MONTHLY_REG_FIRMS_FAILURE:
      return {
        ...state,
        monthlyRegFirmsData: null,
        isLoadingMonthlyRegFirms: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
