import React from "react";
import ReactApexChart from "react-apexcharts";

class ContractsAwardedPieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [15, 13, 15, 19, 22, 16],
      options: {
        chart: {
          width: 350,
          type: "pie"
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " %";
            }
          }
        },
        legend: {
          show: false
        },
        colors: ["#1273EB", "#28C76F", "#EA5455", "#FF9F43", "#00CFE8", "#82868B"],
        labels: [
          "MBE (Minority Business Enterprise)",
          "WBE (Women Business Enterprise)",
          "M/WBE (Minority Women Business Enterprise)",
          "SBE (Small Business Enterprise)",
          "DBE (Disadvantaged Business Enterprise)",
          "DVOB (Small Disadvantaged Veteran Owned Business)"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      }
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="pie"
            width={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default ContractsAwardedPieChart;
