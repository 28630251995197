import { useState, useEffect, useMemo, Fragment } from "react";
import { Col, Row, Table, Input } from "reactstrap";
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import Paginate from "components/Pagination/Pagination";
import Search from "components/Search/Search";
import { selectGovernmentList } from "redux/adminListofGovernment/selectors";
import { selectUserToken } from "redux/user/selectors";
import { fetchGovernmentListThunkAction } from "redux/adminListofGovernment/actions";
import { getColumns } from "./AdminListofGovernmentColumn";
import Loader from "components/Loader/Loader";

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 10;

const AdminListofGovernment = () => {
  const [governmentlistData, setGovernmentListData] = useState([]);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState({ sortBy: "company", sortDirection: "asc" });
  const dispatch = useDispatch();
  const { governmentlist, isLoading, totalPage } = useSelector(selectGovernmentList);
  const token = useSelector(selectUserToken);

  useEffect(() => {
    setGovernmentListData(governmentlist);
  }, [governmentlist, governmentlist?.length]);

  const columns = useMemo(() => getColumns(), []);
  const data = useMemo(() => governmentlistData, [governmentlistData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: INITIAL_PAGE_INDEX, sortBy: [], pageSize: INITIAL_PAGE_SIZE },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  useEffect(() => {
    if (token) {
      dispatch(
        fetchGovernmentListThunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex,
            column: sort?.sortBy,
            sort: sort?.sortDirection
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, sort]);

  return (
    <div className="px-3">
      <Row className="justify-content-between align-items-center mx-1">
        <span className="Headers">
          <h4>Government</h4>
        </span>
      </Row>
      <Row>
        <Col>
          <div className="py-2">
            <nav aria-label="..." className="post_footer">
              <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
                <Col xs="12" md="10" className="d-flex align-items-center">
                  <span>Show</span>
                  <Input
                    type="select"
                    style={{
                      width: "65px",
                      margin: "10px"
                    }}
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col xs="12" md="2">
                  <Search onSubmitHandler={onSubmitHandler} />
                </Col>
              </Row>
            </nav>
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          {isLoading ? (
            <Fragment>
              <div className="post_error_loading">
                <Loader />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {error ? (
                <Fragment>
                  <div className="post_error_loading">{error}</div>
                </Fragment>
              ) : (
                <Fragment>
                  <Table
                    {...getTableProps()}
                    className="align-items-center table-flush Firms_table_container"
                    responsive
                    bordered
                  >
                    <thead className="thead-light Firms_table_header">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                          {headerGroup.headers.map((column) => (
                            <th
                              key={column.id}
                              onClick={() => {
                                if (!column.disableSortBy) {
                                  const desc =
                                    column.isSortedDesc === true
                                      ? undefined
                                      : column.isSortedDesc === false
                                      ? true
                                      : false;
                                  setSort({
                                    sortBy: column.id,
                                    sortDirection: desc ? "desc" : "asc"
                                  });
                                  setSortBy([{ id: column.id, desc }]);
                                }
                              }}
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fas fa-arrow-down ml-2"></i>
                                  ) : (
                                    <i className="fas fa-arrow-up ml-2"></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} key={row.id}>
                            {row.cells.map((cell) => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {!error && governmentlistData?.length === 0 && (
                    <div className="post_error_loading">No government data found.</div>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          {!isLoading && !error ? (
            <nav aria-label="..." className="post_footer">
              <Paginate
                pageIndex={pageIndex}
                pageCount={pageCount}
                previousPage={previousPage}
                nextPage={nextPage}
                gotoPage={gotoPage}
              />
            </nav>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default AdminListofGovernment;
