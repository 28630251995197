import React from "react";
import ReactApexChart from "react-apexcharts";

class ReportsPageColumnChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "First Assessment",
          data: this.props.data_1
        },
        {
          name: "Second Assessment",
          data: this.props.data_2
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "25%",
            endingShape: "rounded",
            borderRadius: 10
          }
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          markers: {
            radius: 50
          }
        },
        grid: {
          strokeDashArray: 7
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 3,
          colors: ["transparent"]
        },
        xaxis: {
          categories: this.props.categories
        },
        yaxis: {},
        colors: ["#083063", "#1273EB"],
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            }
          }
        }
      }
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

export default ReportsPageColumnChart;
