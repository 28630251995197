import React from "react";
import { Button, Col, Input, Modal, ModalBody, ModalFooter, Row } from "reactstrap";

function EditPlanModal({ toggleModal, isOpen, plan, onSubmitHandler }) {
  const [name, setName] = React.useState(plan?.name);
  const [billType, setBillType] = React.useState(plan?.bill_type);
  const [price, setPrice] = React.useState(plan?.price);
  const [subcontractors_limit, setSubcontractors_limit] = React.useState(plan?.contractor_limit);
  const [survey_limit, setSurvey_limit] = React.useState(plan?.survey_limit);
  const [description, setDescription] = React.useState(plan?.description);

  React.useEffect(() => {
    if (plan) {
      setName(plan.name);
      setBillType(plan.bill_type);
      setPrice(plan.price);
      setSubcontractors_limit(plan.contractor_limit);
      setSurvey_limit(plan.survey_limit);
      setDescription(plan.description);
    }
  }, [plan]);
  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-xl"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h3 className="modal-title " id="modal-title-default">
                Edit Plan
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>

            <ModalBody>
              <Row className="">
                <Col className="">
                  <label className="form-control-label" htmlFor="userName">
                    Plan Name
                  </label>
                  <input
                    value={name}
                    type="text"
                    className="form-control"
                    placeholder="Small"
                    id="userName"
                    name="userName"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mt-12">
                <Col lg="6" xl="6" className="mt-3">
                  <label className="form-control-label" htmlFor="selected">
                    Plan Bill Type
                  </label>
                  <select
                    type={"select"}
                    value={billType}
                    name="selected"
                    id="type"
                    className="form-control"
                    onChange={(e) => setBillType(e.target.value)}
                  >
                    <option value="" hidden></option>
                    <option value={"one-time"}>One Time</option>
                    <option value={"monthly"}>Per Month</option>
                  </select>
                </Col>
                <Col lg="6" xl="6" className="mt-3">
                  <label className="form-control-label" htmlFor="userName">
                    Plan Price
                  </label>
                  <input
                    value={price}
                    type="number"
                    className="form-control"
                    placeholder="779"
                    id="userName"
                    name="userName"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mt-12">
                <Col lg="6" xl="6" className="mt-3">
                  <label className="form-control-label" htmlFor="userName">
                    Firm Limit
                  </label>
                  <input
                    value={subcontractors_limit}
                    type="text"
                    className="form-control"
                    placeholder="100"
                    id="userName"
                    name="userName"
                    onChange={(e) => setSubcontractors_limit(e.target.value)}
                  />
                </Col>
                <Col lg="6" xl="6" className="mt-3">
                  <label className="form-control-label" htmlFor="userName">
                    Assessment Limit (year)
                  </label>
                  <input
                    value={survey_limit}
                    type="number"
                    className="form-control"
                    placeholder="2"
                    id="userName"
                    name="userName"
                    onChange={(e) => setSurvey_limit(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12" className="p-0 mt-3">
                  <Col>
                    <label className="form-control-label" htmlFor="userName">
                      Plan Description
                    </label>
                    <Input
                      value={description}
                      type="textarea"
                      className="form-control"
                      id="userName"
                      rows="3"
                      placeholder="Assessment 2x within a year, recommandations and referral to  AL consuling"
                      name="userName"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Col>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Col md="12">
                <div className="row">
                  <div className="col-auto mr-auto">
                    <Button
                      className="text-md-left"
                      color="danger"
                      type="button"
                      onClick={() => {
                        toggleModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                  <div className="col-auto">
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        onSubmitHandler({
                          name,
                          billType,
                          price,
                          subcontractors_limit,
                          survey_limit,
                          description
                        });
                        toggleModal(false);
                      }}
                    >
                      Save / Update
                    </Button>
                  </div>
                </div>
              </Col>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </>
  );
}

export default EditPlanModal;
