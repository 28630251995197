import React, { useCallback, useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import debounce from "lodash.debounce";

const Search = ({
  onSubmitHandler,
  isLabel = true,
  placeHolder = "",
  clear = false,
  setClear = () => {}
}) => {
  const [value, setValue] = useState("");

  const debouncedSave = useCallback(
    debounce((nextValue) => onSubmitHandler(nextValue), 500),
    []
  );

  const handleChange = (event) => {
    const { value: nextValue } = event.target;
    setValue(nextValue);
    debouncedSave(nextValue);
  };

  React.useEffect(() => {
    if (clear) {
      setValue("");
      setClear(false);
    }
  }, [clear]);

  return (
    <>
      <Form
        role="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup className="d-flex align-items-center mb-0">
          {isLabel ? <span className="mr-2 h6 mb-0">Search</span> : null}
          <Input
            type="text"
            value={value || ""}
            onChange={handleChange}
            placeholder={placeHolder}
          />
        </FormGroup>
      </Form>
    </>
  );
};

export default Search;
