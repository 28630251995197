import { useState, useEffect, Fragment } from "react";
import { Card, CardBody, CardFooter, Col, Row, CardHeader } from "reactstrap";
import "./PricingPlans.css";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SwitchButton from "components/SwitchButton/SwitchButton";
import EditPlanModal from "components/Modals/EditPlanModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSubscriptionPlanThunkAction,
  updateSubscriptionPlanThunkAction
} from "../../redux/subscriptionPlan/actions";
import { selectSubscriptionPlan } from "../../redux/subscriptionPlan/selectors";
import toast from "react-hot-toast";
import Loader from "components/Loader/Loader";

function PricingPlans() {
  const [open, setOpen] = useState(false);
  const [togglePricingPlan, setTogglePricingPlan] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { Planslist, isLoading } = useSelector(selectSubscriptionPlan);

  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(null);

  const toggleModal = () => {
    setOpen((open) => !open);
  };
  const handleOpenModal = (plan) => {
    setOpen(true);
    setPlan(plan);
  };
  const handleSwitchButton = (e) => {
    setTogglePricingPlan(e.target.checked);
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  useEffect(() => {
    setPlans(Planslist);
  }, [Planslist]);

  useEffect(() => {
    dispatch(
      fetchSubscriptionPlanThunkAction(
        {
          type: togglePricingPlan ? "govt" : "single-firm"
        },
        onSuccess,
        onError
      )
    );
  }, [togglePricingPlan]);

  const onUpdateSuccess = () => {
    dispatch(
      fetchSubscriptionPlanThunkAction(
        {
          type: togglePricingPlan ? "govt" : "single-firm"
        },
        onSuccess,
        onError
      )
    );
  };
  const onUpdateError = (error) => {
    toast.error(error);
  };

  const onSubmitHandler = async (PlanData) => {
    dispatch(
      await updateSubscriptionPlanThunkAction(
        plan?.id,
        {
          name: PlanData?.name,
          bill_type: PlanData?.billType,
          price: PlanData?.price,
          contractor_limit: PlanData?.subcontractors_limit,
          survey_limit: PlanData?.survey_limit,
          description: PlanData?.description,
          type: togglePricingPlan ? "govt" : "single-firm"
        },
        onUpdateSuccess,
        onUpdateError
      )
    );
  };

  return (
    <>
      <div className="px-3">
        <Row className="text-center">
          <Col xs={12} className="pricing_plan_page_title">
            Pricing Plans
          </Col>
          <Col className="pricing_page_description" xs={12}>
            Alacriti Analytics is a seed state diversity procurement platform with 70 + projects
            with a total value of <br /> $664 million dollars in contract opportunities.{" "}
          </Col>
          <Col xs={12}>
            <span
              className={
                togglePricingPlan
                  ? "pricing_plan_page_description mr-4"
                  : "pricing_plan_page_description mr-4 text-primary font-weight-bold"
              }
            >
              Single Firm
            </span>
            <FormGroup className="d-inline-block">
              <FormControlLabel
                control={
                  <SwitchButton
                    checked={togglePricingPlan}
                    onClick={(e) => {
                      handleSwitchButton(e);
                    }}
                  />
                }
              />
            </FormGroup>
            <span
              className={
                togglePricingPlan
                  ? "pricing_plan_page_description pr text-primary font-weight-bold"
                  : "pricing_plan_page_description pr "
              }
            >
              Government
            </span>
          </Col>
          <Col xs={12}>{/* <Button color="primary">Add New Plan</Button> */}</Col>
        </Row>
        {isLoading ? (
          <Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {error ? (
              <Fragment>
                <div className="post_error_loading">{error}</div>
              </Fragment>
            ) : (
              <Fragment>
                <Row className="mt-4 justify-content-center">
                  {plans.length > 0 &&
                    plans.map((plan) => {
                      return (
                        <Col lg="6" xl="4" key={plan?.id}>
                          <Card
                            className="card-stats mb-4 mb-xl-0"
                            style={{
                              minHeight: "450px",
                              border: "none",
                              boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
                              backgroundColor: "#F2F2F2",
                              borderRadius: "6px"
                            }}
                          >
                            <CardHeader
                              style={{ backgroundColor: "#283046" }}
                              className="justify-content-between"
                            >
                              <Row className="text-light justify-content-between mx-2 text-capitalize">
                                <h4 className="mb-0">{plan.name}</h4>
                                <span>Billed {plan.bill_type}</span>
                              </Row>
                            </CardHeader>
                            <CardBody className="pt-0">
                              <Row
                                style={{
                                  backgroundColor: "#161D31",
                                  marginLeft: "-20px",
                                  minHeight: "175px",
                                  marginRight: "-20px"
                                }}
                                className="text-light text-center rounded-bottom"
                              >
                                {plan?.price > 0 ? (
                                  <Col className="my-5">
                                    <h1
                                      className="d-inline-block position-relative"
                                      style={{
                                        color: "#FF9F43",
                                        fontSize: "3.5rem"
                                      }}
                                    >
                                      ${plan.price}
                                    </h1>{" "}
                                    / {plan?.bill_type}
                                  </Col>
                                ) : (
                                  <Col className="my-5">
                                    <h1
                                      className="d-inline-block position-relative"
                                      style={{
                                        color: "#FF9F43",
                                        fontSize: "3rem"
                                      }}
                                    >
                                      Call for Pricing
                                    </h1>{" "}
                                  </Col>
                                )}
                              </Row>
                              <Row className="text-center mt-5">
                                <Col>{plan.description}</Col>
                              </Row>
                            </CardBody>
                            <CardFooter
                              style={{
                                backgroundColor: "#F2F2F2",
                                borderTop: "0px",
                                borderBottomLeftRadius: "0.5rem",
                                borderBottomRightRadius: "0.5rem"
                              }}
                            >
                              <Row className="mx-4 bg-light">
                                <div
                                  sm="6"
                                  className="d-flex align-items-center justify-content-center flex-grow-1 border py-2 rounded border-primary"
                                  style={{
                                    cursor: "pointer"
                                  }}
                                  onClick={() => handleOpenModal(plan)}
                                >
                                  <span className="FiEdit"></span>
                                  <div className="text-primary">Edit</div>
                                </div>
                              </Row>
                            </CardFooter>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
      <EditPlanModal
        toggleModal={toggleModal}
        isOpen={open}
        plan={plan}
        onSubmitHandler={onSubmitHandler}
      />
    </>
  );
}

export default PricingPlans;
