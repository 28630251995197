import React from "react";
import { Card, CardBody, Col, Modal, ModalBody, Row } from "reactstrap";
import { Icon } from "@iconify/react";

function StartSurveyModal({ toggleModal, isOpen }) {
  const leftSideDocs = [
    "Corporate Business Tax Returns",
    "Lease Agreement",
    "NYS M/WBE Certification",
    "Insurance",
    "Credit Report (Experian)",
    "Safety Plans",
    "Birth Certification"
  ];

  const rightSideDocs = [
    "US Citizens Certification",
    "Balance Sheet - Profit Loss Statement",
    "Copies of Contracts",
    "Payroll Report",
    "Certified Payroll",
    "Certificate of Incorporation"
  ];
  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-lg"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <div
                style={{
                  fontWeight: "500"
                }}
              >
                Required Documents for Assessment
              </div>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>

            <ModalBody>
              <Row className="mt-12">
                <Col
                  lg="6"
                  xl="6"
                  style={{
                    padding: "0",
                    borderRightStyle: "dashed ",
                    borderRightWidth: "thin"
                  }}
                >
                  <Card className="card-stats border-0">
                    <CardBody>
                      <Row>
                        {leftSideDocs.map((item) => {
                          return (
                            <Col sm={12} key={item} className="py-1">
                              <Icon icon="bi:check-circle-fill" className="mr-3" />
                              <span>{item}</span>
                            </Col>
                          );
                        })}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  lg="6"
                  xl="6"
                  style={{
                    padding: "0"
                  }}
                >
                  <Card className="card-stats border-0">
                    <CardBody>
                      <Row>
                        {rightSideDocs.map((item) => {
                          return (
                            <Col sm={12} key={item} className="py-1">
                              <Icon icon="bi:check-circle-fill" className="mr-3" />
                              <span>{item}</span>
                            </Col>
                          );
                        })}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </>
  );
}

export default StartSurveyModal;
