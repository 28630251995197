import axiosInstance from "./axiosInstance";

export const getFirmsListApi = (prms) => {
  let path = "/api/v1/govt/firm-list";

  if (localStorage.getItem("role") === "1") {
    path = "/api/v1/admin/firms-list";
  }

  return axiosInstance.get(path, {
    params: {
      limit: prms.limit || 5,
      offset: prms?.page || 0,
      search: prms?.search || "",
      sort: prms?.sort || "",
      column: prms?.column || "",
      start_date: prms?.start_date,
      end_date: prms?.end_date,
      list: prms?.list
    }
  });
};

export const getFirmDetailsApi = (id) => {
  let path = `/api/v1/govt/firm/${id}`;

  if (localStorage.getItem("role") === "1") {
    path = `/api/v1/admin/firm/${id}`;
  }

  return axiosInstance.get(path);
};
