import React from "react";
import ReactApexChart from "react-apexcharts";

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Firms",
          data: this.props.data.map((item) => item?.firm)
        },
        {
          name: "Government Agencies",
          data: this.props.data.map((item) => item?.govtFirm)
        }
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#FF9F43", "#083063"],
        stroke: {
          curve: "smooth"
        },
        title: {
          text: "Total Clients",
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "500",
            fontFamily: "Montserrat, sans-serif"
          }
        },
        grid: {
          strokeDashArray: 7
        },
        markers: {
          size: 5
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          categories: this.props.categories
        },
        yaxis: {},
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height={350}
        />
      </div>
    );
  }
}

export default LineChart;
