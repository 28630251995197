import { useEffect, createContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AuthLayout from "layouts/AuthLayout";

import "flatpickr/dist/themes/material_blue.css";
import "./assets/css/App.css";
import { refreshState } from "redux/user/actions";
import AdminLayout from "layouts/AdminLayout";
import { selectUserRole } from "redux/user/selectors";
import userRoles from "utils/roles";
import { selectUserToken } from "redux/user/selectors";
import FirmsLayout from "layouts/FirmsLayout";
import GovAgencyLayout from "layouts/GovAgencyLayout";
import GovFirmsLayout from "layouts/GovFirmsLayout";

export const toggleContext = createContext();

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const refreshStateHandler = () => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const user = JSON.parse(localStorage.getItem("user")) || "";
    dispatch(refreshState({ token, user, role }));
  };

  useEffect(() => {
    refreshStateHandler();
  }, []);

  const defaultNotFoundPage = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/notfound";
      case userRoles.GOVT:
        return "/gov/notfound";
      case userRoles.FIRMS:
        return "/firms/notfound";
      case userRoles.GOVT_FIRMS:
        return "/govt-firms/notfound";
      default:
        return "/admin/notfound";
    }
  };

  const defaultUnauthorizedPage = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/unauthorized";
      case userRoles.GOVT:
        return "/gov/unauthorized";
      case userRoles.FIRMS:
        return "/firms/unauthorized";
      case userRoles.GOVT_FIRMS:
        return "/govt-firms/unauthorized";
      default:
        return "/admin/unauthorized";
    }
  };

  const defaultHomePage = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/dashboard";
      case userRoles.GOVT:
        return "/gov/dashboard";
      case userRoles.FIRMS:
        return "/firms/start/Assessment";
      case userRoles.GOVT_FIRMS:
        return "/govt-firms/start/Assessment";
      default:
        return "/dashboard";
    }
  };

  return (
    <Routes>
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="/admin/*" element={<AdminLayout />} />
      <Route path="/firms/*" element={<FirmsLayout />} />
      <Route path="/gov/*" element={<GovAgencyLayout />} />
      <Route path="/govt-firms/*" element={<GovFirmsLayout />} />
      {token ? (
        <Route
          path="/"
          element={<Navigate to={`${defaultHomePage()}`} replace state={{ from: location }} />}
        />
      ) : (
        <Route path="/" element={<Navigate to="/auth/admin/login" />} />
      )}
      <Route
        path="/unauthorized"
        element={
          <Navigate to={`${defaultUnauthorizedPage()}`} replace state={{ from: location }} />
        }
      />
      <Route
        path="*"
        element={<Navigate to={`${defaultNotFoundPage()}`} replace state={{ from: location }} />}
      />
    </Routes>
  );
};

export default App;
