import Search from "components/Search/Search";
import Slider from "rc-slider";
import React, { Fragment } from "react";
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import "./OpportunityList.css";
import "rc-slider/assets/index.css";
import BidListingCard from "components/Cards/BidListingCard";
import { Icon } from "@iconify/react";
import Paginate from "components/Pagination/Pagination";
import agencyTypeData, { LocationData, categoryData, jobTypeData } from "./FilterData";
import { useDispatch } from "react-redux";
import { fetchBidListingThunkAction } from "redux/biding/actions";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectBidList } from "redux/biding/selectors";
import Loader from "components/Loader/Loader";
import { getCategoryListApi } from "services/firmBidOpportunityService";
import toast from "react-hot-toast";
import { getAgencyTypeListApi } from "services/firmBidOpportunityService";
import { getLocationsListApi } from "services/firmBidOpportunityService";
import { getJobTypeListApi } from "services/firmBidOpportunityService";
import { set } from "lodash";

const initialState = {
  startDate: "",
  endDate: "",
  range: "",
  agencyType: [],
  category: [],
  location: [],
  jobType: [],
  sortBy: "",
  columnName: "",
  limit: 10,
  bidType: true,
  search: ""
};

function OpportunityList() {
  const [filterData, setFilterData] = React.useState({ ...initialState });
  const [error, setError] = React.useState(null);
  const [categoryLoading, setCategoryLoading] = React.useState(false);
  const [agencyTypeLoading, setAgencyTypeLoading] = React.useState(false);
  const [jobTypeLoading, setJobTypeLoading] = React.useState(false);
  const [locationLoading, setLocationLoading] = React.useState(false);

  const dispatch = useDispatch();
  const { isLoading, bidList, totalPage, naics_code } = useSelector(selectBidList);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [clear, setClear] = React.useState(false);
  const [categoryData, setCategoryData] = React.useState([]);
  const [agencyTypeData, setAgencyTypeData] = React.useState([]);
  const [jobTypeData, setJobTypeData] = React.useState([]);
  const [LocationData, setLocationData] = React.useState([]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePreviousPage = () => {
    if (currentPage === 0) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage === totalPage - 1) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const handleSearch = (key, value, key2, value2) => {
    handlePageChange(0);
    setFilterData((preData) => ({
      ...preData,
      [key]: value,
      [key2]: value2
    }));
  };

  const handleMultiValueFilter = (key, value) => {
    handlePageChange(0);
    if (filterData[key].includes(value)) {
      setFilterData((preData) => ({
        ...preData,
        [key]: preData[key].filter((item) => item !== value)
      }));
    } else {
      setFilterData((preData) => ({
        ...preData,
        [key]: [...preData[key], value]
      }));
    }
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  React.useEffect(() => {
    const fetchCategoryList = async () => {
      try {
        setCategoryLoading(true);
        const { data } = await getCategoryListApi();
        setCategoryData(data?.data);
        setCategoryLoading(false);
        if (data.success !== true) {
          throw new Error(data?.message);
        }
      } catch (error) {
        setCategoryLoading(false);
        toast.error(error.response?.data?.message || error?.message);
      }
    };

    const fetchAgencyTypeList = async () => {
      try {
        setAgencyTypeLoading(true);
        const { data } = await getAgencyTypeListApi();
        setAgencyTypeData(data?.data);
        setAgencyTypeLoading(false);
        if (data.success !== true) {
          throw new Error(data?.message);
        }
      } catch (error) {
        setAgencyTypeLoading(false);
        toast.error(error.response?.data?.message || error?.message);
      }
    };

    const fetchJobTypeList = async () => {
      try {
        setJobTypeLoading(true);
        const { data } = await getJobTypeListApi();
        setJobTypeData(data?.data);
        setJobTypeLoading(false);
        if (data.success !== true) {
          throw new Error(data?.message);
        }
      } catch (error) {
        setJobTypeLoading(false);
        toast.error(error.response?.data?.message || error?.message);
      }
    };

    const fetchLocationsList = async () => {
      try {
        setLocationLoading(true);
        const { data } = await getLocationsListApi();
        const regionsMap = {};

        // Iterate through the locations array and organize the data by region
        data.data.forEach((location) => {
          const { region, name } = location;

          // Check if the region already exists in the regionsMap
          if (!regionsMap[region]) {
            // If not, create a new entry with an array containing the location name
            regionsMap[region] = { region, locations: [name] };
          } else {
            // If the region already exists, push the location name to its array
            regionsMap[region].locations.push(name);
          }
        });

        const regionsArray = Object.values(regionsMap);

        setLocationData(regionsArray);
        setLocationLoading(false);
        if (data.success !== true) {
          throw new Error(data?.message);
        }
      } catch (error) {
        setLocationLoading(false);
        toast.error(error.response?.data?.message || error?.message);
      }
    };

    fetchCategoryList();
    fetchAgencyTypeList();
    fetchJobTypeList();
    fetchLocationsList();
  }, []);

  React.useEffect(() => {
    dispatch(fetchBidListingThunkAction(filterData, currentPage, onSuccess, onError));
  }, [filterData, currentPage]);

  return (
    <div className="px-3">
      <div className="d-flex justify-content-between my-2 text-capitalize">
        <h4>Bid Opportunity List</h4>
      </div>
      <div>
        {agencyTypeLoading || categoryLoading || jobTypeLoading || locationLoading ? (
          <Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </Fragment>
        ) : (
          <Row className="">
            <Col md="4" lg="3" className="d-flex flex-column justify-content-between w-full">
              <div className="pt-2">Filter</div>
              <hr className="dotted" />
            </Col>
            <Col md="8" lg="9">
              <div
                className="d-flex mb-2"
                style={{
                  columnGap: "10px"
                }}
              >
                <Button
                  className="btn w-full mx-auto d-flex justify-content-between"
                  color={filterData?.bidType === true ? "primary" : "secondary"}
                  type="button"
                  style={{
                    width: "100%"
                  }}
                  onClick={() => handleSearch("bidType", true)}
                >
                  <span>My Bid Opportunities</span>
                  <span>{bidList?.my_bid}</span>
                </Button>
                <Button
                  className="btn w-full mx-auto d-flex justify-content-between"
                  color={filterData?.bidType === false ? "primary" : "secondary"}
                  type="button"
                  style={{
                    width: "100%"
                  }}
                  onClick={() => handleSearch("bidType", false)}
                >
                  <span>All Bid Opportunities</span>
                  <span>{bidList?.total_bid}</span>
                </Button>
              </div>
              <hr className="dotted" />
            </Col>

            <Col md="4" lg="3">
              <div className="mt-3">
                <Search
                  onSubmitHandler={(value) => {
                    handleSearch("search", value);
                  }}
                  clear={clear}
                  setClear={setClear}
                  isLabel={false}
                  placeHolder="search by job title"
                />
              </div>

              <div className="filter_container mt-4 p-3">
                <div>
                  <h6 className="mb-4">Filter your results by:</h6>
                  <div className="">
                    <input
                      type="radio"
                      className="mr-3"
                      name="date"
                      id="today"
                      value="today"
                      onChange={(e) =>
                        handleSearch(
                          "startDate",
                          moment.utc(moment().startOf("day")).format(),
                          "endDate",
                          moment.utc(moment().endOf("day")).format()
                        )
                      }
                      checked={
                        filterData.startDate === moment.utc(moment().startOf("day")).format()
                      }
                    />
                    <label htmlFor="today">Today</label>
                  </div>

                  <div className="">
                    <input
                      type="radio"
                      className="mr-3"
                      name="date"
                      id="7days"
                      value="7days"
                      onChange={(e) =>
                        handleSearch(
                          "startDate",
                          moment.utc(moment().subtract(7, "days").startOf("day").format()).format(),
                          "endDate",
                          moment.utc(moment().endOf("day")).format()
                        )
                      }
                      checked={
                        filterData.startDate ===
                        moment.utc(moment().subtract(7, "days").startOf("day").format()).format()
                      }
                    />
                    <label htmlFor="7days">Last 7 days</label>
                  </div>
                  <div className="">
                    <input
                      type="radio"
                      className="mr-3"
                      name="date"
                      id="all"
                      value="all"
                      onChange={(e) => handleSearch("startDate", "", "endDate", "")}
                      checked={filterData.startDate === ""}
                    />
                    <label htmlFor="all">All</label>
                  </div>
                </div>

                <hr className="dotted" />

                {/* <div style={{ marginBottom: "2rem" }}>
                <div className="d-flex justify-content-between my-2">
                  <span>Range</span>
                  <span>In miles</span>
                </div>
                <Slider
                  included
                  max={50}
                  marks={{
                    0: 0,
                    5: 5,
                    10: 10,
                    15: 15,
                    20: 20,
                    25: 25,
                    30: 30,
                    35: 35,
                    40: 40,
                    45: 45,
                    50: 50
                  }}
                  dotStyle={{
                    display: "none"
                  }}
                  railStyle={{
                    backgroundColor: "#6666661F",
                    height: "8px"
                  }}
                  trackStyle={{
                    height: "8px",
                    backgroundColor: "#1273EB"
                  }}
                  handleStyle={{
                    backgroundColor: "white",
                    border: "2px solid #1273EB",
                    marginTop: "-3px",
                    marginLeft: "3px"
                  }}
                  step={5}
                  onChange={(value) => handleSearch("range", value)}
                  value={filterData.range}
                  allowCross={false}
                />
              </div>

              <hr className="dotted" /> */}

                <div className="mt-3">
                  <h6 className="mb-4">Agencies type</h6>

                  {agencyTypeData.map((item, index) => {
                    return (
                      <div className="" key={index}>
                        <input
                          type="checkbox"
                          onChange={(e) => handleMultiValueFilter("agencyType", e.target.value)}
                          checked={filterData.agencyType.includes(item)}
                          value={item}
                          className="mr-3"
                          name="agencyType"
                          id={item.name}
                        />
                        <label htmlFor={item.name}>{item}</label>
                      </div>
                    );
                  })}
                </div>

                <hr className="dotted" />

                <div className="mt-3">
                  <h6 className="mb-4">Category</h6>

                  <div
                    style={{
                      height: "300px",
                      overflowY: "scroll"
                    }}
                  >
                    {categoryData.map((item, index) => {
                      return (
                        <div className="d-flex align-items-start" key={index}>
                          <input
                            type="checkbox"
                            name="category"
                            onChange={(e) => handleMultiValueFilter("category", e.target.value)}
                            checked={filterData.category.includes(item.category_id)}
                            value={item.category_id}
                            className="mr-3 mt-1"
                            id={item.category_id + "category"}
                          />
                          <label
                            htmlFor={item.category_id + "category"}
                            style={{
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            {item.category_name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <hr className="dotted" />

                <div className="mt-3">
                  <h6 className="mb-4">Location</h6>
                  <div
                    style={{
                      height: "300px",
                      overflowY: "scroll"
                    }}
                  >
                    {LocationData.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <div
                            className="px-2 py-1"
                            style={{
                              backgroundColor: "#D8D8D8"
                            }}
                          >
                            <input
                              type="checkbox"
                              className="mr-3"
                              name="date"
                              id="today"
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setFilterData((preData) => ({
                                    ...preData,
                                    location: [...preData.location].concat(item.locations)
                                  }));
                                } else {
                                  setFilterData((preData) => ({
                                    ...preData,
                                    location: [...preData.location].filter(
                                      (item2) => !item.locations.includes(item2)
                                    )
                                  }));
                                }
                              }}
                              defaultChecked={item.locations.every((item2) =>
                                filterData.location.includes(item2)
                              )}
                              value={item}
                            />
                            <label htmlFor="today">
                              {item.region === null ? "Other" : item.region}
                            </label>
                          </div>
                          {item.locations.map((item2, index2) => {
                            return (
                              <div className="px-2" key={index2}>
                                <input
                                  type="checkbox"
                                  className="mr-3"
                                  name="date"
                                  id="today"
                                  onChange={(e) =>
                                    handleMultiValueFilter("location", e.target.value)
                                  }
                                  checked={filterData.location.includes(item2)}
                                  value={item2}
                                />
                                <label htmlFor="today">{item2}</label>
                              </div>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>

                <hr className="dotted" />

                <div className="mt-3">
                  <h6 className="mb-4">Job type</h6>

                  {jobTypeData.map((item, index) => {
                    return (
                      <div className="d-flex align-items-start" key={index}>
                        <input
                          type="checkbox"
                          name="jobType"
                          onChange={(e) => handleMultiValueFilter("jobType", e.target.value)}
                          checked={filterData.jobType.includes(item)}
                          value={item}
                          className="mr-3 mt-1"
                          id={item + "job"}
                        />
                        <label
                          htmlFor={item + "job"}
                          style={{
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          {item}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col md="8" lg="9">
              <div className="d-flex justify-content-between mt-4">
                <div
                  style={{
                    fontSize: "1.125rem",
                    lineHeight: "1.49625rem"
                  }}
                >
                  <span>Showing results for:</span>
                  <span className="font_weight ml-2">
                    {filterData?.bidType === true
                      ? `My Bid Opportunities : (${bidList?.my_bid})`
                      : `All Bid Opportunities : (${bidList?.total_bid})`}
                  </span>
                </div>
                <div
                  onClick={() => {
                    setClear(true);
                    setFilterData({ ...initialState });
                  }}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  Click here to remove all filters
                </div>
              </div>
              <Row
                className="mt-3"
                style={{
                  rowGap: "0.5rem"
                }}
              >
                <Col md="2" className="font_weight">
                  Date Range:{" "}
                </Col>
                <Col md="10">
                  {filterData.startDate === moment.utc(moment().startOf("day")).format()
                    ? "Today"
                    : filterData.startDate ===
                      moment.utc(moment().subtract(7, "days").startOf("day").format()).format()
                    ? "Last 7 days"
                    : filterData.startDate === ""
                    ? "All"
                    : ""}
                </Col>

                <Col md="2" className="font_weight">
                  Categories:{" "}
                </Col>
                <Col md="10">
                  <div className="d-flex flex-wrap">
                    {filterData.category.length > 0
                      ? filterData.category.map((item, index) => {
                          return (
                            <span key={item}>
                              {index !== 0 && (
                                <Icon
                                  icon="radix-icons:dot-filled"
                                  width="20"
                                  height="20"
                                  color="gray"
                                />
                              )}
                              {
                                categoryData.find((item2) => item2.category_id === item)
                                  .category_name
                              }
                            </span>
                          );
                        })
                      : "----"}
                  </div>
                </Col>
                <Col md="2" className="font_weight">
                  Location:{" "}
                </Col>
                <Col md="10">
                  {filterData.location.length > 0
                    ? filterData.location.map((item, index) => {
                        return (
                          <span key={item}>
                            {index !== 0 && (
                              <Icon
                                icon="radix-icons:dot-filled"
                                width="20"
                                height="20"
                                color="gray"
                              />
                            )}
                            {item}
                          </span>
                        );
                      })
                    : "----"}
                </Col>
              </Row>

              <div className="d-flex justify-content-between mt-3 flex-wrap">
                <div className="d-flex justify-content-center align-items-center">
                  <label htmlFor="pageSize" className="mr-4 sort_by">
                    Sort by:
                  </label>
                  <Input
                    id="pageSize"
                    className="pageSize"
                    name="sortBy"
                    type="select"
                    onChange={(e) => {
                      const { columnName, orderBy } = JSON.parse(e.target.value);
                      handleSearch("columnName", columnName, "sortBy", orderBy);
                    }}
                    value={JSON.stringify({
                      columnName: filterData.columnName,
                      orderBy: filterData.sortBy
                    })}
                  >
                    <option
                      value={JSON.stringify({
                        columnName: "",
                        orderBy: ""
                      })}
                    >
                      Select Sort By
                    </option>
                    <option
                      value={JSON.stringify({
                        columnName: "duedatetime",
                        orderBy: "desc"
                      })}
                    >
                      Due Date desc
                    </option>
                    <option
                      value={JSON.stringify({
                        columnName: "duedatetime",
                        orderBy: "asc"
                      })}
                    >
                      Due Date asc
                    </option>
                    <option
                      value={JSON.stringify({
                        columnName: "issuedate",
                        orderBy: "desc"
                      })}
                    >
                      Publish Date desc
                    </option>
                    <option
                      value={JSON.stringify({
                        columnName: "issuedate",
                        orderBy: "asc"
                      })}
                    >
                      Publish Date asc
                    </option>
                  </Input>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <label htmlFor="pageSize" className="mr-4 sort_by">
                    Display:
                  </label>
                  <Input
                    id="pageSize"
                    className="pageSize"
                    name="limit"
                    type="select"
                    onChange={(e) => handleSearch("limit", Number(e.target.value))}
                    value={filterData.limit}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                  </Input>
                </div>
              </div>

              <div className="mt-4">
                {isLoading ? (
                  <Fragment>
                    <div className="post_error_loading">
                      <Loader />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    {error ? (
                      <Fragment>
                        <div className="post_error_loading">{error}</div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {bidList?.paginatedList?.data?.map((item, index) => {
                          return <BidListingCard key={index} data={item} naics_code={naics_code} />;
                        })}
                        {!error && bidList?.paginatedList?.data?.length === 0 && (
                          <div className="post_error_loading">No Bid Opportunity data found.</div>
                        )}
                        <Row className="">
                          <Col className="d-flex justify-content-center">
                            <Paginate
                              pageCount={Math.ceil(totalPage)}
                              pageIndex={currentPage}
                              gotoPage={handlePageChange}
                              previousPage={handlePreviousPage}
                              nextPage={handleNextPage}
                            />
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}

export default OpportunityList;
