import axiosInstance from "./axiosInstance";

export const getAssessmentFirmsApi = (prms) => {
  let path = "/api/v1/firms/dashboard/survey-data";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/dashboard/survey-data";
  }

  return axiosInstance.get(path, {
    params: {
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });
};

export const getAssessmentScoreByCategoryApi = (prms) => {
  let path = "/api/v1/firms/dashboard/chg-sub-category";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/dashboard/chg-sub-category";
  }

  return axiosInstance.get(path, {
    params: {
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });
};

export const getFirmsTotalChangesApi = (prms) => {
  let path = "/api/v1/firms/dashboard/analysis-data";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/dashboard/analysis-data";
  }

  return axiosInstance.get(path, {
    params: {
      start_date: prms?.start_date,
      end_date: prms?.end_date
    }
  });
};

export const getFirmsCurrentPlanApi = () => {
  let path = "/api/v1/firms/dashboard/active-plan";

  if (localStorage.getItem("role") === "3") {
    path = "/api/v1/govt-firms/dashboard/active-plan";
  }

  return axiosInstance.get(path);
};
