import RestrictedAuth from "components/RestrictedAuth";
import Register from "pages/Register/Register";
import Login from "pages/Login/Login";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Forgotpassword from "pages/Forgotpassword/Forgotpassword";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import FirmLogin from "pages/FirmLogin/FirmLogin";
import GovernmentRegister from "pages/GovernmentRegister/GovernmentRegister";
import SendEmail from "pages/SendEmail/SendEmail";
import VerifyOTP from "pages/VerifyOTP/VerifyOTP";

function AuthLayout() {
  const location = useLocation();

  return (
    <Routes>
      <Route element={<RestrictedAuth restricted={true} />}>
        <Route path="government/verifyemail" element={<SendEmail />} />
        <Route path="firm/verifyemail" element={<SendEmail />} />
        <Route path="government/verifyotp" element={<VerifyOTP />} />
        <Route path="firm/verifyotp" element={<VerifyOTP />} />

        <Route path="firm/register" element={<Register />} />
        <Route path="government/register" element={<GovernmentRegister />} />
        <Route path="gov/firm/register" element={<Register />} />

        <Route path="admin/login" element={<Login />} />
        <Route path="firm/login" element={<FirmLogin />} />
        <Route path="government/login" element={<FirmLogin />} />
        <Route path="gov/firm/login" element={<Login />} />

        <Route
          path="*"
          element={<Navigate to={`/auth/admin/login`} replace state={{ from: location }} />}
        />
        <Route path="forgotpassword" element={<Forgotpassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
      </Route>
    </Routes>
  );
}

export default AuthLayout;
