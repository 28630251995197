import { getAdminAssessmentScoreSubcategoryApi } from "services/dashboardServices";
import { getAdminDistributionIndustryApi } from "services/dashboardServices";
import { getAdminMonthlyRegFirmApi } from "services/dashboardServices";
import { getAdminTotalChangesApi } from "services/dashboardServices";
import { getAdminDashboardRevenueApi } from "services/dashboardServices";
import { getAdminDashboardTotalFirmsApi } from "services/dashboardServices";
import {
  FETCH_ADMIN_TOTAL_FIRMS_REQUEST,
  FETCH_ADMIN_TOTAL_FIRMS_SUCCESS,
  FETCH_ADMIN_TOTAL_FIRMS_FAILURE,
  FETCH_DISTRIBUTION_INDUSTRY_REQUEST,
  FETCH_DISTRIBUTION_INDUSTRY_SUCCESS,
  FETCH_DISTRIBUTION_INDUSTRY_FAILURE,
  FETCH_ASSESSMENT_SCORE_SUBCATEGORY_REQUEST,
  FETCH_ASSESSMENT_SCORE_SUBCATEGORY_SUCCESS,
  FETCH_ASSESSMENT_SCORE_SUBCATEGORY_FAILURE,
  FETCH_ADMIN_TOTAL_CHANGES_REQUEST,
  FETCH_ADMIN_TOTAL_CHANGES_SUCCESS,
  FETCH_ADMIN_TOTAL_CHANGES_FAILURE,
  FETCH_ADMIN_REVENUE_REQUEST,
  FETCH_ADMIN_REVENUE_SUCCESS,
  FETCH_ADMIN_REVENUE_FAILURE,
  FETCH_MONTHLY_REG_FIRMS_REQUEST,
  FETCH_MONTHLY_REG_FIRMS_SUCCESS,
  FETCH_MONTHLY_REG_FIRMS_FAILURE
} from "./types";

export const fetchAdminTotalFirmsRequest = () => {
  return {
    type: FETCH_ADMIN_TOTAL_FIRMS_REQUEST
  };
};

export const fetchAdminTotalFirmsSuccess = (data) => {
  return {
    type: FETCH_ADMIN_TOTAL_FIRMS_SUCCESS,
    payload: data
  };
};

export const fetchAdminTotalFirmsFailure = (error) => {
  return {
    type: FETCH_ADMIN_TOTAL_FIRMS_FAILURE,
    payload: error
  };
};

export const fetchAdminTotalFirmsThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAdminTotalFirmsRequest());
      const { data } = await getAdminDashboardTotalFirmsApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchAdminTotalFirmsSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchAdminTotalFirmsFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchDistributionIndustryRequest = () => {
  return {
    type: FETCH_DISTRIBUTION_INDUSTRY_REQUEST
  };
};

export const fetchDistributionIndustrySuccess = (data) => {
  return {
    type: FETCH_DISTRIBUTION_INDUSTRY_SUCCESS,
    payload: data
  };
};

export const fetchDistributionIndustryFailure = (error) => {
  return {
    type: FETCH_DISTRIBUTION_INDUSTRY_FAILURE,
    payload: error
  };
};

export const fetchDistributionIndustryThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDistributionIndustryRequest());
      const { data } = await getAdminDistributionIndustryApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchDistributionIndustrySuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchDistributionIndustryFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchAssessmentScoreSubcategoryRequest = () => {
  return {
    type: FETCH_ASSESSMENT_SCORE_SUBCATEGORY_REQUEST
  };
};

export const fetchAssessmentScoreSubcategorySuccess = (data) => {
  return {
    type: FETCH_ASSESSMENT_SCORE_SUBCATEGORY_SUCCESS,
    payload: data
  };
};

export const fetchAssessmentScoreSubcategoryFailure = (error) => {
  return {
    type: FETCH_ASSESSMENT_SCORE_SUBCATEGORY_FAILURE,
    payload: error
  };
};

export const fetchAssessmentScoreSubcategoryThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAssessmentScoreSubcategoryRequest());
      const { data } = await getAdminAssessmentScoreSubcategoryApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchAssessmentScoreSubcategorySuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchAssessmentScoreSubcategoryFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchTotalChangesRequest = () => {
  return {
    type: FETCH_ADMIN_TOTAL_CHANGES_REQUEST
  };
};

export const fetchTotalChangesSuccess = (data) => {
  return {
    type: FETCH_ADMIN_TOTAL_CHANGES_SUCCESS,
    payload: data
  };
};

export const fetchTotalChangesFailure = (error) => {
  return {
    type: FETCH_ADMIN_TOTAL_CHANGES_FAILURE,
    payload: error
  };
};

export const fetchTotalChangesThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchTotalChangesRequest());
      const { data } = await getAdminTotalChangesApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchTotalChangesSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchTotalChangesFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchRevenueRequest = () => {
  return {
    type: FETCH_ADMIN_REVENUE_REQUEST
  };
};

export const fetchRevenueSuccess = (data) => {
  return {
    type: FETCH_ADMIN_REVENUE_SUCCESS,
    payload: data
  };
};

export const fetchRevenueFailure = (error) => {
  return {
    type: FETCH_ADMIN_REVENUE_FAILURE,
    payload: error
  };
};

export const fetchRevenueThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchRevenueRequest());
      const { data } = await getAdminDashboardRevenueApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchRevenueSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchRevenueFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchMonthlyRegFirmsRequest = () => {
  return {
    type: FETCH_MONTHLY_REG_FIRMS_REQUEST
  };
};

export const fetchMonthlyRegFirmsSuccess = (data) => {
  return {
    type: FETCH_MONTHLY_REG_FIRMS_SUCCESS,
    payload: data
  };
};

export const fetchMonthlyRegFirmsFailure = (error) => {
  return {
    type: FETCH_MONTHLY_REG_FIRMS_FAILURE,
    payload: error
  };
};

export const fetchMonthlyRegFirmsThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMonthlyRegFirmsRequest());
      const { data } = await getAdminMonthlyRegFirmApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchMonthlyRegFirmsSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchMonthlyRegFirmsFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};
