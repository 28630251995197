import ReportsPageColumnChart from "components/Charts/ReportsPageColumnChart";
import React from "react";
import { Col, Modal, ModalBody, Row, Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectFirmsReportList } from "redux/firmsReport/selectors";
import { fetchCompareSurveyAnalysisThunkAction } from "redux/firmsReport/actions";
import { assessmentSectionTitles } from "utils/roles";
import Loader from "components/Loader/Loader";
import { Icon } from "@iconify/react";

const ReportSubCategoryModal = ({ toggleModal, isOpen, SurveyData }) => {
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState({});
  const [graphData, setGraphData] = React.useState([]);
  const [graphDataSecond, setGraphDataSecond] = React.useState([]);
  const [graphTitles, setGraphTitles] = React.useState([]);

  const dispatch = useDispatch();
  const { surveyLoading, compareSurveyAnalysisData } = useSelector(selectFirmsReportList);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  React.useEffect(() => {
    if (SurveyData?.survey[0]?.survey_id && SurveyData?.survey[1]?.survey_id) {
      dispatch(
        fetchCompareSurveyAnalysisThunkAction(
          {
            survey_id1: SurveyData?.survey[0]?.survey_id,
            survey_id2: SurveyData?.survey[1]?.survey_id
          },
          onSuccess,
          onError
        )
      );
    }
  }, [SurveyData?.survey[0]?.survey_id, SurveyData?.survey[1]?.survey_id]);

  React.useEffect(() => {
    setData(compareSurveyAnalysisData);
    setGraphData(
      compareSurveyAnalysisData?.first_survey?.map((item) => item?.section_total_score?.toFixed(0))
    );
    setGraphDataSecond(
      compareSurveyAnalysisData?.second_survey?.map((item) => item?.section_total_score?.toFixed(0))
    );
    setGraphTitles(
      compareSurveyAnalysisData?.first_survey?.map(
        (item) => assessmentSectionTitles[`${item?.section_id}`]
      )
    );
  }, [compareSurveyAnalysisData]);

  const FirstSurveyTotal = data?.first_survey?.reduce(
    (prevValue, currentValue) => prevValue + currentValue.section_total_score,
    0
  );
  const SecondSurveyTotal = data?.second_survey?.reduce(
    (prevValue, currentValue) => prevValue + currentValue.section_total_score,
    0
  );

  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-xl"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <p className="modal-title " id="modal-title-default">
                Average score for each subcategory of the Assessment
              </p>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>

            <ModalBody>
              {surveyLoading ? (
                <React.Fragment>
                  <div className="post_error_loading">
                    <Loader />
                  </div>
                </React.Fragment>
              ) : (
                <>
                  {error ? (
                    <React.Fragment>
                      <div className="post_error_loading">{error}</div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Row>
                        <Col>
                          {graphTitles?.length ? (
                            <ReportsPageColumnChart
                              data_1={graphData}
                              data_2={graphDataSecond}
                              categories={graphTitles}
                            />
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="mt-12">
                        <Col
                          lg="6"
                          xl="6"
                          style={{
                            padding: "0",
                            borderRightStyle: "dashed ",
                            borderRightWidth: "thin"
                          }}
                        >
                          <Card
                            className="card-stats "
                            style={{
                              border: "none"
                            }}
                          >
                            <CardBody>
                              <Row>
                                <Col className="d-flex align-items-center justify-content-between ">
                                  <span className="h5">First Assessment</span>
                                  <span className="h5" style={{ color: "#1273EB" }}>
                                    Average{" "}
                                    {(FirstSurveyTotal / data?.first_survey?.length).toFixed(2)}
                                  </span>
                                </Col>
                              </Row>

                              {surveyLoading ? (
                                <React.Fragment>
                                  <div className="post_error_loading">
                                    <Loader />
                                  </div>
                                </React.Fragment>
                              ) : (
                                <>
                                  {error ? (
                                    <React.Fragment>
                                      <div className="post_error_loading">{error}</div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {data?.first_survey?.map((survey_1) => {
                                        return (
                                          <Row key={survey_1?.section_id}>
                                            <Col className="d-flex align-items-center justify-content-between m-1">
                                              <span>
                                                <Icon
                                                  icon="akar-icons:circle-fill"
                                                  className="mx-2 mb-1"
                                                  color="#aaa"
                                                />
                                                {assessmentSectionTitles[`${survey_1?.section_id}`]}
                                              </span>
                                              <span>{survey_1?.section_total_score}</span>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </React.Fragment>
                                  )}
                                </>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col
                          lg="6"
                          xl="6"
                          style={{
                            padding: "0"
                          }}
                        >
                          <Card
                            className="card-stats"
                            style={{
                              border: "none"
                            }}
                          >
                            <CardBody>
                              <Row>
                                <Col className="d-flex align-items-center justify-content-between ">
                                  <span className="h5">Second Assessment</span>
                                  <span className="h5" style={{ color: "#1273EB" }}>
                                    Average{" "}
                                    {(SecondSurveyTotal / data?.second_survey?.length).toFixed(2)}
                                  </span>
                                </Col>
                              </Row>
                              {surveyLoading ? (
                                <React.Fragment>
                                  <div className="post_error_loading">
                                    <Loader />
                                  </div>
                                </React.Fragment>
                              ) : (
                                <>
                                  {error ? (
                                    <React.Fragment>
                                      <div className="post_error_loading">{error}</div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {data?.second_survey?.map((survey_2) => {
                                        return (
                                          <Row key={survey_2?.section_id}>
                                            <Col className="d-flex align-items-center justify-content-between m-1">
                                              <span>
                                                <Icon
                                                  icon="akar-icons:circle-fill"
                                                  className="mx-2 mb-1"
                                                  color="#aaa"
                                                />
                                                {assessmentSectionTitles[`${survey_2?.section_id}`]}
                                              </span>
                                              <span>{survey_2?.section_total_score}</span>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </React.Fragment>
                                  )}
                                </>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </>
              )}
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default ReportSubCategoryModal;
