import axiosInstance from "./axiosInstance";

export const postRegisterUserApi = (userData) =>
  axiosInstance.post("/api/v1/auth/register", userData);

export const postLoggedInUserApi = (userData) => axiosInstance.post("/api/v1/auth/login", userData);

export const postLoggedOutUserApi = () => axiosInstance.post("/api/v1/auth/logout");

export const postForgotPasswordUserApi = (userData) =>
  axiosInstance.post("/api/v1/auth/forgot-password", userData);

export const postResetPasswordUserApi = (token, userData) =>
  axiosInstance.post(`/api/v1/auth/reset-password/${token}`, userData);

export const getUsersProfileDetailApi = () => axiosInstance.get("/api/v1/user/profile");

export const updateUserProfileAPI = (userData) =>
  axiosInstance.put("/api/v1/user/update", userData);

export const postSendEmailApi = (userData) => axiosInstance.post("/api/v1/auth/otp-sent", userData);

export const postVerifyOTPApi = (userData) =>
  axiosInstance.post("/api/v1/auth/otp-verify", userData);
