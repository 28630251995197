import {
  FETCH_FIRMS_LIST_REQUEST,
  FETCH_FIRMS_LIST_SUCCESS,
  FETCH_FIRMS_LIST_FAILURE,
  RESET_FIRMS_LIST_DATA,
  SELECTED_FIRM
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  firmslist: [],
  FirmDetail: null,
  totalPage: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIRMS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_FIRMS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        firmslist: action.payload.firmslist,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_FIRMS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        firmslist: [],
        error: action.payload
      };
    case RESET_FIRMS_LIST_DATA:
      return {
        ...state,
        firmslist: [],
        totalPage: 0
      };
    case SELECTED_FIRM:
      return {
        ...state,
        FirmDetail: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
