import { Icon } from "@iconify/react";
import React, { Fragment, useState } from "react";
import { Button, Tooltip } from "reactstrap";

function TooltipItem({ item, index }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <Fragment>
      <Button color="primary" outline className="px-3 py-2" id={"Tooltip-" + index} disabled>
        <Icon icon="material-symbols:download" width="24" height="24" />
        <span className="ml-2">View</span>
      </Button>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={"Tooltip-" + index}
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        {item?.file_name}
      </Tooltip>
    </Fragment>
  );
}

export default TooltipItem;
