import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AES, enc } from "crypto-js";

import "../Login/Login.css";
import { Col, FormGroup, Row } from "reactstrap";
import AuthLogo from "components/AuthLogo/AuthLogo";
import { selectUser } from "redux/user/selectors";
import { verifyEmailThunkAction } from "redux/user/actions";
import { verifyOTPThunkAction } from "redux/user/actions";

function VerifyOTP() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isGovernmentOTPPage = pathname.includes("government/verifyotp");
  const { isSigning } = useSelector(selectUser);
  const verifiedEmail = localStorage.getItem("verifiedEmail");

  const navigateToRegister = (email) => {
    if (isGovernmentOTPPage) {
      navigate("/auth/government/register", {
        replace: true
      });
    } else {
      navigate("/auth/firm/register", {
        replace: true
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: ""
    },
    validationSchema: Yup.object().shape({
      otp: Yup.string().required("Security Code is required.")
    }),
    onSubmit: (values) => {
      dispatch(
        verifyOTPThunkAction(
          { otp: values.otp.toString(), email: verifiedEmail },
          navigateToRegister
        )
      );
    }
  });

  const resendOTPHandler = () => {
    dispatch(verifyEmailThunkAction({ email: verifiedEmail }, (email) => {}));
  };

  useEffect(() => {
    if (!verifiedEmail) {
      if (isGovernmentOTPPage) {
        navigate("/auth/government/verifyemail", {
          replace: true
        });
      } else {
        navigate("/auth/firm/verifyemail", {
          replace: true
        });
      }
    }
  }, [verifiedEmail]);

  return (
    <Fragment>
      <div className="login_container">
        <AuthLogo />
        <div className="login_form mx-auto">
          <Row className="d-flex justify-content-between px-5 pt-4">
            <div>
              <h2 className="verify_email_title">
                {" "}
                {isGovernmentOTPPage ? "Government Agency" : "Firm"}
              </h2>
              <h2 className="login_form_title">Verify Security Code</h2>
            </div>
          </Row>
          <form onSubmit={formik.handleSubmit} className="sign_up_form">
            <Row className="px-lg-5 px-md-4 px-3 py-1">
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="userName">
                    Security Code
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-control otp-input"
                    placeholder="Enter Security Code"
                    id="otp"
                    name="otp"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.otp}
                  />
                  {formik.touched.otp && formik.errors.otp ? (
                    <div className="text-danger mt-2">{formik.errors.otp}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="12 text-right">
                <button
                  style={{ textAlign: "right", color: "black" }}
                  className="btn btn-link"
                  type="button"
                  onClick={resendOTPHandler}
                >
                  Resend Security Code?
                </button>
              </Col>

              <Col sm={12}>
                <button
                  disabled={!formik.isValid || isSigning}
                  className="btn login_btn mx-auto mb-4 mt-2"
                  type="submit"
                >
                  {isSigning ? "Verifiying..." : "Submit"}
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default VerifyOTP;
