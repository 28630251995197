import moment from "moment";
import { Badge } from "reactstrap";

export const getColumns = (handleOpenModal) => {
  return [
    {
      Header: "Company",
      accessor: "company",
      Cell: ({ row }) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenModal(row?.original?.user_id)}
          >
            {row.original.company}
          </span>
        );
      }
    },
    {
      Header: "Join Date",
      accessor: "created_at",
      disableFilters: true,
      Cell: ({ cell: { value } }) => {
        return (
          <div
            style={{
              width: "100px"
            }}
          >
            {moment.utc(value).format("MM-DD-YYYY")}
          </div>
        );
      }
    },
    {
      Header: "Username",
      accessor: "username"
    },
    {
      Header: "Year founded",
      accessor: "year_founded"
    },
    {
      Header: "Phone",
      accessor: "phone"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Certification type",
      accessor: "certification_type"
    },
    {
      Header: "NAICS",
      accessor: "naics_code"
    },
    // {
    //   Header: "Union member ",
    //   accessor: "union_member"
    // },
    {
      Header: "Total Assessments",
      accessor: "total_assessments",
      disableFilters: true
    },
    {
      Header: "Type ",
      accessor: "role_id",
      Cell: ({ cell: { value } }) => {
        if (value === 3) {
          return (
            <Badge pill style={{ color: "#FF9F43", backgroundColor: "#FFF5EC" }}>
              Government
            </Badge>
          );
        } else value === 4;
        {
          return (
            <Badge pill style={{ color: "#1273EB", backgroundColor: "#E7F1FD" }}>
              Self-Service
            </Badge>
          );
        }
      }
    }
  ];
};
