import React from "react";
import { Col } from "reactstrap";
import { getFormattedContact } from "utils/roles";

function PDFQuestionTable(props) {
  const { questionList = [] } = props;
  return (
    <>
      {questionList?.map((question, index) => {
        const usaCurrency = () => {
          if (question?.is_financial) {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0
            }).format(Math.abs(Number(question?.answer_value)));
          }
          return new Intl.NumberFormat("en-US", {
            maximumSignificantDigits: 3
          }).format(question?.answer_value);
        };

        return (
          <>
            <Col
              xs={12}
              className="d-flex justify-content-between assessment_result_accordian"
              key={question?.serial_number}
            >
              <div className="m-1">
                <b className="mr-2">0{question?.serial_number}</b>
                <b>{question?.question}</b>
              </div>
            </Col>
            <Col
              sm={12}
              className={`mt-2 assessment_result_action_plan_description ${
                question?.serial_number % 14 === 0 ? "mb-2" : ""
              }`}
            >
              <div className="m-1">
                {question?.type !== "3-text"
                  ? question?.type === "number"
                    ? usaCurrency()
                    : question?.answer_value
                  : JSON.parse(question?.answer_value || "[]").map((answer) => (
                      <div key={answer?.id} className="mb-2">
                        {answer?.projectName} - {getFormattedContact(answer?.contactNumber)}
                      </div>
                    ))}
              </div>
            </Col>
          </>
        );
      })}
    </>
  );
}

export default PDFQuestionTable;
