export const FETCH_ASSESSMENT_FIRMS_REQUEST = "FETCH_ASSESSMENT_FIRMS_REQUEST";
export const FETCH_ASSESSMENT_FIRMS_SUCCESS = "FETCH_ASSESSMENT_FIRMS_SUCCESS";
export const FETCH_ASSESSMENT_FIRMS_FAILURE = "FETCH_ASSESSMENT_FIRMS_FAILURE";

export const FETCH_ASSESSMENT_SCORE_BY_CATEGORY_REQUEST =
  "FETCH_ASSESSMENT_SCORE_BY_CATEGORY_REQUEST";
export const FETCH_ASSESSMENT_SCORE_BY_CATEGORY_SUCCESS =
  "FETCH_ASSESSMENT_SCORE_BY_CATEGORY_SUCCESS";
export const FETCH_ASSESSMENT_SCORE_BY_CATEGORY_FAILURE =
  "FETCH_ASSESSMENT_SCORE_BY_CATEGORY_FAILURE";

export const FETCH_FIRMS_TOTAL_CHANGES_REQUEST = "FETCH_FIRMS_TOTAL_CHANGES_REQUEST";
export const FETCH_FIRMS_TOTAL_CHANGES_SUCCESS = "FETCH_FIRMS_TOTAL_CHANGES_SUCCESS";
export const FETCH_FIRMS_TOTAL_CHANGES_FAILURE = "FETCH_FIRMS_TOTAL_CHANGES_FAILURE";
