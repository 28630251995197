import moment from "moment";

export const getColumns = () => {
  return [
    {
      Header: "Agency Name",
      accessor: "company",
      Cell: ({ row }) => {
        return <span>{row.original.company}</span>;
      }
    },
    {
      Header: "Username",
      accessor: "username"
    },
    {
      Header: "Agency Type",
      accessor: "agency_type"
    },
    {
      Header: "Join Date",
      accessor: "created_at",
      Cell: ({ row }) => {
        return <span>{moment(`${row.original.created_at}`).format("MM-DD-YYYY")}</span>;
      }
    },
    {
      Header: "Phone",
      accessor: "phone"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Firms",
      accessor: "total_firms"
      // disableSortBy: true
    }
  ];
};
