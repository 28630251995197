import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Modal, ModalBody, Row } from "reactstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Payment from "./Payment";
import "./Payment.css";
import axiosInstance from "../../services/axiosInstance";
import Loader from "components/Loader/Loader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LOAD_SCRIPT);

function PaymentModal({ toggleModal, isOpen, planId }) {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const fetchPaymentIntentApi = async () => {
      try {
        const { data } = await axiosInstance.post("/api/v1/user/plan/purchase/plan/payment", {
          plan_id: planId
        });

        setClientSecret(data.data.client_secret);
      } catch (error) {
        console.log(error);
      }
    };

    if (planId) {
      fetchPaymentIntentApi();
    }
  }, [planId]);

  const appearance = {
    theme: "night",
    labels: "floating",
    variables: {
      fontFamily: ' "Gill Sans", sans-serif',
      fontLineHeight: "1.5",
      borderRadius: "10px",
      colorBackground: "#161d31",
      colorPrimaryText: "#fff"
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px"
      },
      ".Input": {
        padding: "12px"
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "s"
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "none"
      },
      ".Tab:hover": {
        border: "none",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)"
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        backgroundColor: "#fff",
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)"
      },
      ".Label": {
        fontWeight: "500"
      }
    }
  };
  const options = {
    clientSecret,
    appearance
  };

  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-xl"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>

            <ModalBody>
              <Row className="mt-12">
                <Col xs={12}>
                  <Card className="card-stats">
                    <CardBody>
                      {clientSecret ? (
                        <Elements options={options} stripe={stripePromise}>
                          <Payment clientSecret={clientSecret} />
                        </Elements>
                      ) : (
                        <Row className="">
                          <Col xs={12} className="d-flex justify-content-center">
                            <Loader />
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </>
  );
}
export default PaymentModal;
