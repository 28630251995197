export const FETCH_ADMIN_TOTAL_FIRMS_REQUEST = "FETCH_ADMIN_TOTAL_FIRMS_REQUEST";
export const FETCH_ADMIN_TOTAL_FIRMS_SUCCESS = "FETCH_ADMIN_TOTAL_FIRMS_SUCCESS";
export const FETCH_ADMIN_TOTAL_FIRMS_FAILURE = "FETCH_ADMIN_TOTAL_FIRMS_FAILURE";

export const FETCH_DISTRIBUTION_INDUSTRY_REQUEST = "FETCH_DISTRIBUTION_INDUSTRY_REQUEST";
export const FETCH_DISTRIBUTION_INDUSTRY_SUCCESS = "FETCH_DISTRIBUTION_INDUSTRY_SUCCESS";
export const FETCH_DISTRIBUTION_INDUSTRY_FAILURE = "FETCH_DISTRIBUTION_INDUSTRY_FAILURE";

export const FETCH_ASSESSMENT_SCORE_SUBCATEGORY_REQUEST =
  "FETCH_ASSESSMENT_SCORE_SUBCATEGORY_REQUEST";
export const FETCH_ASSESSMENT_SCORE_SUBCATEGORY_SUCCESS =
  "FETCH_ASSESSMENT_SCORE_SUBCATEGORY_SUCCESS";
export const FETCH_ASSESSMENT_SCORE_SUBCATEGORY_FAILURE =
  "FETCH_ASSESSMENT_SCORE_SUBCATEGORY_FAILURE";

export const FETCH_ADMIN_TOTAL_CHANGES_REQUEST = "FETCH_ADMIN_TOTAL_CHANGES_REQUEST";
export const FETCH_ADMIN_TOTAL_CHANGES_SUCCESS = "FETCH_ADMIN_TOTAL_CHANGES_SUCCESS";
export const FETCH_ADMIN_TOTAL_CHANGES_FAILURE = "FETCH_ADMIN_TOTAL_CHANGES_FAILURE";

export const FETCH_ADMIN_REVENUE_REQUEST = "FETCH_ADMIN_REVENUE_REQUEST";
export const FETCH_ADMIN_REVENUE_SUCCESS = "FETCH_ADMIN_REVENUE_SUCCESS";
export const FETCH_ADMIN_REVENUE_FAILURE = "FETCH_ADMIN_REVENUE_FAILURE";

export const FETCH_MONTHLY_REG_FIRMS_REQUEST = "FETCH_MONTHLY_REG_FIRMS_REQUEST";
export const FETCH_MONTHLY_REG_FIRMS_SUCCESS = "FETCH_MONTHLY_REG_FIRMS_SUCCESS";
export const FETCH_MONTHLY_REG_FIRMS_FAILURE = "FETCH_MONTHLY_REG_FIRMS_FAILURE";
