import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardText, Col, Row } from "reactstrap";
import { selectUserToken } from "redux/user/selectors";
import { selectUserRole } from "redux/user/selectors";
import userRoles from "utils/roles";

function NotFound({
  statusCode = "404",
  message = "We can not seem to find the page you are looking for."
}) {
  const role = useSelector(selectUserRole) || localStorage.getItem("role");
  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const navigate = useNavigate();

  const defaultHomePage = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/dashboard";
      case userRoles.GOVT:
        return "/gov/dashboard";
      case userRoles.FIRMS:
        return "/firms/dashboard";
      case userRoles.GOVT_FIRMS:
        return "/govt-firms/dashboard";
      default:
        return "/nofound";
    }
  };

  return (
    <>
      <div className="px-3">
        <Row>
          <Col>
            <div className="big_text text">
              <h1>
                {statusCode?.charAt(0)}
                <span className="color_blue">{statusCode?.charAt(1)}</span>
                {statusCode?.charAt(2)}
              </h1>
              <p>
                <CardText>{message}</CardText>
              </p>
              {token ? (
                <button
                  style={{
                    backgroundColor: "#1273eb",
                    color: "#fff",
                    padding: "0.5rem 1.5rem",
                    fontWeight: "500",
                    border: "none",
                    borderRadius: "2rem",
                    marginTop: "10px"
                  }}
                  onClick={() =>
                    navigate(`${defaultHomePage()}`, {
                      replace: true
                    })
                  }
                >
                  Go back to dashboard page
                </button>
              ) : (
                <button
                  style={{
                    backgroundColor: "#1273eb",
                    color: "#fff",
                    padding: "0.5rem 1.5rem",
                    fontWeight: "500",
                    border: "none",
                    borderRadius: "2rem",
                    marginTop: "10px"
                  }}
                  onClick={() =>
                    navigate(`${defaultHomePage()}`, {
                      replace: true
                    })
                  }
                >
                  Go back to Login page
                </button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default NotFound;
