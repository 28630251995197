import { useRef, Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";

import usePasswordShow from "hooks/usePasswordShow";

import showPassword from "../../assets/img/theme/show-password-icon.png";
import hidePassword from "../../assets/img/theme/hide-password-icon.png";
import AuthLogo from "components/AuthLogo/AuthLogo";

import "./Register.css";
import { Button, Col, FormGroup, Input, Row, Tooltip } from "reactstrap";
import { registerUserThunkAction } from "redux/user/actions";
import { selectUser } from "redux/user/selectors";
import userRoles from "utils/roles";
import { industriesData } from "utils/roles";
import toast from "react-hot-toast";
import axiosInstance from "../../services/axiosInstance";
import moment from "moment";
import { CleaningServices } from "@mui/icons-material";
import MapModal from "components/Modals/MapModal";
import { Icon } from "@iconify/react";
import "react-country-state-city/dist/react-country-state-city.css";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages //async functions
} from "react-country-state-city";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [countryId, setCountryId] = useState(0);
  const [regionName, setRegionName] = useState("");

  const [ethnicityValue, setEthnicityValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [subCategoryValue, setSubCategoryValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [oldData, setOldData] = useState(null);
  const [divisionValue, setDivisionValue] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const verifiedEmail = localStorage.getItem("verifiedEmail") || "";

  const options = [
    { value: "SBE", label: "SBE" },
    { value: "MBE", label: "MBE" },
    { value: "LBE", label: "LBE" },
    { value: "WBE", label: "WBE" },
    { value: "DBE", label: "DBE" },
    { value: "SDVOB", label: "SDVOB" },
    { value: "LGBTQ", label: "LGBTQ" }
  ];

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" }
  ];

  const ethnicityoptions = [
    { value: "Hispanic or Latino", label: "Hispanic or Latino" },
    { value: "Not Hispanic or Latino", label: "Not Hispanic or Latino" },
    { value: "White", label: "White" },
    { value: "Black or African American", label: "Black or African American" },
    { value: "American Indian or Alaska Native", label: "American Indian or Alaska Native" },
    { value: "Asian", label: "Asian" },
    {
      value: "Native Hawaiian or Other Pacific -Islander",
      label: "Native Hawaiian or Other Pacific -Islander"
    },
    { value: "Some Other Race", label: "Some Other Race" }
  ];

  const divisionOptions = [
    { value: "Construction", label: "Construction" },
    { value: "Goods", label: "Goods" },
    { value: "Services", label: "Services" }
  ];

  // const cityOptions = [
  //   { value: "New York", label: "New York" },
  //   { value: "Los Angeles", label: "Los Angeles" },
  //   { value: "Chicago", label: "Chicago" },
  //   { value: "Houston", label: "Houston" },
  //   { value: "Phoenix", label: "Phoenix" },
  //   { value: "Philadelphia", label: "Philadelphia" },
  //   { value: "San Antonio", label: "San Antonio" },
  //   { value: "San Diego", label: "San Diego" },
  //   { value: "Dallas", label: "Dallas" },
  //   { value: "San Jose", label: "San Jose" },
  //   { value: "Austin", label: "Austin" },
  //   { value: "Jacksonville", label: "Jacksonville" },
  //   { value: "Fort Worth", label: "Fort Worth" },
  //   { value: "Columbus", label: "Columbus" },
  //   { value: "Charlotte", label: "Charlotte" },
  //   { value: "San Francisco", label: "San Francisco" },
  //   { value: "Indianapolis", label: "Indianapolis" },
  //   { value: "Seattle", label: "Seattle" },
  //   { value: "Denver", label: "Denver" },
  //   { value: "Washington", label: "Washington" },
  //   { value: "Boston", label: "Boston" },
  //   { value: "El Paso", label: "El Paso" },
  //   { value: "Nashville", label: "Nashville" },
  //   { value: "Detroit", label: "Detroit" },
  //   { value: "Oklahoma City", label: "Oklahoma City" },
  //   { value: "Portland", label: "Portland" },
  //   { value: "Las Vegas", label: "Las Vegas" },
  //   { value: "Memphis", label: "Memphis" },
  //   { value: "Louisville", label: "Louisville" },
  //   { value: "Baltimore", label: "Baltimore" },
  //   { value: "Milwaukee", label: "Milwaukee" },
  //   { value: "Albuquerque", label: "Albuquerque" },
  //   { value: "Tucson", label: "Tucson" },
  //   { value: "Fresno", label: "Fresno" },
  //   { value: "Mesa", label: "Mesa" },
  //   { value: "Sacramento", label: "Sacramento" },
  //   { value: "Atlanta", label: "Atlanta" }
  // ];

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "black"
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "black"
    })
  };

  const userId = Number(searchParams.get("userId"));
  const isGovernmentFirmRegisterPage = pathname.includes("gov/firm");

  const { isSigning } = useSelector(selectUser);
  const captchaRef = useRef(null);

  const {
    passwordShown: passwordShown1,
    togglePasswordVisiblity: togglePasswordVisiblity1,
    type: type1
  } = usePasswordShow();
  const {
    passwordShown: passwordShown2,
    togglePasswordVisiblity: togglePasswordVisiblity2,
    type: type2
  } = usePasswordShow();

  const onSuccess = () => {
    localStorage.removeItem("verifiedEmail");
    if (isGovernmentFirmRegisterPage) {
      navigate("/auth/gov/firm/login");
    } else {
      navigate("/auth/firm/login");
    }
  };

  var year = moment().year();

  const formik = useFormik({
    initialValues: {
      userName: "",
      firstName: "",
      lastName: "",
      jobTitle: "",
      description: "",
      companyName: "",
      industries: null,
      FEIN: "",
      yearFounded: null,
      phone: "",
      email: verifiedEmail,
      certificationType: "",
      ownerDemographics: null,
      NAICScode: "",
      franchise: "",
      franchiseValue: "",
      unionMember: "",
      unionMemberValue: "",
      confirmPassword: "",
      sendEmail: true,
      password: "",
      // race: "",
      gender: "",
      ethnicity: "",
      city: "",
      state: "",
      country: "",
      // zipcode: "",
      // category: "",
      // subCategory: "",
      division: "",
      location: []
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string()
        .trim()
        .required("Username is required.")
        .min(2, "Username must be at least 2 characters.")
        .max(26, "You can not add more then 26 characters.")
        .matches(/^[^ ]+$/, "You can not enter space in username."),
      firstName: Yup.string()
        .trim()
        .required("First name is required.")
        .min(2, "First name must be at least 2 characters.")
        .max(26, "You can not add more then 26 characters."),
      lastName: Yup.string()
        .trim()
        .required("Last name is required.")
        .min(2, "Last name must be at least 2 characters.")
        .max(26, "You can not add more then 26 characters."),
      // jobTitle: Yup.string().trim().max(255, "You can not add more then 255 characters."),
      // description: Yup.string()
      //   .trim()
      //   .required("Description is required.")
      //   .min(150, "Description must be at least 150 characters.")
      //   .max(1024, "You can not add more then 1024 characters."),
      // companyName: Yup.string()
      //   .trim()
      //   .required("CompanyName is required.")
      //   .max(255, "You can not add more then 255 characters."),
      // industries: Yup.string().trim().nullable(),
      // FEIN: Yup.string().trim().max(255, "You can not add more then 255 characters."),
      // yearFounded: Yup.number()
      //   .typeError("Please enter a number.")
      //   .nullable()
      //   .min(500, "Year must be greater than 500.")
      //   .max(year, "Year must be less than 2022."),
      phone: Yup.string()
        .trim()
        .required("Phone is required.")
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone number."),
      email: Yup.string().required("Email is required.").email("Invalid email address."),
      // certificationType: Yup.string().trim().required("Certification type is required."),
      // ownerDemographics: Yup.string().trim().nullable(),
      // NAICScode: Yup.string().trim().max(255, "You can not add more then 255 characters."),
      // franchise: Yup.string().trim().max(255, "You can not add more then 255 characters."),
      // franchiseValue: Yup.string().when("franchise", {
      //   is: (value) => value === "yes",
      //   then: Yup.string()
      //     .required("Franchise is required.")
      //     .max(255, "You can not add more then 255 characters."),
      //   otherwise: Yup.string().notRequired()
      // }),
      // unionMember: Yup.string(),
      // unionMemberValue: Yup.string().when("unionMember", {
      //   is: (value) => value === "yes",
      //   then: Yup.string().required("Union member is required."),
      //   otherwise: Yup.string().notRequired()
      // }),
      password: Yup.string()
        .required("Password is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),

      confirmPassword: Yup.string()
        .required("Confirm Password is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .oneOf([Yup.ref("password"), null], "Passwords must match."),
      // division: Yup.string().required("Division is required."),
      country: Yup.string().required("Country is required."),
      state: Yup.string().required("State is required."),
      city: Yup.string().required("City is required.")
      // zipcode: Yup.string().trim().required("Zipcode is required."),
      // category: Yup.string().required("Category is required."),
      // subCategory: Yup.string().required("Sub Category is required."),
      // location: Yup.array().test({
      //   message: "Please select at least one State, County and Region.",
      //   test: (arr) => {
      //     return arr.length > 0;
      //   }
      // })
    }),
    onSubmit: async (values) => {
      try {
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
          const token = captchaRef?.current?.getValue();
          captchaRef?.current?.reset();

          if (!token || token === "") {
            toast.error("Please complete the CAPTCHA to proceed with your registration.");
            return;
          }

          const { data } = await axiosInstance.post("/api/v1/auth/recaptcha", { token });

          if (data.statusCode !== 200) {
            throw new Error(data?.message);
          }
        }

        const requestBody = {
          username: values.userName,
          email: values.email,
          password: values.password,
          role_id: userId ? userRoles.GOVT_FIRMS : userRoles.FIRMS,
          phone: values.phone.replace("-", "").replace("(", "").replace(")", "").replace(" ", ""),
          first_name: values.firstName,
          last_name: values.lastName,
          // job_title: values.jobTitle,
          // description: values.description,
          // company: values.companyName,
          // industries: values.industries,
          // fein: values.FEIN,
          // year_founded: values.yearFounded,
          // certification_type: values.certificationType,
          // naics_code: values.NAICScode,
          // race: raceValue,
          // gender: values.gender,
          // ethnicity: ethnicityValue,
          // franchise: values.franchise === "yes" ? values.franchiseValue : "No",
          // union_member: values.unionMember,
          // owner_demographics: values.ownerDemographics,
          send_cred: values.sendEmail,
          created_by: userId ? userId : undefined,
          city: values.city,
          // state: values.state,
          // country: values.country,

          // zip_code: values.zipcode,
          // category: values.category,
          // sub_category: values.subCategory,
          // division: values.division,
          location: [
            {
              state: values.state,
              county: values.country,
              region: regionName
            }
          ],
          is_firm: true
        };

        dispatch(registerUserThunkAction(requestBody, onSuccess));
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
        console.log(error?.response?.data?.message || error?.message);
      }
    }
  });

  console.log(formik.values);

  useEffect(() => {
    if (!verifiedEmail) {
      if (!isGovernmentFirmRegisterPage) {
        navigate("/auth/firm/verifyemail", {
          replace: true
        });
      }
    }
  }, [verifiedEmail]);

  const mapModalHandler = (data) => {
    setRegionData(data);
  };

  useEffect(() => {
    if (regionData.length > 0) {
      formik.setFieldValue("location", regionData);
    }
  }, [regionData]);

  useEffect(() => {
    GetCountries().then((result) => {
      setCountryOptions(result.map((item) => ({ value: item.id, label: item.name, ...item })));
    });
  }, []);

  return (
    <Fragment>
      <div className="register_container">
        <AuthLogo />
        <MapModal
          open={isOpen}
          oldData={oldData}
          closeModal={() => {
            setIsOpen(false);
          }}
          submit={mapModalHandler}
        />
        <div className="register_form mx-auto">
          <Row className="d-flex justify-content-between px-5 pt-4">
            <h3 className="register_form_title">
              Sign Up <span className="register_page_title">(Firm)</span>
            </h3>
            <div>
              <div className="have_an_account">
                Have an Account ?
                <Link
                  to={isGovernmentFirmRegisterPage ? "/auth/gov/firm/login" : "/auth/firm/login"}
                  style={{
                    textDecoration: "none",
                    color: "#1273EB",
                    marginLeft: "1rem",
                    fontWeight: "700"
                  }}
                >
                  Log in
                </Link>
              </div>
            </div>
          </Row>
          <form onSubmit={formik.handleSubmit} className="register_form_tag">
            <Row className="px-lg-5 px-md-4 px-3 py-3">
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="userName">
                    Username*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    id="userName"
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                  />
                  {formik.touched.userName && formik.errors.userName ? (
                    <div className="text-danger mt-2">{formik.errors.userName}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="firstName">
                    First Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-danger mt-2">{formik.errors.firstName}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="lastName">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    id="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-danger mt-2">{formik.errors.lastName}</div>
                  ) : null}
                </FormGroup>
              </Col>
              {/* <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="description">
                    Description*
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-danger mt-2">{formik.errors.description}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="jobTitle">
                    Job Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    id="jobTitle"
                    name="jobTitle"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.jobTitle}
                  />
                  {formik.touched.jobTitle && formik.errors.jobTitle ? (
                    <div className="text-danger mt-2">{formik.errors.jobTitle}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="companyName">
                    Company Name*
                    <span className="pl-2 company_name_label">
                      (as it appears in incorporation documents)
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company Name"
                    id="companyName"
                    name="companyName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="text-danger mt-2">{formik.errors.companyName}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="industries">
                    Industries
                  </label>
                  <Input
                    type="select"
                    className="form-control"
                    placeholder="Industries"
                    id="industries"
                    name="industries"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.industries || ""}
                    defaultChecked={false}
                  >
                    <option disabled value="">
                      Select Industries type
                    </option>

                    {industriesData.map((item) => {
                      return <option key={item}>{item}</option>;
                    })}
                  </Input>
                  {formik.touched.industries && formik.errors.industries ? (
                    <div className="text-danger mt-2">{formik.errors.industries}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="FEIN">
                    FEIN
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="FEIN"
                    id="FEIN"
                    name="FEIN"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.FEIN}
                  />
                  {formik.touched.FEIN && formik.errors.FEIN ? (
                    <div className="text-danger mt-2">{formik.errors.FEIN}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="yearFounded">
                    Year founded
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Year Founded"
                    id="yearFounded"
                    name="yearFounded"
                    onChange={(e) => {
                      if (Number(e.target.value) > -1) {
                        formik.setFieldValue("yearFounded", e.target.value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.yearFounded || ""}
                  />
                  {formik.touched.yearFounded && formik.errors.yearFounded ? (
                    <div className="text-danger mt-2">{formik.errors.yearFounded}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="phone">
                    Phone*
                    <span className="pl-2 company_name_label">
                      Required phone number format: (###) ###-####
                    </span>
                  </label>
                  <NumberFormat
                    className="form-control"
                    format="(###) ###-####"
                    mask="_"
                    placeholder="Phone"
                    id="phone"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />

                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger mt-2">{formik.errors.phone}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="email">
                    E-mail*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    name="email"
                    disabled={!!verifiedEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger mt-2">{formik.errors.email}</div>
                  ) : null}
                </FormGroup>
              </Col>
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="certificationType">
                    Certification Type(s)*
                  </label>
                  <Select
                    options={options}
                    isMulti
                    onChange={(e) => {
                      formik.setFieldValue(
                        "certificationType",
                        e.map((item) => item.value).toString()
                      );
                    }}
                    onBlur={() => formik.setFieldTouched("certificationType", true)}
                    styles={customStyles}
                  />
                  {formik.touched.certificationType && formik.errors.certificationType ? (
                    <div className="text-danger mt-2">{formik.errors.certificationType}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="ownerDemographics">
                    Owner Demographics
                  </label>
                  <Input
                    type="select"
                    className="form-control"
                    placeholder="Owner Demographics"
                    id="ownerDemographics"
                    name="ownerDemographics"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ownerDemographics || ""}
                  >
                    <option disabled value="">
                      Select Owner Demographics
                    </option>
                    <option>4846AFEWD432</option>
                  </Input>
                  {formik.touched.ownerDemographics && formik.errors.ownerDemographics ? (
                    <div className="text-danger mt-2">{formik.errors.ownerDemographics}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="NAICScode">
                    NAICS Code(s)
                    <a href="https://www.naics.com/search/" target="_blank" rel="noreferrer">
                      <span id="TooltipExample">
                        <Icon
                          icon="ep:info-filled"
                          color="black"
                          width="15"
                          height="15"
                          className="mb-2"
                        />
                      </span>

                      <Tooltip
                        isOpen={tooltipOpen}
                        placement="top"
                        target="TooltipExample"
                        toggle={() => {
                          setTooltipOpen(!tooltipOpen);
                        }}
                      >
                        <div className="text-center">
                          <span className="m-1">
                            For more Information visit : <b>www.naics.com/search</b>
                          </span>
                        </div>
                      </Tooltip>
                    </a>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="NAICS Code"
                    id="NAICScode"
                    name="NAICScode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.NAICScode}
                  />
                  {formik.touched.NAICScode && formik.errors.NAICScode ? (
                    <div className="text-danger mt-2">{formik.errors.NAICScode}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="race">
                    Gender
                  </label>
                  <Select
                    options={genderOptions}
                    onChange={(e) => {
                      formik.setFieldValue("gender", e.value);
                    }}
                    styles={customStyles}
                  />
                  {formik.touched.gender && formik.errors.gender ? (
                    <div className="text-danger mt-2">{formik.errors.gender}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="ethnicity">
                    Ethnicity
                  </label>
                  <Select
                    options={ethnicityoptions}
                    isMulti
                    onChange={(e) => {
                      setEthnicityValue(e.map((item) => item.value).toString());
                    }}
                    styles={customStyles}
                  />
                  {formik.touched.ethnicity && formik.errors.ethnicity ? (
                    <div className="text-danger mt-2">{formik.errors.ethnicity}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="division">
                    Division*
                  </label>
                  <Select
                    placeholder="choose division"
                    options={divisionOptions}
                    onChange={(e) => {
                      formik.setFieldValue("division", e.value);
                    }}
                    onBlur={() => formik.setFieldTouched("division", true)}
                    styles={customStyles}
                  />
                  {formik.touched.division && formik.errors.division ? (
                    <div className="text-danger mt-2">{formik.errors.division}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6" className="mt-3">
                <FormGroup>
                  <label className="form-control-label">
                    Choose State, County and Region via Map*
                  </label>
                  <Button
                    className="btn-block"
                    type="button"
                    size="lg"
                    onClick={() => {
                      setIsOpen(true);
                      setOldData(regionData);
                    }}
                    onBlur={() => formik.setFieldTouched("location", true)}
                  >
                    Click Here for Map
                  </Button>
                </FormGroup>
              </Col> */}
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="country">
                    Country*
                  </label>
                  <Select
                    options={countryOptions}
                    placeholder=""
                    isClearable={false}
                    isSearchable={true}
                    value={
                      formik.values.country !== ""
                        ? countryOptions.find((option) => option.label === formik.values.country)
                        : ""
                    }
                    onChange={(option) => {
                      formik.setFieldValue("state", "");
                      formik.setFieldValue("city", "");
                      if (option) {
                        GetState(option.value).then((result) => {
                          setStateOptions(
                            result.map((item) => ({ value: item.id, label: item.name }))
                          );
                        });
                        formik.setFieldValue("country", option.label);
                        setRegionName(option.region);
                        setCountryId(option.value);
                      } else {
                        setStateOptions([]);
                        setCityOptions([]);
                        formik.setFieldValue("state", "");
                        formik.setFieldValue("city", "");
                      }
                    }}
                    onBlur={() => formik.setFieldTouched("country", true)}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="state">
                    State*
                  </label>
                  <Select
                    options={stateOptions}
                    isClearable={false}
                    isSearchable={true}
                    placeholder=""
                    value={
                      formik.values.state !== ""
                        ? stateOptions.find((option) => option.label === formik.values.state)
                        : ""
                    }
                    onChange={(option) => {
                      formik.setFieldValue("city", "");
                      if (option) {
                        GetCity(countryId, option.value).then((result) => {
                          setCityOptions(
                            result.map((item) => ({ value: item.id, label: item.name }))
                          );
                        });
                        formik.setFieldValue("state", option ? option.label : "");
                      } else {
                        setCityOptions([]);
                        formik.setFieldValue("city", "");
                      }
                    }}
                    onBlur={() => formik.setFieldTouched("state", true)}
                    styles={customStyles}
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <div className="text-danger mt-2">{formik.errors.state}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="city">
                    City*
                  </label>
                  <Select
                    options={cityOptions}
                    placeholder=""
                    isClearable={false}
                    isSearchable={true}
                    onChange={(option) => {
                      formik.setFieldValue("city", option ? option.label : "");
                    }}
                    value={
                      formik.values.city !== ""
                        ? cityOptions.find((option) => option.label === formik.values.city)
                        : ""
                    }
                    onBlur={() => formik.setFieldTouched("city", true)}
                    styles={customStyles}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="text-danger mt-2">{formik.errors.city}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="region">
                    Region
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    placeholder="Region"
                    id="region"
                    name="region"
                    value={regionName}
                  />
                </FormGroup>
              </Col>

              {/* {regionData?.length > 0 ? (
                <>
                  <Col lg="6" className="">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="county">
                        County
                      </label>
                    </FormGroup>
                  </Col>

                  <Col lg="6" className="">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="region">
                        Region
                      </label>
                    </FormGroup>
                  </Col>
                </>
              ) : null} */}
              {/* {regionData?.map((item, index) => {   
                return (
                  <>
                    <Col lg="6" className="">
                      <FormGroup>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          placeholder="County"
                          id="county"
                          name="county"
                          value={item.countyName}
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          // value={formik.values.county}
                        />
                        {/* {formik.touched.county && formik.errors.county ? (
                      <div className="text-danger mt-2">{formik.errors.county}</div>
                    ) : null} */}
              {/* </FormGroup> */}
              {/* </Col> */}
              {/* <Col lg="6" className="">
                      <FormGroup>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          placeholder="Region"
                          id="region"
                          name="region"
                          value={item.regionName} */}
              {/* // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          // value={formik.values.region} */}
              {/* /> */}
              {/* {formik.touched.region && formik.errors.region ? (
                      <div className="text-danger mt-2">{formik.errors.region}</div>
                    ) : null} */}
              {/* </FormGroup>
                    </Col>
                  </> */}
              {/* );
              })} */}
              {/* {formik.touched.location && formik.errors.location ? (
                <Col lg="12">
                  <div className="text-danger mt-2">{formik.errors.location}</div>
                </Col>
              ) : null}{" "} */}

              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="city">
                    City*
                  </label>
                  <Select
                    options={cityOptions}
                    placeholder=""
                    onChange={(e) => {
                      formik.setFieldValue("city", e.value);
                    }}
                    onBlur={() => formik.setFieldTouched("city", true)}
                    styles={customStyles}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="text-danger mt-2">{formik.errors.city}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="zipcode">
                    Zipcode*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="zipcode"
                    id="zipcode"
                    name="zipcode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.zipcode}
                  />
                  {formik.touched.zipcode && formik.errors.zipcode ? (
                    <div className="text-danger mt-2">{formik.errors.zipcode}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="franchise">
                    Franchise
                  </label>

                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      value="yes"
                      name="franchise"
                      id="yes"
                      className="mr-2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.franchise === "yes"}
                    />
                    <label htmlFor="yes" className="mr-4 mt-2">
                      Yes
                    </label>
                    <input
                      type="radio"
                      value="no"
                      name="franchise"
                      id="no"
                      className="mr-2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.franchise === "no"}
                    />
                    <label htmlFor="no" className="mt-2">
                      No
                    </label>

                    {formik.values.franchise === "yes" && (
                      <input
                        type="text"
                        className="form-control ml-3"
                        placeholder="franchise"
                        id="franchise"
                        name="franchiseValue"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.franchiseValue}
                      />
                    )}
                  </div>
                  {formik.touched.franchiseValue && formik.errors.franchiseValue ? (
                    <div className="text-danger mt-2">{formik.errors.franchiseValue}</div>
                  ) : null}
                </FormGroup>
              </Col> */}
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="franchise">
                    Union member
                  </label>

                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      value="Yes"
                      name="unionMember"
                      id="unionyes"
                      className="mr-2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.unionMember === "Yes"}
                    />
                    <label htmlFor="unionyes" className="mr-4 mt-2">
                      Yes
                    </label>
                    <input
                      type="radio"
                      value="No"
                      name="unionMember"
                      id="unionno"
                      className="mr-2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.unionMember === "No"}
                    />
                    <label htmlFor="unionno" className="mt-2">
                      No
                    </label> */}

              {/* {formik.values.unionMember === "yes" && (
                      <input
                        type="text"
                        className="form-control ml-3"
                        placeholder="Union"
                        id="Union"
                        name="unionMemberValue"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.unionMemberValue}
                      />
                    )} */}
              {/* </div> */}

              {/* {formik.touched.unionMemberValue && formik.errors.unionMemberValue ? (
                    <div className="text-danger mt-2">{formik.errors.unionMemberValue}</div>
                  ) : null} */}
              {/* </FormGroup>
              </Col> */}
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="password">
                    Password*
                  </label>
                  <div className="position-relative">
                    <input
                      type={type1}
                      className="form-control"
                      placeholder="Password"
                      id="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {passwordShown1 ? (
                      <img
                        src={hidePassword}
                        alt="hide-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity1}
                      />
                    ) : (
                      <img
                        src={showPassword}
                        alt="show-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity1}
                      />
                    )}
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger mt-2">{formik.errors.password}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="confirmPassword">
                    Confirm Password*
                  </label>

                  <div className="position-relative">
                    <input
                      type={type2}
                      className="form-control"
                      placeholder="Confirm Password"
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                    />

                    {passwordShown2 ? (
                      <img
                        src={hidePassword}
                        alt="hide-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity2}
                      />
                    ) : (
                      <img
                        src={showPassword}
                        alt="show-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity2}
                      />
                    )}
                  </div>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="text-danger mt-2">{formik.errors.confirmPassword}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <input
                    type="checkbox"
                    name="sendEmail"
                    id="sendEmail"
                    className="mr-3"
                    checked={formik.values.sendEmail}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue("sendEmail", e.target.checked);
                    }}
                  />{" "}
                  <label className="form-control-label" htmlFor="sendEmail">
                    Send these credentials via email.
                  </label>
                </FormGroup>
              </Col>
              <Col lg="6">
                {process.env.REACT_APP_ENVIRONMENT === "production" ? (
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-cabinet-title">
                      Are You Human*
                    </label>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} ref={captchaRef} />
                  </FormGroup>
                ) : null}
              </Col>
            </Row>

            <Row>
              <button
                disabled={!formik.isValid || isSigning}
                className="btn register_btn mx-auto mb-4 mt-2"
                type="submit"
              >
                {isSigning ? "Registering..." : "Register"}
              </button>
            </Row>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default Register;
