import { useRef, Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";

import usePasswordShow from "hooks/usePasswordShow";

import showPassword from "../../assets/img/theme/show-password-icon.png";
import hidePassword from "../../assets/img/theme/hide-password-icon.png";
import AuthLogo from "components/AuthLogo/AuthLogo";

import "../Register/Register.css";
import { Col, FormGroup, Input, Row } from "reactstrap";
import { registerUserThunkAction } from "redux/user/actions";
import { selectUser } from "redux/user/selectors";
import userRoles from "utils/roles";
import axiosInstance from "../../services/axiosInstance";
import toast from "react-hot-toast";

function GovernmentRegister() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isGovernmentOTPPage = pathname.includes("government/register");

  const [type, setType] = useState("");

  const verifiedEmail = localStorage.getItem("verifiedEmail") || "";

  const options = [
    { value: "General Government", label: "General Government" },
    { value: "State Government", label: "State Government" },
    { value: "K-12", label: "K-12" },
    { value: "Higher Education", label: "Higher Education" }
  ];

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "black"
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "black"
    })
  };

  const { isSigning } = useSelector(selectUser);
  const captchaRef = useRef(null);

  const {
    passwordShown: passwordShown1,
    togglePasswordVisiblity: togglePasswordVisiblity1,
    type: type1
  } = usePasswordShow();
  const {
    passwordShown: passwordShown2,
    togglePasswordVisiblity: togglePasswordVisiblity2,
    type: type2
  } = usePasswordShow();

  const onSuccess = () => {
    localStorage.removeItem("verifiedEmail");
    navigate("/auth/government/login");
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      firstName: "",
      lastName: "",
      jobTitle: "",
      agencyName: "",
      FEIN: "",
      phone: "",
      email: verifiedEmail,
      agencyType: [],
      franchise: "",
      franchiseValue: "",
      confirmPassword: "",
      sendEmail: true,
      password: ""
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string()
        .trim()
        .required("Username is required.")
        .min(2, "Username must be at least 2 characters.")
        .max(26, "You can not add more then 26 characters.")
        .matches(/^[^ ]+$/, "You can not enter space in username."),
      firstName: Yup.string()
        .trim()
        .required("First Name is required.")
        .min(2, "First Name must be at least 2 characters.")
        .max(26, "You can not add more then 26 characters."),
      lastName: Yup.string()
        .trim()
        .required("Last Name is required.")
        .min(2, "Last Name must be at least 2 characters.")
        .max(26, "You can not add more then 26 characters."),
      jobTitle: Yup.string().trim().max(255, "You can not add more then 255 characters."),
      agencyName: Yup.string()
        .trim()
        .required("Agency Name is required.")
        .max(255, "You can not add more then 255 characters."),
      FEIN: Yup.string()
        .trim()
        .required("FEIN is required.")
        .max(255, "You can not add more then 255 characters."),
      phone: Yup.string()
        .required("Phone is required.")
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone number."),
      email: Yup.string().required("Email is required.").email("Invalid email address."),
      // franchise: Yup.string().trim(),
      // franchiseValue: Yup.string().when("franchise", {
      //   is: (value) => value === "yes",
      //   then: Yup.string().required("Franchise is required."),
      //   otherwise: Yup.string().notRequired()
      // }),
      password: Yup.string()
        .required("Password is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      confirmPassword: Yup.string()
        .required("Confirm Password is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .oneOf([Yup.ref("password"), null], "Passwords must match.")
    }),
    onSubmit: async (values) => {
      try {
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
          const token = captchaRef?.current?.getValue();
          captchaRef?.current?.reset();

          if (!token || token === "") {
            toast.error("Please complete the CAPTCHA to proceed with your registration.");
            return;
          }

          const { data } = await axiosInstance.post("/api/v1/auth/recaptcha", { token });

          if (data.statusCode !== 200) {
            throw new Error(data?.message);
          }
        }

        const requestBody = {
          username: values.userName,
          first_name: values.firstName,
          last_name: values.lastName,
          job_title: values.jobTitle,
          company: values.agencyName,
          fein: values.FEIN,
          phone: values.phone.replace("-", "").replace("(", "").replace(")", "").replace(" ", ""),
          email: values.email,
          agency_type: type,
          // franchise: values.franchise === "yes" ? values.franchiseValue : undefined,
          password: values.password,
          send_cred: values.sendEmail,
          role_id: userRoles.GOVT
        };

        dispatch(registerUserThunkAction(requestBody, onSuccess));
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
        console.log(error?.response?.data?.message || error?.message);
      }
    }
  });

  useEffect(() => {
    if (!verifiedEmail) {
      if (isGovernmentOTPPage) {
        navigate("/auth/government/verifyemail", {
          replace: true
        });
      }
    }
  }, [verifiedEmail]);

  return (
    <Fragment>
      <div className="register_container">
        <AuthLogo />
        <div className="register_form mx-auto">
          <Row className="d-flex justify-content-between px-5 pt-4">
            <h3 className="register_form_title">
              Sign Up{" "}
              <span className="register_page_title">
                {isGovernmentOTPPage ? "(Government Agency)" : ""}
              </span>
            </h3>
            <div>
              <div className="have_an_account">
                Have an Account ?
                <Link
                  to="/auth/government/login"
                  style={{
                    textDecoration: "none",
                    color: "#1273EB",
                    marginLeft: "1rem",
                    fontWeight: "700"
                  }}
                >
                  Log in
                </Link>
              </div>
            </div>
          </Row>
          <form onSubmit={formik.handleSubmit} className="register_form_tag">
            <Row className="px-lg-5 px-md-4 px-3 py-3">
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="userName">
                    Username*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    id="userName"
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                  />
                  {formik.touched.userName && formik.errors.userName ? (
                    <div className="text-danger mt-2">{formik.errors.userName}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="firstName">
                    First Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-danger mt-2">{formik.errors.firstName}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="lastName">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    id="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-danger mt-2">{formik.errors.lastName}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="jobTitle">
                    Job Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    id="jobTitle"
                    name="jobTitle"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.jobTitle}
                  />
                  {formik.touched.jobTitle && formik.errors.jobTitle ? (
                    <div className="text-danger mt-2">{formik.errors.jobTitle}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="agencyName">
                    Agency Name*
                    <span className="pl-2 company_name_label">
                      (as it appears on payment method)
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Agency Name"
                    id="agencyName"
                    name="agencyName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.agencyName}
                  />
                  {formik.touched.agencyName && formik.errors.agencyName ? (
                    <div className="text-danger mt-2">{formik.errors.agencyName}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="FEIN">
                    FEIN*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="FEIN"
                    id="FEIN"
                    name="FEIN"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.FEIN}
                  />
                  {formik.touched.FEIN && formik.errors.FEIN ? (
                    <div className="text-danger mt-2">{formik.errors.FEIN}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="phone">
                    Phone*
                    <span className="pl-2 company_name_label">
                      Required phone number format: (###) ###-####
                    </span>
                  </label>
                  <NumberFormat
                    className="form-control"
                    format="(###) ###-####"
                    mask="_"
                    placeholder="Phone"
                    id="phone"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />

                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger mt-2">{formik.errors.phone}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="email">
                    E-mail*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    name="email"
                    disabled={!!verifiedEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger mt-2">{formik.errors.email}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label">Agency Type</label>
                  <Select
                    options={options}
                    isMulti
                    onChange={(e) => {
                      setType(e.map((item) => item.value).toString());
                    }}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="franchise">
                    Franchise
                    <span className="pl-2 company_name_label">
                      (Part of a larger government entity?)
                    </span>
                  </label>

                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      value="yes"
                      name="franchise"
                      id="yes"
                      className="mr-2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.franchise === "yes"}
                    />
                    <label htmlFor="yes" className="mr-4 mt-2">
                      Yes
                    </label>
                    <input
                      type="radio"
                      value="no"
                      name="franchise"
                      id="no"
                      className="mr-2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.franchise === "no"}
                    />
                    <label htmlFor="no" className="mt-2">
                      No
                    </label>

                    {formik.values.franchise === "yes" && (
                      <input
                        type="text"
                        className="form-control ml-3"
                        placeholder="franchise"
                        id="franchise"
                        name="franchiseValue"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.franchiseValue}
                      />
                    )}
                  </div>
                  {formik.touched.franchiseValue && formik.errors.franchiseValue ? (
                    <div className="text-danger mt-2">{formik.errors.franchiseValue}</div>
                  ) : null}
                </FormGroup>
              </Col> */}

              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="password">
                    Password*
                  </label>
                  <div className="position-relative">
                    <input
                      type={type1}
                      className="form-control"
                      placeholder="Password"
                      id="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {passwordShown1 ? (
                      <img
                        src={hidePassword}
                        alt="hide-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity1}
                      />
                    ) : (
                      <img
                        src={showPassword}
                        alt="show-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity1}
                      />
                    )}
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger mt-2">{formik.errors.password}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="confirmPassword">
                    Confirm Password*
                  </label>

                  <div className="position-relative">
                    <input
                      type={type2}
                      className="form-control"
                      placeholder="Confirm Password"
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                    />

                    {passwordShown2 ? (
                      <img
                        src={hidePassword}
                        alt="hide-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity2}
                      />
                    ) : (
                      <img
                        src={showPassword}
                        alt="show-password"
                        className="position-absolute password_icon"
                        onClick={togglePasswordVisiblity2}
                      />
                    )}
                  </div>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="text-danger mt-2">{formik.errors.confirmPassword}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <input
                    type="checkbox"
                    name="sendEmail"
                    id="sendEmail"
                    className="mr-3"
                    checked={formik.values.sendEmail}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue("sendEmail", e.target.checked);
                    }}
                  />{" "}
                  <label className="form-control-label" htmlFor="sendEmail">
                    Send these credentials via email.
                  </label>
                </FormGroup>
              </Col>
              <Col lg="6">
                {process.env.REACT_APP_ENVIRONMENT === "production" ? (
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-cabinet-title">
                      Are You Human*
                    </label>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} ref={captchaRef} />
                  </FormGroup>
                ) : null}
              </Col>
            </Row>

            <Row>
              <button
                disabled={!formik.isValid || isSigning}
                className="btn register_btn mx-auto mb-4 mt-2"
                type="submit"
              >
                {isSigning ? "Registering..." : "Register"}
              </button>
            </Row>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default GovernmentRegister;
