import toast from "react-hot-toast";
import {
  FETCH_SUBSCRIPTION_PLAN_FAILURE,
  FETCH_SUBSCRIPTION_PLAN_SUCCESS,
  FETCH_SUBSCRIPTION_PLAN_REQUEST,
  FETCH_CURRENT_PLAN_FAILURE,
  FETCH_CURRENT_PLAN_SUCCESS,
  FETCH_CURRENT_PLAN_REQUEST,
  FETCH_BILLING_HISTORY_FAILURE,
  FETCH_BILLING_HISTORY_SUCCESS,
  FETCH_BILLING_HISTORY_REQUEST,
  FETCH_USER_PLAN_LIST_FAILURE,
  FETCH_USER_PLAN_LIST_SUCCESS,
  FETCH_USER_PLAN_LIST_REQUEST
} from "./types";

import {
  getSubscriptionPlanApi,
  updateSubscriptionPlanApi,
  CurrentPlanApi,
  BillingHistoryApi,
  getUserPlanListApi
} from "services/plansServices";

export const fetchSubscriptionPlanRequest = () => {
  return {
    type: FETCH_SUBSCRIPTION_PLAN_REQUEST
  };
};

export const fetchSubscriptionPlanSuccess = (Planslist) => {
  return {
    type: FETCH_SUBSCRIPTION_PLAN_SUCCESS,
    payload: { Planslist }
  };
};

export const fetchSubscriptionPlanFailure = (error) => {
  return {
    type: FETCH_SUBSCRIPTION_PLAN_FAILURE,
    payload: error
  };
};
export const fetchCurrentPlanRequest = () => {
  return {
    type: FETCH_CURRENT_PLAN_REQUEST
  };
};

export const fetchCurrentPlanSuccess = (Plandetail) => {
  return {
    type: FETCH_CURRENT_PLAN_SUCCESS,
    payload: { Plandetail }
  };
};

export const fetchCurrentPlanFailure = (error) => {
  return {
    type: FETCH_CURRENT_PLAN_FAILURE,
    payload: error
  };
};
export const fetchBillingHistoryRequest = () => {
  return {
    type: FETCH_BILLING_HISTORY_REQUEST
  };
};

export const fetchBillingHistorySuccess = (billingHistory) => {
  return {
    type: FETCH_BILLING_HISTORY_SUCCESS,
    payload: { billingHistory }
  };
};

export const fetchBillingHistoryFailure = (error) => {
  return {
    type: FETCH_BILLING_HISTORY_FAILURE,
    payload: error
  };
};

export const fetchUserPlanRequest = () => {
  return {
    type: FETCH_USER_PLAN_LIST_REQUEST
  };
};

export const fetchUserPlanSuccess = (UserPlanList) => {
  return {
    type: FETCH_USER_PLAN_LIST_SUCCESS,
    payload: { UserPlanList }
  };
};

export const fetchUserPlanFailure = (error) => {
  return {
    type: FETCH_USER_PLAN_LIST_FAILURE,
    payload: error
  };
};

export const fetchSubscriptionPlanThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchSubscriptionPlanRequest());
      const { data } = await getSubscriptionPlanApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchSubscriptionPlanSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchSubscriptionPlanFailure(error?.response?.data?.message || error?.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const updateSubscriptionPlanThunkAction = async (id, PlanData, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { data } = await updateSubscriptionPlanApi(id, PlanData);

      if (data.success !== true) {
        throw new Error(data.message);
      }

      toast.success("Plan updated successfully.");
      onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchCurrentPlanThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCurrentPlanRequest());
      const { data } = await CurrentPlanApi();

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchCurrentPlanSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchCurrentPlanFailure(error?.response?.data?.message || error?.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchBillingHistoryThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchBillingHistoryRequest());
      const { data } = await BillingHistoryApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchBillingHistorySuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchBillingHistoryFailure(error?.response?.data?.message || error?.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchUsersPlanListThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUserPlanRequest());
      const { data } = await getUserPlanListApi();

      if (data?.success !== true) {
        throw new Error(data?.message);
      }
      dispatch(fetchUserPlanSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchUserPlanFailure(error?.response?.data?.message || error?.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};
