import { getFirmsTotalChangesApi } from "services/firmsDashboardServices";
import { getAssessmentScoreByCategoryApi } from "services/firmsDashboardServices";
import { getAssessmentFirmsApi } from "services/firmsDashboardServices";
import {
  FETCH_ASSESSMENT_FIRMS_REQUEST,
  FETCH_ASSESSMENT_FIRMS_SUCCESS,
  FETCH_ASSESSMENT_FIRMS_FAILURE,
  FETCH_ASSESSMENT_SCORE_BY_CATEGORY_REQUEST,
  FETCH_ASSESSMENT_SCORE_BY_CATEGORY_SUCCESS,
  FETCH_ASSESSMENT_SCORE_BY_CATEGORY_FAILURE,
  FETCH_FIRMS_TOTAL_CHANGES_REQUEST,
  FETCH_FIRMS_TOTAL_CHANGES_SUCCESS,
  FETCH_FIRMS_TOTAL_CHANGES_FAILURE
} from "./types";

export const fetchAssessmentFirmsRequest = () => {
  return {
    type: FETCH_ASSESSMENT_FIRMS_REQUEST
  };
};

export const fetchAssessmentFirmsSuccess = (data) => {
  return {
    type: FETCH_ASSESSMENT_FIRMS_SUCCESS,
    payload: data
  };
};

export const fetchAssessmentFirmsFailure = (error) => {
  return {
    type: FETCH_ASSESSMENT_FIRMS_FAILURE,
    payload: error
  };
};

export const fetchAssessmentFirmsThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAssessmentFirmsRequest());
      const { data } = await getAssessmentFirmsApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchAssessmentFirmsSuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchAssessmentFirmsFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchAssessmentScoreByCategoryRequest = () => {
  return {
    type: FETCH_ASSESSMENT_SCORE_BY_CATEGORY_REQUEST
  };
};

export const fetchAssessmentScoreByCategorySuccess = (data) => {
  return {
    type: FETCH_ASSESSMENT_SCORE_BY_CATEGORY_SUCCESS,
    payload: data
  };
};

export const fetchAssessmentScoreByCategoryFailure = (error) => {
  return {
    type: FETCH_ASSESSMENT_SCORE_BY_CATEGORY_FAILURE,
    payload: error
  };
};

export const fetchAssessmentScoreByCategoryThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAssessmentScoreByCategoryRequest());
      const { data } = await getAssessmentScoreByCategoryApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchAssessmentScoreByCategorySuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchAssessmentScoreByCategoryFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchTotalChangesRequest = () => {
  return {
    type: FETCH_FIRMS_TOTAL_CHANGES_REQUEST
  };
};

export const fetchTotalChangesSuccess = (data) => {
  return {
    type: FETCH_FIRMS_TOTAL_CHANGES_SUCCESS,
    payload: data
  };
};

export const fetchTotalChangesFailure = (error) => {
  return {
    type: FETCH_FIRMS_TOTAL_CHANGES_FAILURE,
    payload: error
  };
};

export const fetchTotalChangesThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchTotalChangesRequest());
      const { data } = await getFirmsTotalChangesApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchTotalChangesSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchTotalChangesFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};
