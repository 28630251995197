import React from "react";

import { Card, CardBody, Col, Modal, ModalBody, Row } from "reactstrap";
import { FirmDetailThunkAction } from "redux/adminListofFirms/actions";
import { selectFirmsList } from "redux/adminListofFirms/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

function FirmsDetalModal({ toggleModal, isOpen, id }) {
  const [firmDetail, setFirmDetail] = React.useState(null);
  const dispatch = useDispatch();
  const { FirmDetail } = useSelector(selectFirmsList);

  React.useEffect(() => {
    if (id) {
      dispatch(FirmDetailThunkAction(id));
    }
  }, [id]);

  React.useEffect(() => {
    if (FirmDetail) {
      setFirmDetail(FirmDetail);
    }
  }, [FirmDetail]);

  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-xl"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h2 className="modal-title " id="modal-title-default">
                Firm Details
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>

            <ModalBody>
              <Row className="mt-12">
                <Col
                  lg="6"
                  xl="6"
                  style={{
                    padding: "0",
                    borderRightStyle: "dashed ",
                    borderRightWidth: "thin"
                  }}
                >
                  <Card
                    className="card-stats "
                    style={{
                      border: "none"
                    }}
                  >
                    <CardBody>
                      <Row>
                        <div className="col">
                          <span className="h5 font-weight-bold mb-0">User Info</span>
                        </div>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Username
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.username}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Name
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.name}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Job Title
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.job_title}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Phone
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.phone}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Email
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.email}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Union member
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.union_member ? "Yes" : "No"}</span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  lg="6"
                  xl="6"
                  style={{
                    padding: "0"
                  }}
                >
                  <Card
                    className="card-stats"
                    style={{
                      border: "none"
                    }}
                  >
                    <CardBody>
                      <Row>
                        <div className="col">
                          <span className="h5 font-weight-bold mb-0"> Compnay Info</span>
                        </div>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Company Name
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.company}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          FEIN
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.fein}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Year founded
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.year_founded}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Certification Type(s)
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.certification_type}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          NAICS Code(s)
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.naics_code}</span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>
                          <Icon icon="fluent:arrow-circle-right-28-filled" className="mr-2 mb-1" />
                          Franchise
                        </Col>
                        <Col className="text-right">
                          <span>{firmDetail?.franchise ? "Yes" : "No"}</span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </>
  );
}

export default FirmsDetalModal;
