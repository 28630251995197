import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import showDetails from "../../assets/img/theme/details_eye_icon.png";
import { useNavigate } from "react-router-dom";
import "./BidListingCard.css";
import moment from "moment";

function BidListingCard({ data, naics_code }) {
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState([]);

  const handleToggle = (index) => {
    setExpandedItems((prevExpanded) => {
      const isExpanded = prevExpanded.includes(index);
      return isExpanded
        ? prevExpanded.filter((itemIndex) => itemIndex !== index)
        : [...prevExpanded, index];
    });
  };

  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
  const numericRegex = /(\d+\.)|(\d+\))|(\d+\.\))|(\d+\.\s)|(\d+\)\s)|(\d+\.\)\s)|(\d+\))(\s)/;

  const formatTextWithLinks = (text) => {
    let urlTextLink = text.replace(urlRegex, (url) => {
      if (url.startsWith("www.")) {
        url = "http://" + url;
      }
      return ` <a href="${url}" target="_blank">${url}</a>`;
    });

    const decodedString = document.createElement("textarea");
    decodedString.innerHTML = urlTextLink;
    const formattedText = decodedString.value
      .replace(/•/g, "<br/>•")
      .replace(numericRegex, "<br/>$&");

    return formattedText;
  };

  // Create function NAICS code highlight
  const highlightNAICS = (data) => {
    const HighlightNAICSCode = naics_code.substring(1, naics_code.length - 1).split(",");
    const naicsCodes = data.substring(1, data.length - 1).split(",");

    return naicsCodes.map((code, index) => {
      if (HighlightNAICSCode.includes(code)) {
        return (
          <span
            key={index}
            style={{
              backgroundColor: "#EAF9F1",
              color: "#3DBD76",
              borderRadius: "4px",
              padding: "5px 10px",
              margin: "0 5px"
            }}
          >
            {code}
          </span>
        );
      }
      return (
        <span
          key={index}
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            margin: "0 5px"
          }}
        >
          {code}
        </span>
      );
    });
  };

  return (
    <Card className="bid_listing_card_container card-stats mb-4">
      <CardHeader className="bid_listing_card_header">
        <Row className="justify-content-between align-items-center mx-1">
          <Col sm="3">
            <h6 className="m-0 p-0">{data?.id}</h6>
          </Col>
          <Col sm="7" className="d-flex justify-content-between">
            <h6 className="m-0 p-0">{data?.contracttitle}</h6>
            <span style={{ color: "#B6D4F9" }}>|</span>
          </Col>
          <Col
            sm="2"
            className="d-flex align-items-center"
            style={{
              whiteSpace: "nowrap",
              columnGap: "0.5rem",
              color: "#1273EB",
              cursor: "pointer"
            }}
            onClick={() => navigate(`${data?.id}`)}
          >
            <h6 className="m-0 p-0">View Details</h6>
            <img
              src={showDetails}
              alt="hide-password"
              style={{
                width: "20px",
                height: "20px"
              }}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="justify-content-between align-items-center mx-1">
          <Col sm="3" className="">
            <h6 className="font_weight_700_title">Project number:</h6>
          </Col>
          <Col sm="9" className="">
            <h6 className="Agency_text_2">{data?.adid}</h6>
          </Col>
          <Col sm="3" className="mt-2">
            <h6 className="font_weight_700_title">Agency:</h6>
          </Col>
          <Col sm="9" className="mt-2 mt-2">
            <Row>
              <Col sm="3" className="Agency_text">
                Agency Type:{" "}
              </Col>{" "}
              <Col sm="8" className="Agency_text_2">
                {data?.agencytype}
              </Col>
              <Col sm="3" className="Agency_text">
                Contact Person:{" "}
              </Col>{" "}
              <Col sm="8" className="Agency_text_2">
                {data?.contacts[0]?.first_name} {data?.contacts[0]?.last_name}
              </Col>
              <Col sm="3" className="Agency_text">
                Email:{" "}
              </Col>{" "}
              <Col sm="8" className="Agency_text_2">
                {data?.contacts[0]?.email}
              </Col>
              <Col sm="3" className="Agency_text">
                Phone number:{" "}
              </Col>{" "}
              <Col sm="8" className="Agency_text_2">
                {data?.contacts[0]?.phone}
              </Col>
            </Row>
          </Col>
          <Col sm="3" className="mt-2">
            <h6 className="font_weight_700_title">Bid release date</h6>
          </Col>
          <Col sm="9" className="mt-2">
            <span className="Agency_text_2">{moment(data?.issuedate).format("MM/DD/YYYY")}</span>
          </Col>
          <Col sm="3" className="mt-2">
            <h6 className="font_weight_700_title">Bid due date/ Time</h6>
          </Col>
          <Col sm="9" className="mt-2">
            <span className="Agency_text_2">
              {moment(data?.duedatetime).format("MM/DD/YYYY hh:mm A")}
            </span>
          </Col>

          <Col sm="3" className="mt-2">
            <h6 className="font_weight_700_title">Location:</h6>
          </Col>
          <Col sm="9" className="mt-2">
            <span className="Agency_text_2">{data?.jobsite}</span>
          </Col>
          <Col sm="3" className="mt-2">
            <h6 className="font_weight_700_title">Generated Naics Codes:</h6>
          </Col>
          <Col sm="9" className="mt-2">
            <span className="Agency_text_2">
              {data?.generated_naics_codes ? highlightNAICS(data?.generated_naics_codes) : "___"}
            </span>
          </Col>
          <Col sm="3" className="mt-2">
            <h6 className="font_weight_700_title">Description:</h6>
          </Col>
          <Col sm="9" className="mt-2">
            <div className="Agency_text_2 text-justify">
              {expandedItems.includes(data?.id) ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatTextWithLinks(data.description)
                  }}
                />
              ) : (
                // data.description
                // show full description if expanded
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatTextWithLinks(data.description.slice(0, 1000) + "...")
                  }}
                />
              )}{" "}
              {/* show truncated description */}
              <span
                className="text-primary"
                style={{
                  cursor: "pointer"
                }}
                onClick={() => handleToggle(data?.id)}
              >
                {expandedItems.includes(data?.id) ? "Read Less" : "Read More"}
              </span>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default BidListingCard;
