import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AES, enc } from "crypto-js";

import { fetchLoginUserThunkAction } from "redux/user/actions";
import usePasswordShow from "hooks/usePasswordShow";

import showPassword from "../../assets/img/theme/show-password-icon.png";
import hidePassword from "../../assets/img/theme/hide-password-icon.png";

import "./FirmLogin.css";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import AuthLogo from "components/AuthLogo/AuthLogo";
import { selectUser } from "redux/user/selectors";
import useViewport from "hooks/useViewport";

function FirmLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useViewport();
  const { pathname } = useLocation();
  const isGovernmentLoginPage = pathname.includes("government");

  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();

  const token = localStorage.getItem("token");
  const remember = localStorage.getItem("isRemember");

  const [isRemember, setIsRemember] = useState(remember?.toLowerCase() === "true" || false);
  const { isSigning } = useSelector(selectUser);

  const navigateToHome = () => {
    localStorage.setItem("isRemember", isRemember);

    if (isRemember) {
      const envryptedEmail = AES.encrypt(
        formik.values.userName,
        process.env.REACT_APP_DEV_CRYPTO_HASH
      ).toString();
      const envryptedPassword = AES.encrypt(
        formik.values.password,
        process.env.REACT_APP_DEV_CRYPTO_HASH
      ).toString();
      localStorage.setItem("username", envryptedEmail);
      localStorage.setItem("password", envryptedPassword);
    }
    // navigate("/");
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required")
      // .min(6)
    }),
    onSubmit: (values) => {
      dispatch(
        fetchLoginUserThunkAction(
          { username: values.userName, password: values.password },
          navigateToHome
        )
      );
    }
  });

  useEffect(() => {
    async function getUser() {
      if (remember?.toLowerCase() === "true") {
        await formik.setFieldValue(
          "userName",
          AES.decrypt(
            localStorage.getItem("username")?.toString(),
            process.env.REACT_APP_DEV_CRYPTO_HASH
          )?.toString(enc.Utf8)
        );
        await formik.setFieldValue(
          "password",
          AES.decrypt(
            localStorage.getItem("password")?.toString(),
            process.env.REACT_APP_DEV_CRYPTO_HASH
          )?.toString(enc.Utf8)
        );
        // formik.submitForm();
      }
    }

    getUser();
  }, [remember]);

  return (
    <>
      <div className="bg_firm">
        <AuthLogo />
        <div className="firm_login_container">
          <Row
            className="mt-4 d-flex  justify-content-around"
            style={{
              margin: "0"
            }}
          >
            <Col className="mx-4 firm_login_form" lg="6 " xl="3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{
                  height: "600px",
                  border: "none",

                  backgroundColor: "#fff"
                }}
              >
                <CardHeader
                  style={{ backgroundColor: "#fff", border: "none" }}
                  className="justify-content-between"
                >
                  <Row className="justify-content-between text-capitalize">
                    <Col>
                      <h2 className="login_page_title">
                        {isGovernmentLoginPage ? "Government Agency" : "Firm"}
                      </h2>
                      <h2
                        style={{
                          fontWeight: "600"
                        }}
                        // className={width < 500 ? ""}
                      >
                        Existing Users
                      </h2>
                    </Col>
                  </Row>
                </CardHeader>
                <form onSubmit={formik.handleSubmit} className="">
                  <CardBody className="pt-0">
                    <Row
                      style={{
                        backgroundColor: "#fff"
                      }}
                      className="rounded-bottom"
                    >
                      <Col className="my-2 existing_user_text">
                        If you already have an account, please enter your information below.
                      </Col>
                    </Row>
                    <Row className="mt-12">
                      <Col className="mt-4">
                        <FormGroup>
                          <label
                            className="form-control-label firm_login_label_text mb-3"
                            htmlFor="userName"
                          >
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control p-4 firm_login_input"
                            placeholder="Enter Username"
                            id="userName"
                            name="userName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.userName}
                          />
                          {formik.touched.userName && formik.errors.userName ? (
                            <div className="text-danger mt-2">{formik.errors.userName}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-12">
                      <Col className="mt-4">
                        <FormGroup>
                          <label
                            className="form-control-label firm_login_label_text mb-3"
                            htmlFor="userName"
                          >
                            Password
                          </label>
                          <div className="position-relative">
                            <input
                              type={type}
                              className="form-control p-4 firm_login_input"
                              placeholder="Enter Password"
                              id="password"
                              name="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                            />
                            {passwordShown ? (
                              <img
                                src={hidePassword}
                                alt="hide-password"
                                className="position-absolute password_icon"
                                onClick={togglePasswordVisiblity}
                              />
                            ) : (
                              <img
                                src={showPassword}
                                alt="show-password"
                                className="position-absolute password_icon"
                                onClick={togglePasswordVisiblity}
                              />
                            )}
                          </div>

                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger mt-2">{formik.errors.password}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col lg="6" xl="6" className="mt-3">
                        <FormGroup>
                          <input
                            type="checkbox"
                            name="remember"
                            id="sendEmail"
                            className="mr-3"
                            checked={isRemember}
                            onChange={(e) => {
                              setIsRemember(e.target.checked);
                            }}
                          />
                          <label className="form-control-label" htmlFor="sendEmail">
                            Remember me
                          </label>
                        </FormGroup>
                      </Col>
                      <Col lg="6 text-right" xl="6" className="mt-3">
                        <NavLink
                          to="/auth/forgotpassword"
                          tag={Link}
                          className="nav-link-inner--text"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          Forgot Password?
                        </NavLink>
                      </Col>
                    </Row>
                    <Row className="mx-6 mt-3">
                      <Button
                        sm="6"
                        disabled={!formik.isValid || isSigning}
                        className="login_btn d-flex align-items-center justify-content-center flex-grow-1  py-2 "
                        color="primary"
                        type="submit"
                      >
                        {isSigning ? "Logging in..." : "Login"}
                      </Button>
                    </Row>
                  </CardBody>
                </form>
              </Card>
            </Col>
            <Col className="mx-4 mt-3 firm_form_details" lg="6" xl="5">
              <Card
                className="card-stats mb-xl-0"
                style={{
                  border: "none",
                  borderRadius: "1rem",
                  backgroundColor: "#283046"
                }}
              >
                <CardHeader
                  style={{ backgroundColor: "#283046", alignSelf: "center", border: "none" }}
                  className="justify-content-between text-center mt-3"
                >
                  <Row className="justify-content-between  text-capitalize">
                    <h2
                      style={{
                        fontWeight: "700"
                      }}
                      className="text-light mt-4 mb-4"
                    >
                      New Users
                    </h2>
                  </Row>
                </CardHeader>
                <CardBody className="text-light pt-0 ">
                  <Row className="mx-6 mt-3">
                    <p>
                      ALacriti Analytics provides users with a comprehensive analysis of their
                      business&lsquo;s strengths, areas for improvement and personalized
                      recommendation plans to increase capacity and success in providing your goods
                      and services to government agencies.
                    </p>
                    <p>
                      If you have received an invitation from a government agency, please follow the
                      instructions in the email or SMS invitation. If you have not, please join us
                      by creating an account.
                    </p>

                    <Button
                      className="firm_register_btn my-5"
                      onClick={() => {
                        if (isGovernmentLoginPage) {
                          navigate("/auth/government/verifyemail");
                        } else {
                          navigate("/auth/firm/verifyemail");
                        }
                      }}
                    >
                      Create An Account
                    </Button>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default FirmLogin;
