import {
  FETCH_GOVERNMENT_LIST_REQUEST,
  FETCH_GOVERNMENT_LIST_SUCCESS,
  FETCH_GOVERNMENT_LIST_FAILURE,
  RESET_GOVERNMENT_LIST_DATA
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  governmentlist: [],
  totalPage: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GOVERNMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_GOVERNMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        governmentlist: action.payload.governmentlist,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_GOVERNMENT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        governmentlist: [],
        error: action.payload
      };
    case RESET_GOVERNMENT_LIST_DATA:
      return {
        ...state,
        governmentlist: [],
        totalPage: 0
      };
    default:
      return state;
  }
};

export default reducer;
