import {
  FETCH_ALL_ASSESSMENT_LIST_REQUEST,
  FETCH_ALL_ASSESSMENT_LIST_SUCCESS,
  FETCH_ALL_ASSESSMENT_LIST_FAILURE,
  RESET_ALL_ASSESSMENT_LIST_DATA
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  allassessmentlist: [],
  totalPage: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_ASSESSMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ALL_ASSESSMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allassessmentlist: action.payload.allassessmentlist,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_ALL_ASSESSMENT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        allassessmentlist: [],
        error: action.payload
      };
    case RESET_ALL_ASSESSMENT_LIST_DATA:
      return {
        ...state,
        allassessmentlist: [],
        totalPage: 0
      };
    default:
      return state;
  }
};

export default reducer;
