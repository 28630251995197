import {
  FETCH_BID_LIST_REQUEST,
  FETCH_BID_LIST_SUCCESS,
  FETCH_BID_LIST_FAILURE,
  FETCH_BID_DETAILS_REQUEST,
  FETCH_BID_DETAILS_SUCCESS,
  FETCH_BID_DETAILS_FAILURE
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  bidList: [],
  bidDetails: {},
  totalPage: 0,
  naics_code: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BID_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_BID_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bidList: action.payload.listData,
        totalPage: action.payload.totalPage,
        naics_code: action.payload.naics_code,
        error: ""
      };
    case FETCH_BID_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        bidList: [],
        error: action.payload
      };
    case FETCH_BID_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_BID_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bidDetails: action.payload,
        error: ""
      };
    case FETCH_BID_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        bidDetails: {},
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
