import toast from "react-hot-toast";

import {
  FETCH_ADMIN_FILES_DATA_REQUEST,
  FETCH_ADMIN_FILES_DATA_SUCCESS,
  FETCH_ADMIN_FILES_DATA_FAILURE,
  FETCH_FIRMS_FILES_LISTING_DATA_REQUEST,
  FETCH_FIRMS_FILES_LISTING_DATA_SUCCESS,
  FETCH_FIRMS_FILES_LISTING_DATA_FAILURE
} from "./types";
import { getFileListingApi } from "services/adminfileManagerService";
import { getFirmsFilesListingApi } from "services/adminfileManagerService";
import { postFirmsFilesApi } from "services/adminfileManagerService";
import { postDownloadFirmsFilesApi } from "services/adminfileManagerService";

export const fetchFilesRequest = () => {
  return {
    type: FETCH_ADMIN_FILES_DATA_REQUEST
  };
};

export const fetchFilesSuccess = (data, page_count, totalRecords) => {
  return {
    type: FETCH_ADMIN_FILES_DATA_SUCCESS,
    payload: { data, page_count, totalRecords }
  };
};

export const fetchFilesFailure = (error) => {
  return {
    type: FETCH_ADMIN_FILES_DATA_FAILURE,
    payload: error
  };
};

export const fetchFilesListingThunkAction = (reqParams, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchFilesRequest());
      const response = await getFileListingApi(reqParams);

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(
        fetchFilesSuccess(
          response?.data?.data?.data,
          Math.ceil(
            response?.data?.data?.meta?.total_records / response?.data?.data?.meta?.records_per_page
          ),
          response?.data?.data?.meta?.total_records
        )
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchFilesFailure(error?.response?.data?.message || error?.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchFirmsFilesListingRequest = () => {
  return {
    type: FETCH_FIRMS_FILES_LISTING_DATA_REQUEST
  };
};

export const fetchFirmsFilesListingSuccess = (data, username) => {
  return {
    type: FETCH_FIRMS_FILES_LISTING_DATA_SUCCESS,
    payload: { data, username }
  };
};

export const fetchFirmsFilesListingFailure = (error) => {
  return {
    type: FETCH_FIRMS_FILES_LISTING_DATA_FAILURE,
    payload: error
  };
};

export const fetchFirmsFilesListingThunkAction = (reqParams, firmId, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchFirmsFilesListingRequest());
      const response = await getFirmsFilesListingApi(reqParams, firmId);

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      dispatch(
        fetchFirmsFilesListingSuccess(
          response?.data?.data?.userFiles,
          response?.data?.data?.username
        )
      );

      onSuccess();
    } catch (error) {
      dispatch(fetchFirmsFilesListingFailure(error?.response?.data?.message || error?.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const postFirmsFilesThunkAction = (postData, firmId, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      const promise = postFirmsFilesApi(postData, firmId);

      toast.promise(promise, {
        loading: "Loading...",
        success: "File uploaded successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const response = await promise;

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      onSuccess();
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
};

export const downloadFirmsFilesThunkAction = (postData, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      toast.success("Download Started.");
      const response = await postDownloadFirmsFilesApi(postData);

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/zip" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${postData?.username}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      console.log(error?.response?.data?.message || error?.message);
    }
  };
};
