import toast from "react-hot-toast";
import { postRegisterUserApi } from "services/userServices";
import { postVerifyOTPApi } from "services/userServices";
import { postSendEmailApi } from "services/userServices";
import { postLoggedOutUserApi } from "services/userServices";
import {
  postLoggedInUserApi,
  postForgotPasswordUserApi,
  postResetPasswordUserApi,
  getUsersProfileDetailApi,
  updateUserProfileAPI
} from "services/userServices";

import {
  REFRESH_STATE,
  RESET_IS_SIGNING,
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
  SET_FORGET_PASSWORD_USER_EMAIL,
  RESET_FORGET_PASSWORD_USER_EMAIL,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  SET_USER_PROFILE_PERCENTAGE
} from "./types";

export const setLoggedInUser = ({ user, token, role }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { user, token, role }
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER
  };
};

export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING
  };
};
export const setForgetpasswordUserEmail = () => {
  return {
    type: SET_FORGET_PASSWORD_USER_EMAIL
  };
};
export const resetForgetpasswordUserEmail = () => {
  return {
    type: RESET_FORGET_PASSWORD_USER_EMAIL
  };
};

export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING
  };
};

export const refreshState = ({ token, user, role }) => ({
  type: REFRESH_STATE,
  payload: { token, user, role }
});

export const fetchUsersProfileRequest = () => {
  return {
    type: FETCH_USER_PROFILE_REQUEST
  };
};

export const fetchUsersProfileSuccess = (users) => {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: { users }
  };
};

export const fetchUsersProfileFailure = (error) => {
  return {
    type: FETCH_USER_PROFILE_FAILURE,
    payload: error
  };
};

export const registerUserThunkAction = (user, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());

      const promise = postRegisterUserApi(user);

      toast.promise(promise, {
        loading: "Loading...",
        success: "User registered successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      console.log(error?.message || error?.response?.data?.message);
    }
  };
};

export const fetchLoginUserThunkAction = ({ username, password }, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());

      const { data } = await postLoggedInUserApi({ username: username, password });

      if (data.success !== true) {
        throw new Error(data.message);
      }

      toast.success("Logged in successfully.");
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("role", data.data.user.role_id);
      localStorage.setItem("user", JSON.stringify(data.data.user));
      dispatch(
        setLoggedInUser({
          user: data.data.user,
          token: data.data.token,
          role: data.data.user.role_id
        })
      );
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const loggingOutUserThunkAction = (onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await postLoggedOutUserApi();

      if (data.success !== true) {
        throw new Error(data.message);
      }

      const userRole = localStorage.getItem("role");

      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("user");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("isRemember");
      dispatch(setLoggedOutUser());
      toast.success("Logged out successfully.");
      onSuccess(Number(userRole));
    } catch (error) {
      localStorage.removeItem("token");
      dispatch(setLoggedOutUser());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const forgetPasswordEmailThunkAction = (username, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setForgetpasswordUserEmail());
      const { data } = await postForgotPasswordUserApi({
        username: username
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success(data.message);
      onSuccess();
      dispatch(resetForgetpasswordUserEmail());
    } catch (error) {
      dispatch(resetForgetpasswordUserEmail());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const ResetPasswordUserApiThunkAction = (token, { password, email }, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setForgetpasswordUserEmail());
      const { data } = await postResetPasswordUserApi(token, { password, email });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success(data.message);
      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      dispatch(resetForgetpasswordUserEmail());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchUsersProfileDetailThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUsersProfileRequest());
      const { data } = await getUsersProfileDetailApi();

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }
      dispatch(fetchUsersProfileSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchUsersProfileFailure(error.message));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const updateUserProfileThunkAction = async (UserData, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { data } = await updateUserProfileAPI(UserData);

      if (data.success !== true) {
        throw new Error(data.message);
      }

      toast.success("Profile updated successfully.");
      onSuccess();
    } catch (error) {
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const verifyEmailThunkAction = ({ email }, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const promise = postSendEmailApi({
        email
      });

      toast.promise(promise, {
        loading: "Loading...",
        success: ({ data }) => {
          return `${data.message}`;
        },
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }

      onSuccess(email);
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      console.log(error?.message || error?.response?.data?.message);
    }
  };
};

export const verifyOTPThunkAction = ({ otp, email }, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const { data } = await postVerifyOTPApi({
        email,
        otp
      });

      if (data.success !== true) {
        throw new Error(data.message);
      }

      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const profilePercentageThunkAction = (profilePercentage) => {
  return async (dispatch) => {
    dispatch({
      type: SET_USER_PROFILE_PERCENTAGE,
      payload: profilePercentage
    });
  };
};
