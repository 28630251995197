import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import userRoles from "utils/roles";
import { useDispatch, useSelector } from "react-redux";
import { selectUserRole } from "redux/user/selectors";
import { startNewAssessmentThunkAction } from "redux/firmsListofAssessment/actions";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

function GovernmentStartSurvey() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const NavigateTo = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/add/Assessment";
      case userRoles.GOVT:
        return "/gov/add/Assessment";
      case userRoles.FIRMS:
        return "/firms/add/Assessment";
      case userRoles.GOVT_FIRMS:
        return "/govt-firms/add/Assessment";
      default:
        return "/dashboard";
    }
  };

  const onSuccess = () => {
    navigate(`${NavigateTo()}`, {
      replace: true
    });
  };

  const onError = (error) => {
    Swal.fire({
      position: "center",
      icon: "warning",
      html: `<h5>${error || "something went wrong"}</h5>`,
      showConfirmButton: false,
      timer: 1400
    });
  };

  const formik = useFormik({
    initialValues: {
      agreeTermsConditions: false
    },
    validationSchema: Yup.object().shape({
      agreeTermsConditions: Yup.bool().required().isTrue("must be true")
    }),
    onSubmit: (values) => {
      dispatch(startNewAssessmentThunkAction(onSuccess, onError));
    }
  });

  return (
    <>
      <div className="px-3">
        <div className="d-flex justify-content-between my-2">
          <h4>Start Assessment</h4>
        </div>

        <Form role="form" onSubmit={formik.handleSubmit}>
          <Row className="mt-4">
            <Col sm={12} className={`content active-content`}>
              <Card
                className="card-stats mb-xl-0"
                style={{
                  borderRadius: "0.5rem"
                }}
              >
                <CardHeader
                  style={{
                    borderRadius: "0.5rem"
                  }}
                >
                  <Row className="justify-content-start">
                    <Col>Terms and Conditions</Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={12} md={12} className="mt-2">
                      <div>
                        <p
                          style={{
                            fontWeight: "500"
                          }}
                        >
                          Agreement To Terms
                        </p>
                      </div>
                      <div className="mt-2">
                        You agree that by accessing the Site, you have read, understood, and agreed
                        to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF
                        THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE
                        AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                      </div>
                      <div className="mt-3">
                        Supplemental terms and conditions or documents that may be posted on the
                        Site from time to time are hereby expressly incorporated herein by
                        reference. We reserve the right, in our sole discretion, to make changes or
                        modifications to these Terms of Use at any time and for any reason. We will
                        alert you about any changes by updating the “Last updated” date of these
                        Terms of Use, and you waive any right to receive specific notice of each
                        such change. Please ensure that you check the applicable Terms every time
                        you use our Site so that you understand which Terms apply. You will be
                        subject to, and will be deemed to have been made aware of and to have
                        accepted, the changes in any revised Terms of Use by your continued use of
                        the Site after the date such revised Terms of Use are posted.
                      </div>
                      <div className="mt-3">
                        The Site is intended for users who are at least 18 years old. Persons under
                        the age of 18 are not permitted to use or register for the Site.
                      </div>

                      <div className="mt-3">
                        <Col className="px-0">
                          <FormGroup>
                            <input
                              type="checkbox"
                              name="Agree"
                              id="agreeTermsConditions"
                              className="mr-3"
                              checked={formik.values.agreeTermsConditions}
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                formik.setFieldValue("agreeTermsConditions", e.target.checked);
                              }}
                            />{" "}
                            <label className="form-control-label" htmlFor="agreeTermsConditions">
                              <b>You agree to our Terms and conditions and Privacy Policy.</b>
                            </label>
                          </FormGroup>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Col className="mt-3 mx-0 px-0 d-flex justify-content-between">
                <Button color="danger" className="px-5 py-2" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className=" px-3 py-2"
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty}
                >
                  Start Assessment
                </Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default GovernmentStartSurvey;
