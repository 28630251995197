import {
  FETCH_FIRMS_LIST_REQUEST,
  FETCH_FIRMS_LIST_SUCCESS,
  FETCH_FIRMS_LIST_FAILURE,
  RESET_FIRMS_LIST_DATA,
  SELECTED_FIRM
} from "./types";

import { getFirmsListApi, getFirmDetailsApi } from "../../services/adminListofFirms.js";
import toast from "react-hot-toast";

export const fetchFirmsListRequest = () => {
  return {
    type: FETCH_FIRMS_LIST_REQUEST
  };
};

export const fetchFirmsListSuccess = (firmslist, totalPage) => {
  return {
    type: FETCH_FIRMS_LIST_SUCCESS,
    payload: { firmslist, totalPage }
  };
};

export const fetchFirmsListFailure = (error) => {
  return {
    type: FETCH_FIRMS_LIST_FAILURE,
    payload: error
  };
};

export const resetFirmsList = () => {
  return {
    type: RESET_FIRMS_LIST_DATA
  };
};
export const selectedFirm = (Firm) => {
  return {
    type: SELECTED_FIRM,
    payload: Firm
  };
};

export const fetchFirmsListThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchFirmsListRequest());
      const { data } = await getFirmsListApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data?.message);
      }

      dispatch(
        fetchFirmsListSuccess(data?.data?.data, data?.data?.meta?.total_records / params?.limit)
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchFirmsListFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const FirmDetailThunkAction = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await getFirmDetailsApi(id);

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      dispatch(selectedFirm(data?.data));
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};
