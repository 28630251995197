import React from "react";
import ReactApexChart from "react-apexcharts";

class HeatMapChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          // name: "Series 1",
          data: this.props.data
        }
      ],
      options: {
        chart: {
          height: this.props.width ? 140 : 350,
          type: "radar",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true
        },
        plotOptions: {
          radar: {
            size: this.props.width ? 140 : 240,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: [
                  "#08BE0C",
                  "#31CC00",
                  "#96DC00",
                  "#ECC600",
                  "#E0AF00",
                  "#DC8400",
                  "#F37500",
                  "#F86411",
                  "#FF3D00",
                  "#E22400"
                ]
              }
            }
          }
        },
        colors: ["#1273EB"],
        markers: {
          size: 4,
          colors: ["#000"],
          strokeColor: "#1273EB",
          strokeWidth: 2
        },
        tooltip: {
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => ""
            }
          }
        },
        xaxis: {
          categories: this.props.categories,
          position: "bottom",
          labels: {
            show: true,

            style: {
              colors: ["#000", "#000", "#000", "#000", "#000", "#000", "#000"],
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 500
            }
          }
        },
        yaxis: {
          tickAmount: 10,
          decimalsInFloat: 2,
          max: 10,
          min: 0,
          labels: {
            formatter: function (val, i) {
              return val.toFixed(2);
            },
            style: {
              fontSize: "0.7rem",
              fontWeight: "normal",
              fontFamily: "Montserrat, sans-serif"
            }
          }
        }
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.width !== prevProps.width) {
      this.setState({
        options: {
          chart: {
            height: this.props.width ? 140 : 350
          },
          plotOptions: {
            radar: {
              size: this.props.width ? 140 : 240
            }
          }
        }
      });
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radar"
          height={this.props.width ? 350 : 550}
        />
      </div>
    );
  }
}

export default HeatMapChart;
