import React from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";

function BidCard({ cardColor, borderColor, count, titleColor, countColor, children }) {
  return (
    <Card
      className="card-stats mb-4 mb-xl-0"
      style={{
        backgroundColor: cardColor,
        minHeight: "50px",
        borderColor: borderColor
      }}
    >
      <CardBody>
        <Row>
          <div className="col d-flex justify-content-between align-items-center">
            <CardTitle
              tag="h6"
              className="text-capitalize mb-0"
              style={{
                color: titleColor
              }}
            >
              {children}
            </CardTitle>
            <span
              className="h2 font-weight-bold mb-0 text-capitalize"
              style={{ color: countColor }}
            >
              {count}
            </span>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
}

export default BidCard;
