import { useState, useEffect, Fragment } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import UncontrolledDropDown from "components/UncontrolledDropDown/UncontrolledDropDown";
import "./FileManager.css";
import AddImage from "components/Modals/AddImage";
import { postFileManagerThunkAction } from "redux/fileManager/actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchFileListingThunkAction } from "redux/fileManager/actions";
import { selectFileManager } from "redux/fileManager/selectors";
import { selectUserToken } from "redux/user/selectors";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { getFileType } from "utils/roles";
import toast from "react-hot-toast";
import { deleteFileManagerThunkAction } from "redux/fileManager/actions";
import { Icon } from "@iconify/react";
import { Tooltip } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { fetchFirmsFilesListingThunkAction } from "redux/adminfileManager/actions";
import { selectAdminFileManager } from "redux/adminfileManager/selectors";
import { postFirmsFilesThunkAction } from "redux/adminfileManager/actions";
import { downloadFirmsFilesThunkAction } from "redux/adminfileManager/actions";

function FirmsFileManager() {
  const dispatch = useDispatch();
  const { id: firmId } = useParams();
  const navigate = useNavigate();
  const { firmsFileManagerData, isLoading, firmUserName } = useSelector(selectAdminFileManager);
  const token = useSelector(selectUserToken) || localStorage.getItem("token");

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [date, setDate] = useState({
    end_date: "",
    start_date: ""
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  useEffect(() => {
    if (token && firmId) {
      dispatch(
        fetchFirmsFilesListingThunkAction(
          {
            start_date: date?.start_date,
            end_date: date?.end_date
          },
          firmId,
          onSuccess,
          onError
        )
      );
    }
  }, [token, date, firmId]);

  useEffect(() => {
    setFileData(firmsFileManagerData);
  }, [firmsFileManagerData]);

  const addNewImageHandler = (files = []) => {
    const requestBodyFormData = new FormData();

    files?.map((item) => {
      requestBodyFormData.append("files", item);
    });

    dispatch(
      postFirmsFilesThunkAction(requestBodyFormData, firmId, () => {
        dispatch(
          fetchFirmsFilesListingThunkAction(
            {
              start_date: date?.start_date,
              end_date: date?.end_date
            },
            firmId,
            onSuccess,
            onError
          )
        );
      })
    );
  };

  const downloadFileHandle = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  const handleValue = (start_date, end_date) => {
    setDate({
      end_date: end_date,
      start_date: start_date
    });
  };

  const downloadAllFilesHandler = () => {
    const reqBody = {
      userIds: [Number(firmId)],
      username: firmUserName
    };

    dispatch(downloadFirmsFilesThunkAction(reqBody));
  };

  return (
    <div className="px-3">
      <div className="d-flex justify-content-between my-2">
        <h4>Firm Files</h4>
        <div className="d-flex ">
          <Button color="primary" className="px-3 py-2" onClick={handleOpen}>
            + Upload File
          </Button>
          <AddImage
            addNewImageHandler={addNewImageHandler}
            handleClose={handleClose}
            modalOpen={modalOpen}
          />
          <Button
            color="primary"
            className="px-3 py-2 back_to_survey_list ml-2"
            onClick={() => navigate(-1)}
          >
            <Icon icon="charm:chevron-left" color="white" className="mr-2" />
            Back
          </Button>
        </div>
      </div>

      <Row className="mt-4">
        <Col>
          {isLoading ? (
            <Fragment>
              <div className="post_error_loading">
                <Loader />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {error ? (
                <Fragment>
                  <div className="post_error_loading">{error}</div>
                </Fragment>
              ) : (
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{
                    borderRadius: "0.5rem"
                  }}
                >
                  <CardHeader
                    style={{
                      borderRadius: "0.5rem"
                    }}
                  >
                    <Row className="justify-content-between">
                      <h5 className="mt-2 ml-2">{firmUserName}</h5>{" "}
                      <div>
                        <Icon
                          icon="material-symbols:download-rounded"
                          color="#24B565"
                          width="20"
                          height="20"
                          className="mr-2"
                          style={{
                            cursor: "pointer"
                          }}
                          onClick={downloadAllFilesHandler}
                        />
                        <span id="TooltipExample">
                          <Icon
                            icon="ant-design:info-circle-outlined"
                            color="blue"
                            width="20"
                            height="20"
                          />
                        </span>

                        <Tooltip
                          isOpen={tooltipOpen}
                          placement="bottom"
                          target="TooltipExample"
                          toggle={() => {
                            setTooltipOpen(!tooltipOpen);
                          }}
                        >
                          Number of files - {fileData?.length}
                        </Tooltip>
                        <UncontrolledDropDown
                          dateFilter={date}
                          setDateFilter={setDate}
                          handleValue={handleValue}
                        />
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="row gallery">
                      {fileData?.map((file) => {
                        return (
                          <div className="col-lg-3 col-md-4 col-xs-6 thumb" key={file?.id}>
                            <div
                              key={file?.id}
                              className="card file-manager-item file"
                              onClick={() => downloadFileHandle(file?.url)}
                            >
                              <div
                                className="card-img-top file-logo-wrapper"
                                style={{ backgroundColor: "#F2F2F2" }}
                              >
                                <div className="dropdown float-end">
                                  <i
                                    data-feather="more-vertical"
                                    className="toggle-dropdown mt-n25"
                                  ></i>
                                </div>
                                <div className="d-flex align-items-center justify-content-center w-100">
                                  <img
                                    src={getFileType(file?.type)}
                                    alt="file-icon"
                                    className="img-fluid img-thumbnail"
                                  />
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="content-wrapper">
                                  <p className="card-text file-name mb-0">{file?.filename}</p>
                                </div>
                                <small className="file-accessed text-muted">
                                  created {moment(file?.created_at).fromNow()}
                                </small>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {!error && fileData?.length === 0 && (
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <i data-feather="alert-circle" className="me-50"></i>
                          No Results
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Fragment>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default FirmsFileManager;
