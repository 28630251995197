import {
  FETCH_FILES_DATA_REQUEST,
  FETCH_FILES_DATA_SUCCESS,
  FETCH_FILES_DATA_FAILURE
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  fileManagerData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FILES_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_FILES_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fileManagerData: action.payload.data,

        error: ""
      };
    case FETCH_FILES_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        fileManagerData: [],

        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
