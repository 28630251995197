export const SET_IS_SIGNING = "SET_IS_SIGNING";
export const RESET_IS_SIGNING = "RESET_IS_SIGNING";

export const SET_LOGGEDIN_USER = "SET_USER";
export const SET_LOGGEDOUT_USER = "SET_LOGGEDOUT_USER";

export const SET_FORGET_PASSWORD_USER_EMAIL = "SET_FORGET_PASSWORD_USER_EMAIL";
export const RESET_FORGET_PASSWORD_USER_EMAIL = "RESET_FORGET_PASSWORD_USER_EMAIL";

export const REFRESH_STATE = "REFRESH_STATE";

export const FETCH_USER_PROFILE_REQUEST = "FETCH_USER_PROFILE_REQUEST";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";

export const SET_USER_PROFILE_PERCENTAGE = "SET_USER_PROFILE_PERCENTAGE";
