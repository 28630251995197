import React from "react";

function Description({ descriptionData }) {
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;

  const formatTextWithLinks = (text) => {
    let urlTextLink = text.replace(urlRegex, (url) => {
      if (url.startsWith("www.")) {
        url = "http://" + url;
      }
      return ` <a href="${url}" target="_blank">${url}</a>`;
    });

    const decodedString = document.createElement("textarea");
    decodedString.innerHTML = urlTextLink;
    const formattedText = decodedString.value.replace(/•/g, "<br/>•");

    return formattedText;
  };
  return (
    <>
      <div className="mb-4 text-justify">
        {descriptionData ? (
          <div dangerouslySetInnerHTML={{ __html: formatTextWithLinks(descriptionData) }} />
        ) : (
          " "
        )}
      </div>
      {/* <div className="mb-4">
        <span>
          In order to access the bid documents, you will need to register your company on our
          web-based solution powered by Jaggaer:
        </span>
      </div>
      <div className="mb-4">
        <h6>Jaggaer Supplier Registration:</h6>
        <span>https://solutions.sciquest.com/apps/Router/SupplierLogin</span>
      </div>
      <div className="mb-4">
        <h6>Public Site URL:</h6>
        <span>https://bids.sciquest.com/apps/Router/PublicEvent?CustomerOrg=SUNY</span>
      </div>
      <div className="mb-4">
        <h6>Tutorial Video on registration process</h6>
        <span>https://www.youtube.com/watch?v=EPwSanSotkw</span>
      </div>
      <div className="mb-4">
        <h6>Current Bid Opportunities/Vendor Database Portal</h6>
        <span>
          https://www.asa.stonybrook.edu/Procurement/CurrentBidOpportunities/Vendors/Account/LogIn
        </span>
      </div>
      <div className="mb-4">
        <h6>Service-Disabled Veteran-Owned Set Aside:</h6>
        <span>No</span>
      </div>
      <div className="mb-4">
        <h6>Total MWBE Participation Goals:</h6>
        <span>0%</span>
      </div>
      <div className="mb-4">
        <h6>Service-Disabled Veteran-Owned Business Contracting Goal:</h6>
        <span>0%</span>
      </div>
      <div className="mb-4">
        <h6>Due date:</h6>
        <span>09/07/2023 3:00 PM</span>
      </div>
      <div className="mb-4">
        <h6>Contract term:</h6>
        <span>11-12-23 - 11-15-23</span>
      </div>
      <div className="mb-4">
        <h6>County(ies):</h6>
        <span>All NYS counties</span>
      </div>
      <div className="mb-4">
        <h6>Location:</h6>
        <span>Stony Brook University</span>
      </div>
      <div className="mb-4">
        <h6>
          Business entities awarded an identical or substantially similar procurement contract
          within the past five years:
        </h6>
        <span>N/A</span>
      </div> */}
    </>
  );
}

export default Description;
