import { Icon } from "@iconify/react";
import ZingChart from "components/Charts/ZingChart";
import { colors } from "data/mapData";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";

function MapModal({ open, closeModal, submit, oldData }) {
  const [state, setState] = useState("");
  const [result, setResult] = useState(oldData || []);

  useEffect(() => {
    if (open === true) {
      setResult(oldData || []);
    }
  }, [open, oldData]);

  function submitHandler(event) {
    submit(result);
    closeModalHandler();
    event.preventDefault();
  }

  function setRegionData(data) {
    let temp = [...result];
    if (temp.find((item) => item?.countyName === data?.countyName)) return;
    if (temp.find((item) => item?.stateName !== data?.stateName)) {
      temp = [];
    }
    temp.push(data);
    setResult(temp);
  }
  function closeModalHandler() {
    closeModal();
    setResult([]);
    setState("");
  }

  function changeColorOpacity(color, opacity) {
    // Check if the input color is in hexadecimal format (e.g., #RRGGBB)
    if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
      // Remove the '#' symbol if present
      color = color.slice(1);

      // Convert the hex color to RGB
      let r = parseInt(color.slice(0, 2), 16);
      let g = parseInt(color.slice(2, 4), 16);
      let b = parseInt(color.slice(4, 6), 16);

      // Ensure the opacity is within the range [0, 1]
      opacity = Math.min(1, Math.max(0, opacity));

      // Create a new RGBA color with the specified opacity
      const rgbaColor = `rgba(${r},${g},${b},${opacity})`;
      return rgbaColor;
    } else {
      console.error("Invalid color format. Please use hexadecimal color format (e.g., #RRGGBB).");
      return null;
    }
  }

  return (
    <Modal size="xl" isOpen={open} centered backdrop={"static"}>
      <Form onSubmit={submitHandler}>
        <ModalHeader toggle={closeModalHandler}>choose {state ? "County" : "State"}</ModalHeader>
        <ModalBody>
          <ZingChart state={state} setState={setState} setRegion={setRegionData} />
          {result.length > 0 && state ? (
            <div className="mx-3">
              <span>Your Selected County</span>
            </div>
          ) : result.length > 0 ? (
            <div className="mx-3">
              <span>
                Your Selected County and Region In <b>{result[0]?.stateName}</b> State
              </span>
            </div>
          ) : null}

          <div className="d-flex flex-wrap">
            {result.length > 0 &&
              result?.map((item, index) => (
                <div
                  key={index}
                  className="m-3"
                  style={{
                    borderRadius: "10px"
                  }}
                >
                  <div
                    className="p-3"
                    style={{
                      position: "relative",
                      backgroundColor: `${changeColorOpacity(colors[item?.regionName], 0.2)}`,
                      backgroundBlendMode: "lighten",
                      borderRadius: "10px"
                    }}
                  >
                    <span
                      className="mr-3"
                      style={{
                        color: `${colors[item?.regionName]}`,
                        opacity: "1"
                      }}
                    >
                      {item?.countyName}, {item?.regionName}
                    </span>
                    <Icon
                      icon="maki:cross"
                      onClick={() => {
                        let temp = [...result];
                        temp.splice(index, 1);
                        setResult(temp);
                      }}
                      color={`${colors[item?.regionName]}`}
                      style={{
                        cursor: "pointer"
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between" style={{ border: "0px" }}>
          {state ? (
            <Button
              color="btn"
              size="sm"
              type="button"
              onClick={() => setState("")}
              className="btn btn-secondary px-4 py-2 d-flex align-items-center"
            >
              <b> back</b>
            </Button>
          ) : (
            <div> </div>
          )}

          {/* {result?.countyName ? (
            <div>
              {result?.countyName}, {result?.regionName}, {result?.stateName}
            </div>
          ) : null} */}

          <Button
            color="btn"
            type="submit"
            disabled={!result?.length > 0}
            className="btn btn-primary px-4 py-2 d-flex align-items-center"
          >
            <b> Apply</b>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default MapModal;
