import { useState, useEffect, Fragment, React } from "react";
import "./AdminFileManager.css";
import { Button, Card, CardBody, CardHeader, Col, Row, Tooltip } from "reactstrap";
import { Icon } from "@iconify/react";
import UncontrolledDropDown from "components/UncontrolledDropDown/UncontrolledDropDown";
import "./FileManager.css";
import { useDispatch, useSelector } from "react-redux";
import { selectAdminFileManager } from "redux/adminfileManager/selectors";
import { selectUserToken } from "redux/user/selectors";
import { fetchFilesListingThunkAction } from "redux/adminfileManager/actions";
import moment from "moment";
import { getFile } from "utils/roles";
import Paginate from "components/Pagination/Pagination";
import Loader from "components/Loader/Loader";
import Search from "components/Search/Search";
import { useNavigate } from "react-router-dom";
import FileManagerDropDown from "components/FileManagerDropDown/FileManagerDropDown";
import { downloadFirmsFilesThunkAction } from "redux/adminfileManager/actions";
import { env } from "../../env";
import toast from "react-hot-toast";

function AdminFileManager() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { AdminFileManagerData, isLoading, PageCount, totalRecords } =
    useSelector(selectAdminFileManager);
  const token = useSelector(selectUserToken) || localStorage.getItem("token");

  const [maxFileLimit, setMaxFileLimit] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectFirms, setSelectFirms] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [search, setsSearch] = useState("");
  const [date, setDate] = useState({
    end_date: "",
    start_date: ""
  });
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const nextPage = () => {
    setCurrentPage((prePage) => prePage + 1);
  };

  const previousPage = () => {
    setCurrentPage((prePage) => prePage - 1);
  };

  useEffect(() => {
    if (token) {
      dispatch(
        fetchFilesListingThunkAction(
          {
            offset: currentPage,
            search: search,
            filter: selectFirms
          },
          onSuccess,
          onError
        )
      );
    }
  }, [token, date, currentPage, search, selectFirms]);

  useEffect(() => {
    setFileData(AdminFileManagerData);
  }, [AdminFileManagerData]);

  const onSearchHandler = (keyword) => {
    setCurrentPage(0);
    setsSearch(keyword);
  };

  const goToFirmsFileListingPage = (firmId) => {
    navigate(`/admin/firms-files/${firmId}`);
  };
  const handleFirmValue = (role) => {
    setSelectFirms(role);
    setCurrentPage(0);
  };

  const maxFileLimitHandler = (event, firm) => {
    event.stopPropagation();

    const isAlreadyExist = maxFileLimit.findIndex((item) => item?.id === firm?.id);

    if (isAlreadyExist !== -1) {
      setMaxFileLimit((preData) => preData.filter((item) => item?.id !== firm?.id));
    } else {
      const currentNumberOfFiles = maxFileLimit.reduce((acc, item) => {
        return acc + item?.total_files;
      }, 0);

      const newTotal = firm?.total_files + currentNumberOfFiles;

      if (
        newTotal <=
        Number(env.REACT_APP_MAX_FILE_DOWNLOAD || process.env.REACT_APP_MAX_FILE_DOWNLOAD || 100)
      ) {
        setMaxFileLimit((preData) => [...preData, firm]);
      } else {
        toast.error("You can select upto 100 files at a time.");
      }
    }
  };

  const downloadFolderZipHandler = (isFolderSpecific, event, firmId, fileName) => {
    event.stopPropagation();
    if (!isFolderSpecific && maxFileLimit.length === 0) {
      toast.error("Please select at least one firm to download the files.");
      return;
    }
    const reqBody = {
      userIds: isFolderSpecific ? [Number(firmId)] : maxFileLimit.map((item) => Number(item?.id)),
      username: isFolderSpecific
        ? [fileName]
        : maxFileLimit?.length === 1
        ? [maxFileLimit[0]?.username]
        : ["Firms"]
    };

    dispatch(
      downloadFirmsFilesThunkAction(reqBody, () => {
        if (!isFolderSpecific) {
          setMaxFileLimit([]);
        }
      })
    );
  };

  return (
    <div className="px-3">
      <Row className="justify-content-between align-items-center">
        <Col xs="12" md="6">
          <h4>Firm Files</h4>
        </Col>
        <Col xs="12" md="3">
          <Row>
            <span className="m-2">Select List Type : </span>
            <FileManagerDropDown handleFirmValue={handleFirmValue} />
          </Row>
        </Col>
        <Col xs="12" md="3">
          <Search onSubmitHandler={onSearchHandler} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card
            className="card-stats mb-4 mb-xl-0"
            style={{
              borderRadius: "0.5rem"
            }}
          >
            <CardHeader
              style={{
                borderRadius: "0.5rem"
              }}
            >
              <Row className="justify-content-between">
                <h5 className="mt-2 ml-2">Files</h5>
                <div className="mt-2 mr-4">
                  <Row className="align-items-center">
                    <Icon
                      icon="material-symbols:download-rounded"
                      color="#24B565"
                      width="20"
                      height="20"
                      className="mr-1"
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={(e) => downloadFolderZipHandler(false, e)}
                    />
                    {maxFileLimit.length ? (
                      <span className="mr-2">
                        ({maxFileLimit.reduce((acc, item) => acc + item?.total_files, 0)})
                      </span>
                    ) : null}

                    <span id="TooltipExample">
                      <Icon
                        icon="ant-design:info-circle-outlined"
                        color="blue"
                        width="20"
                        height="20"
                      />
                    </span>

                    <Tooltip
                      isOpen={tooltipOpen}
                      placement="bottom"
                      target="TooltipExample"
                      toggle={() => {
                        setTooltipOpen(!tooltipOpen);
                      }}
                    >
                      Number of folders - {totalRecords}
                    </Tooltip>
                  </Row>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Fragment>
                  <div className="post_error_loading">
                    <Loader />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {error ? (
                    <Fragment>
                      <div className="post_error_loading mb-5">{error}</div>
                    </Fragment>
                  ) : (
                    <>
                      <div className="row gallery">
                        {fileData?.map((file) => {
                          return (
                            <div className="col-lg-3 col-md-4 col-xs-6 thumb" key={file?.id}>
                              <div
                                className="card file-manager-item file  "
                                onClick={() => goToFirmsFileListingPage(file?.id)}
                              >
                                <div className="form-check ml-2 mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input largerCheckbox"
                                    id="customCheck7"
                                    onChange={() => {}}
                                    onClick={(e) => maxFileLimitHandler(e, file)}
                                    checked={
                                      maxFileLimit?.findIndex((item) => item?.id === file?.id) !==
                                      -1
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customCheck7"
                                  ></label>
                                </div>
                                <div
                                  className="card-img-top file-logo-wrapper"
                                  style={{ backgroundColor: "#F2F2F2" }}
                                >
                                  <div className="dropdown float-end">
                                    <i
                                      data-feather="more-vertical"
                                      className="toggle-dropdown mt-n25"
                                    ></i>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center w-100">
                                    <img
                                      src={getFile(file?.role_id)}
                                      alt="file-icon"
                                      className="img-fluid img-thumbnail"
                                    />
                                  </div>
                                </div>
                                <div className="card-body">
                                  <Row className="justify-content-between align-items-center">
                                    <Col>
                                      <small className="file-accessed text-muted">
                                        {file?.govt_name ? `( ${file?.govt_name} )` : <br />}
                                      </small>
                                      <div className="content-wrapper">
                                        <p className="card-text file-name mb-0">{file?.username}</p>
                                      </div>
                                      <small className="file-accessed text-muted">
                                        Last Update {moment(file?.created_at).fromNow()}
                                      </small>
                                    </Col>

                                    <Icon
                                      icon="material-symbols:download-rounded"
                                      color="#24B565"
                                      width="20"
                                      height="20"
                                      className="ms-5 mr-1"
                                      style={{
                                        cursor: "pointer"
                                      }}
                                      onClick={(e) =>
                                        downloadFolderZipHandler(true, e, file?.id, file?.username)
                                      }
                                    />
                                    <span className="mr-2"> ({file?.total_files})</span>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <nav aria-label="..." className="post_footer">
                          <Paginate
                            pageIndex={currentPage}
                            pageCount={PageCount}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            gotoPage={(page) => {
                              setCurrentPage(page);
                            }}
                          />
                        </nav>
                      </div>
                    </>
                  )}
                  {!error && fileData?.length === 0 ? (
                    <Fragment>
                      <div className="post_error_loading mb-5">No firms found.</div>
                    </Fragment>
                  ) : null}
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AdminFileManager;
